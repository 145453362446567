import React, { useState } from "react";

import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";

export default function DeletePage({ getPages, deletePage }) {
  const [loader, setLoader] = useState(false);

  async function handlePageDelete(id) {
    setLoader(true);

    try {
      const response = await apiClient.delete(`/page/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response.status === 200) {
        setLoader(false);

        toastMessage("Sayfa Silindi", "success");
        getPages();

        document.getElementById("deletePageModalCloseButton").click();
      }
    } catch (error) {
      setLoader(false);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  return (
    <div className="d-flex gap-3">
      <button
        type="button"
        className="btn btn-outline-danger w-100"
        onClick={() => handlePageDelete(deletePage?.id)}
      >
        Sil
      </button>

      <button
        type="button"
        className="btn btn-outline-theme w-100"
        data-bs-dismiss="modal"
      >
        İptal
      </button>

      {loader && (
        <div
          className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
          style={{ background: "rgba(29, 40, 53, 0.95)", zIndex: 99 }}
        >
          <div className="spinner-border text-theme"></div>
        </div>
      )}
    </div>
  );
}

import React, { useState } from "react";
import toastMessage from "../ToastMessage";
import apiClient from "../../ApiClient";

export default function TalepFormu() {
  const [konu, setKonu] = useState("");
  const [aciklama, setAciklama] = useState("");

  const handleSubmit = async () => {
    if (!konu || !aciklama) {
      toastMessage("Lütfen tüm alanları doldurun.", "error");
    } else {
      /*
      Gizli olacaklar
      Müşteri Adı
      Kullanıcı Adı
      Tarih Saat
      */
      const formData = { title: konu, description: aciklama };

      try {
        await apiClient.customPost(`/request-form`, formData, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        toastMessage("Talebiniz Gönderildi", "success");
        setKonu("");
        setAciklama("");
      } catch (error) {
        toastMessage("Talebiniz Gönderilemedi", "error");
        console.error(error);
      }
    }
  };

  return (
    <div>
      <div>
        <div className="mb-3">
          <span>Başlık</span>
          <input
            type="text"
            className="form-control"
            value={konu}
            onChange={(e) => setKonu(e.target.value)}
          />
        </div>

        <div className="mb-3">
          <span>Açıklama</span>
          <textarea
            className="form-control"
            value={aciklama}
            onChange={(e) => setAciklama(e.target.value)}
            style={{ height: 400 }}
          />
        </div>
      </div>

      <div className="d-flex gap-3">
        <button
          type="button"
          className="btn btn-outline-theme w-100"
          data-bs-dismiss="modal"
        >
          İptal
        </button>

        <button
          type="button"
          className="btn btn-theme w-100"
          onClick={handleSubmit}
        >
          Gönder
        </button>
      </div>
    </div>
  );
}

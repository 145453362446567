import React, { useContext, useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../ToastMessage";

import { Card, CardBody, CardExpandToggler } from "../card/card";

export default function CardVatandasSayisi({
  title = "Güncel",
  show = true,
  setStreetVoterData = false,
  setNeighborhoodVoterData = false,
  setNeighborhoodAverageAgeData = false,
  graphic,
  city,
  district,
  neighbourhood,
  street,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [data, setData] = useState();

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Hemsehrilik Göç",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Dogum Tarihi",
                value: "select",
              },
            ],
            condition: [
              city
                ? {
                    key: "Adres Il",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              street
                ? {
                    key: "Adres Sokak",
                    value: context
                      .removeTurkishCharacters(street)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        const currentYear = new Date().getFullYear();

        const ages = response.map((date) => {
          const year = date["dogum tarihi"].split(".")[2];
          return currentYear - year;
        });

        const totalAges = ages.reduce((total, age) => total + age, 0);

        const averageAge = totalAges / ages.length;

        setData(parseInt(averageAge));

        if (setNeighborhoodAverageAgeData) {
          setNeighborhoodAverageAgeData(parseInt(averageAge));
        }
      } else {
        setData([]);
      }
    } catch (error) {
      console.error("Medeni Durum Error", error);
      setData(null);
    }
  }

  useEffect(() => {
    // if (city) {
    getData();
    getTableData(3, district, neighbourhood);
    // }
  }, [city, district, neighbourhood, street]);

  //--

  const [totalVoters, setTotalVoters] = useState(null);

  async function getTableData(graphic, district, neighborhood) {
    if (graphic === 3) {
      setTotalVoters(null);
    }

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Vatandaş Sayısı",
          type: `hane-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Hanedeki Secmen Sayisi",
                value: "sum",
              },
              {
                key: "Ilk Defa Oy Kullanacak Secmen Sayisi(17)",
                value: "sum",
              },
            ],
            condition: [
              city
                ? {
                    key: "Adres Il",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Adres Ilcesi",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Adres Mahallesi",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (graphic === 3) {
        if (response.length !== 0) {
          setTotalVoters(response);
        } else {
          setTotalVoters([]);
        }

        if (setNeighborhoodVoterData) {
          if (response.length !== 0) {
            setNeighborhoodVoterData(response);
          } else {
            setNeighborhoodVoterData(null);
          }
        }

        if (setStreetVoterData) {
          if (response.length !== 0) {
            setStreetVoterData(response);
          } else {
            setStreetVoterData(null);
          }
        }

        // setTotalVoters(response.data[0]);
      }
    } catch (error) {
      console.error("Map Table Error", error);
      if (graphic === 3) {
        setTotalVoters(null);
      }
    }
  }

  return (
    show && (
      <div className="col">
        <Card className="h-100">
          <CardBody>
            {data !== "filter" ? (
              totalVoters ? (
                totalVoters[0]["hanedeki secmen sayisi_sum"] !== 0 &&
                totalVoters[0][
                  "ilk defa oy kullanacak secmen sayisi(17)_sum"
                ] !== 0 ? (
                  <>
                    {title !== "" && (
                      <div className="d-flex fw-bold h6 mb-3 text-center">
                        <span className="flex-grow-1">
                          {"Güncel".toUpperCase()}
                        </span>
                      </div>
                    )}

                    <div className="row align-items-center">
                      <div className={"col-12"}>
                        <div className={`lg text-inverse text-opacity-100`}>
                          <div className="text-center ">
                            Toplam Seçmen
                            <br />
                            <strong className="h5">
                              {totalVoters ? (
                                totalVoters[0].length !== 0 ? (
                                  <CountUp
                                    end={
                                      totalVoters[0][
                                        "hanedeki secmen sayisi_sum"
                                      ] || 0
                                    }
                                  />
                                ) : (
                                  "Bilinmiyor"
                                )
                              ) : (
                                <div className="spinner-border text-theme"></div>
                              )}
                            </strong>{" "}
                          </div>

                          <div className="text-center pt-2">
                            İlk Defa Oy Kullanacak Seçmen Sayısı
                            <br />
                            <strong className="h5">
                              {totalVoters ? (
                                totalVoters[0].length !== 0 ? (
                                  <CountUp
                                    end={
                                      totalVoters[0][
                                        "ilk defa oy kullanacak secmen sayisi(17)_sum"
                                      ] || 0
                                    }
                                  />
                                ) : (
                                  "Bilinmiyor"
                                )
                              ) : (
                                <div className="spinner-border text-theme"></div>
                              )}
                            </strong>{" "}
                          </div>

                          <div className="text-center pt-2">
                            Ortalama Seçmen Yaşı
                            <br />
                            <strong className="h5">
                              <CountUp end={data || 0} />
                            </strong>{" "}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    Analiz Verisi Eklenmemiştir
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">İl Seçmelisiniz</span>
            )}
          </CardBody>
        </Card>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}

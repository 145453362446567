import React, { useState, useEffect, useContext } from "react";

import { ToastContainer } from "react-toastify";
import moment from "moment";
import pdfMake, { log } from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import html2canvas from "html2canvas";

import logo from "../../images/logo.png";

import PageFilter from "../../components/PageFilter.jsx";
import toastMessage from "../../components/ToastMessage.js";
import { Card, CardBody } from "../../components/card/card.jsx";
import dernekMuhtarlikJson from "../../json/dernek_ve_muhtarlik.json";
import siyasetmetreSecimJson from "../../json/siyasetmetre_secim.json";
import bireyselJson from "../../json/bireysel.json";

import StratejiHaritasi from "../strateji-haritasi/index.jsx";
import CardBinaHane from "../../components/info-cards/CardBinaHane.jsx";
import CardOlumSayisi from "../../components/info-cards/CardOlumSayisi.jsx";
import CardVatandasSayisi from "../../components/info-cards/CardVatandasSayisi.jsx";
import ChartBarTimeLine from "../../components/echart/ChartBarTimeLine.jsx";
import ChartBar from "../../components/echart/ChartBar.jsx";
import ChartPie from "../../components/echart/ChartPie.jsx";
import ChartLineMultiple from "../../components/echart/ChartLineMultiple.jsx";
import apiClient from "../../ApiClient.js";
import EChartExample12 from "../../components/echart/echart12.jsx";
import SecimAnaliziTable2 from "../siyasi-analiz/secim-analizi/SecimAnaliziTable2.jsx";
import GetNote from "./GetNote.jsx";
import CardMedeniDurum from "../../components/info-cards/CardMedeniDurum.jsx";
import Anasayfa from "../anasayfa/index.jsx";
import SandikAnalizi from "../siyasi-analiz/sandik-analizi/index.jsx";
import DinamikPage from "../dinamik-page/index.jsx";
import DynamicCard from "../../components/DynamicCard.jsx";
import { AppSettings } from "../../config/app-settings.js";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function HazirRaporlar({
  justPDF = false,
  raporFilter = false,
}) {
  const context = useContext(AppSettings);

  const [pdfDataUrl, setPdfDataUrl] = useState("");
  const [showPdfNeighborhood, setShowPdfNeighborhood] = useState(false);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(false);

  const [pdfDataUrlStreet, setPdfDataUrlStreet] = useState("");
  const [showPdfStreet, setShowPdfStreet] = useState(false);
  const [streetLoader, setStreetLoader] = useState(false);

  //--

  // const [wordsImage, setWordsImage] = useState(false);

  // const handleScreenshot = (data) => {
  //   const imageItem = document.getElementById("words-item");

  //   if (imageItem) {
  //     setTimeout(() => {
  //       html2canvas(imageItem).then((canvas) => {
  //         const imageURL = canvas.toDataURL();
  //         setWordsImage(imageURL);
  //       });
  //     }, 5000);
  //   }
  // };

  //--

  const [logoData, setLogoData] = useState("");

  const imageToBase64 = (imagePath, type) => {
    fetch(imagePath)
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          if (type === "logo") {
            setLogoData(reader.result);
          }
        };
        reader.onerror = (error) => {
          console.error("Error reading image:", error);
        };
        reader.readAsDataURL(blob);
      })
      .catch((error) => {
        console.error("Error fetching image:", error);
      });
  };

  useEffect(() => {
    imageToBase64(logo, "logo");
  }, []);

  //--

  // STREET DATA
  const [streetAge2Data, setStreetAge2Data] = useState(null);

  const [streetAgeData, setStreetAgeData] = useState(null);
  const [streetVoterProfileData, setStreetVoterProfileData] = useState(null);
  const [streetWordsData, setStreetWordsData] = useState(null);
  const [wordsChartData, setWordsChartData] = useState(null);

  const [streetChestData, setStreetChestData] = useState(null);

  const [streetPoliticalProfileData, setStreetPoliticalProfileData] =
    useState(null);

  async function getStreetData(graphic, district, street) {
    try {
      const response = await apiClient.get(
        `/${"street-analysis"}?graphic=${graphic}${40 ? `&city=${40}` : ""}${
          district ? `&district=${district}` : ""
        }${street ? `&street=${street}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        if (graphic === 8) {
          if (response.data[0].length !== 0) {
            const reportTableWidth = [];
            const reportTableKey = [];
            const reportTableValue = [];

            response.data[0].forEach((item) => {
              reportTableWidth.push("*");
              reportTableKey.push(bireyselJson.Secmen_Yasi[item.Parametre]);
              reportTableValue.push(item.Yuzde);
            });

            setStreetAgeData([
              reportTableWidth,
              reportTableKey,
              reportTableValue,
            ]);
          } else {
            setStreetAgeData([]);
          }
        }

        if (graphic === 9) {
          if (response.data[0].length !== 0) {
            const reportTableWidth = [];
            const reportTableKey = [];
            const reportTableValue = [];

            response.data[0].forEach((item) => {
              reportTableWidth.push("*");
              reportTableKey.push(
                bireyselJson.Secmen_Profili[item.Parametre] || "Bilinmiyor"
              );
              reportTableValue.push(item.Yuzde);
            });

            setStreetVoterProfileData([
              reportTableWidth,
              reportTableKey,
              reportTableValue,
            ]);
          } else {
            setStreetVoterProfileData([]);
          }
        }

        if (graphic === 10) {
          if (response.data[0].length !== 0) {
            const reportTableWidth = ["*", "*"];
            const reportTableKey = ["IL - MEMLEKET ILCE", "ORAN"];
            const reportTableValue = [reportTableKey];

            response.data[0].forEach((item) => {
              reportTableValue.push([
                bireyselJson.Memleket_Il[
                  parseInt(item.Parametre.split(" - ")[0])
                ] +
                  " - " +
                  bireyselJson.Memleket_Ilce[
                    parseInt(item.Parametre.split(" - ")[1])
                  ],
                item.Yuzde,
              ]);
            });

            setWordsChartData(response.data);
            setStreetWordsData([reportTableWidth, reportTableValue]);
          } else {
            setWordsChartData([]);
            setStreetWordsData([]);
          }
        }
      }
    } catch (error) {
      console.error("SOKAK ANALİZİ", error);
    }
  }

  async function getStreetAgeData(graphic, district, street) {
    if (graphic === 7) {
      setStreetAge2Data(null);
    }

    try {
      const response = await apiClient.get(
        `/voter-analysis?graphic=${graphic}&city=40${
          district ? `&district=${district}` : ""
        }${street ? `&street=${street}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        if (graphic === 7) {
          setStreetAge2Data(response.data[0][0]);
        }
      }
    } catch (error) {
      console.error("Map Table Error", error);

      if (graphic === 7) {
        setStreetAge2Data(null);
      }
    }
  }

  async function getAgeData(city, district, neighbourhood, street) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: `hane-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "(0-4) Yas Arasi",
                value: "sum",
              },
              {
                key: "(5-6) Yas Arasi",
                value: "sum",
              },
              {
                key: "(5-9) Yas Arasi",
                value: "sum",
              },
              {
                key: "(10-14) Yas Arasi",
                value: "sum",
              },
              {
                key: "(15-19) Yas Arasi",
                value: "sum",
              },
              {
                key: "(18-24) Yas Arasi",
                value: "sum",
              },
              {
                key: "(20-24) Yas Arasi",
                value: "sum",
              },
              {
                key: "(25-29) Yas Arasi",
                value: "sum",
              },
              {
                key: "(30-34) Yas Arasi",
                value: "sum",
              },
              {
                key: "(35-39) Yas Arasi",
                value: "sum",
              },
              {
                key: "(40-44) Yas Arasi",
                value: "sum",
              },
              {
                key: "(45-49) Yas Arasi",
                value: "sum",
              },
              {
                key: "(50-54) Yas Arasi",
                value: "sum",
              },
              {
                key: "(55-59) Yas Arasi",
                value: "sum",
              },
              {
                key: "(60-64) Yas Arasi",
                value: "sum",
              },
              {
                key: "(+65) Yas",
                value: "sum",
              },
            ],
            condition: [
              city
                ? {
                    key: "Adres Il",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Adres Ilcesi",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Adres Mahallesi",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              street
                ? {
                    key: "Adres Sokagi",
                    value: context
                      .removeTurkishCharacters(street)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setStreetAge2Data(response);
    } catch (error) {
      console.error("Age Error", error);
    }
  }

  async function getSecmenProfili(city, district, neighbourhood, street) {
    const conditionData = [
      city
        ? {
            key: "Adres Il",
            value: context.removeTurkishCharacters(city).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      district
        ? {
            key: "Adres Ilce",
            value: context.removeTurkishCharacters(district).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      neighbourhood
        ? {
            key: "Adres Mahalle",
            value: context.removeTurkishCharacters(neighbourhood).toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
      street
        ? {
            key: "Adres Sokak",
            value: street.toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
    ];
    const conditionDataFiltered = conditionData.filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 48,
          title: "Sandık Analizi",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Secmen Profili",
                value: "select",
              },
              {
                key: "Secmen_Profili",
                value: "count",
              },
            ].filter((item) => item !== null),
            condition: conditionDataFiltered,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        const reportTableWidth = [];
        const reportTableKey = [];
        const reportTableValue = [];

        response.forEach((item) => {
          reportTableWidth.push("*");
          reportTableKey.push(item["secmen profili"]);
          reportTableValue.push(item["secmen_profili_count"] + " Kişi");
        });

        setStreetVoterProfileData([
          reportTableWidth,
          reportTableKey,
          reportTableValue,
        ]);
      } else {
        setStreetVoterProfileData([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function getHemsehrilikDurumu(city, district, neighbourhood, street) {
    const conditionData = [
      city
        ? {
            key: "Adres Il",
            value: context.removeTurkishCharacters(city).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      district
        ? {
            key: "Adres Ilce",
            value: context.removeTurkishCharacters(district).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      neighbourhood
        ? {
            key: "Adres Mahalle",
            value: context.removeTurkishCharacters(neighbourhood).toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
      street
        ? {
            key: "Adres Sokak",
            value: street.toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
    ];
    const conditionDataFiltered = conditionData.filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 48,
          title: "-",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Memleket Il",
                value: "select",
              },
              {
                key: "Memleket Ilce",
                value: "select",
              },
              {
                key: "TC",
                value: "select",
              },
            ].filter((item) => item !== null),
            condition: conditionDataFiltered,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        const reportTableWidth = ["*", "*"];
        const reportTableKey = ["IL - MEMLEKET ILCE", "ORAN"];
        const reportTableValue = [reportTableKey];

        const hemsehrilikDurumuMap = new Map();
        const hemsehrilikDurumuOptions = [];

        response.forEach((item) => {
          const value = item["memleket il"] + " - " + item["memleket ilce"];
          hemsehrilikDurumuMap.set(
            value,
            (hemsehrilikDurumuMap.get(value) || 0) + 1
          );
        });

        hemsehrilikDurumuMap.forEach((count, value) => {
          hemsehrilikDurumuOptions.push({
            value: count,
            category: value,
          });

          reportTableValue.push([value, count]);
        });

        setStreetWordsData([reportTableWidth, reportTableValue]);
      } else {
        setStreetWordsData([]);
      }
    } catch (error) {
      console.error(error);
    }
  }

  //--

  function removeHTMLTags(inputString) {
    var doc = new DOMParser().parseFromString(inputString, "text/html");
    return doc.body.textContent || "";
  }

  const [neighborhoodTodosData, setNeighborhoodTodosData] = useState(null);
  const [neighborhoodTodosImagesData, setNeighborhoodTodosImagesData] =
    useState(null);

  const [streetTodosData, setStreetTodosData] = useState(null);

  async function getTodos(name, city, district, neighbourhood, street) {
    try {
      const response = await apiClient.get(
        `/todos?city=${city}&district=${district}&neighbourhood=${neighbourhood}${
          street ? `${name === "street" ? `&street=${street}` : ""}` : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        if (response.data.length !== 0) {
          const todosResult = [];
          const todosImages = {};

          response.data.forEach((element) => {
            if (element.image) {
              element.image.forEach((url, index) => {
                const key = `image${index + 1}`;
                todosImages[key] = url;
              });
            }

            todosResult.push(
              ["Kategori: " + element.category.name],
              ["Başlık: " + element.title],
              [
                "Açıklama: " +
                  (element.description
                    ? removeHTMLTags(element.description)
                    : "-"),
              ],
              [
                "Yönetici Özeti: " +
                  (element.summary ? removeHTMLTags(element.summary) : "-"),
              ]
              // [
              //   {
              //     columns: [
              //       element.image[0] && {
              //         image: "image1",
              //       },
              //       element.image[1] && {
              //         image: "image2",
              //       },
              //     ],
              //     columnGap: 10,
              //   },
              // ]
            );
          });

          if (name === "neighbourhood") {
            setNeighborhoodTodosImagesData(todosImages);
            setNeighborhoodTodosData(todosResult);
          }

          if (name === "street") {
            setStreetTodosData(todosResult);
          }
        } else {
          if (name === "neighbourhood") {
            setNeighborhoodTodosData([]);
          }

          if (name === "street") {
            setStreetTodosData([]);
          }
        }
      }
    } catch (error) {
      console.error("Todo Error", error);
    }
  }

  //--

  const [reportTypeNeighborhood, setReportTypeNeighborhood] = useState(null);
  const [reportType, setReportType] = useState(null);

  const [cityData, setCityData] = useState(null);
  const [districtData, setDistrictData] = useState(null);
  const [neighborhoodData, setNeighborhoodData] = useState(null);
  const [streetData, setStreetData] = useState(null);

  const [getChartImages, setGetChartImages] = useState(
    context?.me?.customer?.settings?.secimAnalizi ? [] : null
  );
  const [baskanlikSecim2Data, setBaskanlikSecim2Data] = useState(null);
  const [voteChartImage12, setVoteChartImage12] = useState([]);

  const [voteChartImage1, setVoteChartImage1] = useState(null);
  const [voteChartImage2, setVoteChartImage2] = useState(null);
  const [voteChartImage3, setVoteChartImage3] = useState(null);
  const [voteChartImage4, setVoteChartImage4] = useState(null);
  const [voteChartImage5, setVoteChartImage5] = useState(null);
  const [voteChartImage6, setVoteChartImage6] = useState(null);
  const [voteChartImage7, setVoteChartImage7] = useState(null);
  const [voteChartImage8, setVoteChartImage8] = useState(null);
  const [voteChartImage9, setVoteChartImage9] = useState(null);
  const [voteChartImage10, setVoteChartImage10] = useState(null);
  const [voteChartImage11, setVoteChartImage11] = useState(null);

  const [headmanData, setHeadmanData] = useState(null);
  const [dernekler, setDernekler] = useState(null);
  const [sendikalar, setSendikalar] = useState(null);
  const [neighborhoodVoterData, setNeighborhoodVoterData] = useState(null);
  const [streetVoterData, setStreetVoterData] = useState(null);
  const [neighborhoodAverageAgeData, setNeighborhoodAverageAgeData] =
    useState(null);
  const [sokakBinaCountData, setSokakBinaCountData] = useState(null);
  const [neighborhoodCountData, setNeighborhoodCountData] = useState(null);
  const [streetCountData, setStreetCountData] = useState(null);
  const [neighborhoodDeathBirthData, setNeighborhoodDeathBirthData] =
    useState(null);
  const [neighborhoodEducationData, setNeighborhoodEducationData] =
    useState(null);
  // const [neighborhoodEducationNote, setNeighborhoodEducationNote] =
  //   useState(null);
  const [neighborhoodAgeData, setNeighborhoodAgeData] = useState(null);
  const [neighborhoodCitizenshipData, setNeighborhoodCitizenshipData] =
    useState(null);
  const [neighborhoodSocioEconomicData, setNeighborhoodSocioEconomicData] =
    useState(null);
  const [neighborhoodDeadthBirthData, setNeighborhoodDeadthBirthData] =
    useState(null);
  const [neighborhoodMaritalData, setNeighborhoodMaritalData] = useState(null);
  const [neighborhoodVoterPoliticalData, setNeighborhoodVoterPoliticalData] =
    useState(null);
  const [neighborhoodVoterTypeData, setNeighborhoodVoterTypeData] =
    useState(null);
  const [
    neighborhoodPoliticalProfileData,
    setNeighborhoodPoliticalProfileData,
  ] = useState(null);

  //--

  async function fetchBaskanlikSecim(city, district, neighbourhood) {
    setBaskanlikSecim2Data(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: `2-baskanlık-secim-tur-2`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Aday",
                value: "select",
              },
              {
                key: "Deger",
                value: "avg",
              },
            ],
            condition: [
              city
                ? {
                    key: "Il",
                    value: context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        setBaskanlikSecim2Data(
          response.map((item) => {
            return {
              value: item.deger_avg.toFixed(2),
              name: item.aday,
            };
          })
        );
      } else {
        setBaskanlikSecim2Data([]);
      }
    } catch (error) {
      console.error("2. Başkanlık Seçim Error", error);
      setBaskanlikSecim2Data(null);
    }
  }

  //--

  const fetchMuhtarlar = async (city, district, neighbourhood) => {
    setHeadmanData(null);

    const conditionData = [
      {
        key: "Il adı",
        value: city,
        operator: "=",
        type: "and",
      },
      {
        key: "IlCe Adı",
        value: district,
        operator: "=",
        type: "and",
      },
      {
        key: "Mahalle Adı",
        value: neighbourhood,
        operator: "=",
        type: "and",
      },
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "-",
          type: "muhtarlik-data",
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "Muhtar Adı",
                value: "select",
              },
              {
                key: "MuhtarTelefonu",
                value: "select",
              },
              {
                key: "Muhtarlık Adı",
                value: "select",
              },
              {
                key: "Il adı",
                value: "select",
              },
              {
                key: "IlCe Adı",
                value: "select",
              },
              {
                key: "Mahalle Adı",
                value: "select",
              },
              {
                key: "Adres",
                value: "select",
              },
              {
                key: "Longitude",
                value: "select",
              },
              {
                key: "Latitude",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        setHeadmanData(response);
      } else {
        setHeadmanData([]);
      }
    } catch (error) {
      setHeadmanData([]);
      console.error(error);
    }
  };

  //--

  const fetchDernekler = async (city, district, neighbourhood) => {
    setDernekler([[], [[], []]]);

    const conditionData = [
      {
        key: "Il",
        value: city,
        operator: "=",
        type: "and",
      },
      {
        key: "Ilce",
        value: district,
        operator: "=",
        type: "and",
      },
      {
        key: "Mahalle",
        value: neighbourhood,
        operator: "=",
        type: "and",
      },
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "-",
          type: "dernekler-data",
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "Il",
                value: "select",
              },
              {
                key: "Ilce",
                value: "select",
              },
              {
                key: "Mahalle",
                value: "select",
              },
              {
                key: "Kurum Adres",
                value: "select",
              },
              {
                key: "Faaliyet Alani",
                value: "select",
              },
              {
                key: "Kurum Adi",
                value: "select",
              },
              {
                key: "Telefon",
                value: "select",
              },
              {
                key: "Detayli Faaliyet Alani",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        const transformedData = [
          ["auto", "auto", "auto", "*", "*", "*", "auto", "*"],
        ];

        const headers = [
          "ŞEHİR",
          "İLÇE",
          "MAHALLE",
          "KURUM ADI",
          "TELEFON",
          "FAALİYET ALANI",
          "DETAYLI FAALİYET ALANI",
          "KURUM ADRES",
        ];
        const rows = response.map((item) => {
          return [
            item?.il || "Bilinmiyor",
            item?.ilce || "Bilinmiyor",
            item?.mahalle || "Bilinmiyor",
            item["kurum adi"] || "Bilinmiyor",
            item?.telefon || "Bilinmiyor",
            item["faaliyet alani"] || "Bilinmiyor",
            item["detayli faaliyet alani"] || "Bilinmiyor",
            item["kurum adres"] || "Bilinmiyor",
          ];
        });

        transformedData.push([headers, ...rows]);
        setDernekler(transformedData);
      } else {
        setDernekler([[], [[], []]]);
      }
    } catch (error) {
      setDernekler([[], [[], []]]);
      console.error(error);
    }
  };

  //--

  const fetchSendikalar = async (city, district, neighbourhood) => {
    setSendikalar([[], [[], []]]);

    const conditionData = [
      {
        key: "IL",
        value: city,
        operator: "=",
        type: "and",
      },
      {
        key: "ILCE",
        value: district,
        operator: "=",
        type: "and",
      },
      {
        key: "Mah",
        value: neighbourhood,
        operator: "=",
        type: "and",
      },
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "-",
          type: "sendikalar-data",
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "SAYI",
                value: "select",
              },
              {
                key: "IL",
                value: "select",
              },
              {
                key: "ILCE",
                value: "select",
              },
              {
                key: "Mah",
                value: "select",
              },
              {
                key: "SendIka AdresI",
                value: "select",
              },
              {
                key: "Sendika Is Kolu",
                value: "select",
              },
              {
                key: "Sendika Adi",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        const transformedData = [
          ["auto", "auto", "auto", "auto", "*", "*", "auto"],
        ];

        const headers = [
          "ŞEHİR",
          "İLÇE",
          "MAHALLE",
          "AD",
          "İŞ KOLU",
          "ADRES",
          "SAYI",
        ];
        const rows = response.map((item) => {
          return [
            item["il"] || "Bilinmiyor",
            item["ilce"] || "Bilinmiyor",
            item?.mah || "Bilinmiyor",
            item["sendika adresi"] || "Bilinmiyor",
            item["sendika is kolu"] || "Bilinmiyor",
            item["sendika adi"] || "Bilinmiyor",
            item["sayi"] || "Bilinmiyor",
          ];
        });

        transformedData.push([headers, ...rows]);
        setSendikalar(transformedData);
      } else {
        setSendikalar([[], [[], []]]);
      }
    } catch (error) {
      setSendikalar([[], [[], []]]);
      console.error(error);
    }
  };

  //--

  const pageSizeWidth = 595;
  const pageSizeHeight = 842;

  const generateNeighborhood = (city, district, neighbourhood) => {
    setNeighborhoodLoader(false);
    setPdfDataUrl(null);
    setShowPdfNeighborhood(false);

    setTimeout(() => {
      setNeighborhoodLoader(true);

      if (
        cityData &&
        districtData &&
        neighborhoodData
        // neighborhoodCountData &&
        // neighborhoodVoterData &&
        // neighborhoodDeathBirthData &&
        // headmanData &&
        // neighborhoodEducationData &&
        // neighborhoodAgeData &&
        // neighborhoodCitizenshipData &&
        // neighborhoodSocioEconomicData &&
        // neighborhoodDeadthBirthData &&
        // neighborhoodMaritalData &&
        // neighborhoodVoterPoliticalData &&
        // neighborhoodPoliticalProfileData &&
        // neighborhoodVoterTypeData &&
        // neighborhoodTodosData
      ) {
        const docDefinition = {
          info: {
            title: "Strateji Merkezi Mahalle Raporu",
            author: "Strateji Merkezi",
            subject: "Mahalle Raporu",
          },
          pageSize: {
            width: pageSizeWidth,
            height: pageSizeHeight,
          },
          pageMargins: [10, 10, 10, 10],
          watermark: {
            text: "STRATEJİ MERKEZİ",
            color: "#3cd2a5",
            opacity: 0.1,
            bold: true,
            italics: false,
          },
          content: [
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: `${city} - ${district} - ${neighbourhood.toUpperCase()}`,
                    },
                  ],
                ],
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "MAHALLE ANALİZ RAPORU",
                    },
                  ],
                ],
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: `Mahalle Muhtarı : ${
                        headmanData && headmanData.length !== 0
                          ? headmanData[0]["muhtar adı"] || "Bilinmiyor"
                          : "Bilinmiyor"
                      }  İletişim Numarası: ${
                        headmanData && headmanData.length !== 0
                          ? headmanData[0]["muhtartelefonu"] || "Bilinmiyor"
                          : "Bilinmiyor"
                      }`,
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 5],
            },
            {
              columns: [
                {
                  width: "50%",
                  text: [
                    {
                      text: `Mahallede toplam`,
                    },
                    {
                      text: ` ${
                        neighborhoodCountData
                          ? neighborhoodCountData?.streetCount
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(neighborhoodCountData?.streetCount)
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: `adet`,
                    },
                    {
                      text: ` SOKAK `,
                      bold: true,
                    },
                    {
                      text: `bulunmaktadır.`,
                    },
                  ],
                  style: "text",
                },
                {
                  width: "50%",
                  text: [
                    {
                      text: "Mahallede son yıl itibariyle toplam",
                    },
                    {
                      text: ` ${
                        neighborhoodDeathBirthData
                          ? neighborhoodDeathBirthData
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                neighborhoodDeathBirthData[0][0]["deger_sum"]
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } Ölüm `,
                      bold: true,
                    },
                    {
                      text: "ve",
                    },
                    {
                      text: ` ${
                        neighborhoodDeathBirthData
                          ? neighborhoodDeathBirthData
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                neighborhoodDeathBirthData[1][0]["deger_sum"]
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } Doğum `,
                      bold: true,
                    },
                    {
                      text: "yaşanmıştır.",
                    },
                  ],
                  style: "text",
                },
              ],
              columnGap: 10,
              margin: [0, 3],
            },
            {
              columns: [
                {
                  width: "50%",
                  text: [
                    {
                      text: "Mahallede toplam",
                    },
                    {
                      text: ` ${
                        neighborhoodCountData
                          ? neighborhoodCountData?.buildingCount
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(neighborhoodCountData?.buildingCount)
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adet",
                    },
                    {
                      text: " BİNA ",
                      bold: true,
                    },
                    {
                      text: "bulunmaktadır.",
                    },
                  ],
                  style: "text",
                },
                {
                  width: "50%",
                  text: [
                    {
                      text: "Sokak Başına",
                    },
                    {
                      text: " Ortalama Bina ",
                      bold: true,
                    },
                    {
                      text: "Sayısı",
                    },
                    {
                      text: ` ${
                        sokakBinaCountData
                          ? sokakBinaCountData?.averageNumberOfBuildingsPerStreet
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                sokakBinaCountData?.averageNumberOfBuildingsPerStreet
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adettir.",
                    },
                  ],
                  style: "text",
                },
              ],
              columnGap: 10,
              margin: [0, 3],
            },
            {
              columns: [
                {
                  width: "50%",
                  text: [
                    {
                      text: "Mahallede toplam",
                    },
                    {
                      text: ` ${
                        neighborhoodCountData
                          ? neighborhoodCountData?.independentSectionCount
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                neighborhoodCountData?.independentSectionCount
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adet",
                    },
                    {
                      text: " HANE ",
                      bold: true,
                    },
                    {
                      text: "bulunmaktadır.",
                    },
                  ],
                  style: "text",
                },
                {
                  width: "50%",
                  text: [
                    {
                      text: "Bina Başına",
                    },
                    {
                      text: " Ortalama Hane ",
                      bold: true,
                    },
                    {
                      text: "Sayısı",
                    },
                    {
                      text: ` ${
                        sokakBinaCountData
                          ? sokakBinaCountData?.averageNumberOfIndependentSectionsPerBuilding
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                sokakBinaCountData?.averageNumberOfIndependentSectionsPerBuilding
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adettir.",
                    },
                  ],
                  style: "text",
                },
              ],
              columnGap: 10,
              margin: [0, 3],
            },
            {
              columns: [
                {
                  width: "50%",
                  text: [
                    {
                      text: "Mahallede toplam",
                    },
                    {
                      text: ` ${
                        neighborhoodVoterData
                          ? neighborhoodVoterData[0][
                              "hanedeki secmen sayisi_sum"
                            ]
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                neighborhoodVoterData[0][
                                  "hanedeki secmen sayisi_sum"
                                ]
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "sayıda",
                    },
                    {
                      text: " BİREY ",
                      bold: true,
                    },
                    {
                      text: "ve",
                    },
                    {
                      text: ` ${
                        neighborhoodVoterData
                          ? neighborhoodVoterData[0][
                              "ilk defa oy kullanacak secmen sayisi(17)_sum"
                            ]
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                neighborhoodVoterData[0][
                                  "ilk defa oy kullanacak secmen sayisi(17)_sum"
                                ]
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "sayıda",
                    },
                    {
                      text: " Yeni Seçmen ",
                      bold: true,
                    },
                    {
                      text: "bulunmaktadır.",
                    },
                  ],
                  style: "text",
                },
                {
                  width: "50%",
                  text: [
                    {
                      text: "Mahallede Ortalama Seçmen Yaşı",
                    },
                    {
                      text: ` ${
                        neighborhoodAverageAgeData
                          ? neighborhoodAverageAgeData
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(neighborhoodAverageAgeData)
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "oranındadır.",
                    },
                  ],
                  style: "text",
                },
              ],
              margin: [0, 3],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "EĞİTİM DURUMU",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodEducationData
                  ? neighborhoodEducationData[0]
                  : ["auto"],
                body: neighborhoodEducationData
                  ? neighborhoodEducationData[1]
                  : [["Bilinmiyor"]],
              },
              style: "tablo",
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "YAŞ GRUBUNA GÖRE BİREY DURUMU",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodAgeData
                  ? neighborhoodAgeData[0].length !== 0
                    ? neighborhoodAgeData[0]
                    : ["auto"]
                  : ["auto"],
                body: [
                  neighborhoodAgeData
                    ? neighborhoodAgeData[1].length !== 0
                      ? neighborhoodAgeData[1]
                      : ["Bilinmiyor"]
                    : ["Yükleniyor..."],
                  neighborhoodAgeData
                    ? neighborhoodAgeData[2].length !== 0
                      ? neighborhoodAgeData[2]
                      : ["Bilinmiyor"]
                    : ["Yükleniyor..."],
                ],
              },
              style: "tablo",
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "ÖLÜM ORANLARI",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodDeadthBirthData
                  ? neighborhoodDeadthBirthData[0].length !== 0
                    ? neighborhoodDeadthBirthData[0]
                    : ["*"]
                  : ["*"],
                body: [
                  neighborhoodDeadthBirthData
                    ? neighborhoodDeadthBirthData[1].length !== 0
                      ? neighborhoodDeadthBirthData[1]
                      : ["Bilinmiyor"]
                    : ["Yükleniyor..."],
                  neighborhoodDeadthBirthData
                    ? neighborhoodDeadthBirthData[2].length !== 0
                      ? neighborhoodDeadthBirthData[2]
                      : ["Bilinmiyor"]
                    : ["Yükleniyor..."],
                ],
              },
              style: "tablo",
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "DOĞUM ORANLARI",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodDeadthBirthData
                  ? neighborhoodDeadthBirthData[3].length !== 0
                    ? neighborhoodDeadthBirthData[3]
                    : ["*"]
                  : ["*"],
                body: [
                  neighborhoodDeadthBirthData
                    ? neighborhoodDeadthBirthData[4].length !== 0
                      ? neighborhoodDeadthBirthData[4]
                      : ["Bilinmiyor"]
                    : ["Yükleniyor..."],
                  neighborhoodDeadthBirthData
                    ? neighborhoodDeadthBirthData[5].length !== 0
                      ? neighborhoodDeadthBirthData[5]
                      : ["Bilinmiyor"]
                    : ["Yükleniyor..."],
                ],
              },
              style: "tablo",
            },
            // {
            //   table: {
            //     widths: ["*"],
            //     body: [
            //       [
            //         {
            //           style: "headerSubTitle",
            //           border: [false, false, false, false],
            //           text: "BİREY SİYASİ DURUM ANALİZİ",
            //           margin: [0, 5],
            //         },
            //       ],
            //     ],
            //   },
            //   margin: [0, 8],
            // },
            // {
            //   layout: "darkHorizontalLines",
            //   table: {
            //     headerRows: 1,
            //     widths: neighborhoodVoterPoliticalData
            //       ? neighborhoodVoterPoliticalData[0].length !== 0
            //         ? neighborhoodVoterPoliticalData[0]
            //         : ["*"]
            //       : ["*"],
            //     body: [
            //       neighborhoodVoterPoliticalData
            //         ? neighborhoodVoterPoliticalData[1].length !== 0
            //           ? neighborhoodVoterPoliticalData[1]
            //           : ["Bilinmiyor"]
            //         : ["Yükleniyor..."],
            //       neighborhoodVoterPoliticalData
            //         ? neighborhoodVoterPoliticalData[2].length !== 0
            //           ? neighborhoodVoterPoliticalData[2]
            //           : ["Bilinmiyor"]
            //         : ["Yükleniyor..."],
            //     ],
            //   },
            //   style: "tablo",
            // },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "SOSYOEKONOMİK STATÜ DURUMU",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodSocioEconomicData
                  ? neighborhoodSocioEconomicData[0].length !== 0
                    ? neighborhoodSocioEconomicData[0]
                    : ["auto"]
                  : ["auto"],
                body: neighborhoodSocioEconomicData
                  ? neighborhoodSocioEconomicData[1][0].length !== 0
                    ? neighborhoodSocioEconomicData[1]
                    : [["Bilinmiyor"]]
                  : [["Yükleniyor..."]],
              },
              style: "tablo",
            },
            { text: "", pageBreak: "after" },
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: `${city} - ${district} - ${neighbourhood}`,
                    },
                  ],
                ],
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "MAHALLE ANALİZ RAPORU",
                    },
                  ],
                ],
              },
            },
            // neighborhoodVoterTypeData
            //   ? {
            //       table: {
            //         widths: ["*"],
            //         body: [
            //           [
            //             {
            //               style: "headerSubTitle",
            //               border: [false, false, false, false],
            //               text: "SEÇMEN TÜRÜNE GÖRE BİREY DAĞILIMI",
            //               margin: [0, 5],
            //             },
            //           ],
            //         ],
            //       },
            //       margin: [0, 8],
            //     }
            //   : null,
            // neighborhoodVoterTypeData
            //   ? {
            //       layout: "darkHorizontalLines",
            //       table: {
            //         headerRows: 1,
            //         widths: neighborhoodVoterTypeData
            //           ? neighborhoodVoterTypeData[0].length !== 0
            //             ? neighborhoodVoterTypeData[0]
            //             : ["*"]
            //           : ["*"],
            //         body: [
            //           neighborhoodVoterTypeData
            //             ? neighborhoodVoterTypeData[1].length !== 0
            //               ? neighborhoodVoterTypeData[1]
            //               : ["Bilinmiyor"]
            //             : ["Yükleniyor..."],
            //           neighborhoodVoterTypeData
            //             ? neighborhoodVoterTypeData[2].length !== 0
            //               ? neighborhoodVoterTypeData[2]
            //               : ["Bilinmiyor"]
            //             : ["Yükleniyor..."],
            //         ],
            //       },
            //       style: "tablo",
            //     }
            //   : null,
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "MEDENİ DURUM ANALİZİ",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodMaritalData
                  ? neighborhoodMaritalData[0].length !== 0
                    ? neighborhoodMaritalData[0]
                    : ["*"]
                  : ["*"],
                body: neighborhoodMaritalData
                  ? neighborhoodMaritalData[1][0].length !== 0
                    ? neighborhoodMaritalData[1]
                    : [["Bilinmiyor"]]
                  : [["Yükleniyor..."]],
              },
              style: "tablo",
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "HEMŞEHRİLİK DURUMU",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: neighborhoodCitizenshipData
                  ? neighborhoodCitizenshipData[0].length !== 0
                    ? neighborhoodCitizenshipData[0]
                    : ["auto"]
                  : ["auto"],
                body: neighborhoodCitizenshipData
                  ? neighborhoodCitizenshipData[1][0].length !== 0
                    ? neighborhoodCitizenshipData[1]
                    : [["Bilinmiyor"]]
                  : [["Yükleniyor..."]],
              },
              style: "tablo",
            },
            { text: "", pageBreak: "after" },
            // {
            //   columns: [
            //     {
            //       width: 80,
            //       image: logoData,
            //     },
            //     {
            //       width: "*",
            //       text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
            //       alignment: "right",
            //       fontSize: 10,
            //       margin: [0, 0, 0, 10],
            //     },
            //   ],
            //   columnGap: 10,
            // },
            // {
            //   table: {
            //     widths: ["*"],
            //     body: [
            //       [
            //         {
            //           style: "header",
            //           border: [false, false, false, false],
            //           text: `${city} - ${district} - ${neighbourhood}`,
            //         },
            //       ],
            //     ],
            //   },
            // },
            // neighborhoodPoliticalProfileData
            //   ? {
            //       table: {
            //         widths: ["*"],
            //         body: [
            //           [
            //             {
            //               style: "headerSubTitle",
            //               border: [false, false, false, false],
            //               text: "SİYASAL KİMLİK ANALİZ SONUÇLARI",
            //             },
            //           ],
            //         ],
            //       },
            //     }
            //   : null,
            // neighborhoodPoliticalProfileData
            //   ? {
            //       layout: "darkHorizontalLines",
            //       table: {
            //         headerRows: 1,
            //         widths: neighborhoodPoliticalProfileData
            //           ? neighborhoodPoliticalProfileData[0].length !== 0
            //             ? neighborhoodPoliticalProfileData[0]
            //             : ["*"]
            //           : ["*"],
            //         body: neighborhoodPoliticalProfileData
            //           ? neighborhoodPoliticalProfileData[1][0].length !== 0
            //             ? neighborhoodPoliticalProfileData[1]
            //             : [["Bilinmiyor"]]
            //           : [["Yükleniyor..."]],
            //       },
            //       style: "tablo",
            //       margin: [0, 10, 0, 0],
            //     }
            //   : null,
            // { text: "", pageBreak: "after" },
            getChartImages
              ? {
                  columns: [
                    {
                      width: 80,
                      image: logoData,
                    },
                    {
                      width: "*",
                      text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                      alignment: "right",
                      fontSize: 10,
                      margin: [0, 0, 0, 10],
                    },
                  ],
                  columnGap: 10,
                }
              : null,
            getChartImages
              ? {
                  table: {
                    widths: ["*"],
                    body: [
                      [
                        {
                          style: "header",
                          border: [false, false, false, false],
                          text: `${city} - ${district} - ${neighbourhood}`,
                        },
                      ],
                    ],
                  },
                }
              : null,
            getChartImages
              ? {
                  table: {
                    widths: ["*"],
                    body: [
                      [
                        {
                          style: "headerSubTitle",
                          border: [false, false, false, false],
                          text: "SEÇİM ANALİZ RAPORU",
                        },
                      ],
                    ],
                  },
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "2009 BELEDIYE BASKANLIGI YEREL SECIMLERI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "24. DONEM MILLETVEKILLIGI GENEL SECIMI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "2014 BELEDIYE BASKANLIGI SECIMI",
                      style: "text",
                    },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[0]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[0],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[1]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[1],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[2]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[2],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "12. CUMHURBASKANLIGI SECIMI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "25. DONEM MILLETVEKILLIGI SECIMLERI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "26. MILLETVEKILLIGI GENEL SECIMLERI",
                      style: "text",
                    },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[3]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[3],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[4]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[4],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[5]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[5],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "1. BASKANLIK SECIMI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "27. DONEM MILLETVEKILLIGI GENEL SECIMLERI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "2019 BELEDIYE BASKANLIGI YEREL SECIMLERI",
                      style: "text",
                    },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[6]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[6],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[7]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[7],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[8]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[8],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "2019 YENILENEN ISTANBUL SECIMI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "28. DONEM MILLETVEKILI GENEL SECIMLERI",
                      style: "text",
                    },
                    {
                      width: pageSizeWidth / 3 - 15,
                      text: "2. BASKANLIK SECIMI 2. TUR",
                      style: "text",
                    },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages
              ? {
                  columns: [
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[9]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[9],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    getChartImages &&
                    getChartImages.length !== 0 &&
                    getChartImages[10]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: getChartImages[10],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                    voteChartImage12 && voteChartImage12[0]
                      ? {
                          width: pageSizeWidth / 3 - 15,
                          image: voteChartImage12[0],
                        }
                      : {
                          width: pageSizeWidth / 3 - 15,
                          text: "Analiz Verisi Eklenmemiştir",
                        },
                  ],
                  columnGap: 5,
                  margin: [0, 5, 0, 0],
                }
              : null,
            getChartImages ? { text: "", pageBreak: "after" } : null,
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: "MAHALLEDE BULUNAN DERNEKLER",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
            },
            {
              layout: "darkHorizontalLines",
              margin: [0, 10, 0, 0],
              table: {
                headerRows: 1,
                widths: dernekler
                  ? dernekler[0].length !== 0
                    ? dernekler[0]
                    : ["auto"]
                  : ["auto"],
                body: dernekler
                  ? dernekler[1][0].length !== 0
                    ? dernekler[1]
                    : [["Bilinmiyor"]]
                  : [["Yükleniyor..."]],
              },
              style: "tablo",
            },
            { text: "", pageBreak: "after" },
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: "MAHALLEDE BULUNAN SENDİKALAR",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
            },
            {
              layout: "darkHorizontalLines",
              margin: [0, 10, 0, 0],
              table: {
                headerRows: 1,
                widths: sendikalar
                  ? sendikalar[0].length !== 0
                    ? sendikalar[0]
                    : ["auto"]
                  : ["auto"],
                body: sendikalar
                  ? sendikalar[1][0].length !== 0
                    ? sendikalar[1]
                    : [["Bilinmiyor"]]
                  : [["Yükleniyor..."]],
              },
              style: "tablo",
            },
            { text: "", pageBreak: "after" },
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: `${city} - ${district} - ${neighbourhood}`,
                    },
                  ],
                ],
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "STRATEJİ HARİTASI",
                    },
                  ],
                ],
              },
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: ["*"],
                // body: [
                //   ["Kategori:"],
                //   ["Başlık:"],
                //   ["Açıklama:"],
                //   ["Yönetici Özeti:"],
                //   [
                //     {
                //       columns: [
                //         {
                //           width: pageSizeWidth / 4 - 15,
                //           image: "snow",
                //         },
                //       ],
                //       columnGap: 10,
                //     },
                //   ],
                // ],
                body: neighborhoodTodosData
                  ? neighborhoodTodosData.length !== 0
                    ? neighborhoodTodosData
                    : [["Görev Mevcut Değil"]]
                  : [["Yükleniyor..."]],
              },
              margin: [0, 5],
            },
          ].filter((item) => item !== null),
          // images: {
          //   snow: {
          //     // url: "https://picsum.photos/seed/picsum/200/300",
          //     url: "https://Strateji Merkezi.fra1.digitaloceanspaces.com/uploads/65bf86ebe771b.png",
          //   },
          // },
          styles: {
            header: {
              fillColor: "#eeeeee",
              alignment: "center",
              margin: [0, 10, 0, 0],
              bold: true,
              fontSize: 12,
            },
            headerSubTitle: {
              fillColor: "#eeeeee",
              alignment: "center",
              bold: false,
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            text: {
              fontSize: 8,
            },
            tablo: {
              fontSize: 8,
            },
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getDataUrl((dataUrl) => {
          setPdfDataUrl(dataUrl);
          setShowPdfNeighborhood(true);
        });
      }
    }, 300);
  };

  const generateStreet = (city, district, neighbourhood, street) => {
    setStreetLoader(false);
    setPdfDataUrlStreet(null);
    setShowPdfStreet(false);

    setTimeout(() => {
      setStreetLoader(true);

      // handleScreenshot();

      if (
        cityData &&
        districtData &&
        neighborhoodData &&
        streetData &&
        streetAge2Data &&
        streetVoterProfileData &&
        streetPoliticalProfileData &&
        streetWordsData &&
        streetChestData
        // streetTodosData &&
      ) {
        const docDefinition = {
          info: {
            title: "Strateji Merkezi Sokak Raporu",
            author: "Strateji Merkezi",
            subject: "Sokak Raporu",
          },
          pageSize: {
            width: pageSizeWidth,
            height: pageSizeHeight,
          },
          pageMargins: [10, 10, 10, 10],
          watermark: {
            text: "STRATEJİ MERKEZİ",
            color: "#3cd2a5",
            opacity: 0.1,
            bold: true,
            italics: false,
          },
          content: [
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: `${city} - ${district} - ${neighbourhood} - ${street}`,
                    },
                  ],
                ],
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "SOKAK ANALİZ RAPORU",
                    },
                  ],
                ],
              },
              margin: [0, 0, 0, 5],
            },
            {
              columns: [
                {
                  width: "33%",
                  text: [
                    {
                      text: "Sokakta toplam",
                    },
                    {
                      text: ` ${
                        streetCountData
                          ? streetCountData?.buildingCount
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(streetCountData?.buildingCount)
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adet",
                    },
                    {
                      text: " BİNA ",
                      bold: true,
                    },
                    {
                      text: "bulunmaktadır.",
                    },
                  ],
                  style: "text",
                },
                {
                  width: "33%",
                  text: [
                    {
                      text: "Sokakta toplam",
                    },
                    {
                      text: ` ${
                        streetCountData
                          ? streetCountData?.independentSectionCount
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                streetCountData?.independentSectionCount
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adet",
                    },
                    {
                      text: " HANE ",
                      bold: true,
                    },
                    {
                      text: "bulunmaktadır.",
                    },
                  ],
                  style: "text",
                },
                {
                  width: "33%",
                  text: [
                    {
                      text: "Sokakta toplam",
                    },
                    {
                      text: ` ${
                        streetVoterData
                          ? streetVoterData[0]["hanedeki secmen sayisi_sum"]
                            ? Intl.NumberFormat("tr-TR", {
                                style: "decimal",
                              }).format(
                                streetVoterData[0]["hanedeki secmen sayisi_sum"]
                              )
                            : "Bilinmiyor"
                          : "Bilinmiyor"
                      } `,
                      bold: true,
                    },
                    {
                      text: "adet",
                    },
                    {
                      text: " BİREY ",
                      bold: true,
                    },
                    {
                      text: "bulunmaktadır.",
                    },
                  ],
                  style: "text",
                },
              ],
              margin: [0, 3],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "YAŞ GRUBUNA GÖRE BİREY SAYILARI",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              columns: [
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(0-4) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 0-4 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(5-6) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 5-6 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(5-9) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 5-9 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(10-14) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 10-14 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(15-19) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 15-19 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(18-24) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 18-24 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(20-24) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 20-24 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(25-29) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 25-29 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
              ],
              margin: [0, 3],
              columnGap: 0,
            },
            {
              columns: [
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(30-34) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 30-34 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(35-39) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 35-39 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(40-44) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 40-44 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(45-49) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 45-49 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(50-54) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 50-54 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(55-59) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 55-59 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(60-64) yas arasi_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 60-64 ",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
                {
                  width: "12.5%",
                  text: [
                    {
                      text: ` ${
                        streetAge2Data && streetAge2Data.length !== 0
                          ? streetAge2Data[0]["(+65) yas_sum"]
                          : "-"
                      } `,
                      bold: true,
                    },
                    {
                      text: "|",
                    },
                    {
                      text: " 65+",
                      bold: true,
                    },
                  ],
                  style: "text",
                  fontSize: 8,
                },
              ],
              margin: [0, 3],
              columnGap: 0,
            },
            // {
            //   table: {
            //     widths: ["*"],
            //     body: [
            //       [
            //         {
            //           style: "headerSubTitle",
            //           border: [false, false, false, false],
            //           text: "SEÇMEN PROFİLİ",
            //           margin: [0, 5],
            //         },
            //       ],
            //     ],
            //   },
            //   margin: [0, 8],
            // },
            // {
            //   layout: "darkHorizontalLines",
            //   table: {
            //     headerRows: 1,
            //     widths: streetVoterProfileData
            //       ? streetVoterProfileData.length !== 0
            //         ? streetVoterProfileData[0].length !== 0
            //           ? streetVoterProfileData[0]
            //           : ["auto"]
            //         : ["auto"]
            //       : ["auto"],
            //     body: [
            //       streetVoterProfileData
            //         ? streetVoterProfileData.length !== 0
            //           ? streetVoterProfileData[1].length !== 0
            //             ? streetVoterProfileData[1]
            //             : ["Bilinmiyor"]
            //           : ["Bilinmiyor"]
            //         : ["Yükleniyor..."],
            //       streetVoterProfileData
            //         ? streetVoterProfileData.length !== 0
            //           ? streetVoterProfileData[2].length !== 0
            //             ? streetVoterProfileData[2]
            //             : ["Bilinmiyor"]
            //           : ["Bilinmiyor"]
            //         : ["Yükleniyor..."],
            //     ],
            //   },
            //   style: "tablo",
            // },
            streetChestData
              ? {
                  table: {
                    widths: ["*"],
                    body: [
                      [
                        {
                          style: "headerSubTitle",
                          border: [false, false, false, false],
                          text: "2. BAŞKANLIK SEÇİMİ VE SOKAĞA GÖRE SANDIK BAŞINA BİREY SAYISI TABLOSU",
                          margin: [0, 5],
                        },
                      ],
                    ],
                  },
                  margin: [0, 8],
                }
              : null,
            streetChestData
              ? {
                  layout: "darkHorizontalLines",
                  table: {
                    headerRows: 1,
                    widths: streetChestData
                      ? streetChestData[0] && streetChestData[0].length !== 0
                        ? streetChestData[0]
                        : ["auto"]
                      : ["auto"],
                    body: streetChestData
                      ? streetChestData[1].length !== 0 &&
                        streetChestData[1][0].length !== 0
                        ? streetChestData[1]
                        : [["Bilinmiyor"]]
                      : [["Yükleniyor..."]],
                  },
                  style: "tablo",
                }
              : null,
            { text: "", pageBreak: "after" },
            streetPoliticalProfileData
              ? {
                  table: {
                    widths: ["*"],
                    body: [
                      [
                        {
                          style: "headerSubTitle",
                          border: [false, false, false, false],
                          text: "SİYASAL KİMLİK ANALİZ SONUÇLARI",
                          margin: [0, 5],
                        },
                      ],
                    ],
                  },
                  margin: [0, 8],
                }
              : null,
            streetPoliticalProfileData
              ? {
                  layout: "darkHorizontalLines",
                  table: {
                    headerRows: 1,
                    widths: streetPoliticalProfileData
                      ? streetPoliticalProfileData[0].length !== 0
                        ? streetPoliticalProfileData[0]
                        : ["auto"]
                      : ["auto"],
                    body: streetPoliticalProfileData
                      ? streetPoliticalProfileData[1][0].length !== 0
                        ? streetPoliticalProfileData[1]
                        : [["Bilinmiyor"]]
                      : [["Yükleniyor..."]],
                  },
                  style: "tablo",
                  margin: [0, 10, 0, 0],
                }
              : null,
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "HEMŞEHRİLİK DURUMU",
                      margin: [0, 5],
                    },
                  ],
                ],
              },
              margin: [0, 8],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths:
                  streetWordsData && streetWordsData.length !== 0
                    ? streetWordsData[0]
                    : ["auto"],
                body: streetWordsData
                  ? streetWordsData.length !== 0
                    ? streetWordsData[1]
                    : [["Bilinmiyor"]]
                  : [["Yükleniyor..."]],
              },
              style: "tablo",
            },
            { text: "", pageBreak: "after" },
            {
              columns: [
                {
                  width: 80,
                  image: logoData,
                },
                {
                  width: "*",
                  text: moment(new Date()).format("DD/MM/YYYY HH:mm"),
                  alignment: "right",
                  fontSize: 10,
                  margin: [0, 0, 0, 10],
                },
              ],
              columnGap: 10,
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "header",
                      border: [false, false, false, false],
                      text: `${city} - ${district} - ${neighbourhood} - ${street}`,
                    },
                  ],
                ],
              },
            },
            {
              table: {
                widths: ["*"],
                body: [
                  [
                    {
                      style: "headerSubTitle",
                      border: [false, false, false, false],
                      text: "STRATEJİ HARİTASI",
                    },
                  ],
                ],
              },
              margin: [0, 0, 0, 10],
            },
            {
              layout: "darkHorizontalLines",
              table: {
                headerRows: 1,
                widths: ["*"],
                body: streetTodosData
                  ? streetTodosData.length !== 0
                    ? streetTodosData
                    : [["Görev Mevcut Değil"]]
                  : [["Yükleniyor..."]],
              },
            },
          ],
          styles: {
            header: {
              fillColor: "#eeeeee",
              alignment: "center",
              margin: [0, 10, 0, 0],
              bold: true,
              fontSize: 12,
            },
            headerSubTitle: {
              fillColor: "#eeeeee",
              alignment: "center",
              bold: false,
              fontSize: 10,
              margin: [0, 0, 0, 10],
            },
            text: {
              fontSize: 10,
            },
            tablo: {
              fontSize: 8,
            },
          },
        };

        const pdfDocGenerator = pdfMake.createPdf(docDefinition);
        pdfDocGenerator.getDataUrl((dataUrl) => {
          setPdfDataUrlStreet(dataUrl);
          setShowPdfStreet(true);
        });
      }
    }, 300);
  };

  function getReport(name, city, district, neighbourhood, street) {
    if (!district) {
      toastMessage("İlçe Seçmelisiniz", "error");
    } else {
      if (name === "neighbourhood") {
        if (!neighbourhood) {
          toastMessage("Mahalle Seçmelisiniz", "error");

          return true;
        }
      }

      if (name === "street") {
        if (!neighbourhood) {
          toastMessage("Mahalle Seçmelisiniz", "error");
        } else if (!street) {
          toastMessage("Sokak Seçmelisiniz", "error");

          return true;
        }
      }
    }

    //--

    if (name === "neighbourhood") {
      setReportTypeNeighborhood("neighbourhood");
    }

    if (name === "street") {
      setReportType("street");
    }

    setCityData(city);
    setDistrictData(district);
    setNeighborhoodData(neighbourhood);

    setStreetData(street);

    getTodos(
      name,
      city.value,
      district.value,
      neighbourhood ? neighbourhood.value : null,
      street ? street.value : null
    );
  }

  //--

  useEffect(() => {
    if (reportTypeNeighborhood === "neighbourhood") {
      generateNeighborhood(
        cityData.label,
        districtData.label.toUpperCase(),
        neighborhoodData.label
      );
    }
  }, [
    reportTypeNeighborhood,
    cityData,
    districtData,
    neighborhoodData,
    neighborhoodCountData,
    neighborhoodVoterData,
    neighborhoodDeathBirthData,
    headmanData,
    dernekler,
    neighborhoodEducationData,
    neighborhoodAgeData,
    neighborhoodCitizenshipData,
    neighborhoodSocioEconomicData,
    neighborhoodDeadthBirthData,
    neighborhoodMaritalData,
    neighborhoodVoterPoliticalData,
    neighborhoodPoliticalProfileData,
    neighborhoodVoterTypeData,
    neighborhoodTodosData,
    getChartImages,
    // voteChartImage1,
    // voteChartImage2,
    // voteChartImage3,
    // voteChartImage4,
    // voteChartImage5,
    // voteChartImage6,
    // voteChartImage7,
    // voteChartImage8,
    // voteChartImage9,
    // voteChartImage10,
    // voteChartImage11,
    baskanlikSecim2Data,
    voteChartImage12,
  ]);

  useEffect(() => {
    if (reportTypeNeighborhood === "neighbourhood") {
      fetchBaskanlikSecim(
        cityData.label,
        districtData.label.toUpperCase(),
        neighborhoodData.label
      );
      fetchMuhtarlar(
        cityData.label,
        districtData.label.toUpperCase(),
        neighborhoodData.label
      );
      fetchDernekler(
        cityData.label,
        districtData.label.toUpperCase(),
        neighborhoodData.label
      );
      fetchSendikalar(
        cityData.label,
        districtData.label.toUpperCase(),
        neighborhoodData.label
      );
    }
  }, [reportTypeNeighborhood, cityData, districtData, neighborhoodData]);

  useEffect(() => {
    if (reportType === "street" && streetData) {
      getAgeData(
        cityData.label,
        districtData.label,
        neighborhoodData.label,
        streetData.label
      );
      getSecmenProfili(
        cityData.label,
        districtData.label,
        neighborhoodData.label,
        streetData.label
      );
      getHemsehrilikDurumu(
        cityData.label,
        districtData.label,
        neighborhoodData.label,
        streetData.label
      );
      // getStreetData(8, districtData.value, streetData.value);
      // getStreetData(9, districtData.value, streetData.value);
      // getStreetData(10, districtData.value, streetData.value);
      // getStreetAgeData(7, districtData.value, streetData.value);
    }
  }, [reportType, cityData, districtData, neighborhoodData, streetData]);

  useEffect(() => {
    if (reportType === "street" && streetData) {
      generateStreet(
        cityData.label,
        districtData.label.toUpperCase(),
        neighborhoodData.label,
        streetData.label
      );
    }
  }, [
    reportType,
    cityData,
    districtData,
    neighborhoodData,
    streetData,
    streetCountData,
    streetVoterData,
    streetVoterProfileData,
    streetTodosData,
    streetChestData,
    streetPoliticalProfileData,
    streetWordsData,
    // streetAge2Data,
  ]);

  //--

  useEffect(() => {
    if (raporFilter) {
      getReport(
        "street",
        raporFilter.city,
        raporFilter.district,
        raporFilter.neighborhood,
        raporFilter.street
      );
    }
  }, [raporFilter]);

  return (
    <div
      className="position-relative overflow-hidden zoom-item mb-5"
      style={{ minHeight: !justPDF ? "100vh" : "" }}
    >
      {!justPDF && <PageFilter report={true} getReport={getReport} />}

      {justPDF ? (
        streetLoader && (
          <div className="col-12">
            {showPdfStreet ? (
              <iframe
                title="pdfViewer"
                src={pdfDataUrlStreet}
                width="100%"
                style={{ height: "calc(100vh - 330px)" }}
              />
            ) : (
              <div className="spinner-border text-theme"></div>
            )}
          </div>
        )
      ) : (
        <Card className="my-4">
          <CardBody>
            <div className="row">
              {neighborhoodLoader && (
                <div className="col-12 col-md">
                  {showPdfNeighborhood ? (
                    <iframe
                      title="pdfViewer"
                      src={pdfDataUrl}
                      width="100%"
                      style={{ height: "calc(100vh - 330px)" }}
                    />
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              )}

              {streetLoader && (
                <div className="col-12 col-md">
                  {showPdfStreet ? (
                    <iframe
                      title="pdfViewer"
                      src={pdfDataUrlStreet}
                      width="100%"
                      style={{ height: "calc(100vh - 330px)" }}
                    />
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              )}

              {!neighborhoodLoader && !streetLoader && (
                <div className="col-12">
                  <span>Raporu Görüntülemek İçin Filtre Yapmalısınız</span>
                </div>
              )}
            </div>
          </CardBody>
        </Card>
      )}

      {/* //-- */}

      {neighborhoodData?.value && (
        <>
          <StratejiHaritasi
            show={false}
            cityProps={cityData}
            districtProps={districtData}
            neighborhoodProps={neighborhoodData}
          />

          <CardBinaHane
            show={false}
            setNeighborhoodCountData={setNeighborhoodCountData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            graphic={1}
          />

          <CardBinaHane
            show={false}
            setStreetCountData={setStreetCountData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            street={streetData?.label}
            graphic={1}
          />

          <CardMedeniDurum
            show={false}
            setSokakBinaCountData={setSokakBinaCountData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            type={2}
          />

          <CardVatandasSayisi
            show={false}
            setNeighborhoodVoterData={setNeighborhoodVoterData}
            setNeighborhoodAverageAgeData={setNeighborhoodAverageAgeData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            graphic={3}
          />

          <CardVatandasSayisi
            show={false}
            setStreetVoterData={setStreetVoterData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            street={streetData?.label}
            graphic={3}
          />

          <CardOlumSayisi
            show={false}
            setNeighborhoodDeathBirthData={setNeighborhoodDeathBirthData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            graphic={4}
          />

          {/* //-- */}

          <ChartBarTimeLine
            show={false}
            type="2023-egitim"
            id={12}
            title="Seçmen Genel Eğitim Durumu"
            setNeighborhoodEducationData={setNeighborhoodEducationData}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            path="voter-analysis"
            graphic={3}
            card={false}
          />

          <Anasayfa
            show={false}
            setNeighborhoodVoterPoliticalData={
              setNeighborhoodVoterPoliticalData
            }
            setNeighborhoodAgeData={setNeighborhoodAgeData}
            setNeighborhoodCitizenshipData={setNeighborhoodCitizenshipData}
            city={cityData}
            district={districtData}
            neighbourhood={neighborhoodData}
          />

          <ChartBarTimeLine
            show={false}
            setNeighborhoodSocioEconomicData={setNeighborhoodSocioEconomicData}
            type="sosyo-ekonomik-statu"
            id={13}
            title={"Yıllara Göre Doğum ve Ölüm Oranları"}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            graphic={12}
            card={false}
          />

          <ChartLineMultiple
            show={false}
            setNeighborhoodDeadthBirthData={setNeighborhoodDeadthBirthData}
            id={13}
            title={"Yıllara Göre Doğum ve Ölüm Oranları"}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            graphic={12}
            card={true}
          />

          <ChartBarTimeLine
            show={false}
            setNeighborhoodMaritalData={setNeighborhoodMaritalData}
            type="medeni-durum"
            id={13}
            title={"Yıllara Göre Doğum ve Ölüm Oranları"}
            city={cityData?.label}
            district={districtData?.label}
            neighbourhood={neighborhoodData?.label}
            graphic={12}
            card={false}
          />

          {context?.me?.customer?.settings?.sandikAnalizi && (
            <>
              <SandikAnalizi
                show={false}
                setNeighborhoodVoterTypeData={setNeighborhoodVoterTypeData}
                setNeighborhoodPoliticalProfileData={
                  setNeighborhoodPoliticalProfileData
                }
                city={cityData?.label}
                district={districtData?.label}
                neighbourhood={neighborhoodData?.label}
              />

              <SandikAnalizi
                show={false}
                setStreetPoliticalProfileData={setStreetPoliticalProfileData}
                city={cityData?.label}
                district={districtData?.label}
                neighbourhood={neighborhoodData?.label}
                street={streetData?.label}
              />
            </>
          )}

          {streetData && context?.me?.customer?.settings?.secimAnalizi && (
            <SecimAnaliziTable2
              show={false}
              cityFilter={cityData}
              districtFilter={districtData}
              neighbourhoodFilter={neighborhoodData}
              streetFilter={streetData}
              setSecimAnaliziSandikNoModalData={false}
              setStreetChestData={setStreetChestData}
            />
          )}

          {context?.me?.customer?.settings?.secimAnalizi && (
            <div
              className="position-absolute"
              style={{
                height: 500,
                opacity: 0,
                visibility: "hidden",
                pointerEvents: "none",
              }}
            >
              <DinamikPage
                width={500}
                height={500}
                getChartImages={setGetChartImages}
                menuId={50}
                removeGrid={true}
                filterPath={`${
                  cityData ? `?where[]=Il:${cityData.label}` : ""
                }${
                  districtData
                    ? `${cityData ? "&" : "?"}where[]=Ilce:${
                        districtData.label
                      }`
                    : ""
                }${
                  neighborhoodData
                    ? `${districtData ? "&" : "?"}where[]=Mahalle:${
                        neighborhoodData.label
                      }`
                    : ""
                }`}
              />

              {baskanlikSecim2Data && (
                <DynamicCard
                  events={false}
                  width={500}
                  height={500}
                  getChartImages={setVoteChartImage12}
                  data={baskanlikSecim2Data}
                  item={{
                    title: "2. BASKANLIK SECIMI 2. TUR",
                    options: {
                      width: { value: "col-3" },
                      options: {
                        series: [
                          {
                            data: baskanlikSecim2Data,
                            name: null,
                            type: "pie",
                            label: {
                              textStyle: {
                                fontSize: 15,
                              },
                            },
                            radius: "50%",
                            itemStyle: {
                              color: "#3cd2a5",
                            },
                          },
                        ],
                        tooltip: {
                          trigger: "item",
                          formatter: function (params) {
                            return (
                              params.data.name + "<br /> %" + params.data.value
                            );
                          },
                        },
                        darkMode: true,
                        legend: {
                          show: true,
                          orient: "horizontal",
                          left: "center",
                          itemGap: 20,
                          textStyle: {
                            color: "#fff",
                          },
                        },
                        visualMap: {
                          min:
                            baskanlikSecim2Data &&
                            baskanlikSecim2Data.length !== 0
                              ? Math.min(
                                  ...baskanlikSecim2Data.map((obj) => obj.value)
                                )
                              : 0,
                          max:
                            baskanlikSecim2Data &&
                            baskanlikSecim2Data.length !== 0
                              ? Math.max(
                                  ...baskanlikSecim2Data.map((obj) => obj.value)
                                )
                              : 0,
                          show: false,
                          inRange: {
                            colorLightness: [0.2, 1],
                          },
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          )}
        </>
      )}

      <ToastContainer pauseOnFocusLoss={false} />
    </div>
  );
}

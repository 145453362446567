import React from "react";

import { Card, CardBody } from "../../components/card/card";

export default function OrnekDosyalar() {
  return (
    <div className="row g-3">
      {[
        {
          file: "sosyo-ekonomik-statu",
          type: "sosyo-ekonomik-statu (Anasayfa)",
        },
        {
          file: "medeni-durum",
          type: "medeni-durum (Anasayfa)",
        },
        {
          file: "hemsehrilik-durumu",
          type: "hemsehrilik-durumu (Anasayfa)",
        },
        {
          file: "dogum-data",
          type: "dogum-data (Anasayfa)",
        },
        {
          file: "olum-data",
          type: "olum-data (Anasayfa)",
        },
        {
          file: "sokak-ve-sandıga-gore-secmen-sayısı",
          type: "sokak-ve-sandıga-gore-secmen-sayısı (Anasayfa)",
        },
        {
          file: "hizmet-sikayet-data",
          type: "hizmet-sikayet-data (Hane ve Sokak Analizi)",
        },
        {
          file: "muhtarlik-data",
          type: "muhtarlik-data (Strateji Haritası)",
        },
        {
          file: "dernekler-data",
          type: "dernekler-data-2 (Strateji Haritası)",
        },
        {
          file: "2023-egitim",
          type: "2023-egitim (Anasayfa)",
        },
        {
          file: "2022-2023-üniversite-ve-öğrenci-sayıları",
          type: "2022-2023-üniversite-ve-öğrenci-sayıları (Anasayfa)",
        },
        {
          file: "internet-kullanim-amaclari",
          type: "internet-kullanim-amaclari-2 (Anasayfa)",
        },
        {
          file: "verilen-goc",
          type: "verilen-goc (Anasayfa)",
        },
        {
          file: "alinan-goc",
          type: "alinan-goc (Anasayfa)",
        },
        {
          file: "siyasetmetre_secim_3",
          type: "siyasetmetre_secim_3 (Sandık Analizi - Sandık Sonucu)",
        },
        {
          file: "2-baskanlık-secim-tur-1",
          type: "2-baskanlık-secim-tur-1 (Seçim Analizi)",
        },
        {
          file: "2-baskanlık-secim-tur-2",
          type: "2-baskanlık-secim-tur-2 (Seçim Analizi)",
        },
      ].map((item, index) => (
        <div key={index} className="col-12 col-md-6 col-lg-3">
          <Card>
            <CardBody>
              <div>
                <span>{item.type}</span>
                <a
                  href={`${process.env.PUBLIC_URL}/download/fix/${item.file}.csv`}
                  className="d-block mt-2"
                >
                  Örnek Dosya İndir
                </a>
              </div>
            </CardBody>
          </Card>
        </div>
      ))}
    </div>
  );
}

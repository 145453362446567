import React from "react";

function Footer({ appFooterFull, appScrollButton }) {
  return (
    <footer
      id="footer"
      className={`app-footer${appFooterFull ? " app-footer-full" : ""}`}
    >
      <p>&copy; 2024 KENTMETRE ŞİRKETLER GRUBU</p>

      <p className={`${appScrollButton ? "pe-5" : ""} border-top pt-3`}>
        <strong className="text-theme">stratejimerkezi.com</strong>'un hiçbir
        kurum ve kuruluşla ilgisi yoktur. Bu sitede sadece açık kaynak araştırma
        ve tahminsel veriler gösterilmektedir.
        <br />
        <strong className="text-theme">stratejimerkezi.com</strong>'da
        gösterilen yer, adres, bilgi ve harita bilgilerinin doğruluğunu garanti
        etmez, sadece bilgilendirme amaçlıdır.
      </p>
    </footer>
  );
}

export default Footer;

import { Card, CardBody } from "../../components/card/card";
import Select from "react-select";
import apiClient from "../../ApiClient";
import { useState } from "react";

export default function FileItem({
  item,
  setSelectedFile,
  setTypeData,
  setFileItemEvents,
}) {
  const [dataColumn, setDataColumn] = useState([]);
  const [data, setData] = useState([]);
  const [isTableVisible, setIsTableVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isDataLoaded, setIsDataLoaded] = useState(false);
  const [isPageLoading, setIsPageLoading] = useState(false);

  const [typeTitle, setTypeTitle] = useState("");
  const [currentPage, setCurrentPage] = useState(1);

  const [selectedColumn, setSelectedColumn] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  async function fetchColumn(type) {
    setTypeTitle(type);

    if (isTableVisible) {
      setIsTableVisible(false);
      return;
    }

    if (isDataLoaded) {
      setIsTableVisible(true);
      return;
    }

    setIsLoading(true);

    try {
      const response = await apiClient.get(`/columns/${type}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        console.log(response);

        setDataColumn(response.data);
        await fetchData(1, type, response.data.columns, searchTerm);
      }
    } catch (error) {
      setDataColumn([]);
      setData([]);
      console.error("Get File Types Error", error);
    } finally {
      setIsLoading(false);
    }
  }

  async function fetchData(page, type, fileColumns, searchTerm) {
    setIsPageLoading(true);

    const conditions = searchTerm
      ? [
          {
            key: selectedColumn.value,
            value: searchTerm,
            operator: "like",
            type: "and",
          },
        ]
      : [];

    const columnsArray = fileColumns.map((item) => ({
      key: item.replace(/[\/\\']/g, ""),
      value: "select",
    }));

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: type,
          description: "",
          customer: null,
          paginate: true,
          status: true,
          order: 1,
          sql: {
            column: columnsArray,
            condition: conditions,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setData(response);
      setIsTableVisible(true);
      setIsDataLoaded(true);
    } catch (error) {
      setData([]);
      console.error(error);
    } finally {
      setIsPageLoading(false);
    }
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page, typeTitle, dataColumn.columns, searchTerm);
  };

  const handleSearch = () => {
    if (selectedColumn && searchTerm !== "") {
      setCurrentPage(1);
      fetchData(1, typeTitle, dataColumn.columns, searchTerm);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
    setSelectedColumn(null);
    setCurrentPage(1);
    fetchData(1, typeTitle, dataColumn.columns, "");
  };

  return (
    <Card>
      <CardBody>
        <div className="d-flex align-items-center justify-content-between">
          <span className="flex-1 me-2">
            {item.type.includes("bireysel-data") ||
            item.type.includes("hane-data") ||
            item.type.includes("akraba-data")
              ? item.type.split("-customer")[0]
              : item.type.split("-customer")[0]}
          </span>

          <div className="d-flex align-items-center gap-2">
            <button
              type="button"
              className="btn btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#fileListImportModal"
              onClick={() => setSelectedFile(item)}
            >
              Dosya Yükle
            </button>

            <button
              type="button"
              className="btn btn-outline-theme d-flex align-items-center gap-2"
              onClick={() => fetchColumn(item.type)}
            >
              <span>Tablo</span>
              <i
                className={`fas fa-angle-${isTableVisible ? "up" : "down"}`}
              ></i>
            </button>

            <button
              type="button"
              className="btn btn-danger"
              data-bs-toggle="modal"
              data-bs-target="#yuklenenDosyalarDeleteModal"
              onClick={() => setSelectedFile(item)}
            >
              Kaldır
            </button>
          </div>
        </div>

        {isLoading ? (
          <div className="spinner-border text-theme"></div>
        ) : (
          isTableVisible &&
          dataColumn &&
          dataColumn.columns.length !== 0 &&
          data && (
            <Card className="mt-3">
              <CardBody>
                <div className="row">
                  <div className="col me-3">
                    <div className="d-flex gap-2 mb-3">
                      <Select
                        options={dataColumn.columns.map((item) => ({
                          label: item,
                          value: item,
                        }))}
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "Seçenek Yok"}
                        placeholder="Sütun Seçiniz..."
                        value={selectedColumn}
                        onChange={(selectedOption) =>
                          setSelectedColumn(selectedOption)
                        }
                      />

                      <input
                        type="text"
                        className="form-control"
                        placeholder="Ara..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        style={{ maxWidth: 300 }}
                      />

                      <button
                        type="button"
                        className="btn btn-theme"
                        onClick={handleSearch}
                      >
                        Ara
                      </button>

                      {searchTerm && selectedColumn && (
                        <button
                          type="button"
                          className="btn btn-secondary"
                          onClick={handleClearSearch}
                        >
                          Kaldır
                        </button>
                      )}
                    </div>
                  </div>

                  <div className="col w-auto flex-none">
                    <button
                      type="button"
                      className="btn btn-outline-theme me-2"
                      data-bs-toggle="modal"
                      data-bs-target="#yuklenenDosyalarIslemler"
                      onClick={() =>
                        setFileItemEvents({
                          column: dataColumn,
                          row: null,
                          type: item,
                        })
                      }
                    >
                      Satır Ekle
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-theme"
                      data-bs-toggle="modal"
                      data-bs-target="#yuklenenDosyalarModal"
                      onClick={() =>
                        setTypeData({ column: dataColumn.columns, type: item })
                      }
                    >
                      Veri Silmek İçin Tıkla
                    </button>
                  </div>
                </div>

                {data.data.length !== 0 ? (
                  isPageLoading ? (
                    <div className="spinner-border text-theme"></div>
                  ) : (
                    <>
                      <div className="table-responsive" style={{ height: 500 }}>
                        <table className="table">
                          <thead>
                            <tr>
                              <th style={{ width: 100 }}>İşlem</th>
                              {dataColumn.columns.map((header, index) => (
                                <th
                                  style={{ whiteSpace: "nowrap" }}
                                  key={index}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {data.data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                <td>
                                  <button
                                    className="btn btn-outline-theme btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#yuklenenDosyalarIslemler"
                                    onClick={() =>
                                      setFileItemEvents({
                                        column: dataColumn,
                                        row: row,
                                        type: item,
                                      })
                                    }
                                  >
                                    Güncelle
                                  </button>
                                </td>
                                {Object.values(row).map((cell, cellIndex) => (
                                  <td key={cellIndex}>{cell}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center mt-4">
                        <ul className="pagination flex-wrap">
                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() => handlePageChange(currentPage - 1)}
                              className={`page-link pointer ${
                                !data.prev_page_url ? "disabled" : ""
                              }`}
                            >
                              Önceki
                            </button>
                          </li>

                          {data.links.map(
                            (number, index) =>
                              index > 0 &&
                              index < data.links.length - 1 && (
                                <li
                                  key={index}
                                  className={`paginate_button page-item ${
                                    currentPage === parseInt(number.label)
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div
                                    onClick={() =>
                                      handlePageChange(parseInt(number.label))
                                    }
                                    className="page-link pointer"
                                  >
                                    {number.label}
                                  </div>
                                </li>
                              )
                          )}

                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() => handlePageChange(currentPage + 1)}
                              className={`page-link pointer ${
                                !data.next_page_url ? "disabled" : ""
                              }`}
                            >
                              Sonraki
                            </button>
                          </li>
                        </ul>
                      </div>
                    </>
                  )
                ) : (
                  <p className="mb-0">Sonuç Bulunamadı</p>
                )}
              </CardBody>
            </Card>
          )
        )}
      </CardBody>
    </Card>
  );
}

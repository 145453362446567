import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import toastMessage from "../../components/ToastMessage";
import { AppSettings } from "../../config/app-settings";

import apiClient from "../../ApiClient";
import PasswordInput from "../../components/PasswordInput";
import { ToastContainer } from "react-toastify";
import RoleInfo from "../../components/RoleInfo";

export default function AddModal({ getData }) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Add User
  const [addButtonDisabled, setAddButtonDisabled] = useState(false);
  const [addFormSelect, setAddFormSelect] = useState({
    customer: "",
    role: "",
  });
  const [addFormData, setAddFormData] = useState({
    username: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    password: "",
    password_confirmation: "",
    settings: {
      title: "",
      image: null,
      kvkk_confirm_date: null,
    },
  });

  const handleChangeAdd = (e) => {
    const { name, value } = e.target;

    setAddFormData((prevData) => {
      if (name === "unvan") {
        return {
          ...prevData,
          settings: {
            ...prevData.settings,
            title: value,
          },
        };
      }

      return {
        ...prevData,
        [name]: value,
      };
    });
  };

  const handleChangeSelectAdd = (selectedOption, selectName) => {
    setAddFormSelect((prevData) => ({
      ...prevData,
      [selectName]: selectedOption,
    }));
  };

  async function handleAddSubmit(event) {
    event.preventDefault();

    if (context?.me?.role.id === 1) {
      addFormData["customer"] = addFormSelect["customer"].value;
    } else {
      addFormData["customer"] = context.me.customer.id;
    }
    addFormData["role"] = addFormSelect["role"].value;

    if (context?.me?.role.id !== 1 && addFormData["role"] === 1) {
      toastMessage("Yönetici Atanamaz", "error");
      return;
    }

    if (addFormData.password.length < 8) {
      toastMessage("Şifre En Az 8 Karakter", "error");
      return;
    }

    if (addFormData.password !== addFormData.password_confirmation) {
      toastMessage("Şifre Aynı Girilmeli", "error");
      return;
    }

    setAddButtonDisabled(true);

    try {
      const response = await apiClient.customPost("/user", addFormData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setAddButtonDisabled(false);

        toastMessage(response.message, "success");

        document.getElementById("addModalClose").click();

        setAddFormSelect({
          customer: "",
          role: "",
        });

        setAddFormData({
          username: "",
          name: "",
          phone: "",
          email: "",
          address: "",
          password: "",
          password_confirmation: "",
        });

        getData();

        window.location.reload();
      }
    } catch (error) {
      console.error("User Add Error", error);
      setAddButtonDisabled(false);

      if (error.response.status === 422) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }

      if (error.response.status === 401) {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }

      if (error.response.status === 403) {
        alert("Bir süre sonra tekrar deneyin.");
        setTimeout(() => {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }, 1000);
      }

      if (error.response.status === 500) {
        alert("Sunucu Hatası!");
      }
    }
  }

  return (
    <>
      <div className="modal modal-cover fade" id="addModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">Kullanıcı Ekle</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="addModalClose"
              ></button>
            </div>

            <ul className="nav nav-tabs nav-tabs-v2 ps-4 pe-4">
              <li className="nav-item me-3">
                <a
                  href="#kullanici_bilgisi"
                  className="nav-link active"
                  data-bs-toggle="tab"
                >
                  Kullanıcı
                </a>
              </li>
              <li className="nav-item me-3">
                <a
                  href="#uyelik_bilgisi"
                  className="nav-link"
                  data-bs-toggle="tab"
                >
                  Üyelik
                </a>
              </li>
            </ul>

            <form onSubmit={handleAddSubmit}>
              <div className="tab-content p-4">
                <div
                  className="tab-pane fade show active"
                  id="kullanici_bilgisi"
                >
                  {context?.me?.role.id === 1 && (
                    <div className="mb-3">
                      <span className="mb-1 d-block">Müşteri Seçiniz</span>
                      <Select
                        options={
                          context.allCustomerSelect
                            ? context.allCustomerSelect
                            : []
                        }
                        placeholder=""
                        classNamePrefix="react-select"
                        defaultValue={addFormSelect.customer}
                        onChange={(selectedOption) =>
                          handleChangeSelectAdd(selectedOption, "customer")
                        }
                        noOptionsMessage={() => "Seçenek yok"}
                      />
                    </div>
                  )}

                  <div className="mb-3">
                    <RoleInfo />

                    <Select
                      options={
                        context.allRolesSelect ? context.allRolesSelect : []
                      }
                      placeholder=""
                      classNamePrefix="react-select"
                      defaultValue={addFormSelect.role}
                      onChange={(selectedOption) =>
                        handleChangeSelectAdd(selectedOption, "role")
                      }
                      required
                      noOptionsMessage={() => "Seçenek yok"}
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">Kullanıcı Adı</span>
                    <input
                      type="text"
                      className="form-control"
                      name="username"
                      value={addFormData.username}
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">Kullanıcı Ünvan</span>
                    <input
                      type="text"
                      className="form-control"
                      name="unvan"
                      value={addFormData.settings.title}
                      onChange={handleChangeAdd}
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">
                      Kullanıcı Yetkilisi Adı Soyadı
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="name"
                      value={addFormData.name}
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">
                      Kullanıcı Aktivasyon Telefonu
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      name="phone"
                      placeholder="+905555555555"
                      value={addFormData.phone}
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">Kullanıcı Email</span>
                    <input
                      type="email"
                      className="form-control"
                      name="email"
                      value={addFormData.email}
                      onChange={handleChangeAdd}
                      required
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">Kullanıcı Adresi</span>
                    <textarea
                      className="form-control"
                      name="address"
                      value={addFormData.address}
                      onChange={handleChangeAdd}
                    />
                  </div>
                </div>

                <div className="tab-pane fade" id="uyelik_bilgisi">
                  <div className="mb-3">
                    <span className="mb-1 d-block">Şifre</span>
                    <PasswordInput
                      name="password"
                      value={addFormData.password}
                      onChange={handleChangeAdd}
                    />
                  </div>

                  <div className="mb-3">
                    <span className="mb-1 d-block">Şifre Doğrula</span>
                    <PasswordInput
                      name="password_confirmation"
                      value={addFormData.password_confirmation}
                      onChange={handleChangeAdd}
                    />
                  </div>
                </div>

                <button
                  type="submit"
                  className="btn btn-outline-theme w-100"
                  disabled={addButtonDisabled}
                >
                  Kaydet
                  {addButtonDisabled && (
                    <div className="spinner-border spinner-border-sm text-theme ms-2"></div>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import PageFilter from "../../components/PageFilter";
import apiClient from "../../ApiClient";
import { Link, useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../../components/ToastMessage";
import bireyselJson from "../../json/bireysel.json";
import { CSVLink } from "react-csv";
import moment from "moment";
import { ToastContainer } from "react-toastify";
import AddReport from "../strateji-haritasi/addReport";
import { Card, CardBody } from "../../components/card/card";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

const listData = [
  {
    category: "Genel Görünüm",
    title: "Giriş Sokak Tabelası",
    answers: [
      {
        title: "İki Tarafta da <br /> Var Problemsiz",
        color: "success",
      },
      {
        title: "Var <br /> Problemli",
        color: "unnecessary",
      },
      {
        title: "Var <br /> Yanlış",
        color: "warning",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Metruk Bina",
    answers: [
      {
        title: "Yok",
        color: "success",
      },
      {
        title: "Var <br /> Problemsiz",
        color: "warning",
      },
      {
        title: "Yok <br /> Problemli",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Boş Arsa",
    answers: [
      {
        title: "Yok",
        color: "success",
      },
      {
        title: "Var <br /> Problemsiz",
        color: "warning",
      },
      {
        title: "Yok <br /> Problemli",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Metruk Araç",
    answers: [
      {
        title: "Yok",
        color: "success",
      },
      {
        title: "Var <br /> Problemsiz",
        color: "warning",
      },
      {
        title: "Yok <br /> Problemli",
        color: "danger",
      },
    ],
  },
  {
    category: "Temizlik Hizmetleri",
    title: "Genel Temizlik",
    answers: [
      {
        title: "Yeterince <br /> İyi",
        color: "success",
      },
      {
        title: "İdare Eder <br /> Seviyede",
        color: "warning",
      },
      {
        title: "Acil <br /> Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Temizlik Hizmetleri",
    title: "Süpürme Gerekliliği",
    answers: [
      {
        title: "Yeterince <br /> İyi",
        color: "success",
      },
      {
        title: "İdare Eder <br /> Seviyede",
        color: "warning",
      },
      {
        title: "Acil <br /> Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Temizlik Hizmetleri",
    title: "Çöp Toplama Gerekliliği",
    answers: [
      {
        title: "Yeterince <br /> İyi",
        color: "success",
      },
      {
        title: "İdare Eder <br /> Seviyede",
        color: "warning",
      },
      {
        title: "Acil <br /> Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Temizlik Hizmetleri",
    title: "Yıkama Gerekliliği",
    answers: [
      {
        title: "Evet",
        color: "success",
      },
      {
        title: "Hayır",
        color: "danger",
      },
    ],
  },
  {
    category: "Temizlik Hizmetleri",
    title: "Konteyner ve Çevresinin Temizliği",
    answers: [
      {
        title: "5",
        color: "success",
      },
      {
        title: "4",
        color: "no-urgent",
      },
      {
        title: "3",
        color: "unnecessary",
      },
      {
        title: "2",
        color: "warning",
      },
      {
        title: "1",
        color: "danger",
      },
    ],
  },
  {
    category: "Temizlik Hizmetleri",
    title: "Konteyner Dışı Çöp Atma",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Yol / Asfalt / Kilit Taşı",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Yaya Yolu / Kaldırım",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Engelli Yolu / Engelli Rampası",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Bebek Arabası Kullanımı",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "Kaldırım <br /> İşgal edilmiş",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Rögar / Mazgal Kapağı",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Yağmur Suyu Kanalı",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "Temizlik <br /> Gerekli",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Hız Kesici",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "İhtiyaç <br /> Yok",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Halk Sağlığı",
    title: "Duman / Kötü Koku",
    answers: [
      {
        title: "Yok",
        color: "success",
      },
      {
        title: "Var",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Aydınlatma Ekipmanları",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "3 <br /> Yok <br /> Gereksiz",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Aydınlatma Yeterli mi?",
    answers: [
      {
        title: "25m - 30m <br /> Aralığında  Evet Var",
        color: "success",
      },
      {
        title: "25m - 30m <br /> Aralığında Hayır Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Tehlite Arz Eden Yapı",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Fen İşleri",
    title: "Tehlite Teşkil Eden Elektirik Kabloları",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Zabıta Hizmetleri",
    title: "Kaldırım İşgali",
    answers: [
      {
        title: "Araç Parkı",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Esnaf / İşyeri",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Beton / Büyük Saksı",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Zabıta Hizmetleri",
    title: "Devam Eden İnşaat Çalışması",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Zabıta Hizmetleri",
    title: "Devam Eden Tadilat Çalışması",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Halk Sağlığı",
    title: "Gürültü Kirliliği",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Zabıta Hizmetleri",
    title: "Asayiş / Güvenlik Problemi",
    answers: [
      {
        title: "Haneler İçin",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Esnaf / İşyerleri İçin",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Yayalar İçin",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Zabıta Hizmetleri",
    title: "Trafik Akaşı Sorunu",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Yeşil Alan / Park ve Bahçeler",
    title: "Yeşil Alanlar",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "3 <br /> Yok",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Yeşil Alan / Park ve Bahçeler",
    title: "Ağaç ve Çiçekler",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "3 <br /> Yok",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Halk Sağlığı",
    title: "Başıboş Hayvanlar",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Halk Sağlığı",
    title: "İlaçlama İhtiyacı",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Yeşil Alan / Park ve Bahçeler",
    title: "Kaldırımlarda Budanması Gereken Ağaçlar",
    answers: [
      {
        title: "Evet",
        color: "success",
      },
      {
        title: "Hayır",
        color: "danger",
      },
    ],
  },
  {
    category: "Güvenlik",
    title: "İtfaiye geçişine uygun mu?",
    answers: [
      {
        title: "Evet",
        color: "success",
      },
      {
        title: "Hayır",
        color: "danger",
      },
    ],
  },
  {
    category: "Sosyal Alan",
    title: "Yetişkinler için Kahvehane",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Sosyal Alan",
    title: "Yetişkinler İçin Kafe / Kafeterya",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Sosyal Alan",
    title: "Sokakta Oynayan Çocuklar",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Görülen Yaklaşık Hane Sayısı",
    answers: [],
  },
  {
    category: "Genel Görünüm",
    title: "Görülen Yaklaşık Esnaf / İşyeri Sayısı",
    answers: [
      {
        title: "0 - 10",
        color: "success",
      },
      {
        title: "11 - 25",
        color: "no-urgent",
      },
      {
        title: "26 - 50",
        color: "unnecessary",
      },
      {
        title: "51 - 100",
        color: "warning",
      },
      {
        title: "101+",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Gün İçinde Görülen Yaklaşık Araç Sayısı",
    answers: [
      {
        title: "0 - 10",
        color: "success",
      },
      {
        title: "11 - 25",
        color: "no-urgent",
      },
      {
        title: "26 - 50",
        color: "unnecessary",
      },
      {
        title: "51 - 100",
        color: "warning",
      },
      {
        title: "101+",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Numarataj",
    answers: [
      {
        title: "Var <br /> Problemsiz",
        color: "success",
      },
      {
        title: "Yok",
        color: "warning",
      },
      {
        title: "Var <br /> Problemli",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Durak (Durak Adını Yazınız)",
    answers: [
      {
        title: "5 <br /> Var <br /> Sorunsuz",
        color: "success",
      },
      {
        title: "4 <br /> Bakım Gerekli <br /> Aciliyeti Yok",
        color: "no-urgent",
      },
      {
        title: "3 <br /> Yok",
        color: "unnecessary",
      },
      {
        title: "2 <br /> Bakım Yapılmalı Yenilenmeli",
        color: "warning",
      },
      {
        title: "1 <br /> Yok <br /> Acil Gerekli",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Islah Gerekli Kanal / Dere / Birikinti",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Okul",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Cami",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "STK / Dernek",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Belediye Sosyal Tesis / Bina",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Ekili Dikili Alan Var mı?",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Hayvansal Üretim Yeri Var mı?",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
  {
    category: "Genel Görünüm",
    title: "Sanayi Tesisi Var mı?",
    answers: [
      {
        title: "Var",
        color: "success",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
];

const tableHead = [
  { key: "Id", label: " #" },
  { key: "Yapilmis_Anketler", label: "Yapılmış Anketler" },
  { key: "TC", label: "TC" },
  { key: "Ad_Soyad", label: "İsim Soyisim", width: 200 },
  { key: "cinsiyet", label: "Cinsiyet", width: 70 },
  { key: "Dogum_Tarihi", label: "Doğum Tarihi" },
  // { key: "Secmen_Yasi", label: "Yaş Grubu" },
  { key: "Yas", label: "Yaş" },
  { key: "Telefon_NO", label: "Telefon" },
  // { key: "Secmen_Profili", label: "Seçmen Profili" },
  { key: "Memleket_Il", label: "Memleket İl" },
  { key: "Memleket_Ilce", label: "Memleket İlçe" },
  { key: "Adres_Il", label: "İl" },
  { key: "Adres_Ilce", label: "İlçe" },
  { key: "Adres_Mahalle", label: "Mahalle", width: 200 },
  { key: "Adres_Sokak", label: "Sokak", width: 200 },
  { key: "building", label: "Bina No" },
  { key: "independent_section", label: "Daire" },
  // {
  //   key: "Tahmini_1_Derece_Ailede_Uyelik",
  //   label: "Tahmini Ailede 1. Derece Üye",
  // },
  // {
  //   key: "Tahmini_2_Derece_Ailede_Uyelik",
  //   label: "Tahmini Ailede 2. Derece Üye",
  // },
  // { key: "Sandik_Sonucu_1", label: "1. Sandık Seçim Sonucu" },
  // { key: "Sandik_Sonucu_2", label: "2. Sandık Seçim Sonucu" },
  // { key: "Sandik_Sonucu_3", label: "3. Sandık Seçim Sonucu" },
  // { key: "Mahalle_Sonucu_1", label: "1. Mahalle Seçim Sonucu" },
  // { key: "Mahalle_Sonucu_2", label: "2. Mahalle Seçim Sonucu" },
  // { key: "Mahalle_Sonucu_3", label: "3. Mahalle Seçim Sonucu" },
  // { key: "Memleket_Sonucu_1", label: "1. Memleket Seçim Sonucu" },
  // { key: "Memleket_Sonucu_2", label: "2. Memleket Seçim Sonucu" },
  // { key: "Memleket_Sonucu_3", label: "3. Memleket Seçim Sonucu" },
];

export default function IletisimYonetimi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [hoveredTableRow, setHoveredTableRow] = useState(null);

  // Get BirthDay
  const months = [
    "Ocak",
    "Şubat",
    "Mart",
    "Nisan",
    "Mayıs",
    "Haziran",
    "Temmuz",
    "Ağustos",
    "Eylül",
    "Ekim",
    "Kasım",
    "Aralık",
  ];

  function formatDate(dateStr) {
    const [day, month, year] = dateStr.split(".").map(Number);
    return `${day} ${months[month - 1]} ${year}`;
  }

  // Filter
  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);
  const [streetFilter, setStreetFilter] = useState(null);

  // Data
  const [data, setData] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [fileColumns, setFileColumns] = useState(null);

  const [filterData, setFilterData] = useState([null]);

  const [memleketIlData, setMemleketIlData] = useState([]);
  const [memleketIlceData, setMemleketIlceData] = useState([]);

  const [memleketIlSelectValue, setMemleketIlSelectValue] = useState(null);
  const [memleketIlceSelectValue, setMemleketIlceSelectValue] = useState(null);

  // Get File Columns
  async function getFileColumns(name) {
    setFileColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        setFileColumns(response.data.columns);
      }
    } catch (error) {
      setFileColumns([]);
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    getFileColumns(`bireysel-data-customer-${context?.me?.customer?.id}`);
  }, []);

  // Get Data
  async function fetchData(page, select = null, condition, filter = [null]) {
    if (cityFilter && districtFilter) {
      if (!select) {
        setData(null);
      }

      if (select === "Memleket Il" && !condition) {
        setMemleketIlData(null);
      }

      if (select && condition) {
        setMemleketIlceData(null);
      }

      const dataColumn = fileColumns.map((item) => ({
        key: item,
        value: "select",
      }));

      const option = {
        chart: 1,
        page_id: 23,
        title: "-",
        type: `bireysel-data-customer-${context?.me?.customer?.id}`,
        description: "",
        customer: null,
        status: true,
        order: 1,
        sql: {
          column: select
            ? [
                {
                  key: condition ? "Memleket Ilce" : select,
                  value: "select",
                },
              ]
            : dataColumn,
          condition: [
            cityFilter
              ? {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            districtFilter
              ? {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            neighbourhoodFilter
              ? {
                  key: "Adres Mahalle",
                  value: context
                    .removeTurkishCharacters(neighbourhoodFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            streetFilter
              ? {
                  key: "Adres Sokak",
                  value: context
                    .removeTurkishCharacters(streetFilter.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                }
              : null,
            select && condition
              ? {
                  key: select,
                  value: condition,
                  operator: "=",
                  type: "and",
                }
              : null,
            ...filter,
          ].filter((item) => item !== null),
          group: [],
        },
      };

      if (!select) {
        option.paginate = true;
      }

      try {
        const response = await apiClient.customPost(
          `/query/preview?page=${page}`,
          option,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        if (response) {
          if (select === "Memleket Il" && !condition) {
            const sortedResponse = response.sort((a, b) => {
              if (a["memleket il"] < b["memleket il"]) {
                return -1;
              }
              if (a["memleket il"] > b["memleket il"]) {
                return 1;
              }
              return 0;
            });

            setMemleketIlData(
              sortedResponse.map((element) => {
                return {
                  value: element["memleket il"],
                  label: element["memleket il"],
                };
              })
            );
          }

          if (select && condition) {
            const sortedResponse = response.sort((a, b) => {
              if (a["memleket ilce"] < b["memleket ilce"]) {
                return -1;
              }
              if (a["memleket ilce"] > b["memleket ilce"]) {
                return 1;
              }
              return 0;
            });

            setMemleketIlceData(
              sortedResponse.map((element) => {
                return {
                  value: element["memleket ilce"],
                  label: element["memleket ilce"],
                };
              })
            );
          }

          if (!select) {
            if (response.length !== 0) {
              setData(response);
            } else {
              setData({ data: [] });
            }
          }
        }
      } catch (error) {
        setData({ data: [] });
        setMemleketIlData([]);
        console.error(error);
      }

      //--

      handleMuhtarlar();
      handleDernekler();
      handleSendikalar();
    }
  }

  useEffect(() => {
    if (fileColumns && cityFilter && districtFilter) {
      fetchData(1, null);
      fetchData(1, "Memleket Il");
    }
  }, [
    fileColumns,
    cityFilter,
    districtFilter,
    neighbourhoodFilter,
    streetFilter,
  ]);

  // Pagination
  function handlePagination(value) {
    setCurrentPage(value);
    fetchData(value, null, null, filterData);
  }

  // Handle Filter
  function handleFilter() {
    const filterArray = [
      memleketIlSelectValue
        ? {
            key: "Memleket Il",
            value: memleketIlSelectValue.label,
            operator: "=",
            type: "and",
          }
        : null,
      memleketIlceSelectValue
        ? {
            key: "Memleket Ilce",
            value: memleketIlceSelectValue.label,
            operator: "=",
            type: "and",
          }
        : null,
    ];

    setFilterData(filterArray);

    fetchData(1, null, null, filterArray);
  }

  //--

  // Atanmış Anketleri Getir
  const [assigmentSelect, setAssigmentSelect] = useState(null);

  const [assigmentData, setAssigmentData] = useState([]);

  async function handleGetReports() {
    setAssigmentData(null);

    try {
      const response = await apiClient.get(
        `/street-report-assigment/${context?.me?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        const formattedData = response.data.map((item) => ({
          value: item.report.id,
          label: item.report.name,
          ...item,
        }));

        setAssigmentData(formattedData);
      } else {
        setAssigmentData([]);
      }
    } catch (error) {
      setAssigmentData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (context?.me) {
      handleGetReports();
    }
  }, [context]);

  // Anket Show
  const [surveyData, setSurveyData] = useState(null);

  async function handleGetShowReport(id) {
    try {
      const response = await apiClient.get(`/street-report/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSurveyData(response.data);
    } catch (error) {
      setSurveyData(null);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (assigmentSelect) {
      handleGetShowReport(assigmentSelect.value);
    }
  }, [assigmentSelect]);

  // Muhtarlar
  const [muhtarlikData, setMuhtarlikData] = useState(false);

  const handleMuhtarlar = async () => {
    setMuhtarlikData(null);

    const conditionData = [
      {
        key: "Il adı",
        value: cityFilter.label,
        operator: "=",
        type: "and",
      },
      {
        key: "IlCe Adı",
        value: districtFilter.label,
        operator: "=",
        type: "and",
      },
      neighbourhoodFilter
        ? {
            key: "Mahalle Adı",
            value: neighbourhoodFilter.label,
            operator: "=",
            type: "and",
          }
        : null,
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "-",
          type: "muhtarlik-data",
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "Muhtar Adı",
                value: "select",
              },
              {
                key: "MuhtarTelefonu",
                value: "select",
              },
              {
                key: "Muhtarlık Adı",
                value: "select",
              },
              {
                key: "Il adı",
                value: "select",
              },
              {
                key: "IlCe Adı",
                value: "select",
              },
              {
                key: "Mahalle Adı",
                value: "select",
              },
              {
                key: "Adres",
                value: "select",
              },
              {
                key: "Longitude",
                value: "select",
              },
              {
                key: "Latitude",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setMuhtarlikData(response);
    } catch (error) {
      setMuhtarlikData([]);
      console.error(error);
    }
  };

  // Dernekler
  const [derneklerData, setDerneklerData] = useState(false);

  const handleDernekler = async () => {
    setDerneklerData(null);

    const conditionData = [
      {
        key: "Il",
        value: cityFilter.label,
        operator: "=",
        type: "and",
      },
      {
        key: "Ilce",
        value: districtFilter.label,
        operator: "=",
        type: "and",
      },
      neighbourhoodFilter
        ? {
            key: "Mahalle",
            value: neighbourhoodFilter.label,
            operator: "=",
            type: "and",
          }
        : null,
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "-",
          type: "dernekler-data",
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "Il",
                value: "select",
              },
              {
                key: "Ilce",
                value: "select",
              },
              {
                key: "Mahalle",
                value: "select",
              },
              {
                key: "Kurum Adres",
                value: "select",
              },
              {
                key: "Faaliyet Alani",
                value: "select",
              },
              {
                key: "Kurum Adi",
                value: "select",
              },
              {
                key: "Telefon",
                value: "select",
              },
              {
                key: "Detayli Faaliyet Alani",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setDerneklerData(response);
    } catch (error) {
      setDerneklerData([]);
      console.error(error);
    }
  };

  // Sendikalar
  const [sendikalarData, setSendikalarData] = useState(false);

  const handleSendikalar = async () => {
    setSendikalarData(null);

    const conditionData = [
      {
        key: "IL",
        value: cityFilter.label,
        operator: "=",
        type: "and",
      },
      {
        key: "ILCE",
        value: districtFilter.label,
        operator: "=",
        type: "and",
      },
      neighbourhoodFilter
        ? {
            key: "Mah",
            value: neighbourhoodFilter.label,
            operator: "=",
            type: "and",
          }
        : null,
    ].filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 24,
          title: "-",
          type: "sendikalar-data",
          description: "",
          status: true,
          customer: null,
          order: 1,
          sql: {
            column: [
              {
                key: "SAYI",
                value: "select",
              },
              {
                key: "IL",
                value: "select",
              },
              {
                key: "ILCE",
                value: "select",
              },
              {
                key: "Mah",
                value: "select",
              },
              {
                key: "SendIka AdresI",
                value: "select",
              },
              {
                key: "Sendika Is Kolu",
                value: "select",
              },
              {
                key: "Sendika Adi",
                value: "select",
              },
            ],
            condition: conditionData,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setSendikalarData(response);
    } catch (error) {
      setSendikalarData([]);
      console.error(error);
    }
  };

  return (
    <>
      <div className="row gap-4 mb-5 pb-5">
        <PageFilter
          setCityFilter={setCityFilter}
          setDistrictFilter={setDistrictFilter}
          setNeighbourhoodFilter={setNeighbourhoodFilter}
          setStreetFilter={setStreetFilter}
        />

        <div className="col-12">
          <div className="border-top pt-4">
            <div className="row">
              <div className="col col-8">
                <div className="row g-3 align-items-end">
                  <div className="col">
                    <div className="d-flex gap-1">
                      <div className="flex-1">
                        {memleketIlData ? (
                          <div>
                            <span className="d-block mb-1">Memleket Il</span>
                            <div className="d-flex gap-1">
                              <div className="flex-1">
                                <Select
                                  options={memleketIlData}
                                  classNamePrefix="react-select"
                                  noOptionsMessage={() => "Seçenek Yok"}
                                  value={memleketIlSelectValue}
                                  onChange={(e) => {
                                    fetchData(1, "Memleket Il", e.label);
                                    setMemleketIlSelectValue(e);
                                    setMemleketIlceSelectValue(null);
                                  }}
                                />
                              </div>

                              {memleketIlSelectValue && (
                                <button
                                  onClick={() => {
                                    setMemleketIlSelectValue(null);
                                    setMemleketIlceData(null);
                                    setTimeout(() => {
                                      setMemleketIlceData([]);
                                    }, 300);
                                    setMemleketIlceSelectValue(null);
                                  }}
                                  className="btn btn-danger btn-sm"
                                  style={{ borderRadius: 4 }}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                              )}
                            </div>
                          </div>
                        ) : (
                          <div className="spinner-border text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col">
                    {memleketIlceData ? (
                      <div>
                        <span className="d-block mb-1">Memleket Ilce</span>
                        <div className="d-flex gap-1">
                          <div className="flex-1">
                            <Select
                              options={memleketIlceData}
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={memleketIlceSelectValue}
                              onChange={(e) => setMemleketIlceSelectValue(e)}
                            />
                          </div>

                          {memleketIlceSelectValue && (
                            <button
                              onClick={() => {
                                setMemleketIlceSelectValue(null);
                              }}
                              className="btn btn-danger btn-sm"
                              style={{ borderRadius: 4 }}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="spinner-border text-theme"></div>
                    )}
                  </div>

                  <div className="col w-auto flex-none">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 px-4"
                      disabled={districtFilter ? false : true}
                      onClick={() => handleFilter()}
                    >
                      Getir
                    </button>
                    {/* {cityFilter &&
                        districtFilter &&
                        data &&
                        data.data.length !== 0 && (
                          <div className="col-6">
                            <CSVLink
                              data={data.data.map((item, index) => ({
                                ...item,
                                Id: index + 1,
                                TC: context.me?.customer?.settings?.primary
                                  ? item?.tc || "Bilinmiyor"
                                  : "--",
                                Ad_Soyad: context.me?.customer?.settings
                                  ?.primary
                                  ? item["ad soyad"] || "Bilinmiyor"
                                  : "--",
                                Dogum_Tarihi: context.me?.customer?.settings
                                  ?.primary
                                  ? item["dogum tarihi"]
                                    ? formatDate(item["dogum tarihi"])
                                    : "Bilinmiyor"
                                  : "--",
                                Secmen_Yasi:
                                  item["secmen yasi"] || "Bilinmiyor",
                                Yas: context.me?.customer?.settings?.primary
                                  ? item["dogum tarihi"]
                                    ? new Date().getFullYear() -
                                      item["dogum tarihi"].split(".")[2]
                                    : "Bilinmiyor"
                                  : "--",
                                Telefon_NO: context.me?.customer?.settings
                                  ?.primary
                                  ? item["telefon no"] || "Bilinmiyor"
                                  : "--",
                                Secmen_Profili:
                                  item["secmen profili"] || "Bilinmiyor",
                                Memleket_Il:
                                  item["memleket il"] || "Bilinmiyor",
                                Memleket_Ilce:
                                  item["memleket ilce"] || "Bilinmiyor",
                                Adres_Il: item["adres il"] || "Bilinmiyor",
                                Adres_Ilce: item["adres ilce"] || "Bilinmiyor",
                                Adres_Mahalle:
                                  item["adres mahalle"] || "Bilinmiyor",
                                Adres_Sokak:
                                  item["adres sokak"] || "Bilinmiyor",
                                building: item["bina no"] || "Bilinmiyor",
                                independent_section:
                                  item["daire"] || "Bilinmiyor",
                                Tahmini_1_Derece_Ailede_Uyelik:
                                  item["tahmini 1. derece ailede uyelik"] ||
                                  "Bilinmiyor",
                                Tahmini_2_Derece_Ailede_Uyelik:
                                  item["tahmini 2. derece ailede uyelik"] ||
                                  "Bilinmiyor",
                                Memleket_Sonucu_1:
                                  item["memleket sonucu 1"] || "Bilinmiyor",
                                Memleket_Sonucu_2:
                                  item["memleket sonucu 2"] || "Bilinmiyor",
                                Memleket_Sonucu_3:
                                  item["memleket sonucu 3"] || "Bilinmiyor",
                                Sandik_Sonucu_1:
                                  item["sandık sonucu 1"] || "Bilinmiyor",
                                Sandik_Sonucu_2:
                                  item["sandık sonucu 2"] || "Bilinmiyor",
                                Sandik_Sonucu_3:
                                  item["sandık sonucu 3"] || "Bilinmiyor",
                              }))}
                              headers={tableHead}
                              filename="stratejimerkezi_iletisim_yonetimi.csv"
                              className="btn btn-outline-theme w-100"
                            >
                              Tablo İndir
                            </CSVLink>
                          </div>
                        )} */}
                  </div>

                  <div className="col w-auto flex-none">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 px-4"
                    >
                      Tablo İndir
                    </button>
                  </div>
                </div>
              </div>

              <div className="col border-start">
                <div className="row align-items-end">
                  <div className="col">
                    <span className="d-block mb-1">Filtreler</span>
                    <Select
                      options={[]}
                      classNamePrefix="react-select"
                      placeholder="Filtre Seçin"
                      noOptionsMessage={() => "Seçenek Yok"}
                    />
                  </div>

                  <div className="col">
                    <span className="d-block mb-1">Filtre Adı</span>
                    <input type="text" className="form-control" />
                  </div>

                  <div className="col w-auto flex-none">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100"
                    >
                      Filtre Ekle
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-12">
          <div className="row">
            <div className="col-12">
              <h5 className="border-top pt-4 mb-3">FİLTRE TABLOSU</h5>
            </div>
            {cityFilter && districtFilter ? (
              data ? (
                <div className="col-12">
                  {data.data.length !== 0 ? (
                    <>
                      <div className="dataTables_wrapper dt-bootstrap5">
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: "calc(100vh - 500px)" }}
                          >
                            <table className="table text-nowrap w-100 dataTable hide-data">
                              <thead>
                                <tr>
                                  {tableHead
                                    .filter(
                                      (item) =>
                                        item.key !== "Yapilmis_Anketler" ||
                                        context?.me?.role?.id === 1 ||
                                        context?.me?.role?.id === 2
                                    )
                                    .map((item, index) => (
                                      <th
                                        className="text-start p-3"
                                        key={index}
                                        style={{
                                          minWidth: item.width || "auto",
                                        }}
                                      >
                                        {item.label}
                                      </th>
                                    ))}
                                </tr>
                              </thead>

                              <tbody>
                                {data.data.map((item, index) => (
                                  <tr
                                    key={index}
                                    onMouseEnter={() =>
                                      setHoveredTableRow(index)
                                    }
                                    onMouseLeave={() =>
                                      setHoveredTableRow(null)
                                    }
                                  >
                                    <td
                                      style={{ minWidth: 80 }}
                                      className="p-3"
                                    >
                                      {index + 1}
                                    </td>
                                    {(context?.me?.role?.id === 1 ||
                                      context?.me?.role?.id === 2 ||
                                      context?.me?.role?.id === 4) && (
                                      <td
                                        style={{ minWidth: 80 }}
                                        className="p-3"
                                      >
                                        <button
                                          type="button"
                                          className="btn btn-outline-theme btn-sm me-2"
                                          data-bs-toggle="modal"
                                          data-bs-target="#reportDetailModal"
                                        >
                                          Anketler
                                        </button>
                                        {(context?.me?.role?.id === 1 ||
                                          context?.me?.role?.id === 5) && (
                                          <button
                                            type="button"
                                            className="btn btn-outline-theme btn-sm"
                                            data-bs-toggle="modal"
                                            data-bs-target="#kisiAnketiModal"
                                          >
                                            Yeni Anket
                                          </button>
                                        )}
                                      </td>
                                    )}
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? context.me?.customer?.settings
                                            ?.primary
                                          ? item?.tc || "Bilinmiyor"
                                          : "--"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? context.me?.customer?.settings
                                            ?.primary
                                          ? item["ad soyad"] || "Bilinmiyor"
                                          : "--"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? context.me?.customer?.settings
                                            ?.primary
                                          ? item["cinsiyet"] || "Bilinmiyor"
                                          : "--"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? context.me?.customer?.settings
                                            ?.primary
                                          ? item["dogum tarihi"]
                                            ? formatDate(item["dogum tarihi"])
                                            : "Bilinmiyor"
                                          : "--"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? context.me?.customer?.settings
                                            ?.primary
                                          ? item["dogum tarihi"]
                                            ? new Date().getFullYear() -
                                              item["dogum tarihi"].split(".")[2]
                                            : "Bilinmiyor"
                                          : "--"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? context.me?.customer?.settings
                                            ?.primary
                                          ? context.phoneFormat(
                                              item["telefon no"]
                                            )
                                          : "--"
                                        : "********"}
                                    </td>
                                    {/* <td style={{ minWidth: 150 }} className="p-3">
                                {item["secmen profili"] || "Bilinmiyor"}
                              </td> */}
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["memleket il"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["memleket ilce"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["adres il"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["adres ilce"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["adres mahalle"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["adres sokak"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["bina no"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    <td
                                      style={{ minWidth: 150 }}
                                      className="p-3"
                                    >
                                      {hoveredTableRow === index
                                        ? item["daire"] || "Bilinmiyor"
                                        : "********"}
                                    </td>
                                    {/* <td
                                style={{ minWidth: 250 }}
                                className="text-center p-3"
                              >
                                {item["tahmini 1. derece ailede uyelik"] ||
                                  "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 250 }}
                                className="text-center p-3"
                              >
                                {item["tahmini 2. derece ailede uyelik"] ||
                                  "Bilinmiyor"}
                              </td> */}
                                    {/* <td
                                style={{ minWidth: 200 }}
                                className="text-center p-3"
                              >
                                {item["sandık sonucu 1"] || "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 200 }}
                                className="text-center p-3"
                              >
                                {item["sandık sonucu 2"] || "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 200 }}
                                className="text-center p-3"
                              >
                                {item["sandık sonucu 3"] || "Bilinmiyor"}
                              </td>

                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["mahalle sonucu 1"] || "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["mahalle sonucu 2"] || "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["mahalle sonucu 3"] || "Bilinmiyor"}
                              </td>

                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["memleket sonucu 1"] || "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["memleket sonucu 2"] || "Bilinmiyor"}
                              </td>
                              <td
                                style={{ minWidth: 220 }}
                                className="text-center p-3"
                              >
                                {item["memleket sonucu 3"] || "Bilinmiyor"}
                              </td> */}
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="row align-items-center mt-3">
                          <div className="mb-0 col-md-12">
                            <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                              <ul className="pagination">
                                <li
                                  className={`paginate_button page-item ${
                                    !data.prev_page_url && "disabled"
                                  }`}
                                >
                                  <Link
                                    to="#"
                                    onClick={() =>
                                      data.prev_page_url &&
                                      handlePagination(
                                        data.prev_page_url.split("?page=")[1]
                                      )
                                    }
                                    className="page-link"
                                  >
                                    Geri
                                  </Link>
                                </li>

                                {data.links.map(
                                  (item, index) =>
                                    !isNaN(parseInt(item.label)) && (
                                      <li
                                        key={index}
                                        className={`paginate_button page-item ${
                                          item.active ? "active" : ""
                                        }`}
                                      >
                                        <Link
                                          to="#"
                                          onClick={() =>
                                            handlePagination(item.label)
                                          }
                                          className="page-link"
                                        >
                                          {item.label}
                                        </Link>
                                      </li>
                                    )
                                )}

                                <li
                                  className={`paginate_button page-item ${
                                    !data.next_page_url && "disabled"
                                  }`}
                                >
                                  <Link
                                    to={"#"}
                                    onClick={() =>
                                      data.next_page_url &&
                                      handlePagination(
                                        data.next_page_url.split("?page=")[1]
                                      )
                                    }
                                    className="page-link"
                                  >
                                    İleri
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <span className="d-block">Analiz Verisi Eklenmemiştir</span>
                  )}
                </div>
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block">
                İl ve İlçe Filtrelemeniz Gerekiyor
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="mt-4 pt-4 border-top">
            <h5 className="mb-4">MUHTARLIKLAR</h5>

            {cityFilter && districtFilter ? (
              muhtarlikData ? (
                muhtarlikData.length !== 0 ? (
                  <div className="dataTables_wrapper dt-bootstrap5">
                    <div className="dataTables_scroll">
                      <div
                        className="dataTables_scrollBody table-responsive"
                        style={{ height: "calc(100vh - 500px)" }}
                      >
                        <table className="table text-nowrap w-100 dataTable">
                          <thead>
                            <tr>
                              <th className="px-1 text-center">İl</th>
                              <th className="px-1 text-center">İlçe</th>
                              <th className="px-1 text-center">Mahalle</th>
                              <th className="px-1 text-center">
                                Muhtarlık Adı
                              </th>
                              <th className="px-1 text-center">Muhtar Adı</th>
                              <th className="px-1 text-center">
                                Muhtar Telefon
                              </th>
                              <th className="px-1 text-center">Adres</th>
                              <th className="px-1 text-center">Konum</th>
                            </tr>
                          </thead>

                          <tbody>
                            {muhtarlikData.map((item, index) => (
                              <tr key={index}>
                                <td className="py-3 text-center">
                                  {item["il adı"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["ilce adı"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["mahalle adı"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["muhtarlık adı"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["muhtar adı"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["muhtartelefonu"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["adres"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["latitude"] && item["longitude"] ? (
                                    <a
                                      href={`https://www.google.com/maps?q=${item[
                                        "latitude"
                                      ].replace(",", ".")},${item[
                                        "longitude"
                                      ].replace(",", ".")}`}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      GÖRÜNTÜLE
                                    </a>
                                  ) : (
                                    "Bilinmiyor"
                                  )}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span>Analiz Verisi Eklenmemiştir</span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block">
                İl ve İlçe Filtrelemeniz Gerekiyor
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="mt-4 pt-4 border-top">
            <h5 className="mb-4">DERNEKLER</h5>

            {cityFilter && districtFilter ? (
              derneklerData ? (
                derneklerData.length !== 0 ? (
                  <div className="dataTables_wrapper dt-bootstrap5">
                    <div className="dataTables_scroll">
                      <div
                        className="dataTables_scrollBody table-responsive"
                        style={{ maxHeight: 500 }}
                      >
                        <table className="table text-nowrap w-100 dataTable mb-0">
                          <thead>
                            <tr>
                              <th className="px-1 text-center">İl</th>
                              <th className="px-1 text-center">İlçe</th>
                              <th className="px-1 text-center">Mahalle</th>
                              <th className="px-1 text-center">Kurum Adı</th>
                              <th className="px-1 text-center">Telefon</th>
                              <th className="px-1 text-center">
                                Faaliye Alanı
                              </th>
                              <th className="px-1 text-center">
                                Detaylı Faaliyet Alanı
                              </th>
                              <th className="px-1 text-center">Kurum Adres</th>
                            </tr>
                          </thead>

                          <tbody>
                            {derneklerData.map((item, index) => (
                              <tr key={index}>
                                <td className="py-3 text-center">
                                  {item["il"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["ilce"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["mahalle"]}
                                </td>
                                <td className="py-3 text-start border-start">
                                  {item["kurum adi"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["telefon"]}
                                </td>
                                <td className="py-3 text-start border-start">
                                  {item["faaliyet alani"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["detayli faaliyet alani"]}
                                </td>
                                <td className="py-3 text-start border-start">
                                  {item["kurum adres"]}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span>Analiz Verisi Eklenmemiştir</span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block">
                İl ve İlçe Filtrelemeniz Gerekiyor
              </span>
            )}
          </div>
        </div>

        <div className="col-12">
          <div className="mt-4 pt-4 border-top">
            <h5 className="mb-4">SENDİKALAR</h5>

            {cityFilter && districtFilter ? (
              sendikalarData ? (
                sendikalarData.length !== 0 ? (
                  <div className="dataTables_wrapper dt-bootstrap5">
                    <div className="dataTables_scroll">
                      <div
                        className="dataTables_scrollBody table-responsive"
                        style={{ maxHeight: 500 }}
                      >
                        <table className="table text-nowrap w-100 dataTable mb-0">
                          <thead>
                            <tr>
                              <th className="px-1 text-center">İl</th>
                              <th className="px-1 text-center">İlçe</th>
                              <th className="px-1 text-center">Mahalle</th>
                              <th className="px-1 text-center">Adı</th>
                              <th className="px-1 text-center">İş Kolu</th>
                              <th className="px-1 text-center">Adres</th>
                              <th className="px-1 text-center">Sayı</th>
                            </tr>
                          </thead>

                          <tbody>
                            {sendikalarData.map((item, index) => (
                              <tr key={index}>
                                <td className="py-3 text-center">
                                  {item["il"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["ilce"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["mah"]}
                                </td>
                                <td className="py-3 text-start border-start">
                                  {item["sendika adresi"]}
                                </td>
                                <td className="py-3 text-start border-start">
                                  {item["sendika is kolu"]}
                                </td>
                                <td className="py-3 text-start border-start">
                                  {item["sendika adi"]}
                                </td>
                                <td className="py-3 text-center border-start">
                                  {item["sayi"]}
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <span>Analiz Verisi Eklenmemiştir</span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block">
                İl ve İlçe Filtrelemeniz Gerekiyor
              </span>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="kisiAnketiModal">
        <div
          className="modal-dialog py-4 px-sm-4"
          style={{
            maxWidth: 500,
          }}
        >
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">Anket Seç</h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="kisiAnketiModalClose"
              ></button>
            </div>

            <div className="modal-body">
              <div style={{ width: 300 }}>
                <span className="d-block mb-2">Atanmış Anketler</span>
                {assigmentData ? (
                  <Select
                    options={assigmentData}
                    placeholder="Anket Seç"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "Seçenek Yok"}
                    value={assigmentSelect}
                    onChange={(e) => {
                      setAssigmentSelect(e);
                      document
                        .getElementById("reportQuestionsModalButton")
                        .click();
                    }}
                  />
                ) : (
                  <div className="spinner-border spinner-border-sm text-theme"></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="reportDetailModal">
        <div className="modal-dialog modal-lg py-4" style={{ maxWidth: 1500 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">Araştırma Cevapları</h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {(context?.me?.role?.id === 1 || context?.me?.role?.id === 2) && (
              <div className="modal-body">
                <div className="mb-3 w-100" style={{ maxWidth: 300 }}>
                  <span className="mb-1 d-block">Anket Seç</span>
                  <Select
                    options={[
                      { value: "Anket Adı", label: "Anket Adı" },
                      {
                        value: "Anket Adı 2",
                        label: "Anket Adı 2",
                      },
                    ]}
                    placeholder="Anket Seç"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "Seçenek Yok"}
                  />
                </div>

                <hr />

                <div className="row gap-4 mb-4">
                  <div className="col-12">
                    <Card>
                      <CardBody>
                        <div className="table-responsive">
                          <table className="table border align-middle">
                            <tbody>
                              {listData.map((item, index) => (
                                <tr key={index}>
                                  <td className="py-2 text-center px-0 text-white">
                                    #{index + 1}
                                  </td>
                                  <td className="py-3 border-start">
                                    {/* <span className="d-block">
                                        {item.category}
                                      </span> */}
                                    <span className="d-block h4 mt-1 mb-0">
                                      {item.title}
                                    </span>
                                  </td>
                                  <td className="py-2 border-start">
                                    {item.answers.length !== 0 ? (
                                      <div
                                        className="row g-1 overflow-hidden my-0"
                                        style={{ borderRadius: 8 }}
                                      >
                                        {item.answers.map(
                                          (answer, answerIndex) => (
                                            <div
                                              key={answerIndex}
                                              className="question-answer-button pe-none col p-0 my-0 d-flex flex-column justify-content-between"
                                            >
                                              <input
                                                hidden
                                                type={
                                                  answer.type
                                                    ? "checkbox"
                                                    : "radio"
                                                }
                                                id={
                                                  answer.type
                                                    ? `${index + 1}_${
                                                        answer.title
                                                      }`
                                                    : `${index + 1}_${
                                                        answer.color
                                                      }`
                                                }
                                                name={`${
                                                  index + 1
                                                }_question_answer`}
                                                checked={
                                                  answerIndex === 0
                                                    ? true
                                                    : false
                                                }
                                                readOnly
                                              />
                                              <label
                                                htmlFor={
                                                  answer.type
                                                    ? `${index + 1}_${
                                                        answer.title
                                                      }`
                                                    : `${index + 1}_${
                                                        answer.color
                                                      }`
                                                }
                                                className={`py-3 h-100 d-flex pointer align-items-center justify-content-center text-center text-uppercase fw-bold text-white bg-dark`}
                                                dangerouslySetInnerHTML={{
                                                  __html: answer.title,
                                                }}
                                              />
                                            </div>
                                          )
                                        )}
                                      </div>
                                    ) : (
                                      <textarea
                                        type="text"
                                        className="form-control"
                                        placeholder="Cevabınızı Yazınız..."
                                        style={{ resize: "none", height: 80 }}
                                        value="5 kişiyiz"
                                        readOnly
                                      />
                                    )}
                                  </td>
                                  <td className="border-start">
                                    {index === 22 ||
                                    index === 20 ||
                                    index === 15 ? (
                                      <p className="mb-0">
                                        Açıklama: <br /> Deneme açıklama metni
                                      </p>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                  <td className="border-start">
                                    {index === 22 ? (
                                      <a
                                        data-fancybox
                                        href="http://www.golyapi.com.tr/upload/hizmetler/kaldirim_yapimi.jpg"
                                      >
                                        <img
                                          src="http://www.golyapi.com.tr/upload/hizmetler/kaldirim_yapimi.jpg"
                                          width={100}
                                          alt=""
                                        />
                                      </a>
                                    ) : (
                                      "-"
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </CardBody>
                    </Card>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <AddReport
        surveyInnerPage={true}
        assigmentSelect={assigmentSelect}
        surveyData={surveyData}
      />

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

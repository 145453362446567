import React, { useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Card, CardBody } from "../../components/card/card.jsx";
import { AppSettings } from "../../config/app-settings.js";

function PagesError() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  function handleBackBtn() {
    if (localStorage.getItem("user")) {
      if (context?.me?.role?.id === 3) {
        navigate("/strateji-haritasi");
      } else if (context?.me?.role?.id === 4) {
        navigate("/hane-ve-birey-analizi");
      } else if (context?.me?.role?.id === 5) {
        navigate("/arastirma-raporu");
      } else {
        navigate("/durum-analizi", {
          state: { menu: { id: 51 } },
        });
      }
    } else {
      navigate("/giris-yap");
    }
  }

  useEffect(() => {
    context.setAppHeaderNone(true);
    context.setThemePanel(false);
    context.setAppScrollButton(false);
    context.setAppWeather(false);
    context.setAppSidebarNone(true);
    context.setAppFooterFull(true);
    context.setAppContentClass("p-0");

    return function cleanUp() {
      context.setThemePanel(true);
      context.setAppHeaderNone(false);
      context.setAppScrollButton(true);
      context.setAppWeather(true);
      context.setAppFooterFull(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className="login error-page">
      <div className="error-page-content">
        <Card className="mb-5 mx-auto" style={{ maxWidth: "320px" }}>
          <CardBody>
            <Card>
              <div className="error-code">404</div>
            </Card>
          </CardBody>
        </Card>

        <h1>Oops!</h1>

        <h3>Aradığınız sayfayı bulamıyoruz</h3>

        <button
          onClick={handleBackBtn}
          className="btn btn-outline-theme px-3 rounded-pill mt-3"
        >
          <i className="fa fa-arrow-left me-1 ms-n1"></i> Geri Dön
        </button>
      </div>
    </div>
  );
}

export default PagesError;

import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, useRoutes, useLocation } from "react-router-dom";
import AppRoute from './config/app-route.jsx';
import { slideToggle } from './composables/slideToggle.js';

// bootstrap
import 'bootstrap';

// css
import 'bootstrap-icons/font/bootstrap-icons.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './scss/styles.scss';

const container = document.getElementById('root');
const root = createRoot(container);

// function findTitleForPath(menus, targetPath) {
// 	for (const menu of menus) {
// 		if (menu.path === targetPath) {
// 			return menu.title;
// 		} else if (menu.children) {
// 			const childMatch = findTitleForPath(menu.children, targetPath);
// 			if (childMatch) {
// 				return childMatch;
// 			}
// 		}
// 	}
// 	return null;
// }

function App() {
	if (localStorage.getItem("user")) {
		const filteredAppRoute = AppRoute[0].children.filter(function (page) {
			if (page.removed) {
				if (localStorage.getItem("user") &&
					JSON.parse(localStorage.getItem("user")).role.id === 3) {
					return page.path === "strateji-haritasi" || page.path === "kentmetre-haritasi" || page.path === "profil";
				} else if (localStorage.getItem("user") &&
					JSON.parse(localStorage.getItem("user")).role.id === 4) {
					return page.path === "hane-ve-birey-analizi" || page.path === "halkla-iletisim-yonetimi" || page.path === "hazir-raporlar" || page.path === "kullanicilar" || page.path === "profil";
				} else if (localStorage.getItem("user") &&
					JSON.parse(localStorage.getItem("user")).role.id === 5) {
					return page.path === "arastirma-raporu" || page.path === "profil";
				} else {
					return page;
				}
			} else {
				return page
			}
		});

		AppRoute[0].children = filteredAppRoute
	} else {
		const filteredAppRoute = AppRoute[0].children.filter(function (page) {
			if (!page.removed) {
				return page;
			}
		});

		AppRoute[0].children = filteredAppRoute
	}

	let element = useRoutes(AppRoute);

	let location = useLocation();

	// on every route change
	React.useEffect(() => {
		window.scrollTo(0, 0);
		window.speechSynthesis.cancel();

		var elm = document.querySelector('.app');
		if (elm) {
			elm.classList.remove('app-sidebar-mobile-toggled');
			// elm.querySelector(".app-content-title").textContent = findTitleForPath(menus, element.props.match.pathname)
		}
		var elm2 = document.querySelector('.app-top-nav');
		if (elm2 && elm2.style.display === 'block') {
			slideToggle(document.querySelector('.app-top-nav'));
		}
	}, [location]);

	return element;
}

root.render(
	<Router>
		<App />
	</Router>
);

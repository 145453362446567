import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";

import toastMessage from "../../components/ToastMessage";
import { CSVLink } from "react-csv";

export default function AnalysisTable({
  cityId,
  districtId,
  neighbourhoodId,
  neighbourhoodName,
  setSandikCalimasiModalData,
}) {
  const context = useContext(AppSettings);

  // 2023 Result
  const [partyResult, setPartyResult] = useState([]);

  // Chest Result Data
  const [allChestResult, setAllChestResult] = useState([]);

  // Analysis Data
  const [analysisTable, setAnalysisTable] = useState(false);

  async function getAnalysisTable(city, district, neighbourhood, street) {
    setAnalysisTable(null);
    console.log(
      context.removeTurkishCharacters(city).toUpperCase(),
      context.removeTurkishCharacters(district).toUpperCase(),
      neighbourhood.toUpperCase()
    );
  }

  useEffect(() => {
    getAnalysisTable(cityId, districtId, neighbourhoodName, null);
  }, [cityId, districtId, neighbourhoodName]);

  return (
    <div className="d-flex flex-column h-100">
      <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
        <span className="h6 mb-0">
          <strong>{neighbourhoodName}</strong> - Sandık Seçim Sonucu Analizi
          <br />
          <span className="mt-1 d-block">
            <strong>{analysisTable?.length}</strong> Tane Sonuç Bulundu
          </span>
        </span>

        {/* {analysisTable && analysisTable.length !== 0 && (
          <CSVLink
            data={analysisTable.map((item, index) => {
              const partyData = partyResult[0]?.data;
              const chestData = allChestResult[index]?.data;

              const totalParty = partyData
                ? partyData.reduce((acc, curr) => {
                    if ([8, 12, 19, 13].includes(curr.parameter)) {
                      acc += curr.total;
                    }
                    return acc;
                  }, 0)
                : 0;

              const totalYuzde = chestData
                ? chestData.reduce((acc, curr) => {
                    if ([8, 12, 19, 13].includes(curr.Parametre)) {
                      acc += curr.Yuzde;
                    }
                    return acc;
                  }, 0)
                : 0;

              let colorText, colorInfo;
              const diff = (totalYuzde - totalParty).toFixed(2);

              if (diff <= -10) {
                colorText = "Kaybettiren Sandık";
                colorInfo = "%-10 ve Daha Altı";
              } else if (diff > -10 && diff <= -5) {
                colorText = "Ortalamayı Düşüren Sandık";
                colorInfo = "%-10 ve %-5";
              } else if (diff > -5 && diff <= -2.5) {
                colorText = "Ortalama Altı Kaybedilen Sandık";
                colorInfo = "%-5 ve %-2.5";
              } else if (diff > -2.5 && diff <= 2.5) {
                colorText = "Kaybedilmesi Muhtemel Çok Riskli Sandık";
                colorInfo = "%-2.5 ve %+2.5";
              } else if (diff > 2.5 && diff <= 5) {
                colorText = "Riskli Sandık";
                colorInfo = "%+2.5 ve %+5";
              } else if (diff > 5 && diff <= 10) {
                colorText = "Az Riskli";
                colorInfo = "%+5 ve %+10";
              } else {
                colorText = "Risksiz Sandık";
                colorInfo = "%+10 ve Daha Üzeri";
              }

              return {
                ...item,
                districtName: item?.districtName.toUpperCase() || "Bilinmiyor",
                neighbourhoodName: item?.neighbourhoodName || "Bilinmiyor",
                total: item?.total || "Bilinmiyor",
                colorText: "%" + diff + " - " + colorText,
                colorInfo: colorInfo,
                totalParty: "%" + totalParty.toFixed(2),
                Sandik_No: item?.Sandik_No || "Bilinmiyor",
              };
            })}
            headers={[
              { key: "districtName", label: "İLÇE ADI" },
              { key: "neighbourhoodName", label: "MAHALLE ADI" },
              { key: "total", label: "SECMEN SAYISI" },
              { key: "colorText", label: "CUMHUR İTTİFAKI OY SONUCU" },
              { key: "colorInfo", label: "CUMHUR İTTİFAKI OY ORANI" },
              { key: "totalParty", label: "CUMHUR İTTİFAKI 2023 MV. OY ORANI" },
              { key: "Sandik_No", label: "SANDIK NO" },
            ]}
            filename={
              neighbourhoodName +
              "_stratejimerkezi_sandik_secim_sonucu_analizi.csv"
            }
            className="btn btn-outline-theme"
          >
            Tablo İndir
          </CSVLink>
        )} */}
      </div>

      {/* <div className="flex-1 overflow-hidden">
        <div className="table-responsive">
          <table className="table w-100">
            <thead>
              <tr>
                <th style={{ whiteSpace: "normal", minWidth: 100 }}>
                  İLÇE ADI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 150 }}>
                  MAHALLE ADI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 50 }}>
                  SECMEN SAYISI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 250 }}>
                  CUMHUR İTTİFAKI İLÇE ORTALAMASINA GÖRE OY FARKI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 150 }}>
                  CUMHUR İTTİFAKI OY ORANI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 250 }}>
                  SANDIK İÇİN CUMHUR İTTİFAKI 2023 MV. OY ORANI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 100 }}>
                  SANDIK NO
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 130 }}>
                  SANDIK SONUÇLARI
                </th>
                <th style={{ whiteSpace: "normal", minWidth: 130 }}>
                  SANDIK SEÇMENLERİ
                </th>
              </tr>
            </thead>

            <tbody>
              {analysisTable ? (
                analysisTable.length !== 0 ? (
                  analysisTable.map((item, index) => {
                    const partyData = partyResult[0]?.data;
                    const chestData = allChestResult[index]?.data;

                    const totalParty = partyData
                      ? partyData.reduce((acc, curr) => {
                          if ([8, 12, 19, 13].includes(curr.parameter)) {
                            acc += curr.total;
                          }
                          return acc;
                        }, 0)
                      : 0;

                    const totalYuzde = chestData
                      ? chestData.reduce((acc, curr) => {
                          if ([8, 12, 19, 13].includes(curr.Parametre)) {
                            acc += curr.Yuzde;
                          }
                          return acc;
                        }, 0)
                      : 0;

                    let colorCode, colorText, colorInfo;
                    const diff = (totalYuzde - totalParty).toFixed(2);

                    if (diff <= -10) {
                      colorCode = "#cd0000";
                      colorText = "Kaybettiren Sandık";
                      colorInfo = "%-10 ve Daha Altı";
                    } else if (diff > -10 && diff <= -5) {
                      colorCode = "#ff0000";
                      colorText = "Ortalamayı Düşüren Sandık";
                      colorInfo = "%-10 ve %-5";
                    } else if (diff > -5 && diff <= -2.5) {
                      colorCode = "#ff4242";
                      colorText = "Ortalama Altı Kaybedilen Sandık";
                      colorInfo = "%-5 ve %-2.5";
                    } else if (diff > -2.5 && diff <= 2.5) {
                      colorCode = "#FFA500";
                      colorText = "Kaybedilmesi Muhtemel Çok Riskli Sandık";
                      colorInfo = "%-2.5 ve %+2.5";
                    } else if (diff > 2.5 && diff <= 5) {
                      colorCode = "#ffd200";
                      colorText = "Riskli Sandık";
                      colorInfo = "%+2.5 ve %+5";
                    } else if (diff > 5 && diff <= 10) {
                      colorCode = "#8fd700";
                      colorText = "Az Riskli";
                      colorInfo = "%+5 ve %+10";
                    } else {
                      colorCode = "#00ff00";
                      colorText = "Risksiz Sandık";
                      colorInfo = "%+10 ve Daha Üzeri";
                    }

                    return (
                      <tr key={index}>
                        <td style={{ whiteSpace: "normal" }}>
                          {item?.districtName.toUpperCase() || "Bilinmiyor"}
                        </td>
                        <td style={{ whiteSpace: "normal" }}>
                          {item?.neighbourhoodName || "Bilinmiyor"}
                        </td>
                        <td style={{ whiteSpace: "normal" }}>
                          {item?.total || "Bilinmiyor"}
                        </td>
                        <td
                          className="fw-bold"
                          style={{ whiteSpace: "normal", color: colorCode }}
                        >
                          {"%" + diff}
                          <br />
                          {colorText}
                        </td>
                        <td style={{ whiteSpace: "normal" }}>{colorInfo}</td>
                        <td style={{ whiteSpace: "normal" }}>
                          {"%" + totalParty.toFixed(2)}
                        </td>
                        <td style={{ whiteSpace: "normal" }}>
                          {item?.Sandik_No || "Bilinmiyor"}
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#sandikCalismasiModal"
                            onClick={() =>
                              setSandikCalimasiModalData({
                                data: item,
                                city: null,
                                district: null,
                                neighbourhood: null,
                                table: null,
                              })
                            }
                          >
                            DETAY
                          </button>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-theme"
                            data-bs-toggle="modal"
                            data-bs-target="#sandikCalismasiModalTable"
                            onClick={() =>
                              setSandikCalimasiModalData({
                                data: item,
                                table: true,
                              })
                            }
                          >
                            DETAY
                          </button>
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>
                      <span className="d-block text-center">
                        Mahalle Seçmelisiniz
                      </span>
                    </td>
                  </tr>
                )
              ) : (
                <tr>
                  <td>
                    <div className="spinner-border text-theme"></div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div> */}
    </div>
  );
}

import axios from 'axios';

const getApiUrl = () => {
    if (process.env.NODE_ENV === 'development') {
        return process.env.REACT_APP_API_URL;
    } else if (process.env.NODE_ENV === 'production') {
        return process.env.REACT_APP_PRODUCTION_API_URL;
    }
};

const apiClient = axios.create({
    baseURL: getApiUrl() + '/api',
});

apiClient.customPost = async (url, data, config = {}) => {
    try {
        const response = await apiClient.post(url, data, {
            headers: {
                'Content-Type': 'application/json',
                ...config.headers,
            },
            ...config,
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

apiClient.customGet = async (url, config = {}) => {
    try {
        const response = await apiClient.get(url, {
            headers: {
                'Content-Type': 'application/json',
                ...config.headers,
            },
            params: config.params, // Adding params to the request
        });
        return response.data;
    } catch (error) {
        throw error;
    }
};

export default apiClient;

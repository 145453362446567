import React, { useContext, useEffect, useState } from "react";
import ReactECharts from "echarts-for-react";
import egitimJson from "../../json/egitim.json";
import analizJson from "../../json/siyasetmetre_analiz.json";
import demografiJson from "../../json/demografi.json";
import { useNavigate } from "react-router-dom";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import toastMessage from "../ToastMessage";
import { ToastContainer } from "react-toastify";
import PageChartCard from "../page-chart-card/pageChartCard";
import { Card, CardBody } from "../card/card";

const ChartBarTimeLine = ({
  show = true,
  setNeighborhoodEducationData = false,
  setNeighborhoodSocioEconomicData = false,
  setNeighborhoodMaritalData = false,
  id = null,
  path = "home",
  title,
  city,
  type,
  district,
  neighbourhood,
  street,
  graphic,
  card,
}) => {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  const [loader, setLoader] = useState(true);
  const [yearData, setYearData] = useState([]);
  const [titleData, setTitleData] = useState([]);
  const [optionsData, setOptionsData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [chartColor, setChartColor] = useState("#3cd2a5");

  // useEffect(() => {
  //   setChartColor(context?.chartColor);
  // }, [context]);

  var colors = [
    "#5470c6",
    "#91cc75",
    "#fac858",
    "#ee6666",
    "#3ba272",
    "#73c0de",
    "#9a60b4",
    "#5470c6",
    "#ea7ccc",
    "#91cc75",
    "#fac858",
    "#73c0de",
    "#ee6666",
    "#3ba272",
    "#9a60b4",
    "#ea7ccc",
  ];

  function popRandomColor() {
    var rand = Math.random();
    var color = colors[Math.floor(rand * colors.length)];
    colors.splice(Math.floor(rand * colors.length), 1);
    return color;
  }

  const option = {
    baseOption: {
      timeline: {
        axisType: "category",
        autoPlay: true,
        playInterval: 3000,
        checkpointStyle: {
          borderColor: chartColor === "random" ? "#ffffff" : chartColor,
          color: chartColor === "random" ? "#ffffff" : chartColor,
        },
        lineStyle: {
          color: "white",
        },
        itemStyle: {
          color: chartColor === "random" ? "#ffffff" : chartColor,
        },
        data: yearData,
        label: {
          formatter: function (s) {
            return new Date(s).getFullYear();
          },
        },
      },
      tooltip: {},
      calculable: true,
      grid: {
        top: 70,
        bottom: 110,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow",
            label: {
              show: true,
              formatter: function (params) {
                return params.value.replace("\n", "");
              },
            },
          },
        },
      },
      xAxis: [
        {
          type: "category",
          axisLabel: {
            interval: 0,
            rotate: 45,
            textStyle: {
              color: "#fff",
            },
            formatter: function (value, index) {
              return value.substring(0, 7) + "...";
            },
          },
          data: titleData,
          splitLine: { show: false },
        },
      ],
      yAxis: [
        {
          type: "value",
          name: "",
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
      ],
      series: [{ name: "Toplam Değer", type: "bar" }],
      visualMap: {
        show: false,
        min: 0,
        max: 10,
        inRange: {
          colorLightness: [0.1, 0.5],
        },
      },
    },
    options: optionsData,
  };

  async function getData() {
    setLoader(true);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: title,
          type: type,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Tarih",
                value: "select",
              },
              {
                key: type === "medeni-durum" ? "Alt_Kategori_2" : "Parametre",
                value: "select",
              },
              {
                key: "Deger",
                value: type === "sosyo-ekonomik-statu" ? "select" : "sum",
              },
            ],
            condition: [
              city
                ? {
                    key: type === "2023-egitim" ? "SehIr" : "Sehir",
                    value:
                      type === "medeni-durum"
                        ? city.toUpperCase()
                        : context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setLoader(false);

      if (response.length !== 0) {
        if (type === "sosyo-ekonomik-statu") {
          response.forEach((item) => {
            item.deger = parseFloat(item.deger.replace(",", "."));
          });

          const result = response.reduce((acc, curr) => {
            const key = `${curr.tarih}-${curr.parametre}`;

            if (!acc[key]) {
              acc[key] = {
                tarih: curr.tarih,
                parametre: curr.parametre,
                deger: 0,
              };
            }

            acc[key].deger += curr.deger;
            return acc;
          }, {});

          const mergedData = Object.values(result).map((item) => ({
            tarih: item.tarih,
            parametre: item.parametre,
            deger: item.deger.toFixed(2),
          }));

          //--

          const dates = [
            ...new Set(
              mergedData.map((item) => `20${item.tarih.split("/")[2]}-`)
            ),
          ];
          const parameters = [
            ...new Set(mergedData.map((item) => item.parametre)),
          ];

          const groupedData = dates.map((date) => {
            const year = date.slice(0, 4);

            const seriesData = parameters.map((param) => {
              const entry = mergedData.find(
                (item) =>
                  item.tarih.includes(year.split("20")[1]) &&
                  item.parametre === param
              );
              return entry ? entry.deger : "0";
            });

            return {
              title: {
                text: `${year} Dağılımı`,
                textStyle: {
                  color: "#fff",
                },
              },
              series: [
                {
                  data: seriesData,
                  itemStyle: {
                    color: "#3cd2a5",
                  },
                },
              ],
            };
          });

          setYearData(dates);
          setTitleData(parameters);
          setOptionsData(groupedData);

          //--

          if (setNeighborhoodSocioEconomicData) {
            if (response.length !== 0) {
              const reportTableWidth = [];
              const reportTableKeys = parameters;
              reportTableKeys.unshift("YIL");

              reportTableKeys.forEach((item) => {
                reportTableWidth.push("*");
              });

              const reportOptionsData = [reportTableKeys];

              groupedData.forEach((element) => {
                reportOptionsData.push([
                  element.title.text.match(/\d+/)[0],
                  ...element.series[0].data.map(String),
                ]);
              });

              setNeighborhoodSocioEconomicData([
                reportTableWidth,
                reportOptionsData,
              ]);
            } else {
              setNeighborhoodSocioEconomicData(null);
            }
          }
        }

        if (type === "2023-egitim") {
          const inputArray = response.sort((a, b) => a.tarih - b.tarih);
          const years = [...new Set(inputArray.map((item) => item.tarih))];
          const parameters = inputArray.map((item) =>
            type === "medeni-durum" ? item.alt_kategori_2 : item.parametre
          );
          const formattedYears = years.map((year) => `${year}-`);
          const chartData = years.map((year) => {
            const filteredData = inputArray.filter(
              (item) => item.tarih === year
            );
            return {
              title: {
                text: `${year} Dağılımı`,
                textStyle: {
                  color: "#fff",
                },
              },
              series: [
                {
                  data: filteredData.map((item) => item.deger_sum),
                  itemStyle: {
                    color: chartColor,
                  },
                },
              ],
            };
          });

          setYearData(formattedYears);
          setTitleData([...new Set(parameters)]);
          setOptionsData(chartData);

          //--

          if (setNeighborhoodEducationData) {
            if ([...new Set(parameters)].length !== 0) {
              const reportTitleData = [...new Set(parameters)];
              reportTitleData.unshift("YIL");
              const reportTableWidth = [];

              reportTitleData.forEach((item) => {
                reportTableWidth.push("auto");
              });

              const reportOptionsData = [reportTitleData];

              chartData.forEach((element) => {
                reportOptionsData.push([
                  element.title.text.match(/\d+/)[0],
                  ...element.series[0].data.map(String),
                ]);
              });

              reportOptionsData.forEach((element, index) => {
                if (index !== 0) {
                  if (element.length === 10) {
                    element.push("0");
                  }
                }
              });

              setNeighborhoodEducationData([
                reportTableWidth,
                reportOptionsData,
              ]);
            } else {
              setNeighborhoodEducationData(null);
            }
          }
        }

        if (type === "medeni-durum") {
          const inputArray = response.sort((a, b) => a.tarih - b.tarih);
          const years = [...new Set(inputArray.map((item) => item.tarih))];
          const parameters = inputArray.map((item) =>
            type === "medeni-durum" ? item.alt_kategori_2 : item.parametre
          );
          const formattedYears = years.map((year) => `${year}-`);
          const chartData = years.map((year) => {
            const filteredData = inputArray.filter(
              (item) => item.tarih === year
            );
            return {
              title: {
                text: `${year} Dağılımı`,
                textStyle: {
                  color: "#fff",
                },
              },
              series: [
                {
                  data: filteredData.map((item) => item.deger_sum),
                  itemStyle: {
                    color: chartColor,
                  },
                },
              ],
            };
          });

          setYearData(formattedYears);
          setTitleData([...new Set(parameters)]);
          setOptionsData(chartData);

          //--

          if (setNeighborhoodMaritalData) {
            if ([...new Set(parameters)].length !== 0) {
              const reportTitleData = [...new Set(parameters)];
              reportTitleData.unshift("YIL");
              const reportTableWidth = [];

              reportTitleData.forEach((item) => {
                reportTableWidth.push("*");
              });

              const reportOptionsData = [reportTitleData];

              chartData.forEach((element) => {
                reportOptionsData.push([
                  element.title.text.match(/\d+/)[0],
                  ...element.series[0].data.map(String),
                ]);
              });

              reportOptionsData.forEach((element, index) => {
                if (index !== 0) {
                  if (element.length === 10) {
                    element.push("0");
                  }
                }
              });

              setNeighborhoodMaritalData([reportTableWidth, reportOptionsData]);
            } else {
              setNeighborhoodMaritalData(null);
            }
          }
        }
      } else {
        setYearData([]);
        setTitleData([]);
        setOptionsData([]);
        setTableData([]);

        if (setNeighborhoodEducationData) {
          setNeighborhoodEducationData(null);
        }
        if (setNeighborhoodSocioEconomicData) {
          setNeighborhoodSocioEconomicData([[], [[], []]]);
        }
        if (setNeighborhoodMaritalData) {
          setNeighborhoodMaritalData([[], [[], []]]);
        }
      }
    } catch (error) {
      setLoader(false);
      setYearData([]);
      setTitleData([]);
      setOptionsData([]);
      setTableData([]);

      console.error((show ? title : "-") + " Error", error);
    }
  }

  useEffect(() => {
    // if (city) {
    getData();
    // }
    // eslint-disable-next-line
  }, [chartColor, city, district, neighbourhood, street]);

  return (
    <>
      {show && (
        <div className="h-100">
          {!loader ? (
            optionsData.length !== 0 ? (
              card ? (
                <PageChartCard
                  id={id}
                  title={title}
                  autoIncrement={4324}
                  path={path}
                  city={city}
                  district={district}
                  neighbourhood={neighbourhood}
                  street={street}
                  graphic={graphic}
                  chart={
                    <ReactECharts
                      option={option}
                      className="w-100 pb-5"
                      style={{ height: "100%" }}
                    />
                  }
                  height="auto"
                  chartType="timeline"
                  tableData={[titleData, optionsData]}
                />
              ) : (
                <ReactECharts
                  option={option}
                  className="w-100 pb-5"
                  style={{ height: "100%" }}
                />
              )
            ) : (
              <div className="d-flex align-items-center justify-content-center h-100">
                <span className="d-block text-center">
                  Analiz Verisi Eklenmemiştir
                </span>
              </div>
            )
          ) : (
            <div className="d-flex align-items-center justify-content-center h-100">
              <div className="spinner-border text-theme"></div>
            </div>
          )}
        </div>
      )}
      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
};

export default ChartBarTimeLine;

import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import { CSVLink } from "react-csv";
import { ToastContainer } from "react-toastify";
import TurkishDateComponent from "../../components/TurkishDateComponent";
import toastMessage from "../../components/ToastMessage";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import AddModal from "./addModal";
import UpdateModal from "./updateModal";

function Musteriler() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  useEffect(() => {
    if (context && context.me) {
      if (context?.me?.role?.id !== 1) {
        navigate("/404");
      }
    }
  }, [context]);

  // Get Data
  const searchRef = useRef();
  const tableRef = useRef();
  const tableHead = [
    {
      key: "id",
      label: "#",
    },
    {
      key: "primary",
      label: "Birincil Veri",
    },
    {
      key: "createSurvey",
      label: "Anket Oluşturabilir",
    },
    {
      key: "secimAnalizi",
      label: "Seçim Analizi Görebilir",
    },
    {
      key: "sandikAnalizi",
      label: "Sandık Analizi Görebilir",
    },
    {
      key: "settings.domain",
      label: "Domain",
    },
    {
      key: "customer.name",
      label: "Müşteri Adı",
    },
    {
      key: "started_at",
      label: "Üyelik Başlangıç Tarihi",
    },
    {
      key: "ended_at",
      label: "Ü. Bitiş Tarihi",
    },
    {
      key: "city.name",
      label: "Ü. Kapsam Şehrini",
    },
    {
      key: "district.name",
      label: "Ü. Kapsam İlçesini",
    },
    {
      key: "reports[0].label",
      label: "Kentmetre Rapor 1",
    },
    {
      key: "reports[1].label",
      label: "Kentmetre Rapor 2",
    },
    {
      key: "reports[2].label",
      label: "Kentmetre Rapor 3",
    },
    {
      key: "customer.competent",
      label: "M. Yetkilisi Adı Soyadı",
    },
    {
      key: "customer.phone",
      label: "M. Telefonu",
    },
    {
      key: "customer.email",
      label: "M. Email",
    },
    {
      key: "customer.address",
      label: "M. Adresi",
    },
    {
      key: "contact.name",
      label: "İletişim Adı",
    },
    {
      key: "contact.competent",
      label: "İ. Yetkilisi Adı Soyadı",
    },
    {
      key: "contact.phone",
      label: "İ. Telefonu",
    },
    {
      key: "contact.email",
      label: "İ. Email",
    },
    {
      key: "contact.address",
      label: "İ. Adresi",
    },
  ];

  const [data, setData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchCol, setSearchCol] = useState("customer");
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.get(
        `/customer?limit=${limitPage}${
          sortDirection ? `&sort=${sortBy}&direction=${sortDirection}` : ""
        }&page=${currentPage}&query=${search}&query_field=${searchCol}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.status === 200) {
        setData(response.data);
      }
    } catch (error) {
      console.error("Customer Error", error);
      setData(null);

      if (error.response.status === 500) {
        setErrorMessage("500 | Sunucu Hatası!");
      } else if (error.response.status === 404) {
        navigate("/404");
      } else if (error.response.status === 403) {
        setErrorMessage("Çok fazla istek atıldı.");
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      } else {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, [limitPage, currentPage, search, sortBy, sortDirection]);

  // Sort
  const handleSort = (key) => {
    if (sortBy === key) {
      setSortDirection((prevSortDirection) => {
        if (prevSortDirection === "asc") return "desc";
        if (prevSortDirection === "desc") return "asc";
        // if (prevSortDirection === "desc") return null;
        // return "asc";
      });
    } else {
      setSortBy(key);
      setSortDirection("asc");
    }
  };

  // Limit Change
  function limitChange(e) {
    setLimitPage(e.target.value);
    setCurrentPage(1);
  }

  // Pagination
  function handlePagination(value) {
    setCurrentPage(value);
  }

  // Search
  function handleSearchCol(e) {
    setSearchCol(e.target.value);
  }

  function handleSearch() {
    setSearch(searchRef.current.value);
    setCurrentPage(1);
  }

  function handleReset() {
    setLimitPage(10);
    setCurrentPage(1);
    setSearch("");
    setSearchCol("customer");
    setSortBy("id");
    setSortDirection("desc");
  }

  // Update
  const [updateItem, setUpdateItem] = useState(null);

  // Delete
  const [deleteItem, setDeleteItem] = useState(null);

  const handleDelete = async () => {
    if (deleteItem) {
      try {
        const response = await apiClient.delete(`/customer/${deleteItem.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          toastMessage(response.data.message, "success");
          document.getElementById("deleteConfirmModalClose").click();
          getData();
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }

        if (error.response.status === 404) {
          toastMessage("Bulunamadı!", "error");
        } else {
          setDeleteItem(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  };

  return (
    context?.me?.role?.id === 1 && (
      <>
        <div className="zoom-item">
          <div className="border-bottom mb-4 pb-4">
            <button
              type="button"
              className="btn btn-outline-theme"
              data-bs-toggle="modal"
              data-bs-target="#addModal"
            >
              Müşteri Ekle
            </button>
          </div>

          {errorMessage ? (
            errorMessage
          ) : data ? (
            <div className="dataTables_wrapper dt-bootstrap5">
              <div className="row mb-3">
                <div className="col-md-4 mb-3 mb-md-0">
                  <div className="dataTables_length">
                    <label className="d-inline-flex align-items-center">
                      <select
                        className="form-select form-select-sm ms-0"
                        onChange={limitChange}
                        defaultValue={limitPage}
                      >
                        <option value="10">10</option>
                        <option value="20">20</option>
                        <option value="30">30</option>
                        <option value="50">50</option>
                      </select>
                      Görüntüle
                    </label>
                  </div>
                </div>

                <div className="col-md-8 text-md-end">
                  <div className="d-flex justify-content-center justify-content-md-end">
                    <div className="dataTables_filter d-flex border-end pe-2">
                      <select
                        className="form-select form-select-sm"
                        onChange={handleSearchCol}
                        defaultValue={searchCol}
                        style={{ width: 130 }}
                      >
                        <option value="customer">Müşteri</option>
                        <option value="contact">İletişim</option>
                      </select>

                      <input
                        type="search"
                        className="form-control form-control-sm border-end-0 rounded-end-0"
                        placeholder="Arama..."
                        ref={searchRef}
                      />

                      <button
                        type="button"
                        className="btn buttons-print btn-outline-default btn-sm rounded-start-0"
                        onClick={() => handleSearch()}
                      >
                        Ara
                      </button>
                    </div>

                    <CSVLink
                      data={data.data}
                      headers={tableHead}
                      filename="stratejimerkezi_musteriler.csv"
                      className="btn buttons-print btn-outline-default btn-sm ms-2"
                    >
                      CSV
                    </CSVLink>

                    <div className="border-start ms-2 ps-2">
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm"
                        onClick={() => handleReset()}
                      >
                        Sıfırla
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              {data.data.length === 0 ? (
                "Müşteri Bulunamadı."
              ) : (
                <>
                  <div className="dataTables_scroll">
                    <div className="dataTables_scrollBody table-responsive">
                      <table
                        className="table text-nowrap w-100 dataTable"
                        ref={tableRef}
                      >
                        <thead>
                          <tr>
                            {tableHead.map((item, index) => (
                              <th
                                key={index}
                                onClick={() =>
                                  item.key !== "customer_name" &&
                                  handleSort(item.key)
                                }
                                className={
                                  item.key !== "customer_name"
                                    ? `sorting ${
                                        sortBy === item.key
                                          ? `sorting_${sortDirection}`
                                          : ""
                                      }`
                                    : ""
                                }
                              >
                                {item.label}
                              </th>
                            ))}
                            <th className="text-end pe-0">İşlemler</th>
                          </tr>
                        </thead>

                        <tbody>
                          {data.data.map((item, index) => (
                            <tr key={index}>
                              <td>{item?.id || "-"}</td>
                              <td>
                                {item?.settings?.primary ? "Göster" : "Gizle"}
                              </td>
                              <td>
                                {item?.settings?.createSurvey ? "Evet" : "Hayır"}
                              </td>
                              <td>
                                {item?.settings?.secimAnalizi ? "Evet" : "Hayır"}
                              </td>
                              <td>
                                {item?.settings?.sandikAnalizi ? "Evet" : "Hayır"}
                              </td>
                              <td>
                                {item?.settings?.domain ? (
                                  <a
                                    href={`http://${item?.settings?.domain}.stratejimerkezi.com/`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    {item?.settings?.domain}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{item?.customer?.name || "-"}</td>
                              <td>
                                {item.started_at ? (
                                  <TurkishDateComponent
                                    dateString={item.started_at}
                                  />
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {item.ended_at ? (
                                  <TurkishDateComponent
                                    dateString={item.ended_at}
                                  />
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>{item?.settings?.city?.label || "Tümü"}</td>
                              <td>
                                {item?.settings?.district?.label || "Tümü"}
                              </td>
                              <td>
                                {item?.reports[0]
                                  ? item?.reports[0].label
                                  : "-"}
                              </td>
                              <td>
                                {item?.reports[1]
                                  ? item?.reports[1].label
                                  : "-"}
                              </td>
                              <td>
                                {item?.reports[2]
                                  ? item?.reports[2].label
                                  : "-"}
                              </td>
                              <td>{item?.customer?.competent || "-"}</td>
                              <td>
                                {item.customer.phone ? (
                                  <a href={`tel:${item.customer.phone}`}>
                                    {item.customer.phone}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {item.customer.email ? (
                                  <a href={`mailto:${item.customer.email}`}>
                                    {item.customer.email}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={{ whiteSpace: "wrap" }}>
                                <p className="mb-0" style={{ width: 200 }}>
                                  {item?.customer?.address || "-"}
                                </p>
                              </td>
                              <td>{item?.contact?.name || "-"}</td>
                              <td>{item?.contact?.competent || "-"}</td>
                              <td>
                                {item.contact.phone ? (
                                  <a href={`tel:${item.contact.phone}`}>
                                    {item.contact.phone}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td>
                                {item.contact.email ? (
                                  <a href={`mailto:${item.contact.email}`}>
                                    {item.contact.email}
                                  </a>
                                ) : (
                                  "-"
                                )}
                              </td>
                              <td style={{ whiteSpace: "wrap" }}>
                                <p className="mb-0" style={{ width: 200 }}>
                                  {item?.contact?.address || "-"}
                                </p>
                              </td>
                              <td className="text-end">
                                <button
                                  type="button"
                                  className="btn btn-outline-theme btn-sm me-2"
                                  data-bs-toggle="modal"
                                  data-bs-target="#updateModal"
                                  onClick={() =>
                                    setUpdateItem({
                                      id: item?.id || null,
                                      customer_name: item?.customer?.name || "",
                                      customer_email:
                                        item?.customer?.email || "",
                                      customer_phone:
                                        item?.customer?.phone || "",
                                      customer_address:
                                        item?.customer?.address || "",
                                      customer_competent:
                                        item?.customer?.competent || "",
                                      contact_name: item?.contact?.name || "",
                                      contact_email: item?.contact?.email || "",
                                      contact_phone: item?.contact?.phone || "",
                                      contact_address:
                                        item?.contact?.address || "",
                                      contact_competent:
                                        item?.contact?.competent || "",
                                      reports: [
                                        item?.reports[0] || null,
                                        item?.reports[1] || null,
                                        item?.reports[2] || null,
                                      ],
                                      api_key: item?.api_key || "",
                                      rate: item?.rate?.value || 100,
                                      started_at: item?.started_at || "",
                                      ended_at: item?.ended_at || "",
                                      createSurvey: item?.settings?.createSurvey,
                                      secimAnalizi: item?.settings?.secimAnalizi,
                                      sandikAnalizi: item?.settings?.sandikAnalizi,
                                      primary: item?.settings?.primary,
                                      country: item?.settings?.country || "",
                                      city: item?.settings?.city || "",
                                      district: item?.settings?.district || "",
                                      logo: item?.settings?.logo || "",
                                      theme: item?.settings?.theme || "",
                                      domain: item?.settings?.domain || "",
                                    })
                                  }
                                >
                                  Düzenle
                                </button>

                                <button
                                  type="button"
                                  className="btn btn-outline-danger btn-sm"
                                  data-bs-toggle="modal"
                                  data-bs-target="#deleteConfirmModal"
                                  onClick={() =>
                                    setDeleteItem({
                                      id: item.id,
                                      name: item.customer.name,
                                    })
                                  }
                                >
                                  Sil
                                </button>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="row align-items-center mt-3">
                    <div className="mb-0 col-md-12">
                      <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                        <ul className="pagination">
                          <li
                            className={`paginate_button page-item ${
                              !data.prev_page_url && "disabled"
                            }`}
                          >
                            <Link
                              to="#"
                              onClick={() =>
                                data.prev_page_url &&
                                handlePagination(
                                  data.prev_page_url.split("?page=")[1]
                                )
                              }
                              className="page-link"
                            >
                              Geri
                            </Link>
                          </li>

                          {data.links.map(
                            (item, index) =>
                              !isNaN(parseInt(item.label)) && (
                                <li
                                  key={index}
                                  className={`paginate_button page-item ${
                                    item.active ? "active" : ""
                                  }`}
                                >
                                  <Link
                                    to="#"
                                    onClick={() => handlePagination(item.label)}
                                    className="page-link"
                                  >
                                    {item.label}
                                  </Link>
                                </li>
                              )
                          )}

                          <li
                            className={`paginate_button page-item ${
                              !data.next_page_url && "disabled"
                            }`}
                          >
                            <Link
                              to={"#"}
                              onClick={() =>
                                data.next_page_url &&
                                handlePagination(
                                  data.next_page_url.split("?page=")[1]
                                )
                              }
                              className="page-link"
                            >
                              İleri
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div className="spinner-border text-theme"></div>
          )}
        </div>

        <AddModal getData={getData} />

        <UpdateModal getData={getData} updateItem={updateItem} />

        <div className="modal modal-cover fade" id="deleteConfirmModal">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  Emin misiniz?
                  <br />
                  <span className="h5">
                    Kullanıcı Adı: {deleteItem && deleteItem.name}
                  </span>
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="deleteConfirmModalClose"
                ></button>
              </div>

              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={handleDelete}
                  >
                    Sil
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    İptal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <ToastContainer pauseOnFocusLoss={false} />
      </>
    )
  );
}

export default Musteriler;

import React, { useContext, useEffect, useRef, useState } from "react";

import PageFilter from "../../components/PageFilter";
import { Card, CardBody } from "../../components/card/card";
import { AppSettings } from "../../config/app-settings";
import AnalysisTable from "./analysisTable";

export default function SandikCalismasi() {
  // Base
  const tableRef = useRef(null);
  const context = useContext(AppSettings);

  // Page Filter
  const [cityFilter, setCityFilter] = useState({
    value: 40,
    label: "ISTANBUL",
  });
  const [districtFilter, setDistrictFilter] = useState("filter");

  // All Neighborhood
  const [allNeighborhood, setAllNeighborhood] = useState(null);
  const [selectedNeighborhood, setSelectedNeighborhood] = useState(null);

  const fetchNeighborhood = async (id) => {
    setAllNeighborhood(null);

    const data = await context.getAllNeighborhood(id);
    setAllNeighborhood(data);
  };

  useEffect(() => {
    if (districtFilter && districtFilter !== "filter") {
      fetchNeighborhood(districtFilter?.value);
    } else {
      setAllNeighborhood([]);
    }
  }, [districtFilter]);

  // Chest No
  const [sandikCalismasiModalData, setSandikCalimasiModalData] = useState({
    data: null,
    table: null,
  });

  return (
    <div className="row g-4">
      <PageFilter
        setCityFilter={setCityFilter}
        setDistrictFilter={setDistrictFilter}
        neighbourhood={false}
        street={false}
      />

      <div className="col-12">
        <Card>
          <CardBody>
            {districtFilter && districtFilter !== "filter" ? (
              allNeighborhood ? (
                allNeighborhood.length !== 0 ? (
                  <div className="row chest-work-page">
                    <div
                      className="px-0 w-100 border-end neighbourhood-menu"
                      style={{ maxWidth: 200 }}
                    >
                      <div
                        className="position-sticky py-2 top-0 overflow-x-hidden overflow-y-auto"
                        style={{ maxHeight: "calc(100vh - 100px)" }}
                      >
                        {allNeighborhood.map((item, index) => (
                          <div
                            key={index}
                            className={`d-flex align-items-center py-1 m-1 gap-1 ${
                              selectedNeighborhood &&
                              selectedNeighborhood.value === item.value
                                ? "text-theme fw-bold"
                                : "text-white"
                            } pointer`}
                            onClick={() => setSelectedNeighborhood(item)}
                          >
                            <span>{index + 1}.</span>
                            <span className="d-block">{item.label}</span>
                          </div>
                        ))}
                      </div>
                    </div>

                    <div className="flex-1 overflow-hidden px-4 row flex-column gap-4">
                      {selectedNeighborhood ? (
                        <AnalysisTable
                          cityId={cityFilter ? cityFilter.label : null}
                          districtId={
                            districtFilter ? districtFilter.label : null
                          }
                          neighbourhoodId={selectedNeighborhood.value}
                          neighbourhoodName={selectedNeighborhood.label}
                          setSandikCalimasiModalData={
                            setSandikCalimasiModalData
                          }
                        />
                      ) : (
                        <span className="d-block">Mahalle Seçmelisiniz</span>
                      )}
                    </div>
                  </div>
                ) : (
                  <span className="d-block text-center">
                    Analiz Verisi Eklenmemiştir
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">
                İl ve İlçe Seçmelisiniz
              </span>
            )}
          </CardBody>
        </Card>
      </div>
    </div>
  );
}

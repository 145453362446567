import React, { useContext, useEffect, useState } from "react";

import moment from "moment";
import { GoogleMap, InfoWindow, Marker } from "@react-google-maps/api";

import Fancybox from "./Fancybox";
import { Card, CardBody } from "./card/card";
import { AppSettings } from "../config/app-settings";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
const options = {
  center,
  zoom: defaultZoom,
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { invert_lightness: true },
        { saturation: -50 },
        { lightness: 40 },
        { gamma: 0.5 },
        { hue: "#3cd2a5" },
      ],
    },
  ],
};

export default function AnketCevaplariModal({
  data,
  reportMapShow,
  setReportMapShow,
  reportSelectedAnswerMarker,
  setReportSelectedAnswerMarker,
  reportSelectedMarker,
  setReportSelectedMarker,
}) {
  const context = useContext(AppSettings);

  const [itemLocation, setItemLocation] = useState(null);

  const [allDistrict, setAllDistrict] = useState([]);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  async function fetchDistrict(value) {
    setAllDistrict([]);

    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        setAllDistrict(data);
      }
    } else {
      setAllDistrict([]);
    }
  }

  async function fetchNeighborhood(value) {
    setAllNeighborhood([]);

    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        setAllNeighborhood(data);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  useEffect(() => {
    if (data) {
      fetchDistrict(data.city);
      fetchNeighborhood(data.district);
    }
  }, [data]);

  return (
    <div className="modal-body pt-0">
      {data ? (
        <div className="row gap-4 mb-4">
          <div className="col-12">
            {reportMapShow && (
              <div
                className="position-absolute top-0 start-0 w-100 h-100"
                style={{ zIndex: 99, background: "#141b24d9" }}
              >
                <div className="p-3 w-100 h-100">
                  <button
                    className="btn btn-outline-theme mb-3"
                    onClick={() => {
                      setReportMapShow(false);
                      setReportSelectedMarker(null);
                      setItemLocation(null);
                    }}
                  >
                    Haritayı Gizle
                  </button>

                  <GoogleMap
                    mapContainerStyle={{
                      height: "100%",
                      width: "100%",
                    }}
                    options={options}
                  >
                    {itemLocation
                      ? itemLocation.answer.images &&
                        itemLocation.answer.images.length !== 0 &&
                        itemLocation.answer.images.map((img, imgIndex) => (
                          <>
                            <Marker
                              key={imgIndex}
                              position={{
                                lat: parseFloat(img.location.lat),
                                lng: parseFloat(img.location.lng),
                              }}
                              onClick={() => {
                                setReportSelectedMarker(img);
                                setReportSelectedAnswerMarker(itemLocation);
                              }}
                            />

                            {reportSelectedMarker && (
                              <InfoWindow
                                onCloseClick={() => {
                                  setReportSelectedMarker(null);
                                  setItemLocation(null);
                                }}
                                position={{
                                  lat: parseFloat(
                                    reportSelectedMarker.location.lat
                                  ),
                                  lng: parseFloat(
                                    reportSelectedMarker.location.lng
                                  ),
                                }}
                              >
                                <div className="text-start">
                                  <span className="d-block mb-4">
                                    Kategori:{" "}
                                    {reportSelectedAnswerMarker?.category.name}
                                    <br />
                                    Soru:{" "}
                                    {
                                      reportSelectedAnswerMarker?.question
                                        .question
                                    }
                                    <br />
                                    Cevap:{" "}
                                    {reportSelectedAnswerMarker?.answer.answer}
                                  </span>

                                  <span className="d-block mb-4">
                                    {reportSelectedMarker.description}
                                  </span>

                                  <div
                                    className="carousel slide"
                                    id={`anket-reply-detail-${1}-img-${imgIndex}`}
                                  >
                                    <div className="carousel-inner">
                                      <Fancybox
                                        options={{
                                          Carousel: {
                                            infinite: false,
                                          },
                                        }}
                                      >
                                        {reportSelectedMarker.list.map(
                                          (imgList, imgListIndex) => (
                                            <div
                                              className={`carousel-item${
                                                imgListIndex === 0
                                                  ? " active"
                                                  : ""
                                              }`}
                                            >
                                              <a
                                                data-fancybox={
                                                  img.description + "-gallery"
                                                }
                                                href={imgList}
                                                key={imgListIndex}
                                                className="col-6"
                                              >
                                                <img
                                                  className="w-100"
                                                  src={imgList}
                                                  alt={
                                                    imgListIndex +
                                                    "_" +
                                                    img.description
                                                  }
                                                />
                                              </a>
                                            </div>
                                          )
                                        )}
                                      </Fancybox>
                                    </div>

                                    <button
                                      className="carousel-control-prev"
                                      type="button"
                                      data-bs-target={`#anket-reply-detail-${1}-img-${imgIndex}`}
                                      data-bs-slide="prev"
                                    >
                                      <span
                                        className="carousel-control-prev-icon"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="visually-hidden">
                                        Previous
                                      </span>
                                    </button>

                                    <button
                                      className="carousel-control-next"
                                      type="button"
                                      data-bs-target={`#anket-reply-detail-${1}-img-${imgIndex}`}
                                      data-bs-slide="next"
                                    >
                                      <span
                                        className="carousel-control-next-icon"
                                        aria-hidden="true"
                                      ></span>
                                      <span className="visually-hidden">
                                        Next
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </InfoWindow>
                            )}
                          </>
                        ))
                      : data?.reply[0].map(
                          (item, index) =>
                            item.answer.images &&
                            item.answer.images.length !== 0 &&
                            item.answer.images.map((img, imgIndex) => (
                              <>
                                <Marker
                                  key={imgIndex}
                                  position={{
                                    lat: parseFloat(img.location.lat),
                                    lng: parseFloat(img.location.lng),
                                  }}
                                  onClick={() => {
                                    setReportSelectedMarker(img);
                                    setReportSelectedAnswerMarker(item);
                                  }}
                                />

                                {reportSelectedMarker && (
                                  <InfoWindow
                                    onCloseClick={() => {
                                      setReportSelectedMarker(null);
                                      setItemLocation(null);
                                    }}
                                    position={{
                                      lat: parseFloat(
                                        reportSelectedMarker.location.lat
                                      ),
                                      lng: parseFloat(
                                        reportSelectedMarker.location.lng
                                      ),
                                    }}
                                  >
                                    <div className="text-start">
                                      <span className="d-block mb-4">
                                        Kategori:{" "}
                                        {
                                          reportSelectedAnswerMarker?.category
                                            .name
                                        }
                                        <br />
                                        Soru:{" "}
                                        {
                                          reportSelectedAnswerMarker?.question
                                            .question
                                        }
                                        <br />
                                        Cevap:{" "}
                                        {
                                          reportSelectedAnswerMarker?.answer
                                            .answer
                                        }
                                      </span>

                                      <span className="d-block mb-4">
                                        {reportSelectedMarker.description}
                                      </span>

                                      <div
                                        className="carousel slide"
                                        id={`anket-reply-detail-${index}-img-${imgIndex}`}
                                      >
                                        <div className="carousel-inner">
                                          <Fancybox
                                            options={{
                                              Carousel: {
                                                infinite: false,
                                              },
                                            }}
                                          >
                                            {reportSelectedMarker.list.map(
                                              (imgList, imgListIndex) => (
                                                <div
                                                  className={`carousel-item${
                                                    imgListIndex === 0
                                                      ? " active"
                                                      : ""
                                                  }`}
                                                >
                                                  <a
                                                    data-fancybox={
                                                      img.description +
                                                      "-gallery"
                                                    }
                                                    href={imgList}
                                                    key={imgListIndex}
                                                    className="col-6"
                                                  >
                                                    <img
                                                      className="w-100"
                                                      src={imgList}
                                                      alt={
                                                        imgListIndex +
                                                        "_" +
                                                        img.description
                                                      }
                                                    />
                                                  </a>
                                                </div>
                                              )
                                            )}
                                          </Fancybox>
                                        </div>

                                        <button
                                          className="carousel-control-prev"
                                          type="button"
                                          data-bs-target={`#anket-reply-detail-${index}-img-${imgIndex}`}
                                          data-bs-slide="prev"
                                        >
                                          <span
                                            className="carousel-control-prev-icon"
                                            aria-hidden="true"
                                          ></span>
                                          <span className="visually-hidden">
                                            Previous
                                          </span>
                                        </button>

                                        <button
                                          className="carousel-control-next"
                                          type="button"
                                          data-bs-target={`#anket-reply-detail-${index}-img-${imgIndex}`}
                                          data-bs-slide="next"
                                        >
                                          <span
                                            className="carousel-control-next-icon"
                                            aria-hidden="true"
                                          ></span>
                                          <span className="visually-hidden">
                                            Next
                                          </span>
                                        </button>
                                      </div>
                                    </div>
                                  </InfoWindow>
                                )}
                              </>
                            ))
                        )}
                  </GoogleMap>
                </div>
              </div>
            )}

            <hr />

            <div className="text-start">
              <div className="d-block">
                Adres:{" "}
                <ol className="d-inline-flex breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    {
                      context.allCity.find(function (item) {
                        return item.value === data.city;
                      }).label
                    }
                  </li>
                  <li className="breadcrumb-item">
                    {allDistrict.length !== 0 &&
                      allDistrict.find(function (item) {
                        return item.value === data.district;
                      }).label}
                  </li>
                  <li className="breadcrumb-item">
                    {allNeighborhood.length !== 0 &&
                      allNeighborhood.find(function (item) {
                        return item.value === data.neighbourhood;
                      }).label}
                  </li>
                  <li className="breadcrumb-item">{data?.street}</li>
                  {data?.building && (
                    <li className="breadcrumb-item">{data?.building}</li>
                  )}
                  {data?.independent && (
                    <li className="breadcrumb-item">{data?.independent}</li>
                  )}
                </ol>
              </div>
              <span className="d-block">
                Oluşturma Tarihi:{" "}
                {moment(data.created_at, "YYYY-MM-DD HH:mm:ss").format(
                  "DD MMMM YYYY"
                )}
              </span>
              <span className="d-block">
                Güncelleme Tarihi:{" "}
                {moment(data.updated_at, "YYYY-MM-DD HH:mm:ss").format(
                  "DD MMMM YYYY"
                )}
              </span>
            </div>

            <hr />

            <h4 className="d-block mb-3">Kullanıcı Bilgileri</h4>

            <div className="table-responsive">
              <table className="table border mb-0 align-middle">
                <thead>
                  <tr>
                    <th className="border-bottom px-2">Ad Soyad</th>
                    <th className="border-bottom border-start px-2">
                      Kullanıcı Adı
                    </th>
                    <th className="border-bottom border-start px-2">Rol</th>
                    <th className="border-bottom border-start px-2">Email</th>
                    <th className="border-bottom border-start px-2">Telefon</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>{data.user.name}</td>
                    <td className="border-start">{data.user.username}</td>
                    <td className="border-start">{data.user.role.name}</td>
                    <td className="border-start">{data.user.email}</td>
                    <td className="border-start">{data.user.phone}</td>
                  </tr>
                </tbody>
              </table>
            </div>

            <hr />

            <div className="row g-3">
              <div className="col">
                <h4 className="d-block mb-3">
                  {data.report.name}{" "}
                  <span className="h5">
                    / Toplam Soru: {data?.reply[0].length}
                  </span>
                </h4>
              </div>

              <div className="col w-auto flex-none">
                <button
                  className="btn btn-outline-theme"
                  onClick={() => {
                    setReportMapShow(true);
                    setItemLocation(null);
                  }}
                >
                  Tüm Konumları Göster
                </button>
              </div>
            </div>

            <div className="row g-2">
              {data?.reply[0].map((item, index) => (
                <div key={index} className="col-12 col-md-6 col-lg-3">
                  <Card className="h-100">
                    <CardBody className="d-flex flex-column overflow-hidden">
                      <div className="row text-white border-bottom pb-2 mb-3">
                        <span className="col">
                          <span className="fw-bold h5">{index + 1}.</span> Soru
                        </span>
                        <span className="col text-end">
                          {item?.category?.name}
                        </span>
                      </div>
                      <div
                        className="flex-1"
                        style={{
                          maxHeight: 350,
                          overflowY: "auto",
                          overflowX: "hidden",
                          paddingRight: 10,
                          marginRight: -10,
                        }}
                      >
                        <div>
                          <span className="d-block h5 mb-3">
                            {item?.question?.question}
                          </span>
                        </div>
                        <div>
                          <div
                            className="d-flex flex-wrap gap-1"
                            style={{ borderRadius: 8 }}
                          >
                            {item.answer.answer
                              .split("-")
                              .map((answer, answerIndex) => (
                                <span
                                  key={answerIndex}
                                  className={`pe-none py-1 px-2 rounded fw-bold text-white bg-dark`}
                                >
                                  {answer}
                                </span>
                              ))}
                          </div>
                        </div>
                        <div>
                          {item.answer.images &&
                          item.answer.images.length !== 0 ? (
                            <>
                              {item.answer.images.map((img, imgIndex) => (
                                <div key={imgIndex}>
                                  <span className="d-block my-2">
                                    {img.description}
                                  </span>

                                  <Fancybox
                                    options={{
                                      Carousel: {
                                        infinite: false,
                                      },
                                    }}
                                  >
                                    <div
                                      className="carousel slide"
                                      id={`anket-reply-list-${index}-item-${imgIndex}`}
                                    >
                                      <div className="carousel-inner">
                                        {img.list.map(
                                          (imgList, imgListIndex) => (
                                            <div
                                              className={`carousel-item${
                                                imgListIndex === 0
                                                  ? " active"
                                                  : ""
                                              }`}
                                            >
                                              <a
                                                data-fancybox={
                                                  img.description + "-gallery"
                                                }
                                                href={imgList}
                                                key={imgListIndex}
                                                className="col-6"
                                              >
                                                <img
                                                  className="w-100 img-cover"
                                                  style={{ height: 200 }}
                                                  src={imgList}
                                                  alt={
                                                    imgListIndex +
                                                    "_" +
                                                    img.description
                                                  }
                                                />
                                              </a>
                                            </div>
                                          )
                                        )}
                                      </div>

                                      {img.list.length > 1 && (
                                        <>
                                          <button
                                            className="carousel-control-prev"
                                            type="button"
                                            data-bs-target={`#anket-reply-list-${index}-item-${imgIndex}`}
                                            data-bs-slide="prev"
                                          >
                                            <span
                                              className="carousel-control-prev-icon"
                                              aria-hidden="true"
                                            ></span>
                                            <span className="visually-hidden">
                                              Previous
                                            </span>
                                          </button>

                                          <button
                                            className="carousel-control-next"
                                            type="button"
                                            data-bs-target={`#anket-reply-list-${index}-item-${imgIndex}`}
                                            data-bs-slide="next"
                                          >
                                            <span
                                              className="carousel-control-next-icon"
                                              aria-hidden="true"
                                            ></span>
                                            <span className="visually-hidden">
                                              Next
                                            </span>
                                          </button>
                                        </>
                                      )}
                                    </div>
                                  </Fancybox>
                                </div>
                              ))}

                              <button
                                className="btn btn-outline-theme mt-2"
                                onClick={() => {
                                  setItemLocation(item);
                                  setReportMapShow(true);
                                }}
                              >
                                Konumları Göster
                              </button>
                            </>
                          ) : (
                            <span className="d-block mt-3">Resim Yok</span>
                          )}
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : (
        <div className="spinner-border text-theme"></div>
      )}
    </div>
  );
}

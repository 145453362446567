import React, { useContext, useEffect, useState } from "react";
import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

function hexToRgba(hex, opacity) {
  // Hex kodundan başındaki # işaretini kaldır
  hex = hex.replace("#", "");

  // R, G, B değerlerini al
  var r = parseInt(hex.substring(0, 2), 16);
  var g = parseInt(hex.substring(2, 4), 16);
  var b = parseInt(hex.substring(4, 6), 16);

  // Opacity (saydamlık) değerini 0 ile 1 arasında normalize et
  var alpha = opacity || 1;

  // rgba formatında geri döndür
  return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
}

export default function MenuItem({
  city,
  district,
  neighborhood,
  menu,
  setTableLocationData,
  setTableShow,
  tableShow,
  customerColor,
  handleTableMarker,
  setTableLocationButton,
  tableLocationButton,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState(null);
  const [dataColumn, setDataColumn] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  async function fetchColumn(type) {
    setDataColumn(null);

    try {
      const response = await apiClient.get(
        `/columns/${`harita-data-customer-${context?.me?.customer?.id}`}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setDataColumn(response.data.columns);
      }
    } catch (error) {
      setDataColumn(null);
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    fetchColumn();
  }, []);

  async function fetchMapData(
    city,
    district,
    neighborhood,
    type,
    page,
    paginate
  ) {
    const columns = dataColumn.map((item) => ({
      key: item,
      value: "select",
    }));

    const options = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `harita-data-customer-${context?.me?.customer?.id}`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: paginate
          ? columns
          : [
              {
                key: "Lat",
                value: "select",
              },
              {
                key: "Lng",
                value: "select",
              },
            ],
        condition: [
          city
            ? {
                key: "Adres Il",
                value: context.removeTurkishCharacters(city).toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "Adres Ilce",
                value: context.removeTurkishCharacters(district).toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          neighborhood
            ? {
                key: "Adres Mahalle",
                value: context
                  .removeTurkishCharacters(neighborhood)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    if (paginate) {
      options.paginate = true;
    }

    setData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        options,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (paginate) {
        setData(response);
      } else {
        setTableLocationData(response);
      }
    } catch (error) {
      setData(null);
      console.error(error);
    }
  }

  useEffect(() => {
    if (dataColumn) {
      fetchMapData(city, district, neighborhood, menu, currentPage, true);
      fetchMapData(city, district, neighborhood, menu, currentPage, false);
    }
  }, [city, district, neighborhood, menu, currentPage, dataColumn]);

  function handlePagination(value) {
    setCurrentPage(value);
  }

  return (
    <div
      className="map-info-container position-absolute row py-3"
      style={{
        background:
          customerColor !== "null"
            ? hexToRgba(customerColor, 0.8)
            : "rgba(19, 29, 40, 0.9)",
        bottom: 16,
        left: 24,
        right: 24,
      }}
    >
      {customerColor !== "null" && (
        <div
          className="position-absolute top-0 start-0 w-100 h-100"
          style={{
            background: "rgb(0, 7, 23)",
            opacity: 0.75,
          }}
        ></div>
      )}

      <div style={{ zIndex: 1 }} className="col-12">
        <div className="d-flex align-items-center justify-content-between">
          <span className="h5 mb-0" id="info-title">
            {city}
            {district ? "/" + district : ""}
            {neighborhood ? "/" + neighborhood : ""}
          </span>

          <div className="d-flex gap-2">
            <button
              type="button"
              className="btn btn-outline-theme"
              onClick={() => {
                setTableShow(!tableShow);
              }}
            >
              Tabloyu {!tableShow ? "Göster" : "Gizle"}
            </button>

            <button
              type="button"
              className="btn btn-outline-theme"
              onClick={() => {
                handleTableMarker();
                setTableLocationButton(!tableLocationButton);
              }}
            >
              Konumları {tableLocationButton ? "Göster" : "Gizle"}
            </button>
          </div>
        </div>
      </div>

      {tableShow && (
        <div
          style={{ zIndex: 1, height: 300 }}
          className="col-12 d-flex flex-column border-top pt-3 mt-3"
        >
          <div className="h-100">
            <div className="d-flex flex-column h-100">
              <div className="table-responsive h-100">
                {data ? (
                  data.data.length !== 0 ? (
                    <table className="table text-nowrap w-100">
                      <thead>
                        <tr>
                          {Object.keys(data.data[0]).map((header) => (
                            <th key={header}>{header.toUpperCase()}</th>
                          ))}
                        </tr>
                      </thead>

                      <tbody>
                        {data.data.map((item, index) => (
                          <tr key={index}>
                            {Object.keys(data.data[0]).map((header) => (
                              <td key={header}>{item[header]}</td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <span className="d-block text-center">
                      Analiz Verisi Eklenmemiştir
                    </span>
                  )
                ) : (
                  <div className="spinner-border text-theme"></div>
                )}
              </div>

              {data && (
                <div className="row align-items-center mt-3 overflow-x-auto">
                  <div className="mb-0 col-md-12">
                    <div className="dataTables_paginate paging_simple_numbers d-flex">
                      <ul className="pagination">
                        <li
                          className={`paginate_button page-item ${
                            !data.prev_page_url && "disabled"
                          }`}
                        >
                          <div
                            onClick={() =>
                              data.prev_page_url &&
                              handlePagination(
                                data.prev_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link pointer"
                          >
                            Geri
                          </div>
                        </li>

                        {data.links.map(
                          (item, index) =>
                            !isNaN(parseInt(item.label)) && (
                              <li
                                key={index}
                                className={`paginate_button page-item ${
                                  item.active ? "active" : ""
                                }`}
                              >
                                <div
                                  onClick={() => handlePagination(item.label)}
                                  className="page-link pointer"
                                >
                                  {item.label}
                                </div>
                              </li>
                            )
                        )}

                        <li
                          className={`paginate_button page-item ${
                            !data.next_page_url && "disabled"
                          }`}
                        >
                          <div
                            onClick={() =>
                              data.next_page_url &&
                              handlePagination(
                                data.next_page_url.split("?page=")[1]
                              )
                            }
                            className="page-link pointer"
                          >
                            İleri
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useEffect, useContext, useState, useRef } from "react";

import Select from "react-select";
import { ToastContainer } from "react-toastify";
import ReactECharts from "echarts-for-react";

import { AppSettings } from "../../config/app-settings.js";
import { Card, CardBody } from "../card/card.jsx";
import toastMessage from "../ToastMessage.js";
import apiClient from "../../ApiClient.js";
import WordCloud from "../WordCloud.jsx";
import DynamicTable from "../DynamicTable.jsx";

function formatErrorMessage(message) {
  const parts = message.split(":");

  const mainMessage = parts[0].trim();
  const note = parts[1] && parts[1].trim();

  return `Veri Tipi Uyuşmazlığı 
  
  ${mainMessage}
  ${note} `;
}

export default function CreateGraphicComponent({ modalOpen = false }) {
  const context = useContext(AppSettings);
  const chartViewSelectOptions = [
    { value: 1, label: "Pasta Grafiği" },
    { value: 2, label: "Çubuk Grafiği" },
    { value: 3, label: "Kelime Bulutu" },
    { value: 4, label: "Tablo" },
  ];
  const columnOperationSelectOptions = [
    { value: "select", label: "TÜMÜ" },
    { value: "column", label: "SÜTUN" },
    { value: "count", label: "SAY" },
    { value: "avg", label: "ORTALAMA" },
    { value: "sum", label: "TOPLAM" },
    { value: "min", label: "MİNİMUM" },
    { value: "max", label: "MAKSİMUM" },
    { value: "distinct", label: "FARKLI" },
    { value: "toDate", label: "TARİH" },
    { value: "dayOfMonth", label: "GÜN" },
    { value: "toMonth", label: "AY" },
    { value: "toYear", label: "YIL" },
    { value: "toHour", label: "SAAT" },
    { value: "toMinute", label: "DAKİKA" },
    { value: "toSecond", label: "SANİYE" },
  ];
  const selectedOperatorOptions = [
    { value: "and", label: "Ve" },
    { value: "or", label: "Veya" },
  ];
  const selectedConditionOptions = [
    { value: "=", label: "=" },
    { value: "!=", label: "!=" },
    { value: ">", label: ">" },
    { value: ">=", label: ">=" },
    { value: "<", label: "<" },
    { value: "<=", label: "<=" },
    { value: "in", label: "İÇİNDE" },
    { value: "notin", label: "DIŞINDA" },
    { value: "like", label: "BENZER" },
    { value: "notlike", label: "BENZEMEZ" },
    { value: "isnull", label: "BOŞ" },
    { value: "isnotnull", label: "DOLU" },
  ];
  const legendPositionOrientSelectOptions = [
    {
      value: "vertical",
      label: "Dikey",
    },
    {
      value: "horizontal",
      label: "Yatay",
    },
  ];
  const legendPositionLeftSelectOptions = [
    {
      value: "left",
      label: "Sol",
    },
    {
      value: "center",
      label: "Orta",
    },
    {
      value: "right",
      label: "Sağ",
    },
  ];
  const legendPositionTopSelectOptions = [
    {
      value: "top",
      label: "Yukarı",
    },
    {
      value: "middle",
      label: "Orta",
    },
    {
      value: "bottom",
      label: "Aşağı",
    },
  ];

  const dateColumn = [
    {
      value: "todate",
      label: "TARİH",
    },
    {
      value: "dayofmonth",
      label: "GÜN",
    },
    {
      value: "tomonth",
      label: "AY",
    },
    {
      value: "toyear",
      label: "YIL",
    },
    {
      value: "tohour",
      label: "SAAT",
    },
    {
      value: "tominute",
      label: "DAKİKA",
    },
    {
      value: "tosecond",
      label: "SANİYE",
    },
  ];

  const substringsToRemove = [
    "_todate",
    "_dayofmonth",
    "_tomonth",
    "_toyear",
    "_tohour",
    "_tominute",
    "_tosecond",
  ];

  const [showLegend, setShowLegend] = useState(true);
  const [legendOrient, setLegendOrient] = useState({
    value: "horizontal",
    label: "Yatay",
  });
  const [legendPosition, setLegendPosition] = useState({
    left: { value: "center", label: "Orta" },
    top: { value: "top", label: "Yukarı" },
  });
  const [graphicWidth, setGraphicWidth] = useState({
    value: "col-12",
    label: "Büyük (4/4)",
  });

  const toggleLegend = () => setShowLegend(!showLegend);

  const handleOrientChange = (value) => setLegendOrient(value);

  const handlePositionChange = (name, value) => {
    setLegendPosition((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [customerControl, setCustomerControl] = useState(null);

  const [customerList, setCustomerList] = useState([]);
  const [customerValue, setCustomerValue] = useState(null);

  const [loader, setLoader] = useState(false);
  const [previewShow, setPreviewShow] = useState(false);

  const [previewData, setPreviewData] = useState([]);

  const [status, setStatus] = useState(true);

  const [groupingColumns, setGroupingColumns] = useState([]);

  useEffect(() => {
    if (context.allCustomerSelect && context.allCustomerSelect.length > 0) {
      const hasAllOption = context.allCustomerSelect.some(
        (customer) => customer.value === "all"
      );

      if (!hasAllOption) {
        const customerListArray = [...context.allCustomerSelect];
        customerListArray.unshift({ value: "all", label: "Tümü" });
        setCustomerList(customerListArray);
      } else {
        setCustomerList(context.allCustomerSelect);
      }
    }
  }, [context.allCustomerSelect]);

  const [columns, setColumns] = useState([]);

  const handleAddColumn = () => {
    setColumns([
      ...columns,
      {
        id: Date.now(),
        selectedColumns: [],
        operationType: null,
        dateGroupValue: null,
      },
    ]);
  };

  const handleRemoveColumn = (id) => {
    setColumns(columns.filter((column) => column.id !== id));
  };

  const handleColumnChange = (selectedColumns, index) => {
    const newColumns = [...columns];
    newColumns[index].selectedColumns = selectedColumns;
    setColumns(newColumns);
  };

  const handleOperationTypeChange = (operationType, index) => {
    const newColumns = [...columns];
    newColumns[index].operationType = operationType;
    setColumns(newColumns);
  };

  const handleDateTypeChange = (dateGroupValue, index) => {
    const newColumns = [...columns];
    newColumns[index].dateGroupValue = dateGroupValue;
    setColumns(newColumns);
  };

  const [page, setPage] = useState(null);
  const [chartOrder, setChartOrder] = useState(1);
  const [chartName, setChartName] = useState("");
  const [chartDescription, setChartDescription] = useState("");
  const [chartView, setChartView] = useState(null);
  const [files, setFiles] = useState(null);
  const [fileColumns, setFileColumns] = useState([]);
  const [operation, setOperation] = useState({
    conditions: [],
    operationType: null,
    orderings: [],
  });

  const [fileTypes, setFileTypes] = useState(null);
  const [fileTypesCustomer, setFileTypesCustomer] = useState(null);

  const [pages, setPages] = useState(null);
  const [pageCustomer, setPageCustomer] = useState(null);

  useEffect(() => {
    if (context && context.me) {
      setFileTypesCustomer(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: "Benim",
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setCustomerControl(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: "Benim",
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setCustomerControl(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: "Benim",
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
    }
  }, [context]);

  async function handlePageSelect(e) {
    if (e) {
      setPages(null);
      setPage(null);

      const data = await context.getOncePages(e.value);

      if (data) {
        setPages(data);
      } else {
        setPages(null);
      }

      setPageCustomer(e);
    }
  }

  async function handleFileTypesCustomer(e) {
    if (e) {
      setFileTypes(null);
      setFiles(null);

      const data = await context.getFileTypes(e.value, true);

      if (data) {
        setFileTypes(data);
      } else {
        setFileTypes(null);
      }

      setFileTypesCustomer(e);
    }
  }

  async function getFileColumns(name) {
    setFileColumns(null);

    try {
      const response = await apiClient.get(`/columns/${name}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });
      if (response) {
        setFileColumns(response.data.columns);
      }
    } catch (error) {
      console.error("Get File Types Error", error);
    }
  }

  useEffect(() => {
    if (files) {
      getFileColumns(files.value);
    }
  }, [files]);

  const addCondition = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: [
        ...prevOperation.conditions,
        {
          selectedColumn: null,
          selectedOperator: null,
          conditionDisabled: false,
          selectedCondition: null,
          inputDisabled: false,
          inputValue: "",
        },
      ],
    }));
  };

  const removeCondition = (conditionIndex) => {
    const newConditions = [...operation.conditions];
    newConditions.splice(conditionIndex, 1);
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleConditionColumnChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedColumn = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleOperatorChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedOperator = selectedOption;
    newConditions[conditionIndex].conditionDisabled =
      selectedOption && selectedOption.value !== "";
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleConditionChange = (conditionIndex, selectedOption) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].selectedCondition = selectedOption;
    if (
      selectedOption &&
      (selectedOption.value === "isnull" ||
        selectedOption.value === "isnotnull")
    ) {
      newConditions[conditionIndex].inputDisabled = true;
      newConditions[conditionIndex].inputValue = "";
    } else {
      newConditions[conditionIndex].inputDisabled = false;
    }
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleInputChange = (conditionIndex, event) => {
    const newConditions = [...operation.conditions];
    newConditions[conditionIndex].inputValue = event.target.value;
    setOperation((prevOperation) => ({
      ...prevOperation,
      conditions: newConditions,
    }));
  };

  const handleAddOrder = () => {
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: [
        ...prevOperation.orderings,
        {
          column: null,
          order: null,
        },
      ],
    }));
  };

  const handleOrderChange = (orderIndex, selectedOption) => {
    const newOrderings = [...operation.orderings];
    newOrderings[orderIndex].order = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  const handleOrderColumnChange = (orderIndex, selectedOption) => {
    const newOrderings = [...operation.orderings];
    newOrderings[orderIndex].column = selectedOption;
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  const handleRemoveOrder = (orderIndex) => {
    const newOrderings = [...operation.orderings];
    newOrderings.splice(orderIndex, 1);
    setOperation((prevOperation) => ({
      ...prevOperation,
      orderings: newOrderings,
    }));
  };

  // const checkDateColumnSelected = (selectedOptions) => {
  //   console.log(selectedOptions);
  //   const selectedValues = selectedOptions.map((option) => option.value);
  //   const dateColumns = ["day", "month", "year", "hour", "minute", "second"];
  //   for (const selectedValue of selectedValues) {
  //     if (dateColumns.includes(selectedValue)) {
  //       toastMessage("Tarih sütunu seçmeyi unutmayın!", "warning");
  //       return;
  //     }
  //   }
  // };

  const handleGroupingChange = (selectedOptions) => {
    setGroupingColumns(selectedOptions);
  };

  const validateConditions = () => {
    for (const condition of operation.conditions) {
      if (
        !condition.selectedColumn ||
        !condition.selectedCondition ||
        !condition.selectedOperator ||
        (condition.selectedCondition.value !== "isnull" &&
          condition.selectedCondition.value !== "isnotnull" &&
          !condition.inputValue)
      ) {
        toastMessage("Koşul Alanlarını Boş Bırakmayın.", "error");
        return false;
      }
    }
    return true;
  };

  const validateColumns = () => {
    for (const column of columns) {
      if (!column.selectedColumns || !column.operationType) {
        toastMessage("Sütun Alanlarını Boş Bırakmayın.", "error");
        return false;
      }
    }
    return true;
  };

  const [data, setData] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [tableCondition, setTableCondition] = useState({
    column: null,
    value: "",
  });

  const handleCreateButtonClick = async () => {
    if (!page || !chartOrder || !chartName || !chartView || !files) {
      toastMessage("Grafik Alanlarını Boş Bırakmayın.", "error");
      return;
    }

    if (chartName.length > 100) {
      toastMessage("Grafik Adı 100 Karakterden Az Olmalıdır.", "error");
      return;
    }

    if (columns.length === 0) {
      toastMessage("En Az Bir Sütun Seçin.", "error");
      return;
    }

    if (!validateColumns()) {
      return;
    }

    // if (operation.conditions.length === 0) {
    //   toastMessage("En Az Bir Koşul Seçin.", "error");
    //   return;
    // }

    if (operation.conditions.length !== 0 && !validateConditions()) {
      return;
    }

    const selectedColumnValues = columns.map(
      (col) => col.selectedColumns.value
    );
    for (const groupingColumn of groupingColumns) {
      if (!selectedColumnValues.includes(groupingColumn.value)) {
        toastMessage(
          `Gruplandırma sütunu "${groupingColumn.label}" seçilen sütunlarda bulunmalıdır.`,
          "error"
        );
        return;
      }
    }

    fetchPreview();
  };

  async function fetchPreview(params) {
    if (page && chartOrder && chartName && chartView && files) {
      const data = {
        chart: chartView.value,
        page_id: page.value,
        title: chartName,
        description: chartDescription,
        status: true,
        order: chartOrder,
        type: files.value,
        customer_id: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        customer: context.me
          ? context?.me?.role?.id === 1
            ? null
            : context.me.customer.id
          : null,
        sql: {
          column:
            columns.length !== 0
              ? columns.map((item) => {
                  return {
                    key: item.selectedColumns.value,
                    value: item.operationType.value,
                  };
                })
              : [],
          condition:
            operation.conditions.length !== 0
              ? [
                  tableCondition.column
                    ? {
                        key: tableCondition.column,
                        value: tableCondition.value,
                        operator: "like",
                        type: "and",
                      }
                    : null,
                  ...operation.conditions.map((item) => {
                    return {
                      key: item.selectedColumn.value,
                      value: item.inputValue,
                      operator: item.selectedCondition.value,
                      type: item.selectedOperator.value,
                    };
                  }),
                ].filter((item) => item !== null)
              : [
                  tableCondition.column
                    ? {
                        key: tableCondition.column,
                        value: tableCondition.value,
                        operator: "like",
                        type: "and",
                      }
                    : null,
                ].filter((item) => item !== null),
          group:
            columns.length !== 0
              ? [
                  ...columns
                    .map((item) =>
                      item.dateGroupValue
                        ? item.dateGroupValue.map(
                            (dateGroup) => dateGroup.value
                          )
                        : []
                    )
                    .flat(),
                  ...groupingColumns.map((item) => item.value),
                ]
              : [],
        },
      };

      setLoader(true);

      if (chartView.value === 4) {
        data.paginate = true;
      } else {
        if (data.paginate) {
          delete data.paginate;
        }
      }

      setData(data);

      try {
        const response = await apiClient.customPost(
          `/query/preview${data.paginate ? `?page=${tablePage}` : ""}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          // toastMessage("İşlem Doğrulandı", "success");
          setLoader(false);
          setPreviewShow(true);

          if (chartView.value === 4) {
            setPreviewData(response);
          } else {
            const filteredResponse = response.filter((item) => {
              return Object.values(item).every(
                (value) => value !== "" && value !== null
              );
            });

            setPreviewData(filteredResponse);
          }

          if (context.graphicUpdate) {
            if (context.graphicUpdate.options.selectedColumn[0]) {
              setPreviewSelectValue(
                response.some((item) =>
                  item.hasOwnProperty(
                    context.graphicUpdate.options.selectedColumn[0].value
                  )
                )
                  ? context.graphicUpdate.options.selectedColumn[0]
                  : null
              );
            } else {
              setPreviewSelectValue(null);
            }

            if (context.graphicUpdate.options.selectedColumn[1]) {
              setPreviewSelectLabel(
                response.some((item) =>
                  item.hasOwnProperty(
                    context.graphicUpdate.options.selectedColumn[1].value
                  )
                )
                  ? context.graphicUpdate.options.selectedColumn[1]
                  : null
              );
            } else {
              setPreviewSelectLabel(null);
            }

            if (context.graphicUpdate.options.selectedColumn[2]) {
              setPreviewSelectWordCloud(
                response.some((item) =>
                  item.hasOwnProperty(
                    context.graphicUpdate.options.selectedColumn[2].value
                  )
                )
                  ? context.graphicUpdate.options.selectedColumn[2]
                  : null
              );
            } else {
              setPreviewSelectWordCloud(null);
            }

            // if (context.graphicUpdate.options.selectedColumn[2]) {
            //   setPreviewChartShow(true);
            // } else {
            //   if (
            //     response.some((item) =>
            //       item.hasOwnProperty(
            //         context.graphicUpdate.options.selectedColumn[0].value
            //       )
            //     ) &&
            //     response.some((item) =>
            //       item.hasOwnProperty(
            //         context.graphicUpdate.options.selectedColumn[1].value
            //       )
            //     )
            //   ) {
            //     setPreviewChartShow(true);
            //   }
            // }
          } else {
            setPreviewSelectWordCloud(null);
            setPreviewSelectValue(null);
            setPreviewSelectLabel(null);
          }
        }
      } catch (error) {
        setLoader(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(formatErrorMessage(errorMessage), "error")
            )
          );
        } else {
          toastMessage(
            formatErrorMessage(error.response.data.message),
            "error"
          );
        }
      }
    }
  }

  useEffect(() => {
    fetchPreview();
  }, [tablePage, tableCondition]);

  const [previewChartShow, setPreviewChartShow] = useState(false);
  const [previewSelectValue, setPreviewSelectValue] = useState(null);
  const [previewSelectLabel, setPreviewSelectLabel] = useState(null);
  const activeChartOptions = [
    {
      darkMode: true,
      tooltip: {
        trigger: "item",
      },
      series: [
        {
          name: "",
          type: "pie",
          radius: "50%",
          data: [],
          label: {
            textStyle: {
              fontSize: 15,
            },
          },
          itemStyle: {
            color: "#3cd2a5",
          },
        },
      ],
    },
    {
      tooltip: {
        trigger: "axis",
      },
      darkMode: true,
      toolbox: {
        show: true,
        feature: {
          magicType: { show: true, type: ["line", "bar"] },
          restore: { show: false },
          saveAsImage: { show: false },
        },
        iconStyle: { borderColor: "#fff" },
      },
      calculable: true,
      xAxis: [
        {
          type: "category",
          data: [],
          axisLabel: {
            rotate: 45,
            textStyle: {
              color: "#fff",
              fontSize: 10,
            },
          },
        },
      ],
      yAxis: [
        {
          type: "value",
          axisLabel: {
            textStyle: {
              color: "#fff",
            },
          },
        },
      ],
      grid: { bottom: "20%" },
      series: [
        {
          name: "",
          type: "bar",
          data: [],
          markPoint: {
            data: [
              { type: "max", name: "Max" },
              { type: "min", name: "Min" },
            ],
          },
          itemStyle: {
            color: "#3cd2a5",
          },
        },
      ],
    },
  ];
  const [chartOptions, setChartOptions] = useState();
  const [wordCloudData, setWordCloudData] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [previewSelectWordCloud, setPreviewSelectWordCloud] = useState(null);

  function handleSelectWordCloud(e) {
    setPreviewSelectWordCloud(e);
    setPreviewSelectValue(null);
    setPreviewSelectLabel(null);
  }

  useEffect(() => {
    if (chartView) {
      setChartOptions(activeChartOptions[chartView.value - 1]);
    }
  }, [chartView]);

  useEffect(() => {
    if (chartView) {
      if (chartView.value !== 2) {
        setChartOptions((prevOptions) => ({
          ...prevOptions,
          legend: {
            show: showLegend,
            orient: legendOrient.value,
            left: legendPosition.left.value,
            top: legendPosition.top.value,
            itemGap: 20,
            textStyle: {
              color: "#fff",
            },
          },
        }));
      }
    }
  }, [chartView, showLegend, legendOrient, legendPosition, showLegend]);

  function handlePreview() {
    if (!previewSelectWordCloud && chartView.value !== 4) {
      if (!previewSelectValue || !previewSelectLabel) {
        toastMessage("Sütun Seçin.", "error");
        return;
      }
    }

    if (chartView.value === 1) {
      const chartData = previewData.map((item) => ({
        value: item[previewSelectValue.value],
        name: item[previewSelectLabel.value],
      }));

      setChartOptions((prevOptions) => ({
        ...prevOptions,
        tooltip: previewSelectValue.value.includes("_avg")
          ? {
              trigger: "item",
              formatter: function (params) {
                return (
                  params.data.name + "<br /> %" + params.data.value.toFixed(2)
                );
              },
            }
          : {
              trigger: "item",
            },
        visualMap: {
          show: false,
          min: Math.min(...chartData.map((obj) => obj.value)),
          max: Math.max(...chartData.map((obj) => obj.value)),
          inRange: {
            colorLightness: [0.2, 1],
          },
        },
        series: [
          {
            ...prevOptions.series[0],
            data: chartData,
          },
        ],
      }));
    } else if (chartView.value === 2) {
      setChartOptions((prevOptions) => ({
        ...prevOptions,
        visualMap: {
          show: false,
          min: Math.min(
            ...previewData
              .map((item) => item[previewSelectValue.value])
              .map((obj) => obj)
          ),
          max: Math.max(
            ...previewData
              .map((item) => item[previewSelectValue.value])
              .map((obj) => obj)
          ),
          inRange: {
            colorLightness: [0.2, 1],
          },
        },
        xAxis: [
          {
            ...prevOptions.xAxis[0],
            data: previewData.map((item) => item[previewSelectLabel.value]),
          },
        ],
        series: [
          {
            ...prevOptions.series[0],
            data: previewData.map((item) => item[previewSelectValue.value]),
          },
        ],
      }));
    } else if (chartView.value === 3) {
      let chartData;

      if (previewSelectWordCloud) {
        chartData = previewData
          .map((item) => item[previewSelectWordCloud.value])
          .join(", ");
      } else {
        chartData = previewData.map((item) => ({
          category: item[previewSelectLabel.value],
          value: item[previewSelectValue.value],
        }));
      }

      setWordCloudData(chartData);
    } else if (chartView.value === 4) {
      setTableData(previewData);
    }

    setPreviewChartShow(true);
  }

  async function handleGraphicPost() {
    setLoader(true);

    const postData = data;
    postData.options = {
      file: {
        customerSelect: fileTypesCustomer,
        fileSelect: files,
      },
      page: {
        customerSelect: pageCustomer,
        pageSelect: page,
      },
      selectedColumn: [
        previewSelectValue,
        previewSelectLabel,
        previewSelectWordCloud,
      ],
      width: graphicWidth,
      options: wordCloudData.length !== 0 ? wordCloudData : chartOptions,
    };

    try {
      const response = context.graphicUpdate
        ? await apiClient.put(`/query/${context.graphicUpdate.id}`, postData, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost(`/query`, postData, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });
      if (response) {
        setLoader(false);
        toastMessage(
          context.graphicUpdate
            ? "Grafik Güncellendi"
            : "Grafik Sayfaya Eklendi",
          "success"
        );

        setTimeout(() => {
          window.location.reload();
          // context.setGraphicFetch(!context.graphicFetch);
          // document.getElementById("createGraphicModalCloseButton").click();
        }, 300);
      }
    } catch (error) {
      setLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  function findNameById(array, id) {
    for (let item of array) {
      if (item.id === id) {
        return item.name;
      }
      if (item.children) {
        for (let child of item.children) {
          if (child.id === id) {
            return child.name;
          }
        }
      }
    }
    return null; // id bulunamazsa null döner
  }

  function handleResetPreview() {
    setPreviewShow(false);
    setPreviewChartShow(false);

    if (!context.graphicUpdate) {
      setPreviewData([]);
      setPreviewSelectValue(null);
      setPreviewSelectWordCloud(null);
      setPreviewSelectLabel(null);
      setShowLegend(true);
      setLegendOrient({
        value: "horizontal",
        label: "Yatay",
      });
      setLegendPosition({
        left: { value: "center", label: "Orta" },
        top: { value: "top", label: "Yukarı" },
      });
      setGraphicWidth({
        value: "col-12",
        label: "Büyük (4/4)",
      });
    }
  }

  function handleReset() {
    if (context && context.me) {
      setChartView(null);

      setPage(null);

      setChartName("");
      setFileTypesCustomer(
        context?.me?.role?.id === 1
          ? {
              value: null,
              label: "Benim",
            }
          : {
              value: context.me.customer.id,
              label: context.me.customer.customer.name,
            }
      );
      setChartOrder(1);
      setChartDescription("");
      setFiles(null);
      setColumns([]);
      setGroupingColumns([]);
      setOperation({
        conditions: [],
        operationType: null,
        orderings: [],
      });

      handleFileTypesCustomer(customerControl);
      handlePageSelect(customerControl);
    }
  }

  // Update
  function getUpdateItems() {
    setChartView({
      value: context.graphicUpdate.chart,
      label: chartViewSelectOptions.find(
        (e) => e.value === context.graphicUpdate.chart
      ).label,
    });

    setChartName(context.graphicUpdate.title);
    setChartOrder(context.graphicUpdate.order);
    setChartDescription(context.graphicUpdate.description);

    if (context.graphicUpdate.options?.page?.customerSelect) {
      handlePageSelect(context.graphicUpdate.options?.page?.customerSelect);
      setPageCustomer(context.graphicUpdate.options?.page?.customerSelect);
    }

    if (context.graphicUpdate.options?.page?.pageSelect) {
      setPage(context.graphicUpdate.options?.page?.pageSelect);
    }

    if (context.graphicUpdate.options?.file?.customerSelect) {
      handleFileTypesCustomer(
        context.graphicUpdate.options?.file?.customerSelect
      );
      setFileTypesCustomer(context.graphicUpdate.options?.file?.customerSelect);
    }
    if (context.graphicUpdate.options?.file?.customerSelect) {
      setFiles(context.graphicUpdate.options.file.fileSelect);
    }

    let newArray = [];

    context.graphicUpdate.payload.group.forEach((item) => {
      substringsToRemove.forEach((checkItem) => {
        if (item.includes(checkItem)) {
          newArray.push(item);
        }
      });
    });

    setColumns(
      context.graphicUpdate.payload.column.map((item, index) => {
        let matchedItems = newArray.filter((newItem) =>
          newItem.startsWith(item.key + "_")
        );
        let dateGroupValue = matchedItems.map((match) => ({
          value: match,
          label: dateColumn.find(
            (e) => e.value === match.toLowerCase().split("_")[1]
          ).label,
        }));

        return {
          id: index,
          selectedColumns: { value: item.key, label: item.key },
          operationType: {
            value: item.value,
            label: columnOperationSelectOptions.find(
              (e) => e.value === item.value
            ).label,
          },
          dateGroupValue: dateGroupValue,
        };
      })
    );

    const groupArray = context.graphicUpdate.payload.group.filter(
      (item) =>
        !substringsToRemove.some((substring) => item.includes(substring))
    );

    setGroupingColumns(
      groupArray.map((item) => ({
        value: item,
        label: item,
      }))
    );

    setOperation({
      conditions: context.graphicUpdate.payload.condition.map((item) => {
        return {
          selectedColumn: { value: item.key, label: item.key },
          selectedOperator: {
            value: item.type,
            label: selectedOperatorOptions.find((e) => e.value === item.type)
              .label,
          },
          conditionDisabled:
            item.operator === "isnull" || item.operator === "isnotnull"
              ? true
              : false,
          selectedCondition: {
            value: item.operator,
            label: selectedConditionOptions.find(
              (e) => e.value === item.operator
            ).label,
          },
          inputDisabled: false,
          inputValue: item.value,
        };
      }),
      operationType: null,
      orderings: [],
    });

    //--

    // if (context.graphicUpdate.chart === 3) {
    //   setWordCloudData(context.graphicUpdate.options.options);
    // } else {
    //   setChartOptions(context.graphicUpdate.options.options);
    // }
    setGraphicWidth(context.graphicUpdate.options.width);

    if (context.graphicUpdate.options.options.legend) {
      setShowLegend(context.graphicUpdate.options.options.legend.show);
      setLegendOrient({
        value: context.graphicUpdate.options.options.legend.orient,
        label: legendPositionOrientSelectOptions.find(
          (e) => e.value === context.graphicUpdate.options.options.legend.orient
        ).label,
      });
      setLegendPosition({
        left: {
          value: context.graphicUpdate.options.options.legend.left,
          label: legendPositionLeftSelectOptions.find(
            (e) => e.value === context.graphicUpdate.options.options.legend.left
          ).label,
        },
        top: {
          value: context.graphicUpdate.options.options.legend.top,
          label: legendPositionTopSelectOptions.find(
            (e) => e.value === context.graphicUpdate.options.options.legend.top
          ).label,
        },
      });
    }
  }

  useEffect(() => {
    if (context.graphicUpdate) {
      getUpdateItems();
    } else {
      handleResetPreview();
      handleReset();
    }
  }, [context]);

  return (
    context &&
    context.me && (
      <div className="row justify-content-center">
        {!previewShow ? (
          <div className="col-12">
            <h4>Grafik</h4>

            <Card className="mb-4">
              <CardBody>
                <div className="row g-3">
                  {/* <div className="col-12">
                  <div className="form-check form-switch">
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id="create_graphic_status_radio"
                      defaultChecked={status}
                      onChange={(e) => setStatus(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="create_graphic_status_radio"
                    >
                      {status ? "Aktif" : "Pasif"}
                    </label>
                  </div>
                </div> */}

                  <div className="col-12">
                    <div className="row">
                      {context?.me?.role?.id === 1 && (
                        <div className="col-6">
                          <span className="mb-1 d-block">Sayfa Müşteri</span>
                          {context.allCustomerSelect ? (
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: "Benim",
                                },
                                ...context.allCustomerSelect,
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={pageCustomer}
                              onChange={(e) => handlePageSelect(e)}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      )}

                      <div
                        className={
                          context?.me?.role?.id === 1 ? "col-6" : "col-12"
                        }
                      >
                        <span className="mb-1 d-block">Sayfa Seçiniz</span>
                        {pages ? (
                          <Select
                            options={pages
                              .filter((page) => {
                                if (
                                  !page.name.startsWith("Anket Sayfası") &&
                                  page.page_type &&
                                  !page.iframe &&
                                  !page.url &&
                                  localStorage.getItem("user") &&
                                  JSON.parse(localStorage.getItem("user")).role
                                    .id === 1
                                ) {
                                  return true;
                                }
                                return (
                                  !page.name.startsWith("Anket Sayfası") &&
                                  page.page_type &&
                                  !page.url &&
                                  page.page_type !== "default"
                                );
                              })
                              .sort((a, b) => a.order - b.order)
                              .map((item) => {
                                if (item.children && item.children.length > 0) {
                                  return {
                                    label: item.name,
                                    options: item.children.map((child) => ({
                                      value: child.id,
                                      label: child.name,
                                    })),
                                  };
                                } else {
                                  return {
                                    value: item.id,
                                    label: item.name,
                                  };
                                }
                              })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "Seçenek Yok"}
                            value={page}
                            onChange={(e) => setPage(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-12">
                    <div className="row">
                      {context?.me?.role?.id === 1 && (
                        <div className="col-6">
                          <span className="mb-1 d-block">Dosya Müşteri</span>
                          {context.allCustomerSelect ? (
                            <Select
                              options={[
                                {
                                  value: null,
                                  label: "Benim",
                                },
                                ...context.allCustomerSelect,
                              ]}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={fileTypesCustomer}
                              onChange={(e) => handleFileTypesCustomer(e)}
                            />
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )}
                        </div>
                      )}

                      <div
                        className={
                          context?.me?.role?.id === 1 ? "col-6" : "col-12"
                        }
                      >
                        <span className="mb-1 d-block">Dosya Seç</span>
                        {fileTypes ? (
                          <Select
                            options={fileTypes.map((item) => {
                              return {
                                value: item.type,
                                label: item.type,
                              };
                            })}
                            placeholder=""
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "Seçenek Yok"}
                            value={files}
                            onChange={(e) => setFiles(e)}
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-6">
                    <span className="mb-1 d-block">Grafik Sırası</span>
                    <input
                      type="number"
                      value={chartOrder}
                      min={1}
                      className="form-control"
                      onChange={(e) => setChartOrder(e.target.value)}
                    />
                  </div>

                  <div className="col-6">
                    <span className="mb-1 d-block">Grafik Görünüm</span>
                    <Select
                      options={chartViewSelectOptions}
                      placeholder=""
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      value={chartView}
                      onChange={setChartView}
                    />
                  </div>

                  <div className="col-12">
                    <span className="mb-1 d-block">Grafik Adı</span>
                    <input
                      type="text"
                      className="form-control"
                      value={chartName}
                      onChange={(e) => setChartName(e.target.value)}
                    />
                  </div>

                  <div className="col-12">
                    <span className="mb-1 d-block">Grafik Açıklama</span>
                    <textarea
                      className="form-control"
                      value={chartDescription}
                      onChange={(e) => setChartDescription(e.target.value)}
                    />
                  </div>
                </div>
              </CardBody>
            </Card>

            <h4>Sorgu</h4>

            <Card className="mb-4">
              <CardBody>
                <div className="row gap-3">
                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 mb-3"
                      onClick={handleAddColumn}
                    >
                      Sütun Ekle
                    </button>

                    {columns.length !== 0 ? (
                      columns.map((column, index) => (
                        <div key={index} className="row g-2 mb-3">
                          <div className="col">
                            {fileColumns ? (
                              <Select
                                options={fileColumns.map((item) => {
                                  return {
                                    value: item,
                                    label: item,
                                  };
                                })}
                                placeholder="Sütun Seç"
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "Seçenek Yok"}
                                value={column.selectedColumns}
                                onChange={(selected) =>
                                  handleColumnChange(selected, index)
                                }
                              />
                            ) : (
                              <div className="spinner-border spinner-border-sm text-theme"></div>
                            )}
                          </div>

                          <div className="col">
                            <Select
                              options={columnOperationSelectOptions}
                              placeholder="İşlem Seç"
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={column.operationType}
                              onChange={(selected) =>
                                handleOperationTypeChange(selected, index)
                              }
                            />
                          </div>

                          <div className="col">
                            <Select
                              options={[
                                { value: "", label: "YOK" },
                                {
                                  value:
                                    column.selectedColumns.value + "_todate",
                                  label: "TARİH",
                                },
                                {
                                  value:
                                    column.selectedColumns.value +
                                    "_dayofmonth",
                                  label: "GÜN",
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tomonth",
                                  label: "AY",
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_toyear",
                                  label: "YIL",
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tohour",
                                  label: "SAAT",
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tominute",
                                  label: "DAKİKA",
                                },
                                {
                                  value:
                                    column.selectedColumns.value + "_tosecond",
                                  label: "SANİYE",
                                },
                              ]}
                              isMulti
                              placeholder="Tarih Gruplandırma"
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={column.dateGroupValue}
                              onChange={(selected) =>
                                handleDateTypeChange(selected, index)
                              }
                            />
                          </div>

                          <div className="col w-auto" style={{ flex: "none" }}>
                            <button
                              className="btn border-0 btn-danger h-100"
                              onClick={() => handleRemoveColumn(column.id)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>Sütun Bulunamadı</div>
                    )}
                  </div>

                  <div className="col-12">
                    <hr className="my-0" />
                  </div>

                  <div className="col-12">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 mb-3"
                      onClick={addCondition}
                    >
                      Koşul Ekle
                    </button>

                    {operation.conditions.length !== 0 ? (
                      operation.conditions.map((condition, conditionIndex) => (
                        <div
                          key={conditionIndex}
                          className="row align-items-center g-2 mb-2"
                        >
                          <div
                            className="col w-auto"
                            style={{ flex: "none", minWidth: 80 }}
                          >
                            {conditionIndex + 1}. Koşul:
                          </div>
                          <div className="col">
                            {fileColumns ? (
                              <Select
                                options={fileColumns.map((item) => {
                                  return {
                                    value: item,
                                    label: item,
                                  };
                                })}
                                placeholder="Sütun Seç"
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "Seçenek Yok"}
                                // isDisabled={condition.conditionDisabled}
                                value={condition.selectedColumn}
                                onChange={(selectedOption) =>
                                  handleConditionColumnChange(
                                    conditionIndex,
                                    selectedOption
                                  )
                                }
                              />
                            ) : (
                              <div className="spinner-border spinner-border-sm text-theme"></div>
                            )}
                          </div>
                          <div className="col">
                            <Select
                              options={selectedConditionOptions}
                              placeholder="Operatör Seç"
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              // isDisabled={condition.conditionDisabled}
                              value={condition.selectedCondition}
                              onChange={(selectedOption) =>
                                handleConditionChange(
                                  conditionIndex,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                          <div className="col">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="DEĞER"
                              disabled={condition.inputDisabled}
                              value={condition.inputValue}
                              onChange={(event) =>
                                handleInputChange(conditionIndex, event)
                              }
                            />
                          </div>
                          <div className="col">
                            <Select
                              options={selectedOperatorOptions}
                              value={condition.selectedOperator}
                              placeholder="Koşul Seç"
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              onChange={(selectedOption) =>
                                handleOperatorChange(
                                  conditionIndex,
                                  selectedOption
                                )
                              }
                            />
                          </div>
                          <div className="col w-auto" style={{ flex: "none" }}>
                            <button
                              className="btn border-0 btn-danger h-100"
                              onClick={() => removeCondition(conditionIndex)}
                            >
                              <i className="fas fa-trash-alt"></i>
                            </button>
                          </div>
                        </div>
                      ))
                    ) : (
                      <div>Koşul Bulunamadı</div>
                    )}
                  </div>

                  <div className="col-12">
                    <hr className="my-0" />
                  </div>

                  {/* <div className="col-12">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100 mb-3"
                  onClick={handleAddOrder}
                >
                  Sıralama Ekle
                </button>

                {operation.orderings.length !== 0 ? (
                  operation.orderings.map((order, orderIndex) => (
                    <div
                      key={orderIndex}
                      className="row align-items-center g-2 mb-2"
                    >
                      <div className="col">
                       {fileColumns ? (
                          <Select
                            options={fileColumns.map((item) => {
                              return {
                                value: item,
                                label: item,
                              };
                            })}
                            placeholder="Sütun Seç"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "Seçenek Yok"}
                            value={order.column}
                            onChange={(selectedOption) =>
                              handleOrderColumnChange(orderIndex, selectedOption)
                            }
                          />
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )}
                      </div>
                      <div className="col">
                        <Select
                          options={[
                            { value: "asc", label: "ARTAN" },
                            { value: "desc", label: "AZALAN" },
                          ]}
                          placeholder="Sıra Seç"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => "Seçenek Yok"}
                          value={order.order}
                          onChange={(selectedOption) =>
                            handleOrderChange(orderIndex, selectedOption)
                          }
                        />
                      </div>
                      <div className="col w-auto" style={{ flex: "none" }}>
                        <button
                          className="btn border-0 btn-danger h-100"
                          onClick={() => handleRemoveOrder(orderIndex)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  ))
                ) : (
                  <div>Sıralama Bulunamadı</div>
                )}
              </div> */}
                  {/* 
              <div className="col-12">
                <hr className="my-0" />
              </div> */}

                  <div className="col-12">
                    <span className="mb-1 d-block">Gruplandırma</span>
                    {fileColumns ? (
                      <Select
                        options={fileColumns.map((item) => {
                          return {
                            value: item,
                            label: item,
                          };
                        })}
                        isMulti
                        placeholder="Sütun Seç"
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "Seçenek Yok"}
                        value={groupingColumns}
                        onChange={(selectedOptions) => {
                          // checkDateColumnSelected(selectedOptions);
                          handleGroupingChange(selectedOptions);
                        }}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                </div>
              </CardBody>
            </Card>

            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  data-bs-dismiss="modal"
                >
                  İptal
                </button>
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-theme w-100 mb-3"
                  onClick={!loader ? handleCreateButtonClick : undefined}
                >
                  Ön İzleme
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12">
            <hr className="mt-n3" />

            <div className="row">
              <div className="col-6">
                <h4 className="mb-0">Grafik Ön İzleme</h4>
              </div>

              <div className="col-6 text-end">
                <small className="fw-medium">Seçilen Sayfa: {page.label}</small>
                <br />
                <small className="fw-medium">
                  Seçilen Grafik:{" "}
                  {
                    chartViewSelectOptions.find(
                      (e) => e.value === chartView.value
                    ).label
                  }
                </small>
              </div>
            </div>

            <div className="row align-items-center mt-3">
              {chartView.value !== 4 && (
                <>
                  <div className="col">
                    <span className="mb-1 d-block">Değer</span>
                    <Select
                      options={
                        previewData.length !== 0
                          ? Object.keys(previewData[0]).map((key) => ({
                              value: key,
                              label: key,
                            }))
                          : []
                      }
                      placeholder="Sütun Seç"
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      value={previewSelectValue}
                      onChange={(e) => {
                        setPreviewSelectWordCloud(null);
                        setPreviewSelectValue(e);
                      }}
                    />
                  </div>

                  <div className="col">
                    <span className="mb-1 d-block">Başlık</span>
                    <Select
                      options={
                        previewData.length !== 0
                          ? Object.keys(previewData[0]).map((key) => ({
                              value: key,
                              label: key,
                            }))
                          : []
                      }
                      placeholder="Sütun Seç"
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      value={previewSelectLabel}
                      onChange={(e) => {
                        setPreviewSelectWordCloud(null);
                        setPreviewSelectLabel(e);
                      }}
                    />
                  </div>
                </>
              )}

              {chartView.value === 3 && (
                <div className="col border-start">
                  <span className="mb-1 d-block">Metin</span>
                  <Select
                    options={
                      previewData.length !== 0
                        ? Object.keys(previewData[0]).map((key) => ({
                            value: key,
                            label: key,
                          }))
                        : []
                    }
                    placeholder="Sütun Seç"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "Seçenek Yok"}
                    value={previewSelectWordCloud}
                    onChange={(e) => handleSelectWordCloud(e)}
                  />
                </div>
              )}
            </div>

            <hr />

            <div className="row">
              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-outline-theme w-100"
                  onClick={() => handleResetPreview()}
                >
                  Geri
                </button>
              </div>

              <div className="col-6">
                <button
                  type="button"
                  className="btn btn-theme w-100 mb-3"
                  onClick={() => handlePreview()}
                >
                  Görüntüle
                </button>
              </div>
            </div>

            {previewChartShow && (
              <>
                <hr className="mt-0" />

                <div className="col-12">
                  <div className="row g-3 mb-3">
                    {chartView.value !== 2 &&
                      chartView.value !== 3 &&
                      chartView.value !== 4 && (
                        <>
                          <div className="col">
                            <span>Menü Alanı</span>
                            <button
                              onClick={toggleLegend}
                              className="btn btn-theme w-100"
                            >
                              Göster/Gizle
                            </button>
                          </div>

                          <div className="col">
                            <span>Menü Hizalama</span>
                            <Select
                              options={legendPositionOrientSelectOptions}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={legendOrient}
                              onChange={(e) => handleOrientChange(e)}
                            />
                          </div>
                          <div className="col">
                            <span>Menü Hizalama (Yatay)</span>
                            <Select
                              options={legendPositionLeftSelectOptions}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={legendPosition.left}
                              onChange={(e) => handlePositionChange("left", e)}
                            />
                          </div>
                          <div className="col border-end">
                            <span>Menü Hizalama (Dikey)</span>
                            <Select
                              options={legendPositionTopSelectOptions}
                              placeholder=""
                              classNamePrefix="react-select"
                              noOptionsMessage={() => "Seçenek Yok"}
                              value={legendPosition.top}
                              onChange={(e) => handlePositionChange("top", e)}
                            />
                          </div>
                        </>
                      )}

                    <div className="col">
                      <span>Grafik Genişliği</span>
                      <Select
                        options={[
                          {
                            value: "col-12",
                            label: "Büyük (4/4)",
                          },
                          {
                            value: "col-4",
                            label: "Orta-Büyük (4/3)",
                          },
                          {
                            value: "col-6",
                            label: "Orta (4/2)",
                          },
                          {
                            value: "col-3",
                            label: "Küçük (4/1)",
                          },
                        ]}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "Seçenek Yok"}
                        value={graphicWidth}
                        onChange={(e) => setGraphicWidth(e)}
                      />
                    </div>
                  </div>

                  <div className="row">
                    <div className={graphicWidth.value}>
                      <Card>
                        <CardBody className="d-flex flex-column">
                          <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
                            <span
                              className={`fw-bold h6 mb-0 d-flex align-items-center justify-content-center text-center flex-1`}
                            >
                              {chartName.toUpperCase()}
                            </span>
                          </div>

                          <div className="position-relative overflow-hidden flex-1">
                            {chartView.value === 4 ? (
                              <DynamicTable
                                data={previewData}
                                tablePage={tablePage}
                                setTablePage={setTablePage}
                                setTableCondition={setTableCondition}
                                tableColumn={columns}
                              />
                            ) : chartView.value === 3 ? (
                              <WordCloud data={wordCloudData} />
                            ) : (
                              <ReactECharts option={chartOptions} />
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  </div>
                </div>

                <hr />

                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-theme w-100 mb-3"
                    onClick={() => handleGraphicPost()}
                  >
                    {context.graphicUpdate
                      ? "Grafiği Güncelle"
                      : "Grafiği Sayfaya Ekle"}
                  </button>
                </div>
              </>
            )}
          </div>
        )}

        {loader && (
          <div
            className="position-fixed top-0 start-0 w-100 h-100 d-flex align-items-center justify-content-center"
            style={{ background: "rgba(29, 40, 53, 0.95)", zIndex: 99 }}
          >
            <div className="spinner-border text-theme"></div>
          </div>
        )}

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}

import React, { useCallback, useContext, useEffect, useState } from "react";

import Select from "react-select";
import {
  GoogleMap,
  InfoWindow,
  Marker,
  MarkerClusterer,
} from "@react-google-maps/api";
import { useNavigate } from "react-router-dom";

import { Card, CardBody } from "./card/card";
import { AppSettings } from "../config/app-settings";
import apiClient from "../ApiClient";
import moment from "moment";
import DynamicCard from "./DynamicCard";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
const options = {
  center,
  zoom: defaultZoom,
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { invert_lightness: true },
        { saturation: -50 },
        { lightness: 40 },
        { gamma: 0.5 },
        { hue: "#3cd2a5" },
      ],
    },
  ],
};

const getMarkerSize = (mag, depth) => {
  // İki örneği baz alarak bir çarpan belirleyelim
  // Oranı belirlemek için
  const scalingFactor = 0.2; // Deneme çarpanı, uygun olanı belirlemek için ayarlayın
  const size = scalingFactor * (mag * depth);

  // Minimum ve maksimum boyutlar
  return Math.max(3, Math.min(size, 100)); // Boyutları 3 ile 15 arasında sınırlayın
};

const getMarkerStyle = (mag, depth) => {
  let color = "red";
  const size = getMarkerSize(mag, depth);

  if (depth >= 0 && depth <= 5) {
    color = "#FF0000"; // Kırmızı
  } else if (depth > 5 && depth <= 10) {
    color = "#FF6666"; // Açık kırmızı
  } else if (depth > 10 && depth <= 20) {
    color = "#FFA500"; // Turuncu
  } else if (depth > 20 && depth <= 40) {
    color = "#FFFF00"; // Sarı
  } else if (depth > 40 && depth <= 80) {
    color = "#008000"; // Yeşil
  } else if (depth > 80 && depth <= 150) {
    color = "#0000FF"; // Mavi
  }

  return {
    path: window.google.maps.SymbolPath.CIRCLE,
    fillColor: color,
    fillOpacity: 1,
    scale: size,
    strokeColor: color,
    strokeWeight: 1,
  };
};

export default function AfetYonetimi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  function handleBack() {
    if (context?.me?.role?.id === 4) {
      navigate("/hane-ve-birey-analizi");
    } else if (context?.me?.role?.id === 1) {
      navigate("/durum-analizi", {
        state: { menu: { id: 51 } },
      });
    }
  }

  useEffect(() => {
    context.setThemePanel(false);
    context.setAppHeaderNone(true);
    context.setAppScrollButton(false);
    context.setAppWeather(false);
    context.setAppSidebarNone(true);
    context.setAppFooterFull(true);
    context.setAppContentClass("login-page disaster-page");

    return function cleanUp() {
      context.setThemePanel(true);
      context.setAppHeaderNone(false);
      context.setAppScrollButton(true);
      context.setAppWeather(true);
      context.setAppFooterFull(false);
      context.setAppSidebarNone(false);
      context.setAppContentClass("");
    };
    // eslint-disable-next-line
  }, [context]);

  // Filter
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  const [allBuilding, setAllBuilding] = useState([]);
  const [buildingValue, setBuildingValue] = useState(null);
  const [buildingLoader, setBuildingLoader] = useState(true);

  const [allApartment, setAllApartment] = useState([]);
  const [apartmentValue, setApartmentValue] = useState(null);
  const [apartmentLoader, setApartmentLoader] = useState(true);

  // Filter Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);

    setBuildingLoader(false);

    setTimeout(() => {
      setStreetLoader(true);
      setBuildingLoader(true);
    }, 500);
  }

  // Filter Select
  function handleCity(e) {
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);
    fetchDistrict(e.value);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);

    resetNeighborhood();
    fetchNeighborhood(e.value && e.value !== "" ? e.value : null);

    resetStreet();
    fetchStreet(null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
    fetchBuilding(null);
    fetchApartment(null);
  }

  function handleStreet(e) {
    setStreetValue(e);

    fetchBuilding(e.value && e.value !== "" ? e.label : null);
    fetchApartment(null);
  }

  function handleBuilding(e) {
    setBuildingValue(e);

    fetchApartment(e.value && e.value !== "" ? e.label : null);
  }

  function handleApartment(e) {
    setApartmentValue(e);
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: "ILCE SECINIZ",
        });

        setAllDistrict(newData);
      }
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: "MAHALLE SECINIZ",
        });

        setAllNeighborhood(newData);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    if (value) {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "Bina Hane Sayısı",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            sql: {
              column: [{ key: "Adres Sokak", value: "select" }],
              condition: [
                {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Mahalle",
                  value: context.removeTurkishCharacters(value).toUpperCase(),
                  operator: "=",
                  type: "and",
                },
              ],
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          const data = response
            .map((item) => {
              return {
                value: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
                label: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          const newData = data.slice();
          newData.unshift({
            value: "",
            label: "SOKAK SECINIZ",
          });

          setAllStreet(newData);
        } else {
          setAllStreet([]);
        }
      } catch (error) {
        setAllStreet(null);
      }
    } else {
      setAllStreet([]);
    }
  }

  async function fetchBuilding(value) {
    setAllBuilding([]);
    setBuildingValue(null);
    setBuildingLoader(false);

    if (value) {
      setTimeout(async () => {
        setBuildingLoader(true);

        try {
          const response = await apiClient.customPost(
            `/query/preview`,
            {
              chart: 1,
              page_id: 23,
              title: "Bina Hane Sayısı",
              type: `bireysel-data-customer-${context?.me?.customer?.id}`,
              description: "",
              customer: null,
              status: true,
              order: 1,
              sql: {
                column: [{ key: "Bina NO", value: "select" }],
                condition: [
                  {
                    key: "Adres Il",
                    value: context
                      .removeTurkishCharacters(cityValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(districtValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighborhoodValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Adres Sokak",
                    value: context.removeTurkishCharacters(value).toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                ],
                group: [],
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.length !== 0) {
            const data = response
              .map((item) => {
                return {
                  value: context
                    .removeTurkishCharacters(item["bina no"])
                    .toUpperCase(),
                  label: context
                    .removeTurkishCharacters(item["bina no"])
                    .toUpperCase(),
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label));

            const newData = data.slice();
            newData.unshift({
              value: "",
              label: "BINA SECINIZ",
            });

            setAllBuilding(newData);
          } else {
            setAllBuilding([]);
          }
        } catch (error) {
          setAllBuilding(null);
        }
      }, 500);
    } else {
      setAllBuilding([]);
    }
  }

  async function fetchApartment(value) {
    setAllApartment([]);
    setApartmentValue(null);
    setApartmentLoader(false);

    setTimeout(async () => {
      setApartmentLoader(true);

      if (value) {
        try {
          const response = await apiClient.customPost(
            `/query/preview`,
            {
              chart: 1,
              page_id: 23,
              title: "Bina Hane Sayısı",
              type: `bireysel-data-customer-${context?.me?.customer?.id}`,
              description: "",
              customer: null,
              status: true,
              order: 1,
              sql: {
                column: [{ key: "Daire", value: "select" }],
                condition: [
                  {
                    key: "Adres Il",
                    value: context
                      .removeTurkishCharacters(cityValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(districtValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighborhoodValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Adres Sokak",
                    value: context
                      .removeTurkishCharacters(streetValue?.label)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                  {
                    key: "Bina NO",
                    value: context.removeTurkishCharacters(value).toUpperCase(),
                    operator: "=",
                    type: "and",
                  },
                ],
                group: [],
              },
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.length !== 0) {
            const data = response
              .map((item) => {
                return {
                  value: context
                    .removeTurkishCharacters(item["daire"])
                    .toUpperCase(),
                  label: context
                    .removeTurkishCharacters(item["daire"])
                    .toUpperCase(),
                };
              })
              .sort((a, b) => a.label.localeCompare(b.label));

            const newData = data.slice();
            newData.unshift({
              value: "",
              label: "DAİRE SECINIZ",
            });

            setAllApartment(newData);
          } else {
            setAllApartment([]);
          }
        } catch (error) {
          setAllApartment(null);
        }
      } else {
        setAllApartment([]);
      }
    }, 500);
  }

  // GET MAP
  const [showTable, setShowTable] = useState(false);

  const [earthquakeData, setEarthquakeData] = useState(null);
  const [selectedEarthquake, setSelectedEarthquake] = useState(null);
  const [filter, setFilter] = useState({
    mag_0_2_9: true,
    mag_3_3_9: true,
    mag_4_4_9: true,
    mag_5_5_9: true,
    mag_6_6_9: true,
    mag_7_7_9: true,
  });

  useEffect(() => {
    fetch("https://api.orhanaydogdu.com.tr/deprem/kandilli/live")
      .then((response) => response.json())
      .then((data) => {
        const filteredData = data.result.reduce((acc, current) => {
          const existing = acc.find((item) => item.title === current.title);
          if (!existing) {
            acc.push(current);
          } else {
            const existingDate = new Date(existing.date);
            const currentDate = new Date(current.date);
            if (currentDate > existingDate) {
              acc = acc.filter((item) => item.title !== current.title);
              acc.push(current);
            }
          }
          return acc;
        }, []);

        setEarthquakeData({ ...data, result: filteredData });
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const handleFilterChange = (e) => {
    setFilter({
      ...filter,
      [e.target.name]: e.target.checked,
    });
  };

  const filterEarthquakes = () => {
    if (!earthquakeData) return [];

    const firstEarthquake = earthquakeData.result[0];

    const filteredEarthquakes = earthquakeData.result
      .slice(1)
      .filter((earthquake) => {
        const mag = earthquake.mag;

        if (filter.mag_0_2_9 && mag >= 0 && mag <= 2.9) return true;
        if (filter.mag_3_3_9 && mag >= 3 && mag <= 3.9) return true;
        if (filter.mag_4_4_9 && mag >= 4 && mag <= 4.9) return true;
        if (filter.mag_5_5_9 && mag >= 5 && mag <= 5.9) return true;
        if (filter.mag_6_6_9 && mag >= 6 && mag <= 6.9) return true;
        if (filter.mag_7_7_9 && mag >= 7 && mag <= 7.9) return true;

        return false;
      });

    return [firstEarthquake, ...filteredEarthquakes];
  };

  // Tesisler Data
  const [tesislerSelectData, setTesislerSelectData] = useState([]);
  const [tesislerSelectValue, setTesislerSelectValue] = useState({
    value: null,
    label: "TÜMÜ",
  });

  const [tesislerData, setTesislerData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);

  const [tesisMarker, setTesisMarker] = useState(null);
  const [tesisMarkerSelected, setTesisMarkerSelected] = useState(null);
  const [allTesisMarkers, setAllTesisMarkers] = useState(null);

  const [filterCity, setFilterCity] = useState(null);
  const [filterDistrict, setFilterDistrict] = useState(null);
  const [filterNeighborhood, setFilterNeighborhood] = useState(null);

  async function handleLocationAllTesis() {
    setAllTesisMarkers(null);
    setTesisMarker(null);
    setTesisMarkerSelected(null);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `afet-yonetimi-saglil-tesisleri`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Il Adi",
            value: "select",
          },
          {
            key: "Ilce Adi",
            value: "select",
          },
          {
            key: "Mahalle Adi",
            value: "select",
          },
          {
            key: "Saglik Tesis Adi",
            value: "select",
          },
          {
            key: "Ana Kategori",
            value: "select",
          },
          {
            key: "Alt Kategori",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Latitude",
            value: "select",
          },
          {
            key: "Longitude",
            value: "select",
          },
        ],
        condition: [
          filterCity
            ? {
                key: "Il adı",
                value: context
                  .removeTurkishCharacters(filterCity.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filterDistrict
            ? {
                key: "IlCe Adı",
                value: context
                  .removeTurkishCharacters(filterDistrict.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filterNeighborhood
            ? {
                key: "Mahalle Adı",
                value: context
                  .removeTurkishCharacters(filterNeighborhood.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          tesislerSelectValue && tesislerSelectValue.value
            ? {
                key: "Alt Kategori",
                value: tesislerSelectValue.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setAllTesisMarkers(response);
    } catch (error) {
      setAllTesisMarkers([]);
      console.error(error);
    }
  }

  async function fetchTesislerSelect(city, district, neighborhood) {
    setTesislerSelectData([]);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `afet-yonetimi-saglil-tesisleri`,
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Alt Kategori",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Il adı",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "IlCe Adı",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          neighborhood
            ? {
                key: "Mahalle Adı",
                value: context
                  .removeTurkishCharacters(neighborhood.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setTesislerSelectData(
        response.length !== 0
          ? [
              {
                value: null,
                label: "TÜMÜ",
              },
              ...response.map((item) => {
                return {
                  value: item["alt kategori"],
                  label: item["alt kategori"],
                };
              }),
            ]
          : []
      );
    } catch (error) {
      setTesislerSelectData([]);
      console.error(error);
    }
  }

  async function fetchTesisler(
    page = 1,
    city = null,
    district = null,
    neighborhood = null,
    filter = null
  ) {
    setTesislerData(null);

    setTesisMarker(null);
    setTesisMarkerSelected(null);
    setAllTesisMarkers(null);

    const payload = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `afet-yonetimi-saglil-tesisleri`,
      description: "",
      customer: null,
      status: true,
      paginate: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Il Adi",
            value: "select",
          },
          {
            key: "Ilce Adi",
            value: "select",
          },
          {
            key: "Mahalle Adi",
            value: "select",
          },
          {
            key: "Saglik Tesis Adi",
            value: "select",
          },
          {
            key: "Ana Kategori",
            value: "select",
          },
          {
            key: "Alt Kategori",
            value: "select",
          },
          {
            key: "Adres",
            value: "select",
          },
          {
            key: "Latitude",
            value: "select",
          },
          {
            key: "Longitude",
            value: "select",
          },
        ],
        condition: [
          city
            ? {
                key: "Il Adi",
                value: context
                  .removeTurkishCharacters(city.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          district
            ? {
                key: "Ilce Adi",
                value: context
                  .removeTurkishCharacters(district.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          neighborhood
            ? {
                key: "Mahalle Adi",
                value: context
                  .removeTurkishCharacters(neighborhood.label)
                  .toUpperCase(),
                operator: "=",
                type: "and",
              }
            : null,
          filter && filter.value
            ? {
                key: "Alt Kategori",
                value: filter.value,
                operator: "=",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setTesislerData(response);
    } catch (error) {
      setTesislerData([]);
      console.error(error);
    }
  }

  async function handleData(city, district, neighborhood) {
    setFilterCity(city && city.value !== "" ? city : null);
    setFilterDistrict(district && district.value !== "" ? district : null);
    setFilterNeighborhood(
      neighborhood && neighborhood.value !== "" ? neighborhood : null
    );

    setCurrentPage(1);
    fetchTesislerSelect(null, null, null);
    setTesislerSelectValue({
      value: null,
      label: "TÜMÜ",
    });

    fetchTesisler(
      1,
      city && city.value !== "" ? city : null,
      district && district.value !== "" ? district : null,
      neighborhood && neighborhood.value !== "" ? neighborhood : null,
      null
    );
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchTesisler(
      page,
      filterCity,
      filterDistrict,
      filterNeighborhood,
      tesislerSelectValue
    );
  };

  const handleTesislerSelect = (e) => {
    setCurrentPage(1);

    setTesislerSelectValue(e);
    fetchTesisler(1, filterCity, filterDistrict, filterNeighborhood, e);
    fetchTesislerSelect(filterCity, filterDistrict, filterNeighborhood);
  };

  useEffect(() => {
    fetchTesisler();
    fetchTesislerSelect(null, null, null);
  }, []);

  // Demografik Analiz
  // const fetchAges = useCallback(async () => {
  //   try {
  //     const response = await apiClient.customPost(
  //       `/query/preview`,
  //       {
  //         chart: 1,
  //         page_id: 23,
  //         title: "-",
  //         type: `bireysel-data-customer-${context?.me?.customer?.id}`,
  //         description: "",
  //         customer: null,
  //         status: true,
  //         order: 1,
  //         sql: {
  //           column: [
  //             {
  //               key: "Dogum Tarihi",
  //               value: "toYear",
  //             },
  //             {
  //               key: "Dogum_Tarihi",
  //               value: "count",
  //             },
  //           ],
  //           condition: [],
  //           group: [],
  //         },
  //       },
  //       {
  //         headers: {
  //           Authorization: `Bearer ${localStorage.getItem("token")}`,
  //         },
  //       }
  //     );

  //     console.log(response);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, []);

  // useEffect(() => {
  //   fetchAges();
  // }, [fetchAges]);

  return (
    (context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4) && (
      <div className="disaster-container mb-5 mx-3 text-center">
        <div style={{ maxWidth: 1500 }} className="mx-auto">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <h3>Afet Yönetimi</h3>

            <button
              onClick={() => handleBack()}
              className="btn btn-danger mb-3"
            >
              Geri Dön
            </button>
          </div>

          <Card>
            <CardBody>
              <div className="row align-items-end text-start g-2">
                <div className="col">
                  <span className="d-block">İL</span>
                  {context.allCity ? (
                    <Select
                      options={context.allCity}
                      value={
                        cityValue
                          ? cityValue
                          : city &&
                            context.allCity.find(function (item) {
                              return item.value === city;
                            })
                      }
                      classNamePrefix="react-select"
                      placeholder="IL SECINIZ..."
                      noOptionsMessage={() => "Seçenek Yok"}
                      onChange={(e) => handleCity(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">İLÇE</span>
                  {districtLoader ? (
                    <Select
                      options={allDistrict}
                      value={districtValue}
                      classNamePrefix="react-select"
                      placeholder="ILCE SECINIZ..."
                      noOptionsMessage={() => "Seçenek Yok"}
                      onChange={(e) => handleDistrict(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">MAHALLE</span>
                  {neighborhoodLoader ? (
                    <Select
                      options={allNeighborhood}
                      value={neighborhoodValue ? neighborhoodValue : ""}
                      classNamePrefix="react-select"
                      placeholder="MAHALLE SECINIZ..."
                      noOptionsMessage={() => "Seçenek Yok"}
                      onChange={(e) => handleNeighborhood(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">SOKAK</span>
                  {streetLoader ? (
                    <Select
                      options={allStreet}
                      value={streetValue ? streetValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      placeholder="SOKAK SECINIZ..."
                      onChange={handleStreet}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">BİNA</span>
                  {buildingLoader ? (
                    <Select
                      options={allBuilding}
                      value={buildingValue ? buildingValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      placeholder="BINA SECINIZ..."
                      onChange={handleBuilding}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col">
                  <span className="d-block">DAİRE</span>
                  {apartmentLoader ? (
                    <Select
                      options={allApartment}
                      value={apartmentValue ? apartmentValue : ""}
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      placeholder="DAIRE SECINIZ..."
                      onChange={handleApartment}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
                <div className="col flex-none w-auto">
                  <button
                    type="button"
                    className="btn btn-outline-theme px-3 me-2"
                    onClick={() => handleData(null, null, null)}
                  >
                    Tümü
                  </button>
                  <button
                    type="button"
                    className="btn btn-outline-theme px-4"
                    onClick={() =>
                      handleData(cityValue, districtValue, neighborhoodValue)
                    }
                  >
                    Getir
                  </button>
                </div>
              </div>

              <hr />

              {/* <hr />

              <div className="mt-3 text-start">
                <div className="mb-3 row align-items-center justify-content-between">
                  <div className="col-6">
                    <h4>Demografik Analiz</h4>
                  </div>
                </div>

                <div className="dataTables_wrapper dt-bootstrap5">
                  <div className="dataTables_scroll">
                    <div
                      className="dataTables_scrollBody table-responsive"
                      style={{ height: 400 }}
                    >
                      <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                        <thead>
                          <tr>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              0-4
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              5-9
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              10-65 Kadın
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              10-65 Erkek
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              65+ Kadın
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              65+ Erkek
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              Hane Başına
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              Tür
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              İçerik
                            </th>
                            <th
                              className="px-2"
                              style={{ borderColor: "#ffffff50" }}
                            >
                              Birim
                            </th>
                          </tr>
                        </thead>

                        <tbody>
                          <tr>
                            <td>5</td>
                            <td>10</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>30</td>
                            <td>EKMEK</td>
                            <td>ADET</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div> */}

              {/* <DynamicCard
                events={false}
                data={[{ name: "asd", value: 1 }]}
                allEvents={false}
                item={{
                  title: "EKMEK",
                  options: {
                    options: {
                      series: [
                        {
                          data: [
                            { name: "0-4", value: 60 },
                            { name: "5-9", value: 5 },
                          ],
                          name: null,
                          type: "pie",
                          label: {
                            textStyle: {
                              fontSize: 15,
                            },
                          },
                          radius: "50%",
                          // itemStyle: {
                          //   color: "#3cd2a5",
                          // },
                        },
                      ],
                      tooltip: {
                        trigger: "item",
                      },
                      darkMode: true,
                      // visualMap: {
                      //   min:
                      //     hizmetData1 &&
                      //     Math.min(...hizmetData1.map((obj) => obj.value)),
                      //   max:
                      //     hizmetData1 &&
                      //     Math.max(...hizmetData1.map((obj) => obj.value)),
                      //   show: false,
                      //   inRange: {
                      //     colorLightness: [0.2, 1],
                      //   },
                      // },
                    },
                  },
                }}
              /> */}

              {earthquakeData ? (
                <>
                  <div className="mt-3 position-relative">
                    <div className="d-flex gap-2 justify-content-center mb-3">
                      {earthquakeData.metadata.date_starts && (
                        <span className="d-block">
                          Başlangıç Tarihi <br />
                          <strong>
                            {moment(
                              earthquakeData.metadata.date_starts,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD MMMM YYYY HH:mm:ss")}
                          </strong>
                        </span>
                      )}

                      {earthquakeData.metadata.date_ends && (
                        <span className="d-block border-start ps-3 ms-3">
                          Bitiş Tarihi <br />
                          <strong>
                            {moment(
                              earthquakeData.metadata.date_ends,
                              "YYYY-MM-DD HH:mm:ss"
                            ).format("DD MMMM YYYY HH:mm:ss")}
                          </strong>
                        </span>
                      )}

                      {earthquakeData.metadata.total && (
                        <span className="d-block border-start ps-3 ms-3">
                          Toplam <br />{" "}
                          <strong>{earthquakeData.metadata.total}</strong>
                        </span>
                      )}
                    </div>

                    <div
                      className="d-flex flex-column gap-1 position-absolute left-0 bottom-0 mx-3 my-2"
                      style={{ zIndex: 1 }}
                    >
                      <div className="d-flex flex-column gap-1 bg-white text-black rounded px-3 py-2 text-start">
                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_0_2_9"
                              id="mag_0_2_9"
                              checked={filter.mag_0_2_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_0_2_9"
                              className="form-check-label"
                            >
                              M≥0 M≤2.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FF0000" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d≥0 d≤5
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_3_3_9"
                              id="mag_3_3_9"
                              checked={filter.mag_3_3_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_3_3_9"
                              className="form-check-label"
                            >
                              M≥3 M≤3.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FF6666" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}5 d≤10{" "}
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_4_4_9"
                              id="mag_4_4_9"
                              checked={filter.mag_4_4_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_4_4_9"
                              className="form-check-label"
                            >
                              M≥4 M≤4.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FFA500" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}10 d≤20
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_5_5_9"
                              id="mag_5_5_9"
                              checked={filter.mag_5_5_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_5_5_9"
                              className="form-check-label"
                            >
                              M≥5 M≤5.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#FFFF00" }}
                            className="rounded ms-2 px-2 flex-1 text-center"
                          >
                            d{">"}20 d≤40
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_6_6_9"
                              id="mag_6_6_9"
                              checked={filter.mag_6_6_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_6_6_9"
                              className="form-check-label"
                            >
                              M≥6 M≤6.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#008000" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d{">"}40 d≤80
                          </div>
                        </div>

                        <div className="d-flex">
                          <div
                            className="form-check form-checkbox"
                            style={{ minWidth: 100 }}
                          >
                            <input
                              type="checkbox"
                              className="form-check-input border-black"
                              name="mag_7_7_9"
                              id="mag_7_7_9"
                              checked={filter.mag_7_7_9}
                              onChange={handleFilterChange}
                            />
                            <label
                              htmlFor="mag_7_7_9"
                              className="form-check-label"
                            >
                              M≥7 M≤7.9
                            </label>
                          </div>

                          <div
                            style={{ background: "#0000FF" }}
                            className="rounded ms-2 px-2 flex-1 text-center text-white"
                          >
                            d{">"}80 d≤150
                          </div>
                        </div>
                      </div>
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={() => setShowTable(!showTable)}
                      >
                        Tablo {showTable ? "Gizle" : "Göster"}
                      </button>
                    </div>

                    <GoogleMap
                      mapContainerStyle={{
                        height: 500,
                        width: "100%",
                      }}
                      options={options}
                    >
                      {allTesisMarkers && allTesisMarkers.length > 0 && (
                        <MarkerClusterer
                          options={{
                            maxZoom: 20, // Set this to control when clusters should stop grouping markers
                            styles: [
                              {
                                textColor: "white", // Set the cluster text color to white
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m1.png",
                                height: 50,
                                width: 50,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m2.png",
                                height: 56,
                                width: 56,
                              },
                              {
                                textColor: "white",
                                url: "https://developers.google.com/maps/documentation/javascript/examples/markerclusterer/m3.png",
                                height: 66,
                                width: 66,
                              },
                            ],
                          }}
                        >
                          {(clusterer) =>
                            allTesisMarkers.map((item, index) => (
                              <Marker
                                key={index}
                                clusterer={clusterer}
                                position={{
                                  lat: parseFloat(
                                    item.latitude.replace(",", ".")
                                  ),
                                  lng: parseFloat(
                                    item.longitude.replace(",", ".")
                                  ),
                                }}
                                onClick={() => setTesisMarkerSelected(item)}
                              />
                            ))
                          }
                        </MarkerClusterer>
                      )}

                      {tesisMarker && (
                        <Marker
                          position={{
                            lat: parseFloat(
                              tesisMarker.latitude.replace(",", ".")
                            ),
                            lng: parseFloat(
                              tesisMarker.longitude.replace(",", ".")
                            ),
                          }}
                          onClick={() => setTesisMarkerSelected(tesisMarker)}
                        />
                      )}
                      {tesisMarkerSelected && (
                        <InfoWindow
                          position={{
                            lat: parseFloat(
                              tesisMarkerSelected.latitude.replace(",", ".")
                            ),
                            lng: parseFloat(
                              tesisMarkerSelected.longitude.replace(",", ".")
                            ),
                          }}
                          onCloseClick={() => setTesisMarkerSelected(null)}
                        >
                          <div className="text-start">
                            <strong>İl:</strong> {tesisMarkerSelected["il adı"]}
                            <br />
                            <strong>İlçe:</strong>{" "}
                            {tesisMarkerSelected["ilce adı"]}
                            <br />
                            <strong>Mahalle:</strong>{" "}
                            {tesisMarkerSelected["mahalle adı"]}
                            <br />
                            <strong>Ana Kategori:</strong>{" "}
                            {tesisMarkerSelected["ana kategori"]}
                            <br />
                            <strong>Alt Kategori:</strong>{" "}
                            {tesisMarkerSelected["alt kategori"]}
                            <br />
                            <strong>Tesis Adı:</strong>{" "}
                            {tesisMarkerSelected["sağlık tesisi adı"]}
                            <br />
                            <strong>Adres:</strong>{" "}
                            {tesisMarkerSelected["adres"]}
                          </div>
                        </InfoWindow>
                      )}

                      {earthquakeData.result.map(
                        (earthquake, index) =>
                          index === 0 && (
                            <Marker
                              key={earthquake._id + "_live"}
                              position={{
                                lat: earthquake.geojson.coordinates[1],
                                lng: earthquake.geojson.coordinates[0],
                              }}
                              icon={{
                                url: "http://www.koeri.boun.edu.tr/sismo/zeqmap/images/neweq0.gif",
                                scaledSize: new window.google.maps.Size(50, 50),
                                anchor: new window.google.maps.Point(25, 25),
                              }}
                            />
                          )
                      )}

                      {filterEarthquakes().map((earthquake, index) => (
                        <Marker
                          key={earthquake._id}
                          position={{
                            lat: earthquake.geojson.coordinates[1],
                            lng: earthquake.geojson.coordinates[0],
                          }}
                          icon={getMarkerStyle(
                            earthquake.mag,
                            earthquake.depth
                          )}
                          onClick={() => setSelectedEarthquake(earthquake)}
                        />
                      ))}

                      {selectedEarthquake && (
                        <InfoWindow
                          position={{
                            lat: selectedEarthquake.geojson.coordinates[1],
                            lng: selectedEarthquake.geojson.coordinates[0],
                          }}
                          onCloseClick={() => setSelectedEarthquake(null)}
                        >
                          <div
                            className="text-start overflow-auto"
                            style={{ height: 300 }}
                          >
                            <strong>Başlık:</strong> {selectedEarthquake.title}{" "}
                            <br />
                            <strong>Tarih:</strong> {selectedEarthquake.date}{" "}
                            <br />
                            <strong>Büyüklük:</strong> {selectedEarthquake.mag}{" "}
                            <br />
                            <strong>Derinlik:</strong>{" "}
                            {selectedEarthquake.depth} km <br />
                            <strong>En Yakın Şehirler:</strong>
                            {selectedEarthquake.location_properties
                              .closestCities.length !== 0 &&
                              selectedEarthquake.location_properties.closestCities.map(
                                (item, index) => (
                                  <div
                                    key={index}
                                    className="border-top border-black mt-1 pt-1"
                                  >
                                    <strong>Şehir:</strong> {item.name}
                                    <br />
                                    <strong>Nüfus:</strong> {item.population}
                                    <br />
                                    <strong>Mesafe:</strong>{" "}
                                    {item.distance.toFixed(2)} m
                                  </div>
                                )
                              )}
                          </div>
                        </InfoWindow>
                      )}
                    </GoogleMap>
                  </div>

                  {showTable && (
                    <div className="dataTables_wrapper dt-bootstrap5">
                      <div className="dataTables_scroll">
                        <div
                          className="dataTables_scrollBody table-responsive"
                          style={{ height: 300 }}
                        >
                          <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                            <thead>
                              <tr>
                                <th className="px-2">Oluş Zamanı</th>
                                <th className="px-2">Enlem</th>
                                <th className="px-2">Boylam</th>
                                <th className="px-2">Der(km)</th>
                                <th className="px-2">Büyüklük</th>
                                <th className="px-2">Yer</th>
                              </tr>
                            </thead>

                            <tbody>
                              {filterEarthquakes().map((earthquake, index) => (
                                <tr>
                                  <td>{earthquake.date_time}</td>
                                  <td>{earthquake.geojson.coordinates[0]}</td>
                                  <td>{earthquake.geojson.coordinates[1]}</td>
                                  <td>{earthquake.depth}</td>
                                  <td>{earthquake.mag}</td>
                                  <td>{earthquake.title}</td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <div className="spinner-border text-theme"></div>
              )}

              <hr />

              <div className="mt-3 text-start">
                <div className="mb-3 row align-items-center justify-content-between">
                  <div className="col-6">
                    <h4>Sağlık Tesisleri</h4>
                  </div>

                  <div className="col w-auto flex-none">
                    <div className="d-flex gap-2">
                      <div
                        className="w-100"
                        style={{ minWidth: 250, maxWidth: 250 }}
                      >
                        <span className="d-block">Alt Kategori</span>
                        <Select
                          options={tesislerSelectData}
                          value={tesislerSelectValue}
                          classNamePrefix="react-select"
                          noOptionsMessage={() => "Seçenek Yok"}
                          placeholder="ALT KATEGORİ SECINIZ..."
                          onChange={(e) => handleTesislerSelect(e)}
                        />
                      </div>
                      <div>
                        <span className="d-block">Konumlar</span>
                        <button
                          type="button"
                          className="btn btn-outline-theme"
                          onClick={() =>
                            allTesisMarkers
                              ? setAllTesisMarkers(null)
                              : handleLocationAllTesis()
                          }
                        >
                          {allTesisMarkers ? "Gizle" : "Göster"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="dataTables_wrapper dt-bootstrap5">
                  {tesislerData ? (
                    tesislerData.data.length !== 0 ? (
                      <>
                        <div className="dataTables_scroll">
                          <div
                            className="dataTables_scrollBody table-responsive"
                            style={{ height: 400 }}
                          >
                            <table className="table text-nowrap w-100 dataTable mb-0 text-start">
                              <thead>
                                <tr>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    İl
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    İlçe
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    Mahalle
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    Ana Kategori
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    Alt Kategori Adı
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    Tesis Adı
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    Adres
                                  </th>
                                  <th
                                    className="px-2"
                                    style={{ borderColor: "#ffffff50" }}
                                  >
                                    Konum
                                  </th>
                                </tr>
                              </thead>

                              <tbody>
                                {tesislerData.data.map((item, index) => (
                                  <tr key={index}>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["il adi"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["ilce adi"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["mahalle adi"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["ana kategori"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["alt kategori"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["saglik tesis adi"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      {item["adres"]}
                                    </td>
                                    <td style={{ borderColor: "#ffffff50" }}>
                                      <span
                                        className="pointer text-theme text-underline"
                                        onClick={() => {
                                          setAllTesisMarkers(null);
                                          setTesisMarkerSelected(null);
                                          setTesisMarker(
                                            tesisMarker
                                              ? tesisMarker.latitude ===
                                                  item.latitude &&
                                                tesisMarker.longitude ===
                                                  item.longitude
                                                ? null
                                                : item
                                              : item
                                          );
                                        }}
                                      >
                                        {tesisMarker
                                          ? tesisMarker.latitude ===
                                              item.latitude &&
                                            tesisMarker.longitude ===
                                              item.longitude
                                            ? "GİZLE"
                                            : "GÖSTER"
                                          : "GÖSTER"}
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center mt-3">
                          <ul className="pagination">
                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage - 1)
                                }
                                className={`page-link pointer ${
                                  !tesislerData.prev_page_url ? "disabled" : ""
                                }`}
                              >
                                Önceki
                              </button>
                            </li>

                            {tesislerData.links.map(
                              (number, index) =>
                                index > 0 &&
                                index < tesislerData.links.length - 1 && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      currentPage === parseInt(number.label)
                                        ? "active"
                                        : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePageChange(parseInt(number.label))
                                      }
                                      className="page-link pointer"
                                    >
                                      {number.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li className={`paginate_button page-item`}>
                              <button
                                type="button"
                                onClick={() =>
                                  handlePageChange(currentPage + 1)
                                }
                                className={`page-link pointer ${
                                  !tesislerData.next_page_url ? "disabled" : ""
                                }`}
                              >
                                Sonraki
                              </button>
                            </li>
                          </ul>
                        </div>
                      </>
                    ) : (
                      <div className="text-center">
                        Analiz Verisi Eklenmemiştir
                      </div>
                    )
                  ) : (
                    <div className="spinner-border text-theme"></div>
                  )}
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  );
}

import React, { useContext, useEffect, useRef, useState } from "react";

import ReactECharts from "echarts-for-react";

import { AppSettings } from "../config/app-settings";
import { Card, CardBody, CardExpandToggler } from "./card/card";
import WordCloud from "./WordCloud";
import apiClient from "../ApiClient";
import DynamicTable from "./DynamicTable";

export default function DynamicCard({
  item,
  result = [1],
  chart = false,
  cityFilter,
  data = [1],
  fullScreen = false,
  menu,
  isActive,
  setActiveIndex,
  readText,
  setCardNote,
  setCardName,
  animate = true,
  events = true,
  width = null,
  height = 600,
  buttons = false,
  getChartImages = false,
  allEvents = true,
}) {
  const chartRef = useRef(null);
  const chartRefItem = useRef(null);
  const context = useContext(AppSettings);

  const [activeCard, setActiveCard] = useState(false);

  const toggleActiveCard = () => {
    setActiveCard(!activeCard);
    readText(item.id);
  };

  useEffect(() => {
    if (isActive) {
      window.speechSynthesis.cancel();

      const msg = new SpeechSynthesisUtterance();
      msg.text = item.description;
      msg.lang = "tr-TR";
      msg.volume = 1;
      msg.rate = 0.8;
      msg.pitch = 0.8;

      msg.onend = () => {
        setActiveCard(false);
        setActiveIndex(null);
      };

      window.speechSynthesis.speak(msg);
    } else {
      window.speechSynthesis.cancel();
    }
  }, [isActive, item.description]);

  // Create Image
  const createImage = () => {
    if (getChartImages && chartRefItem && chartRefItem.current) {
      setTimeout(() => {
        const echartElement =
          chartRefItem.current.querySelector(".echarts-for-react");

        let base64Image = "";

        if (echartElement) {
          const chart = chartRef.current.getEchartsInstance();
          base64Image = chart.getDataURL({
            pixelRatio: 2,
            backgroundColor: "#212d38",
          });
        }

        getChartImages((oldArray) => [...oldArray, base64Image]);
      }, 500);
    }
  };

  useEffect(() => {
    createImage();
  }, [chartRef, getChartImages]);

  // Tablo Preview
  const [tableData, setTableData] = useState(null);
  const [tablePage, setTablePage] = useState(1);
  const [tableCondition, setTableCondition] = useState({
    column: null,
    value: "",
  });

  async function tablePreview() {
    setTableData(null);

    const data = {
      chart: item.chart,
      page_id: item.page_id,
      title: item.title,
      type: item.type,
      description: item.description,
      status: item.status,
      customer: item.customer_id,
      order: item.order,
      paginate: true,
      sql: {
        column: item.payload.column,
        condition:
          item.payload.condition.length !== 0
            ? [
                tableCondition.column
                  ? {
                      key: tableCondition.column,
                      value: tableCondition.value,
                      operator: "like",
                      type: "and",
                    }
                  : null,
                ...item.payload.map((item) => {
                  return {
                    key: item.selectedColumn.value,
                    value: item.inputValue,
                    operator: item.selectedCondition.value,
                    type: item.selectedOperator.value,
                  };
                }),
              ].filter((item) => item !== null)
            : [
                tableCondition.column
                  ? {
                      key: tableCondition.column,
                      value: tableCondition.value,
                      operator: "like",
                      type: "and",
                    }
                  : null,
              ].filter((item) => item !== null),
        group: item.payload.group,
      },
    };

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${tablePage}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setTableData(response);
      }
    } catch (error) {
      setTableData([]);
      console.error(error);
    }
  }

  useEffect(() => {
    if (item.chart === 4) {
      tablePreview();
    }
  }, [item, tablePage, tableCondition]);

  return (
    <div
      className={`graphic-item position-relative ${
        item?.options?.width?.value.split("col-")[1]
          ? "col-12 col-xl-" + item?.options?.width?.value.split("col-")[1]
          : item?.options?.width?.value
      }`}
      style={{
        height: height === "h-full" ? "100%" : "auto",
      }}
    >
      {events &&
        ((context?.me?.role?.id !== 1 &&
          menu?.page_type !== "default" &&
          item?.customer_id) ||
          context?.me?.role?.id === 1) && (
          <div
            className="graphic-events d-flex justify-content-end position-absolute"
            style={{ top: -20, right: 10 }}
          >
            <div className="me-2">{item?.type}</div>

            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#createGraphicModal"
              className="btn btn-sm p-0 btn-success d-flex align-items-center justify-content-center"
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => {
                context.setCreateGraphicOpen(true);
                context.setGraphicUpdate(item);
              }}
            >
              <i className="fas fa-edit" style={{ fontSize: 14 }}></i>
            </button>
            <button
              type="button"
              data-bs-toggle="modal"
              data-bs-target="#graphicDeleteModal"
              className="btn btn-sm p-0 btn-danger d-flex align-items-center justify-content-center"
              style={{
                width: 20,
                height: 20,
              }}
              onClick={() => context.setGraphicDelete(item)}
            >
              <i className="fas fa-trash-alt" style={{ fontSize: 14 }}></i>
            </button>
          </div>
        )}

      <Card
        style={{
          width: width || "auto",
          height: height === "h-full" ? "100%" : height,
        }}
      >
        <CardBody className="d-flex flex-column h-100">
          {item.title && (
            <div
              className={`d-flex align-items-center justify-content-${
                allEvents ? "between" : "center"
              } border-bottom pb-2 mb-3`}
            >
              <span
                className={`fw-bold h6 mb-0 flex-1 ${
                  !fullScreen ? "text-center" : ""
                }`}
                style={{
                  maxWidth: 500,
                }}
              >
                {item?.title?.toUpperCase()}
              </span>

              {allEvents && (
                <div className="d-flex align-items-center ms-2">
                  {setCardNote &&
                    item.description &&
                    item.description !== "" && (
                      <>
                        <div className="me-2 pe-2 border-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme btn-sm"
                            data-bs-toggle="modal"
                            data-bs-target={`#pageCardNoteModal_${menu.id}`}
                            onClick={() => {
                              setCardName(item.title);
                              setCardNote(item.description);
                            }}
                          >
                            Not Oku
                          </button>
                        </div>

                        <div className="me-2 pe-2 border-end">
                          <button
                            type="button"
                            className="btn btn-outline-theme btn-sm"
                            onClick={toggleActiveCard}
                          >
                            {isActive ? "Durdur" : "Not Dinle"}
                          </button>
                        </div>
                      </>
                    )}

                  {fullScreen && <CardExpandToggler />}
                </div>
              )}
            </div>
          )}

          <div
            className="position-relative overflow-hidden flex-1 d-flex flex-column"
            ref={chartRefItem}
          >
            {!chart ? (
              data ? (
                data.length !== 0 && result.length !== 0 ? (
                  item?.options &&
                  item?.options?.options &&
                  item?.options?.options.length !== 0 ? (
                    item?.chart === 4 ? (
                      <DynamicTable
                        data={tableData}
                        tablePage={tablePage}
                        setTablePage={setTablePage}
                        setTableCondition={setTableCondition}
                        tableColumn={item.payload.column}
                      />
                    ) : item?.chart === 3 ? (
                      <WordCloud
                        height={height}
                        animate={animate}
                        data={item?.options?.options}
                      />
                    ) : (
                      <>
                        <ReactECharts
                          ref={chartRef}
                          option={{
                            ...item?.options?.options,
                            series: [
                              {
                                ...item?.options?.options.series[0],
                                animation: getChartImages ? false : true,
                              },
                            ],
                          }}
                          className="flex-1"
                        />

                        {buttons}
                      </>
                    )
                  ) : (
                    <div className="h-100 d-flex align-items-center justify-content-center">
                      <small className="mb-2 d-block">Grafik Eklenmedi</small>
                    </div>
                  )
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <span className="d-block text-center">
                      Analiz Verisi Eklenmemiştir
                    </span>
                  </div>
                )
              ) : (
                <div className="h-100 d-flex align-items-center justify-content-center">
                  <div className="spinner-border text-theme"></div>
                </div>
              )
            ) : (
              chart
            )}
          </div>

          {item.order && <div className="text-end">#{item.order}</div>}
        </CardBody>
      </Card>
    </div>
  );
}

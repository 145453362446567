import React, { useEffect, useRef, useState, useContext } from "react";

import Select from "react-select";

import apiClient from "../../ApiClient.js";
import { AppSettings } from "../../config/app-settings.js";
import AddReport from "../strateji-haritasi/addReport.jsx";
import toastMessage from "../../components/ToastMessage.js";

export default function ArastirmaRaporu({ show = true, card = false }) {
  const context = useContext(AppSettings);

  const [scriptLoaded, setScriptLoaded] = useState(false);
  const [currentLocation, setCurrentLocation] = useState(null);

  // Location Permession
  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        setCurrentLocation({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          zoom: 14,
        });
      },
      (error) => {
        setCurrentLocation(null);
        // setCurrentLocation({
        //   lat: 39.1667,
        //   lng: 35.6667,
        //   zoom: 5.8,
        // });
      },
      { enableHighAccuracy: true }
    );
  }, []);

  // Map
  const mapRef = useRef(null);
  const markerRefReport = useRef(null);
  const [mapModalDataReport, setMapModalDataReport] = useState(null);

  const [removeMarkerReport, setRemoveMarkerReport] = useState(false);
  const [reportModalMapCity, setReportModalMapCity] = useState(false);

  // useEffect(() => {
  //   if (scriptLoaded) {
  //     let map_modal_report;

  //     async function initMap() {
  //       if (document.getElementById("map_report_modal")) {
  //         map_modal_report = new window.google.maps.Map(
  //           document.getElementById("map_report_modal"),
  //           {
  //             center: { lat: 39.1667, lng: 35.6667 },
  //             zoom: 5.8,
  //             styles: [
  //               {
  //                 featureType: "all",
  //                 elementType: "all",
  //                 stylers: [
  //                   {
  //                     invert_lightness: true,
  //                   },
  //                   {
  //                     saturation: -50,
  //                   },
  //                   {
  //                     lightness: 40,
  //                   },
  //                   {
  //                     gamma: 0.5,
  //                   },
  //                   {
  //                     hue: "#3cd2a5",
  //                   },
  //                 ],
  //               },
  //             ],
  //           }
  //         );

  //         if (
  //           reportModalMapCity &&
  //           reportModalMapCity?.value &&
  //           reportModalMapCity?.value !== ""
  //         ) {
  //           let itemGEOReport;

  //           if (reportModalMapCity.value === 34) {
  //             itemGEOReport = JSON.parse(reportModalMapCity.geo);
  //           } else {
  //             itemGEOReport = JSON.parse(reportModalMapCity.geo)[0][0];
  //           }

  //           const polygonCoords = itemGEOReport
  //             .map((coordGroup, coordGroupIndex) => {
  //               if (
  //                 reportModalMapCity.value === 34 &&
  //                 (coordGroupIndex === 0 || coordGroupIndex === 1)
  //               ) {
  //                 return coordGroup[0].map((coord) => ({
  //                   lat: coord[1],
  //                   lng: coord[0],
  //                 }));
  //               } else if (reportModalMapCity.value !== 34) {
  //                 return { lat: coordGroup[1], lng: coordGroup[0] };
  //               }
  //             })
  //             .flat()
  //             .filter(Boolean);

  //           const modalMapCityPolygon = new window.google.maps.Polygon({
  //             paths: polygonCoords,
  //             map: map_modal_report,
  //             fillColor: "#15202bb5",
  //             strokeColor: "#fff",
  //             strokeWeight: 1,
  //             clickable: false,
  //           });

  //           const bounds = new window.google.maps.LatLngBounds();
  //           modalMapCityPolygon
  //             .getPath()
  //             .getArray()
  //             .forEach((point) => {
  //               bounds.extend(point);
  //             });

  //           map_modal_report.fitBounds(bounds);
  //         }

  //         window.google.maps.event.addListener(
  //           map_modal_report,
  //           "click",
  //           (event) => {
  //             const lat = event.latLng.lat();
  //             const lng = event.latLng.lng();
  //             const zoom = map_modal_report.getZoom();

  //             if (markerRefReport.current) {
  //               markerRefReport.current.setMap(null);
  //             }

  //             const marker = new window.google.maps.Marker({
  //               position: { lat: lat, lng: lng },
  //               map: map_modal_report,
  //               animation: window.google.maps.Animation.DROP,
  //               icon: {
  //                 path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
  //                 fillColor: "#de8e00",
  //                 fillOpacity: 1,
  //                 strokeWeight: 0,
  //                 strokeColor: "#fff",
  //                 scale: 0.04,
  //                 size: new window.google.maps.Size(800, 800),
  //                 anchor: new window.google.maps.Point(200, 400),
  //               },
  //               title: "Marker Title",
  //             });

  //             markerRefReport.current = marker;
  //             mapRef.current = map_modal_report;

  //             setRemoveMarkerReport(false);
  //             setMapModalDataReport({ lat, lng, zoom });
  //           }
  //         );

  //         if (removeMarkerReport) {
  //           if (markerRefReport.current) {
  //             markerRefReport.current.setMap(null);
  //           }
  //         }
  //       }
  //     }

  //     initMap();
  //   }
  // }, [
  //   context,
  //   scriptLoaded,
  //   currentLocation,
  //   removeMarkerReport,
  //   reportModalMapCity,
  // ]);

  function handleRemoveMarkerReport() {
    setRemoveMarkerReport(true);
    setMapModalDataReport(null);
  }

  // Atanmış Anketleri Getir
  const [assigmentSelect, setAssigmentSelect] = useState(null);

  const [assigmentData, setAssigmentData] = useState([]);

  async function handleGetReports() {
    setAssigmentData(null);

    try {
      const response = await apiClient.get(
        `/street-report-assigment/${context?.me?.id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        const formattedData = response.data.map((item) => ({
          value: item.report.id,
          label: item.report.name,
          ...item,
        }));

        setAssigmentData(formattedData);
      } else {
        setAssigmentData([]);
      }
    } catch (error) {
      setAssigmentData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (currentLocation && context?.me) {
      handleGetReports();
    }
  }, [currentLocation, context]);

  // Anket Show
  const [surveyData, setSurveyData] = useState(null);

  async function handleGetShowReport(id) {
    try {
      const response = await apiClient.get(`/street-report/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setSurveyData(response.data);
    } catch (error) {
      setSurveyData(null);
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (assigmentSelect) {
      handleGetShowReport(assigmentSelect.value);
    }
  }, [assigmentSelect]);

  //--

  const [location, setLocation] = useState(null);
  const [error, setError] = useState(null);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          console.log({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            zoom: 1,
          });

          setLocation({
            lat: position.coords.latitude,
            lng: position.coords.longitude,
            zoom: 1,
          });
          setError(null);
        },
        (err) => {
          setError(err.message);
        }
      );
    } else {
      setError("Geolocation is not supported by this browser.");
    }
  };

  return (
    show && (
      <div className={`position-relative ${card ? "h-100" : "mb-4"}`}>
        {currentLocation ? (
          <>
            <div className="d-flex align-items-end gap-2 mb-4">
              <div style={{ width: 300 }}>
                <span className="d-block mb-2">Atanmış Anketler</span>
                {assigmentData ? (
                  <Select
                    options={assigmentData}
                    placeholder="Anket Seç"
                    classNamePrefix="react-select"
                    noOptionsMessage={() => "Seçenek Yok"}
                    value={assigmentSelect}
                    onChange={(e) => setAssigmentSelect(e)}
                  />
                ) : (
                  <div className="spinner-border spinner-border-sm text-theme"></div>
                )}
              </div>

              {assigmentSelect && (
                <button
                  type="button"
                  className="btn btn-outline-theme btn-lg"
                  data-bs-toggle="modal"
                  data-bs-target="#addReportModal"
                >
                  <i className="fas fa-lg me-2 fa-plus-circle"></i>
                  Gözlem Oluştur
                </button>
              )}
            </div>

            <AddReport
              getLocation={getLocation}
              currentLocation={currentLocation}
              imageLocation={location}
              scriptLoaded={scriptLoaded}
              assigmentSelect={assigmentSelect}
              surveyData={surveyData}
              mapModalData={mapModalDataReport}
              handleRemoveMarker={handleRemoveMarkerReport}
              setModalMapCity={setReportModalMapCity}
            />

            <button
              type="button"
              className="d-none"
              id="report-detail-modal-button"
              data-bs-toggle="modal"
              data-bs-target="#reportDetailModal"
            >
              Report Detail
            </button>
          </>
        ) : (
          <p>Konuma İzin Vermelisiniz</p>
        )}
      </div>
    )
  );
}

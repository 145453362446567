import moment from "moment";
import React, { useState } from "react";
import Fancybox from "./Fancybox";

export default function TaskListItem({
  handleTodoEdit,
  handleTodoDelete,
  item,
  todo,
  todoDataLength,
  todoIndex,
  sortList,
  handleSpeak,
  removeHTMLTags,
}) {
  const [listShow, setListShow] = useState(false);

  return (
    <div
      className={`list-group list-group-flush ${todoIndex > 0 && "border-top"}`}
      style={{ background: (todoIndex + 1) % 2 === 0 && "#00000030" }}
    >
      <div className="list-group-item d-flex px-3 py-3">
        <div className="flex-fill">
          <div className="d-flex align-items-center justify-content-between gap-1">
            {todoDataLength >= 2 && (
              <span className="d-flex flex-column me-2 mt-1">
                {todoIndex > 0 && (
                  <span
                    className="todo-sort-arrow pe-1 pointer"
                    onClick={() => sortList(todo?.id, todo?.sort, "up")}
                  >
                    <i className="fas fa-angle-up"></i>
                  </span>
                )}
                {todoIndex !== item?.list.length - 1 && (
                  <span
                    className="todo-sort-arrow pe-1 pointer"
                    onClick={() => sortList(todo?.id, todo?.sort, "down")}
                  >
                    <i className="fas fa-angle-down"></i>
                  </span>
                )}
              </span>
            )}
            <div className="flex-1 d-flex align-items-center justify-content-between gap-1">
              {todo.completed && (
                <button className="btn border-0 btn-sm mt-1 py-0 px-1 btn-outline-theme border-none pe-none">
                  <i className="fas fa-lg fa-check-circle"></i>
                </button>
              )}
              <div>
                {todo.title && (
                  <div
                    className="fw-400 lh-20 mt-1 pointer"
                    onClick={() => setListShow(!listShow)}
                  >
                    <strong>{todoIndex + 1}.</strong>{" "}
                    {todo.title.includes("#")
                      ? todo.title.split("#")[1]
                      : todo.title}
                  </div>
                )}
                {todo.summary && (
                  <span
                    className="mb-2 d-inline-block mt-2 text-theme pointer listen-note"
                    onClick={() =>
                      handleSpeak(
                        removeHTMLTags(todo.summary),
                        todo.title.includes("#")
                          ? todo.title.split("#")[1]
                          : todo.title
                      )
                    }
                  >
                    Yönetici Özetini Oku
                  </span>
                )}
              </div>
              {!listShow && (
                <a
                  href="#/"
                  className="text-inverse text-opacity-100 text-decoration-none"
                  onClick={() => setListShow(!listShow)}
                >
                  <i className="fas fa-lg fa-fw fa-plus"></i>
                </a>
              )}
            </div>

            {listShow && (
              <div className="d-flex align-items-center justify-content-end">
                <div className="dropdown">
                  <button
                    className="btn border-0 btn-sm btn-outline-blue"
                    type="button"
                    id={"dropdownMenuButton_" + todo.id}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i className="fas fa-info"></i>
                  </button>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby={"dropdownMenuButton_" + todo.id}
                    style={{
                      padding: "10px 15px",
                    }}
                  >
                    {todo.user && (
                      <span style={{ fontSize: 12 }} className="d-block">
                        Oluşturan Kişi: <br />
                        <strong className="d-block">{todo?.user?.name}</strong>
                      </span>
                    )}
                    {todo.created_at && (
                      <span className="mt-1 d-block" style={{ fontSize: 12 }}>
                        <span className="d-block">Oluşturulma Tarihi:</span>
                        <strong className="d-block">
                          {moment(
                            todo.created_at,
                            "YYYY-MM-DD HH:mm:ss"
                          ).format("DD MMMM YYYY")}
                        </strong>
                      </span>
                    )}

                    {todo.updated_user && (
                      <>
                        <span
                          className="border-top pt-2 mt-2 d-block"
                          style={{ fontSize: 12 }}
                        >
                          Güncelleyen Kişi: <br />
                          <strong className="d-block">
                            {" "}
                            {todo?.updated_user?.name}
                          </strong>
                        </span>

                        {todo.updated_at && (
                          <span
                            className="mt-1 d-block"
                            style={{ fontSize: 12 }}
                          >
                            Güncelleme Tarihi: <br />
                            <strong className="d-block">
                              {" "}
                              {moment(
                                todo.updated_at,
                                "YYYY-MM-DD HH:mm:ss"
                              ).format("DD MMMM YYYY")}
                            </strong>
                          </span>
                        )}
                      </>
                    )}
                  </ul>
                </div>

                <button
                  className="btn border-0 btn-sm btn-outline-theme"
                  data-bs-toggle="modal"
                  data-bs-target="#addMarkerModal"
                  onClick={() =>
                    handleTodoEdit({ category: item.category, todo })
                  }
                >
                  <i className="fas fa-edit"></i>
                </button>

                <button
                  className="btn border-0 btn-sm btn-outline-danger"
                  onClick={() => handleTodoDelete(todo.id)}
                >
                  <i className="fas fa-trash-alt"></i>
                </button>

                {listShow && (
                  <a
                    href="#/"
                    className="text-inverse text-opacity-100 text-decoration-none"
                    onClick={() => setListShow(!listShow)}
                  >
                    <i className="fas fa-lg fa-fw fa-minus"></i>
                  </a>
                )}
              </div>
            )}
          </div>

          {listShow && (
            <>
              {todo.image && (
                <>
                  <hr className="mt-15px mb-15px" />

                  <Fancybox
                    options={{
                      Carousel: {
                        infinite: false,
                      },
                    }}
                  >
                    <div
                      id={"todo_carousel_" + todo.id}
                      className="carousel slide"
                      data-bs-ride="carousel"
                    >
                      <div className="carousel-inner">
                        {todo.image.map((todoImage, todoImageIndex) => (
                          <div
                            key={todoImageIndex + "_carousel"}
                            className={`carousel-item${
                              todoImageIndex === 0 ? " active" : ""
                            }`}
                          >
                            <a
                              href={todoImage}
                              data-fancybox={"todo-" + todo.id + "-gallery"}
                            >
                              <img
                                src={todoImage}
                                className="d-block w-100 img-cover"
                                alt={
                                  todo.title.includes("#")
                                    ? todo.title.split("#")[1]
                                    : todo.title
                                }
                                height={200}
                              />
                            </a>
                          </div>
                        ))}
                      </div>
                      <button
                        className="carousel-control-prev"
                        type="button"
                        data-bs-target={"#todo_carousel_" + todo.id}
                        data-bs-slide="prev"
                      >
                        <span
                          className="carousel-control-prev-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Previous</span>
                      </button>
                      <button
                        className="carousel-control-next"
                        type="button"
                        data-bs-target={"#todo_carousel_" + todo.id}
                        data-bs-slide="next"
                      >
                        <span
                          className="carousel-control-next-icon"
                          aria-hidden="true"
                        ></span>
                        <span className="visually-hidden">Next</span>
                      </button>
                    </div>
                  </Fancybox>
                </>
              )}
              {todo.file && (
                <a
                  href={todo.file[0]}
                  target="_blank"
                  className="mt-2 d-block fw-medium"
                >
                  Dosyayı Görüntüle
                </a>
              )}
              {todo.description && (
                <>
                  <hr className="mt-15px mb-15px" />

                  <span className="mb-2 d-block">Açıklama</span>
                  <p
                    className="mb-2"
                    dangerouslySetInnerHTML={{
                      __html: todo.description,
                    }}
                  ></p>
                </>
              )}
              {todo.summary && (
                <>
                  <hr className="mt-15px mb-15px" />

                  <span className="mb-2 d-block">Yönetici Özeti</span>
                  <p
                    className="mb-2"
                    dangerouslySetInnerHTML={{ __html: todo.summary }}
                  ></p>
                </>
              )}
              {todo.tags.length !== 0 && (
                <>
                  <hr className="mt-15px mb-15px" />

                  <div className="d-flex gap-1 mt-3">
                    {todo.tags.map((tag, tagIndex) => (
                      <span
                        className="badge"
                        key={tagIndex}
                        style={{
                          color:
                            item?.category?.color === "#cfd8dc" ||
                            item?.category?.color === "#efefef"
                              ? "#000"
                              : "#fff",
                          background: item?.category?.color,
                        }}
                      >
                        {tag}
                      </span>
                    ))}
                  </div>
                </>
              )}

              {todo.district && (
                <>
                  <hr className="mt-15px mb-15px" />

                  <span className="d-flex" style={{ fontSize: 12 }}>
                    Adres: {todo.neighbourhood && todo.neighbourhood.label}{" "}
                    {todo.street && todo.street.label}
                    <br />
                    Bina: {todo.building || "-"} Daire:{" "}
                    {todo.independent || "-"}
                    <br />
                    {todo.city && todo.city.label}/
                    {todo.district && todo?.district?.label?.toUpperCase()}
                  </span>
                </>
              )}

              <hr />

              {todo.started_at && (
                <span className="d-flex" style={{ fontSize: 12 }}>
                  Başlama Tarihi:{" "}
                  {moment(todo.started_at, "YYYY-MM-DD HH:mm:ss").format(
                    "DD MMMM YYYY"
                  )}
                </span>
              )}
              {todo.finished_at && (
                <span className="d-flex" style={{ fontSize: 12 }}>
                  Bitme Tarihi:{" "}
                  {moment(todo.finished_at, "YYYY-MM-DD HH:mm:ss").format(
                    "DD MMMM YYYY"
                  )}
                </span>
              )}
              <span className="d-flex" style={{ fontSize: 12 }}>
                Lokasyon: {todo.location ? "Var" : "Yok"}
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

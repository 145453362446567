import React from "react";
import App from "./../app.jsx";

import PagesError from "../pages/error/error.jsx";

//--

import Anasayfa from "../pages/anasayfa";

import SecimAnalizi from "../pages/siyasi-analiz/secim-analizi";
import SokakAnalizi from "../pages/siyasi-analiz/sokak-analizi/index.jsx";
import SandikAnalizi from "../pages/siyasi-analiz/sandik-analizi/index.jsx";
import HaneAnalizi from "../pages/siyasi-analiz/hane-analizi";

import TalepAnalizi from "../pages/talep-analizi";
import KentmetreRaporu1 from "../pages/kentmetre-raporu/KentmetreRaporu1.jsx";
import KentmetreRaporu2 from "../pages/kentmetre-raporu/KentmetreRaporu2.jsx";
import KentmetreRaporu3 from "../pages/kentmetre-raporu/KentmetreRaporu3.jsx";
import IletisimYonetimi from "../pages/iletisim-yonetimi";
import Kullanicilar from "../pages/kullanicilar/index.jsx";
import Musteriler from "../pages/musteriler/index.jsx";
import GirisYap from "../pages/giris-yap/index.jsx";

// import Demo from "../pages/demo/index.jsx";
import IsZekasi from "../pages/is-zekasi/index.jsx";
import LogKayitlari from "../pages/log-kayitlarıi/index.jsx";
import StratejiHaritasi from "../pages/strateji-haritasi/index.jsx";
import KentmetreHaritasi from "../pages/kentmetre-haritasi/index.jsx";
import SandikCalismasi from "../pages/sandik-calismasi/index.jsx";
import HazirRaporlar from "../pages/hazir-raporlar/index.jsx";
import SosyalMedya from "../pages/sosyal-medya/index.jsx";
import AnketVeArastirmalar from "../pages/anket-ve-arastirmalar/index.jsx";
import IleriRaporlama from "../pages/ileri-raporlama/index.jsx";
import HazirRaporlama from "../pages/hazir-raporlama/index.jsx";
import YuklenenDosyalar from "../pages/yuklenen-dosyalar/index.jsx";
import DinamikPage from "../pages/dinamik-page/index.jsx";
import RaporOlustur from "../pages/rapor-olustur/index.jsx";
import ArastirmaRaporu from "../pages/arastirma-raporu/index.jsx";
import OrnekDosyalar from "../pages/ornek-dosyalar/index.jsx";
import MusteriKullanicilar from "../pages/musteri-kullanicilar/index.jsx";
import Profil from "../pages/profil/index.jsx";
import AfetYonetimi from "../components/AfetYonetimi.jsx";

const AppRoute = [
  {
    path: "*",
    element: <App />,
    children: [
      { path: "giris-yap", element: <GirisYap /> },
      { path: "*", element: <PagesError /> },
      { path: "404", element: <PagesError /> },
      { path: ":slug", element: <DinamikPage /> },
      { path: "", removed: true, element: <Anasayfa /> },
      { path: "durum-analizi", removed: true, element: <Anasayfa /> },
      { path: "sandik-analizi", removed: true, element: <SandikAnalizi /> },
      { path: "sandik-calismasi", removed: true, element: <SandikCalismasi /> },
      {
        path: "hane-ve-birey-analizi",
        removed: true,
        element: <HaneAnalizi />,
      },
      { path: "sokak-analizi", removed: true, element: <SokakAnalizi /> },
      { path: "secim-analizi", removed: true, element: <SecimAnalizi /> },
      // {
      //   path: "talep-ve-hizmet-analizi",
      //   removed: true,
      //   element: <TalepAnalizi />,
      // },
      {
        path: "hazir-raporlar",
        removed: true,
        element: <HazirRaporlar />,
      },
      {
        path: "strateji-haritasi",
        removed: true,
        element: <StratejiHaritasi />,
      },
      {
        path: "kentmetre-haritasi",
        removed: true,
        element: <KentmetreHaritasi />,
      },
      {
        path: "arastirma-raporu",
        removed: true,
        element: <ArastirmaRaporu />,
      },
      {
        path: "kentmetre-raporu/*",
        children: [
          { path: "rapor-1", element: <KentmetreRaporu1 /> },
          { path: "rapor-2", element: <KentmetreRaporu2 /> },
          { path: "rapor-3", element: <KentmetreRaporu3 /> },
        ],
      },
      {
        path: "halkla-iletisim-yonetimi",
        removed: true,
        element: <IletisimYonetimi />,
      },
      {
        path: "profil",
        removed: true,
        element: <Profil />,
      },
      // { path: "is-zekasi", removed: true, element: <IsZekasi /> },
      { path: "sosyal-medya", removed: true, element: <SosyalMedya /> },
      {
        path: "anket-ve-arastirmalar",
        removed: true,
        element: <AnketVeArastirmalar />,
      },
      // { path: "ileri-raporlama", removed: true, element: <IleriRaporlama /> },
      // {
      //   path: "hazir-raporlama",
      //   removed: true,
      //   element: <HazirRaporlama />,
      // },
      {
        path: "yuklenen-dosyalar",
        removed: true,
        element: <YuklenenDosyalar />,
      },
      { path: "anket-olustur", element: <RaporOlustur /> },
      { path: "kullanicilar", element: <MusteriKullanicilar /> },
      { path: "afet-yonetimi", element: <AfetYonetimi /> },
      localStorage.getItem("user") &&
      JSON.parse(localStorage.getItem("user")).role.id === 1
        ? {
            path: "yonetim/*",
            children: [
              { path: "kullanicilar", element: <Kullanicilar /> },
              { path: "musteriler", element: <Musteriler /> },
              { path: "log-kayitlari", element: <LogKayitlari /> },
              { path: "ornek-dosyalar", element: <OrnekDosyalar /> },
            ],
          }
        : {},
    ],
  },
];

export default AppRoute;

import React, { useContext, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import SidebarNav from "./sidebar-nav.jsx";
import { AppSettings } from "../../config/app-settings.js";
import { Link, useNavigate } from "react-router-dom";
import ThemePanel from "../theme-panel/theme-panel.jsx";
import apiClient from "../../ApiClient.js";

function Sidebar({ importFileOpen, setImportFileOpen }) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  async function handleLogOut() {
    context.setMe(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // localStorage.removeItem("theme");

    // document.body.classList.forEach((className) => {
    //   if (className.startsWith("theme-")) {
    //     document.body.classList.remove(className);
    //   }
    // });

    document.getElementById("header").style.background =
      "rgba(29, 40, 53, 0.8);";
    document.getElementById("sidebar").style.background = "#212e3b";

    document.querySelector(".app").style.background =
      "linear-gradient(180deg, #3cd2a5 -190%, rgb(0, 7, 23) 90%)";

    navigate("/giris-yap");
  }

  //--

  const toggleAppSidebarMobile = () => {
    var elm = document.querySelector(".app");
    elm.classList.toggle("app-sidebar-toggled");
  };

  //--

  // useEffect(() => {
  //   let inputBuffer = "";

  //   const handleKeyDown = (event) => {
  //     if (event.key.length === 1) {
  //       inputBuffer += event.key;

  //       if (inputBuffer.endsWith("ohal123")) {
  //         document.body.classList.add("ohal-active");
  //       }
  //     } else if (event.key === "Backspace") {
  //       inputBuffer = inputBuffer.slice(0, -1);
  //     }
  //   };

  //   window.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     window.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <React.Fragment>
      <div id="sidebar" className="app-sidebar">
        <div
          className="app-sidebar-button"
          style={{ top: 30 }}
          data-bs-toggle="modal"
          data-bs-target="#createPageModal"
          onClick={() => context.setUpdatePage(null)}
        >
          <i className="fas fa-md fa-fw fa-plus"></i>
        </div>
        <div className="app-sidebar-button" onClick={toggleAppSidebarMobile}>
          <i className="fas fa-md fa-fw fa-arrow-left"></i>
        </div>

        <PerfectScrollbar
          className="app-sidebar-content"
          options={{ suppressScrollX: true }}
        >
          <SidebarNav
            filteredMenu={
              context &&
              context.pages &&
              context.pages
                .map((item) => {
                  if (!item.name.startsWith("Anket Sayfası")) {
                    return item;
                  }
                  return null;
                })
                .filter((item) => item !== null)
            }
          />

          <div className="mt-auto">
            {context &&
              (context?.me?.role?.id === 1 ||
                context?.me?.role?.id === 2 ||
                context?.me?.role?.id === 4) && (
                <div className="row g-2 mx-1 mb-2">
                  <div className="col-12">
                    <Link
                      to="/afet-yonetimi"
                      className="btn btn-sm btn-outline-danger w-100"
                      // onClick={() => document.body.classList.add("ohal-active")}
                    >
                      Afet Yönetimi
                    </Link>
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-theme w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#createGraphicModal"
                      onClick={() => {
                        context.setCreateGraphicOpen(true);
                        context.setGraphicUpdate(null);
                      }}
                    >
                      Grafik Oluştur
                    </button>
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-sm btn-outline-theme w-100"
                      data-bs-toggle="modal"
                      data-bs-target="#fileImportModal"
                      onClick={() => setImportFileOpen(!importFileOpen)}
                    >
                      Dosya Yükle
                    </button>
                  </div>
                </div>
              )}

            {/* <div className="px-2 my-2">
              <ThemePanel />
            </div> */}

            <div
              className="px-2 mt-auto menu-footer"
              style={{ paddingTop: 10, paddingBottom: 10 }}
            >
              <div className="d-flex gap-2">
                <Link
                  to="/profil"
                  className="btn d-block px-1 btn-outline-theme flex-1 menu-footer-title"
                  style={{ fontSize: 12 }}
                >
                  <i className="fa fa-sm fa-user me-1"></i>
                  {(context.me && context.me.name) || (
                    <div className="spinner-border text-theme spinner-border-sm"></div>
                  )}
                </Link>

                <div
                  onClick={handleLogOut}
                  className="btn d-flex align-items-center btn-outline-danger py-0 px-2"
                >
                  <i className="fa fa-sm fa-sign-out-alt"></i>
                </div>
              </div>
            </div>
          </div>
        </PerfectScrollbar>
      </div>
    </React.Fragment>
  );
}

export default Sidebar;

import { useContext, useEffect, useRef, useState } from "react";

import Select from "react-select";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { AppSettings } from "../../config/app-settings";
import FileItem from "./FileItem";
import apiClient from "../../ApiClient";
import ImportFile from "../../components/import-file";
import toastMessage from "../../components/ToastMessage";
import { ToastContainer } from "react-toastify";
import moment from "moment";

const selectedOperatorOptions = [
  { value: "and", label: "Ve" },
  { value: "or", label: "Veya" },
];

const selectedConditionOptions = [
  { value: "=", label: "=" },
  { value: "!=", label: "!=" },
  { value: ">", label: ">" },
  { value: ">=", label: ">=" },
  { value: "<", label: "<" },
  { value: "<=", label: "<=" },
  { value: "in", label: "İÇİNDE" },
  { value: "notin", label: "DIŞINDA" },
  { value: "like", label: "BENZER" },
  { value: "notlike", label: "BENZEMEZ" },
  { value: "isnull", label: "BOŞ" },
  { value: "isnotnull", label: "DOLU" },
];

export default function YuklenenDosyalar() {
  const context = useContext(AppSettings);

  const [fileTypesCustomer, setFileTypesCustomer] = useState(null);
  const [fileTypes, setFileTypes] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);

  const [typeData, setTypeData] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);

  const [showRemoveButton, setShowRemoveButton] = useState(false);

  async function handleFileTypesCustomer(e) {
    setFileTypes(null);
    setSearchTerm("");

    try {
      const response = await apiClient.get(
        `/types${e.value ? `?customer=${e.value}` : ""}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        setFileTypes(response.data);
      } else {
        setFileTypes(null);
      }
    } catch (error) {
      console.error("Get File Types Error", error);
    }

    setFileTypesCustomer(e);
  }

  useEffect(() => {
    if (context && context.me) {
      const customerControl = context?.me?.role?.id === 1 && {
        value: context.me.customer.id,
        label: context.me.customer.customer.name,
        user_id: context.me.id,
      };

      setFileTypesCustomer(customerControl);
      handleFileTypesCustomer(customerControl);
    }
  }, [context]);

  //--

  const [conditions, setConditions] = useState([
    { column: "", operator: "", value: "", condition: "" },
  ]);

  const [previewData, setPreviewData] = useState({ data: [] });
  const [fetchDataArray, setFetchDataArray] = useState(null);

  const handleAddCondition = () => {
    setConditions([
      ...conditions,
      { column: "", operator: "", value: "", condition: "" },
    ]);
  };

  const handleRemoveCondition = (index) => {
    setConditions(conditions.filter((_, i) => i !== index));
  };

  const handleChange = (index, field, value) => {
    const updatedConditions = conditions.map((condition, i) => {
      if (i === index) {
        if (
          field === "operator" &&
          (value.value === "isnull" || value.value === "isnotnull")
        ) {
          return { ...condition, [field]: value, value: "" };
        }
        return { ...condition, [field]: value };
      }
      return condition;
    });
    setConditions(updatedConditions);
  };

  const handleSubmit = async () => {
    setPreviewData(null);

    if (
      conditions.length === 0 ||
      conditions.some(
        (cond) =>
          !cond.column ||
          !cond.operator ||
          (cond.operator.value !== "isnull" &&
            cond.operator.value !== "isnotnull" &&
            !cond.value) ||
          !cond.condition
      )
    ) {
      alert(
        "Lütfen tüm alanları doldurduğunuzdan emin olun ve en az bir koşul ekleyin."
      );
      return;
    }

    const data = {
      chart: 1,
      page_id: 51,
      title: "-",
      description: "",
      status: true,
      order: 1,
      paginate: true,
      type: typeData.type.type,
      customer: fileTypesCustomer.value,
      sql: {
        column: typeData.column.map((item) => ({
          key: item.replace(/[\/\\']/g, ""),
          value: "select",
        })),
        condition:
          conditions.length !== 0
            ? conditions.map((item) => {
                return {
                  key: item.column.value,
                  value: item.value,
                  operator: item.operator.value,
                  type: item.condition.value,
                };
              })
            : [],
        group: [],
      },
    };

    setFetchDataArray(data);
    fetchData(1, data);
  };

  useEffect(() => {
    setConditions([{ column: "", operator: "", value: "", condition: "" }]);
    setPreviewData({ data: [] });
    setFetchDataArray(null);
    setShowRemoveButton(false);
  }, [typeData]);

  const fetchData = async (page, data) => {
    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${page}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setPreviewData(response);
    } catch (error) {
      setPreviewData(null);
      console.error(error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page, fetchDataArray);
  };

  //--

  const [deleteLoader, setDeleteLoader] = useState(false);

  async function handleFileDelete(file, condition) {
    if (!file) {
      toastMessage("Dosya Bulunamadı", "error");
      return;
    }

    const payload = {
      sql: {
        condition: condition
          ? conditions.map((item) => ({
              key: item.column.value,
              value: item.value,
              operator: item.operator.value,
              type: item.condition.value,
            }))
          : [],
      },
      type: file,
    };

    setDeleteLoader(true);

    try {
      const response = await apiClient.customPost(`/delete/query`, payload, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setDeleteLoader(false);

      if (response.success) {
        toastMessage("Veriler Silindi", "success");
        setTimeout(() => {
          document
            .getElementById("yuklenenDosyalarDeleteModalCloseButton")
            .click();
          handleFileTypesCustomer(
            context?.me?.role?.id === 1
              ? {
                  value: fileTypesCustomer.value,
                  label: fileTypesCustomer.label,
                }
              : {
                  value:
                    localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).customer.id,
                  label:
                    localStorage.getItem("user") &&
                    JSON.parse(localStorage.getItem("user")).customer.customer
                      .name,
                }
          );
        }, 300);
      }
    } catch (error) {
      setDeleteLoader(false);
      console.error(error);
    }
  }

  // Satır Ekle/Güncelle
  const [fileItemEvents, setFileItemEvents] = useState(null);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    if (fileItemEvents) {
      const updatedFormData = {};

      if (fileItemEvents.row) {
        Object.keys(fileItemEvents.row).forEach((key) => {
          updatedFormData[key] = fileItemEvents.row[key];
        });
      } else {
        fileItemEvents.column.columns.forEach((key) => {
          updatedFormData[key] = "";
        });
      }

      setFormData(updatedFormData);
    } else {
      setFormData({});
    }
  }, [fileItemEvents]);

  const handleInputChange = (key, value) => {
    setFormData((prevData) => ({
      ...prevData,
      [key]: value,
    }));
  };

  const formatDatesInJson = (obj) => {
    if (obj && typeof obj === "object") {
      for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
          const value = obj[key];
          if (
            typeof value === "object" &&
            value !== null &&
            moment(value).isValid()
          ) {
            obj[key] = moment(value).format("YYYY-MM-DD");
          }
        }
      }
    }
    return obj;
  };

  const handleEventsRow = async () => {
    const hasEmptyField = Object.values(formData).some(
      (value) => value === "" || value === null
    );

    if (hasEmptyField) {
      toastMessage("Satırlar boş bırakılamaz", "error");
      return;
    }

    const formattedData = formatDatesInJson(formData);

    if (formattedData) {
      const resultArray = Object.entries(formattedData).map(
        ([key, value]) => value
      );

      const csvContent = [
        fileItemEvents.column.columns.join(";"),
        resultArray.join(";"),
      ].join("\n");

      const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });

      const file = new File([blob], fileItemEvents.type.type, {
        type: "text/csv;charset=utf-8;",
      });

      //--

      const maxFileSize = 50 * 1024 * 1024; // 5MB in bytes

      if (file.size > maxFileSize) {
        toastMessage("Dosya Boyutu 50MB Fazla Olamaz", "error");
        return;
      }
      if (!file) {
        toastMessage("Dosya Gerekli", "error");
        return;
      }

      const payloadformData = new FormData();
      payloadformData.append("file", file);
      payloadformData.append(
        "data_type",
        context?.me?.role?.id === 1
          ? fileTypesCustomer.value
            ? "custom"
            : "global"
          : "custom"
      );
      payloadformData.append(
        "user_id",
        fileTypesCustomer?.value ? fileTypesCustomer?.user_id : context?.me?.id
      );
      payloadformData.append("type", fileItemEvents.type.type);
      payloadformData.append("separator", ";");
      fileItemEvents.column.types.forEach((item, index) => {
        payloadformData.append("columns[" + index + "][key]", item.key);
        payloadformData.append("columns[" + index + "][value]", item.value);
      });

      // for (var pair of payloadformData.entries()) {
      //   console.log(pair[0] + ", " + pair[1]);
      // }

      //--

      if (fileItemEvents.row) {
        const deletePayload = {
          sql: {
            condition: fileItemEvents.column.columns.map((item, index) => ({
              key: item,
              value: Object.values(fileItemEvents.row)[index],
              operator: "=",
              type: "and",
            })),
          },
          type: fileItemEvents.type.type,
        };

        try {
          // İlk olarak DELETE işlemi yapılır
          const deleteResponse = await apiClient.customPost(
            `/delete/query`,
            deletePayload,
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (deleteResponse.success) {
            // DELETE işlemi başarılıysa
            toastMessage("Veriler Silindi", "success");

            // Daha sonra ikinci işlemi yap
            try {
              const uploadResponse = await apiClient.post(
                "/csv/upload",
                payloadformData,
                {
                  headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${localStorage.getItem("token")}`,
                  },
                }
              );

              if (uploadResponse.status) {
                toastMessage("Satır Başarıyla Yüklendi", "success");
                setTimeout(() => {
                  document
                    .getElementById("yuklenenDosyalarIslemlerCloseButton")
                    .click();
                  handleFileTypesCustomer(
                    context?.me?.role?.id === 1
                      ? {
                          value: fileTypesCustomer.value,
                          label: fileTypesCustomer.label,
                        }
                      : {
                          value:
                            localStorage.getItem("user") &&
                            JSON.parse(localStorage.getItem("user")).customer
                              .id,
                          label:
                            localStorage.getItem("user") &&
                            JSON.parse(localStorage.getItem("user")).customer
                              .customer.name,
                        }
                  );
                }, 300);
              } else {
                toastMessage("Satır Yüklenemedi", "error");
              }
            } catch (uploadError) {
              console.error("Satır Yükleme Hatası", uploadError);
            }
          } else {
            toastMessage("Veriler Silinemedi", "error");
          }

          setDeleteLoader(false);
        } catch (deleteError) {
          console.error("Veri Silme Hatası", deleteError);
          setDeleteLoader(false);
        }
      } else {
        try {
          const response = await apiClient.post(
            "/csv/upload",
            payloadformData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          if (response.status) {
            toastMessage("Satır Başarıyla Yüklendi", "success");

            setTimeout(() => {
              window.location.reload();
            }, 300);
          } else {
            toastMessage("Satır Yüklenemedi", "error");
          }
        } catch (error) {
          console.error("Satır Ekle Hatası", error);
        }
      }
    }
  };

  return (
    <div className="row mb-5 pb-5">
      <div className="col-12">
        <div className="row">
          {context?.me?.role?.id === 1 && (
            <div className="col flex-none" style={{ maxWidth: 300 }}>
              <span className="mb-1 d-block">Müşteri Seç</span>
              {context.allCustomerSelect ? (
                <Select
                  options={[
                    {
                      value: null,
                      label: "Global",
                      user_id: null,
                    },
                    ...context.allCustomerSelect,
                  ]}
                  placeholder=""
                  classNamePrefix="react-select"
                  noOptionsMessage={() => "Seçenek Yok"}
                  value={fileTypesCustomer}
                  onChange={(e) => handleFileTypesCustomer(e)}
                />
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>
          )}

          <div className="col flex-none" style={{ maxWidth: 300 }}>
            <span className="mb-1 d-block">Dosya Ara</span>
            <input
              type="text"
              className="form-control"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>
        </div>
      </div>

      <div className="col-12">
        <hr />
      </div>

      {fileTypes && fileTypesCustomer && fileTypesCustomer.value && (
        <div className="col-12">
          <div className="row g-3 mb-3">
            {[
              {
                type: "bireysel-data-customer-" + fileTypesCustomer.value,
              },
              {
                type: "hane-data-customer-" + fileTypesCustomer.value,
              },
              {
                type: "akraba-data-customer-" + fileTypesCustomer.value,
              },
            ].map((item, index) => (
              <div className="col-12" key={index}>
                <FileItem
                  setSelectedFile={setSelectedFile}
                  item={item}
                  setTypeData={setTypeData}
                  setFileItemEvents={setFileItemEvents}
                />
              </div>
            ))}
          </div>

          <hr />
        </div>
      )}

      <div className="col-12 mb-3">
        <h4 className="mt-3">Sizin Yükledikleriniz</h4>
      </div>

      <div className="col-12">
        <div className="row g-3">
          {fileTypes ? (
            <>
              {fileTypes
                .filter((item) => {
                  if (searchTerm.trim() === "") {
                    return true;
                  }
                  return item.type
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase());
                })
                .filter(
                  (item) =>
                    item.type.split("-customer")[0] !== "bireysel-data" &&
                    item.type.split("-customer")[0] !== "hane-data" &&
                    item.type.split("-customer")[0] !== "akraba-data"
                )
                .map((item, index) => (
                  <div className="col-12" key={index}>
                    <FileItem
                      setSelectedFile={setSelectedFile}
                      item={item}
                      setTypeData={setTypeData}
                      setFileItemEvents={setFileItemEvents}
                    />
                  </div>
                ))}
            </>
          ) : (
            <div className="col-12">
              <div className="spinner-border text-theme"></div>
            </div>
          )}
        </div>
      </div>

      <div className="modal modal-cover fade" id="yuklenenDosyalarIslemler">
        <div className="modal-dialog py-4" style={{ maxWidth: 1000 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <div>
                <h3 className="modal-title">
                  Satır{" "}
                  {fileItemEvents && fileItemEvents.row ? "Güncelle" : "Ekle"}
                </h3>
                <span className="d-block">
                  {fileItemEvents &&
                    fileItemEvents.type.type.split("-customer")[0]}
                </span>
              </div>

              <button
                type="button"
                className="btn-close"
                id="yuklenenDosyalarIslemlerCloseButton"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {fileItemEvents && (
              <>
                <div className="modal-body border-top pb-4">
                  <div className="row g-4">
                    {fileItemEvents.row
                      ? Object.keys(fileItemEvents.row).map((key) => (
                          <div className="col col-md-4" key={key}>
                            <label>{key}</label>
                            <input
                              type="text"
                              className="form-control"
                              value={formData[key] || ""}
                              required
                              onChange={(e) =>
                                handleInputChange(key, e.target.value)
                              }
                            />
                          </div>
                        ))
                      : fileItemEvents.column.types.map((item) => (
                          <div className="col col-md-4" key={item.key}>
                            <label>{item.key}</label>
                            {item.value === "date" ? (
                              <DatePicker
                                locale="tr"
                                className="form-control"
                                required
                                selected={formData[item.key]}
                                onChange={(date) =>
                                  handleInputChange(item.key, date)
                                }
                              />
                            ) : (
                              <input
                                type={
                                  item.value === "integer" ||
                                  item.value === "id" ||
                                  item.value === "phone"
                                    ? "number"
                                    : item.value === "email"
                                    ? "email"
                                    : "text"
                                }
                                required
                                className="form-control"
                                value={formData[item.key] || ""}
                                onChange={(e) =>
                                  handleInputChange(item.key, e.target.value)
                                }
                              />
                            )}
                          </div>
                        ))}
                  </div>
                </div>

                <div className="modal-footer border-top">
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    onClick={() => handleEventsRow()}
                  >
                    {fileItemEvents && fileItemEvents.row ? "Güncelle" : "Ekle"}
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="yuklenenDosyalarModal">
        <div className="modal-dialog py-4" style={{ maxWidth: 1000 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <div>
                <h3 className="modal-title">Koşul Oluştur</h3>
                <span className="d-block">
                  {typeData && typeData.type.type.split("-customer")[0]}
                </span>
              </div>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {typeData && (
              <div className="modal-body">
                <div className="col-12">
                  <button
                    type="button"
                    className="btn btn-outline-theme w-100 mb-3"
                    onClick={handleAddCondition}
                  >
                    Koşul Ekle
                  </button>

                  {conditions.length === 0 && <p>Koşul ekleyebilirsiniz</p>}

                  {conditions.map((condition, index) => (
                    <div
                      className="row align-items-center g-2 mb-2"
                      key={index}
                    >
                      <div
                        className="col w-auto"
                        style={{ flex: "none", minWidth: 80 }}
                      >
                        {index + 1}. Koşul:
                      </div>
                      <div className="col">
                        <Select
                          options={typeData.column.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          value={condition.column}
                          onChange={(option) =>
                            handleChange(index, "column", option)
                          }
                          placeholder="Sütun Seç"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => "Seçenek Yok"}
                        />
                      </div>
                      <div className="col">
                        <Select
                          options={selectedConditionOptions}
                          value={condition.operator}
                          onChange={(option) =>
                            handleChange(index, "operator", option)
                          }
                          placeholder="Operatör Seç"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => "Seçenek Yok"}
                        />
                      </div>
                      <div className="col">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="DEĞER"
                          value={condition.value}
                          onChange={(e) =>
                            handleChange(index, "value", e.target.value)
                          }
                          disabled={
                            condition.operator.value === "isnull" ||
                            condition.operator.value === "isnotnull"
                          }
                        />
                      </div>
                      <div className="col">
                        <Select
                          options={selectedOperatorOptions}
                          value={condition.condition}
                          onChange={(option) =>
                            handleChange(index, "condition", option)
                          }
                          placeholder="Koşul Seç"
                          classNamePrefix="react-select"
                          noOptionsMessage={() => "Seçenek Yok"}
                        />
                      </div>
                      <div className="col w-auto" style={{ flex: "none" }}>
                        <button
                          className="btn border-0 btn-danger h-100"
                          onClick={() => handleRemoveCondition(index)}
                        >
                          <i className="fas fa-trash-alt"></i>
                        </button>
                      </div>
                    </div>
                  ))}
                </div>

                <div className="row mt-4">
                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-dark w-100"
                      data-bs-dismiss="modal"
                    >
                      İptal
                    </button>
                  </div>

                  <div className="col">
                    <button
                      type="button"
                      className="btn btn-outline-theme w-100 mb-3"
                      onClick={handleSubmit}
                    >
                      Ön İzleme
                    </button>
                  </div>
                </div>

                <hr />

                {previewData ? (
                  previewData.data.length !== 0 ? (
                    <>
                      <div className="table-responsive" style={{ height: 500 }}>
                        <table className="table">
                          <thead>
                            <tr>
                              {typeData.column.map((header, index) => (
                                <th
                                  style={{ whiteSpace: "nowrap" }}
                                  key={index}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {previewData.data.map((row, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.values(row).map((cell, cellIndex) => (
                                  <td key={cellIndex}>{cell}</td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>

                      <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center mt-4">
                        <ul className="pagination">
                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() => handlePageChange(currentPage - 1)}
                              className={`page-link pointer ${
                                !previewData.prev_page_url ? "disabled" : ""
                              }`}
                            >
                              Önceki
                            </button>
                          </li>

                          {previewData.links.map(
                            (number, index) =>
                              index > 0 &&
                              index < previewData.links.length - 1 && (
                                <li
                                  key={index}
                                  className={`paginate_button page-item ${
                                    currentPage === parseInt(number.label)
                                      ? "active"
                                      : ""
                                  }`}
                                >
                                  <div
                                    onClick={() =>
                                      handlePageChange(parseInt(number.label))
                                    }
                                    className="page-link pointer"
                                  >
                                    {number.label}
                                  </div>
                                </li>
                              )
                          )}

                          <li className={`paginate_button page-item`}>
                            <button
                              type="button"
                              onClick={() => handlePageChange(currentPage + 1)}
                              className={`page-link pointer ${
                                !previewData.next_page_url ? "disabled" : ""
                              }`}
                            >
                              Sonraki
                            </button>
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : (
                    <span>Veri Bulunamadı</span>
                  )
                ) : (
                  <div className="spinner-border  text-theme"></div>
                )}

                {previewData && previewData.data.length !== 0 && (
                  <>
                    <hr />

                    <div className="">
                      <div
                        className="form-check form-checkbox mb-3 mx-auto"
                        style={{ maxWidth: 400 }}
                      >
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="yuklenen_dosyalar_show_remove_button"
                          onChange={(e) =>
                            setShowRemoveButton(e.target.checked)
                          }
                        />
                        <label
                          className="form-check-label"
                          htmlFor="yuklenen_dosyalar_show_remove_button"
                        >
                          Bulunan koşullar kalıcı olarak silinecektir ve geri
                          dönüşü olmayacaktır. <strong>Emin misiniz?</strong>
                        </label>
                      </div>

                      <div className="d-flex gap-2 justify-content-center">
                        {showRemoveButton && (
                          <button
                            type="button"
                            className="btn btn-danger"
                            onClick={() =>
                              handleFileDelete(typeData.type.type, conditions)
                            }
                            disabled={deleteLoader}
                          >
                            Seçilenleri Kaldır
                            {deleteLoader && (
                              <div className="spinner-border spinner-border-sm text-danger"></div>
                            )}
                          </button>
                        )}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="yuklenenDosyalarDeleteModal">
        <div className="modal-dialog py-4">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                <span className="h5">
                  {selectedFile?.type.split("-customer")[0]}
                </span>
              </h3>

              <button
                type="button"
                className="btn-close"
                id="yuklenenDosyalarDeleteModalCloseButton"
                data-bs-dismiss="modal"
              ></button>
            </div>

            <div className="modal-body text-center mb-3">
              <h4>
                Silme işleminden bu dosyaya bağlı tüm veri ve grafikler
                etkilenecektir. <br />
                <br /> Silmek istediğinize emin misiniz?
              </h4>

              <button
                type="button"
                className="btn btn-danger mt-3"
                onClick={() => handleFileDelete(selectedFile?.type, null)}
                disabled={deleteLoader}
              >
                Tümünü Kaldır
                {deleteLoader && (
                  <div className="spinner-border spinner-border-sm text-white ms-1"></div>
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="fileListImportModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">Dosya Yükle</h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {selectedFile && (
              <div className="modal-body">
                <span className="d-block mb-3">
                  Seçilen: {selectedFile?.type.split("-customer")[0]}
                </span>

                <ImportFile
                  dataname={
                    selectedFile?.type === "bireysel-data" ||
                    selectedFile?.type === "hane-data" ||
                    selectedFile?.type === "akraba-data"
                      ? selectedFile?.type +
                        "-customer-" +
                        fileTypesCustomer.value
                      : selectedFile?.type
                  }
                  pageImport={true}
                  customerProps={fileTypesCustomer}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

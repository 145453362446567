import React, { useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";

import { CSVLink } from "react-csv";
import { ToastContainer } from "react-toastify";
import toastMessage from "../../components/ToastMessage";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import AddModal from "./addModal";
import UpdateModal from "./updateModal";
import Fancybox from "../../components/Fancybox";

import { Card, CardBody } from "../../components/card/card";

function Kullanicilar() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Get Data
  const searchRef = useRef();
  const tableRef = useRef();
  const tableHead = [
    {
      key: "id",
      label: "#",
    },
    {
      key: "profil",
      label: "Profil",
    },
    {
      key: "otp",
      label: "OTP",
    },
    {
      key: "customer",
      label: "Müşteri",
    },
    {
      key: "customer_name",
      label: "Müşteri Yetkilisi Adı Soyadı",
    },
    {
      key: "role",
      label: "Kullanıcı Yetkisi",
    },
    {
      key: "username",
      label: "Kullanıcı Adı",
    },
    {
      key: "unvan",
      label: "Kullanıcı Ünvan",
    },
    {
      key: "name",
      label: "Kullanıcı Yetkilisi Adı Soyadı",
    },
    {
      key: "phone",
      label: "Kullanıcı Telefonu",
    },
    {
      key: "email",
      label: "Kullanıcı Email",
    },
    {
      key: "address",
      label: "Kullanıcı Adresi",
    },
  ];

  const [data, setData] = useState(null);
  const [customerData, setCustomerData] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const [customerId, setCustomerId] = useState(null);

  const [limitPage, setLimitPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [searchCol, setSearchCol] = useState("username");
  const [sortBy, setSortBy] = useState("id");
  const [sortDirection, setSortDirection] = useState("desc");

  useEffect(() => {
    if (context && context.me) {
      if (context?.me?.role?.id !== 1) {
        setCustomerId({
          value: context.me.customer.id,
          label: context.me.customer.customer.name,
        });
      }
    }
  }, [context]);

  async function fetchCustomer(dataArray, value) {
    const roleTextData = {};

    for (const item of dataArray) {
      const roleText = await context.getCustomer(item.customer);
      roleTextData[item.customer] = roleText;
    }

    setCustomerData(roleTextData);
  }

  async function getData() {
    if (customerId) {
      setData(null);

      try {
        const response = await apiClient.get(
          `/user?customer_id=${
            customerId.value
          }&paginate=true&limit=${limitPage}${
            sortDirection ? `&sort=${sortBy}&direction=${sortDirection}` : ""
          }&page=${currentPage}&query=${search}&query_field=${searchCol}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          setData(response.data);
          // fetchCustomer(response.data.data);
        }
      } catch (error) {
        console.error("Users Error", error);
        setData(null);

        if (error.response.status === 500) {
          setErrorMessage("500 | Sunucu Hatası!");
        } else if (error.response.status === 404) {
          navigate("/404");
        } else if (error.response.status === 403) {
          setErrorMessage("Çok fazla istek atıldı.");
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        } else {
          context.setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }

  useEffect(() => {
    if (
      context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4
    ) {
      getData();
    }
    // eslint-disable-next-line
  }, [
    context,
    customerId,
    limitPage,
    currentPage,
    search,
    sortBy,
    sortDirection,
  ]);

  // Sort
  const handleSort = (key) => {
    if (sortBy === key) {
      setSortDirection((prevSortDirection) => {
        if (prevSortDirection === "asc") return "desc";
        if (prevSortDirection === "desc") return "asc";
        // if (prevSortDirection === "desc") return null;
        // return "asc";
      });
    } else {
      setSortBy(key);
      setSortDirection("asc");
    }
  };

  // Limit Change
  function limitChange(e) {
    setCurrentPage(1);
    setLimitPage(e.target.value);
  }

  // Pagination
  function handlePagination(value) {
    setCurrentPage(value);
  }

  // Search
  function handleSearchCol(e) {
    setSearchCol(e.target.value);
  }

  function handleSearch() {
    setCurrentPage(1);
    setSearch(searchRef.current.value);
  }

  function handleReset() {
    setLimitPage(10);
    setCurrentPage(1);
    setSearch("");
    setSearchCol("username");
    setSortBy("id");
    setSortDirection("desc");
  }

  // Update
  const [updateItem, setUpdateItem] = useState(null);

  // Delete
  const [deleteItem, setDeleteItem] = useState(null);

  const handleDelete = async () => {
    if (deleteItem) {
      try {
        const response = await apiClient.delete(`/user/${deleteItem.id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        if (response.status === 200) {
          toastMessage(response.data.message, "success");
          document.getElementById("deleteConfirmModalClose").click();
          getData();
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }

        if (error.response.status === 404) {
          toastMessage("Bulunamadı!", "error");
        } else {
          setDeleteItem(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  };

  //--

  const [customerSelect, setCustomerSelect] = useState(null);

  async function handleCustomerSelect(e) {
    setCustomerSelect(e);
    setCustomerId(e);
  }

  // Birimler
  const [birimlerList, setBirimlerList] = useState(null);
  const [showUsersBirimler, setShowUsersBirimler] = useState(false);
  const [birimLoad, setBirimLoad] = useState(false);

  const [birimlerUpdateItem, setBirimlerUpdateItem] = useState(null);

  const [birimlerDeleteItem, setBirimlerDeleteItem] = useState(null);

  const [birimAdiValue, setBirimAdiValue] = useState("");
  const [birimUserValue, setBirimUserValue] = useState(null);

  const [allUsersSelect, setAllUsersSelect] = useState(null);

  async function fetchAllUsersSelect() {
    if (customerSelect) {
      setAllUsersSelect(null);

      try {
        const response = await apiClient.get(
          `/user?customer_id=${customerSelect.value}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          setAllUsersSelect(response.data);
        }
      } catch (error) {
        console.error("Users Error", error);
        setAllUsersSelect([]);
      }
    }
  }

  async function fetchBirimler() {
    setBirimlerList(null);

    try {
      const response = await apiClient.get(`/groups`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (customerSelect) {
        setBirimlerList(
          response.data.filter(
            (item) => item.customer_id === customerSelect.value
          )
        );
      } else {
        setBirimlerList(response.data);
      }
    } catch (error) {
      setBirimlerList([]);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    fetchBirimler();
    fetchAllUsersSelect();
  }, [customerSelect]);

  useEffect(() => {
    if (birimlerUpdateItem) {
      setBirimAdiValue(birimlerUpdateItem.name);
      setBirimUserValue(
        birimlerUpdateItem.users.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      );
    } else {
      setBirimAdiValue("");
      setBirimUserValue(null);
    }
  }, [birimlerUpdateItem]);

  async function handleBirimEkle() {
    if (birimAdiValue === "") {
      toastMessage("Birim Adı Girmelisiniz", "error");
      return;
    }

    const payload = {
      name: birimAdiValue,
      customer_id: customerSelect
        ? customerSelect.value
        : context?.me?.customer?.id,
    };

    setBirimLoad(true);

    try {
      const response = birimlerUpdateItem
        ? await apiClient.put(
            "/groups/" + birimlerUpdateItem.id,
            {
              name: birimAdiValue,
              users: birimUserValue
                ? birimUserValue.map((item) => {
                    return item.value;
                  })
                : [],
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          )
        : await apiClient.customPost("/groups", payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      if (response) {
        fetchBirimler();

        toastMessage(
          `Departman ${birimlerUpdateItem ? "Düzenlendi" : "Eklendi"}`,
          "success"
        );
        document.getElementById("birimEkleModalCloseButton").click();
        setBirimLoad(false);
      }
    } catch (error) {
      setBirimLoad(false);
      console.error("Departman Ekle Error", error);
    }
  }

  async function handleBirimlerDelete(params) {
    if (birimlerDeleteItem) {
      try {
        const response = await apiClient.delete(
          `/groups/${birimlerDeleteItem.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.status === 200) {
          fetchBirimler();

          toastMessage("Departman Silindi", "success");
          document.getElementById("birimlerDeleteConfirmCloseButton").click();
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }

        if (error.response.status === 404) {
          toastMessage("Bulunamadı!", "error");
        } else {
          setDeleteItem(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    }
  }

  return (
    (context?.me?.role?.id === 1 ||
      context?.me?.role?.id === 2 ||
      context?.me?.role?.id === 4) && (
      <>
        <div className="zoom-item mb-5 pb-5">
          <div className="border-bottom mb-4 pb-4">
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-outline-theme me-3"
                data-bs-toggle="modal"
                data-bs-target="#birimEkleModal"
                onClick={() => setBirimlerUpdateItem(null)}
              >
                Departman Ekle
              </button>

              <button
                type="button"
                className="btn btn-outline-theme"
                data-bs-toggle="modal"
                data-bs-target="#addModal"
              >
                Kullanıcı Ekle
              </button>

              {context?.me?.role.id === 1 && (
                <div className="ms-3 w-100" style={{ maxWidth: 200 }}>
                  {context.allCustomerSelect ? (
                    <Select
                      options={context.allCustomerSelect}
                      placeholder="Müşteri Seç"
                      classNamePrefix="react-select"
                      noOptionsMessage={() => "Seçenek Yok"}
                      value={customerSelect}
                      onChange={(e) => handleCustomerSelect(e)}
                    />
                  ) : (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </div>
              )}
            </div>
          </div>

          <h4 className="mb-4">Kullanıcılar</h4>

          {errorMessage ? (
            errorMessage
          ) : customerId ? (
            data ? (
              <div className="dataTables_wrapper dt-bootstrap5">
                <div className="row mb-3">
                  <div className="col-md-4 mb-3 mb-md-0">
                    <div className="dataTables_length">
                      <label>
                        <select
                          className="form-select form-select-sm ms-0"
                          onChange={limitChange}
                        >
                          <option value="10">10</option>
                          <option value="20">20</option>
                          <option value="30">30</option>
                          <option value="50">50</option>
                        </select>
                      </label>
                      <span className="ms-3">Görüntüle</span>
                    </div>
                  </div>

                  <div className="col-md-8 text-md-end">
                    <div className="d-flex justify-content-center justify-content-md-end">
                      <div className="dataTables_filter d-flex border-end pe-2">
                        <select
                          className="form-select form-select-sm"
                          onChange={handleSearchCol}
                          defaultValue={searchCol}
                          style={{ width: 130 }}
                        >
                          <option value="username">Kullanıcı Adı</option>
                          <option value="name">K. Yetkilisi Adı Soyadı</option>
                          <option value="phone">Kullanıcı Telefon</option>
                          <option value="email">Kullanıcı Email</option>
                        </select>

                        <input
                          type="search"
                          className="form-control form-control-sm border-end-0 rounded-end-0"
                          placeholder="Arama..."
                          ref={searchRef}
                        />

                        <button
                          type="button"
                          className="btn buttons-print btn-outline-default btn-sm rounded-start-0"
                          onClick={() => handleSearch()}
                        >
                          Ara
                        </button>
                      </div>

                      <CSVLink
                        data={data.data}
                        headers={tableHead}
                        filename="stratejimerkezi_kullanicilar.csv"
                        className="btn buttons-print btn-outline-default btn-sm ms-2"
                      >
                        CSV
                      </CSVLink>

                      <div className="border-start ms-2 ps-2">
                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm"
                          onClick={() => handleReset()}
                        >
                          Sıfırla
                        </button>
                      </div>
                    </div>
                  </div>
                </div>

                {data.data.length === 0 ? (
                  "Kullanıcı Bulunamadı"
                ) : (
                  <>
                    <div className="dataTables_scroll">
                      <div className="dataTables_scrollBody table-responsive">
                        <table
                          className="table text-nowrap w-100 dataTable"
                          ref={tableRef}
                        >
                          <thead>
                            <tr>
                              {tableHead.map((item, index) => (
                                <th
                                  key={index}
                                  onClick={() =>
                                    item.key !== "customer_name" &&
                                    handleSort(item.key)
                                  }
                                  className={
                                    item.key !== "customer_name"
                                      ? `sorting ${
                                          sortBy === item.key
                                            ? `sorting_${sortDirection}`
                                            : ""
                                        }`
                                      : ""
                                  }
                                >
                                  {item.label}
                                </th>
                              ))}
                              <th className="text-end pe-0">İşlemler</th>
                            </tr>
                          </thead>

                          <tbody>
                            {data.data.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.id || "-"}</td>
                                <td>
                                  {item?.settings?.image ? (
                                    <Fancybox
                                      options={{
                                        Carousel: {
                                          infinite: false,
                                        },
                                      }}
                                    >
                                      <a
                                        className="d-block"
                                        href={item?.settings?.image}
                                        data-fancybox
                                      >
                                        <img
                                          src={item?.settings?.image}
                                          alt={item.name}
                                          width={100}
                                          height={100}
                                          style={{
                                            objectFit: "cover",
                                            borderRadius: "100%",
                                          }}
                                        />
                                      </a>
                                    </Fancybox>
                                  ) : (
                                    "-"
                                  )}
                                </td>
                                <td>{item?.otp ? "Evet" : "Hayır"}</td>
                                <td>{item?.customer?.customer?.name || "-"}</td>
                                <td>
                                  {item?.customer?.customer?.competent || "-"}
                                </td>
                                <td>{item?.role?.name}</td>
                                <td>{item?.username || "-"}</td>
                                <td>{item?.settings?.title || "-"}</td>
                                <td>{item?.name || "-"}</td>
                                <td>
                                  <a href={`tel:${item.phone}`}>
                                    {item?.phone || "-"}
                                  </a>
                                </td>
                                <td>
                                  <a href={`mailto:${item.email}`}>
                                    {item?.email || "-"}
                                  </a>
                                </td>
                                <td style={{ whiteSpace: "wrap" }}>
                                  <p className="mb-0" style={{ width: 200 }}>
                                    {item?.address || "-"}
                                  </p>
                                </td>
                                <td className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme btn-sm me-2"
                                    data-bs-toggle="modal"
                                    data-bs-target="#updateModal"
                                    onClick={() =>
                                      setUpdateItem({
                                        id: item.id,
                                        customer: {
                                          value: item?.customer?.id || "",
                                          label:
                                            item?.customer?.customer?.name ||
                                            "",
                                        },
                                        role: {
                                          value: item?.role?.id || "",
                                          label: item?.role?.name || "",
                                        },
                                        username: item?.username || "",
                                        name: item?.name || "",
                                        phone: item?.phone || "",
                                        email: item?.email || "",
                                        address: item?.address || "",
                                        settings: {
                                          title: item?.settings?.title || "",
                                          image: item?.settings?.image || null,
                                          kvkk_confirm_date:
                                            item?.settings?.kvkk_confirm_date ||
                                            null,
                                        },
                                      })
                                    }
                                  >
                                    Düzenle
                                  </button>

                                  <button
                                    type="button"
                                    className="btn btn-outline-danger btn-sm"
                                    data-bs-toggle="modal"
                                    data-bs-target="#deleteConfirmModal"
                                    onClick={() =>
                                      setDeleteItem({
                                        id: item.id,
                                        name: item.username,
                                      })
                                    }
                                  >
                                    Sil
                                  </button>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row align-items-center mt-3">
                      <div className="mb-0 col-md-12">
                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                          <ul className="pagination">
                            <li
                              className={`paginate_button page-item ${
                                !data.prev_page_url && "disabled"
                              }`}
                            >
                              <Link
                                to="#"
                                onClick={() =>
                                  data.prev_page_url &&
                                  handlePagination(
                                    data.prev_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                Geri
                              </Link>
                            </li>

                            {data.links.map(
                              (item, index) =>
                                !isNaN(parseInt(item.label)) && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      item.active ? "active" : ""
                                    }`}
                                  >
                                    <Link
                                      to="#"
                                      onClick={() =>
                                        handlePagination(item.label)
                                      }
                                      className="page-link"
                                    >
                                      {item.label}
                                    </Link>
                                  </li>
                                )
                            )}

                            <li
                              className={`paginate_button page-item ${
                                !data.next_page_url && "disabled"
                              }`}
                            >
                              <Link
                                to={"#"}
                                onClick={() =>
                                  data.next_page_url &&
                                  handlePagination(
                                    data.next_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                İleri
                              </Link>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <span>Müşteri Seçin</span>
          )}

          <hr />

          <h4 className="mb-4">Departmanlar</h4>

          {customerId ? (
            birimlerList ? (
              birimlerList.length !== 0 ? (
                <div className="row g-3">
                  {birimlerList.map((item, index) => (
                    <div key={index} className="col-12">
                      <Card>
                        <CardBody>
                          <div className="d-flex align-items-center justify-content-between">
                            <span className="flex-1 d-block h6 mb-0 me-3">
                              {item.name}
                            </span>

                            <div className="d-flex gap-2">
                              {item.users.length !== 0 && (
                                <button
                                  type="button"
                                  className="btn btn-outline-theme"
                                  onClick={() =>
                                    setShowUsersBirimler((prevIndex) =>
                                      prevIndex === index ? null : index
                                    )
                                  }
                                >
                                  Kişileri{" "}
                                  {showUsersBirimler === index
                                    ? "Gizle"
                                    : "Göster"}
                                </button>
                              )}

                              <button
                                type="button"
                                className="btn btn-outline-theme"
                                data-bs-toggle="modal"
                                data-bs-target="#birimEkleModal"
                                onClick={() => setBirimlerUpdateItem(item)}
                              >
                                Düzenle
                              </button>

                              <button
                                type="button"
                                className="btn btn-danger"
                                data-bs-toggle="modal"
                                data-bs-target="#birimlerDeleteConfirm"
                                onClick={() => setBirimlerDeleteItem(item)}
                              >
                                Kaldır
                              </button>
                            </div>
                          </div>

                          {showUsersBirimler === index &&
                            item.users.length !== 0 && (
                              <div className="table-responsive mt-3">
                                <table className="table border mb-0 align-middle">
                                  <thead>
                                    <th className="border-bottom p-2">
                                      Ad Soyad
                                    </th>
                                    <th className="border-bottom border-start p-2">
                                      Kullanıcı Adı
                                    </th>
                                    <th className="border-bottom border-start p-2">
                                      Rol
                                    </th>
                                    <th className="border-bottom border-start p-2">
                                      Email
                                    </th>
                                    <th className="border-bottom border-start p-2">
                                      Telefon
                                    </th>
                                  </thead>
                                  <tbody>
                                    {item.users.map(
                                      (itemUser, itemUserIndex) => (
                                        <tr key={itemUserIndex}>
                                          <td>{itemUser.name}</td>
                                          <td className="border-start">
                                            {itemUser.username}
                                          </td>
                                          <td className="border-start">
                                            {itemUser.role.name}
                                          </td>
                                          <td className="border-start">
                                            {itemUser.email}
                                          </td>
                                          <td className="border-start">
                                            {itemUser.phone}
                                          </td>
                                        </tr>
                                      )
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            )}
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </div>
              ) : (
                <span>Departman Eklenmemiştir.</span>
              )
            ) : (
              <div className="spinner-border text-theme"></div>
            )
          ) : (
            <span>Müşteri Seçin</span>
          )}
        </div>

        <div className="modal modal-cover fade" id="birimEkleModal">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  Departman {birimlerUpdateItem ? "Düzenle" : "Ekle"}
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="birimEkleModalCloseButton"
                ></button>
              </div>

              <div className="modal-body">
                <div className="mb-4">
                  <span className="mb-1 d-block">Departman Adı</span>
                  <input
                    type="text"
                    className="form-control"
                    value={birimAdiValue}
                    onChange={(e) => setBirimAdiValue(e.target.value)}
                  />
                </div>

                {birimlerUpdateItem && (
                  <div className="mb-4">
                    <span className="mb-1 d-block">Kullanıcılar</span>
                    {allUsersSelect ? (
                      <Select
                        options={allUsersSelect.map((item) => {
                          return {
                            value: item.id,
                            label: item.name,
                          };
                        })}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "Seçenek Yok"}
                        isMulti
                        value={birimUserValue}
                        onChange={(e) => setBirimUserValue(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>
                )}

                <button
                  type="submit"
                  className="btn btn-outline-theme w-100"
                  onClick={() => handleBirimEkle()}
                  disabled={birimLoad}
                >
                  {birimlerUpdateItem ? "Güncelle" : "Kaydet"}
                  {birimLoad && (
                    <div className="spinner-border spinner-border-sm text-theme"></div>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>

        <AddModal getData={getData} />

        <UpdateModal getData={getData} updateItem={updateItem} />

        <div className="modal modal-cover fade" id="deleteConfirmModal">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  Emin misiniz?
                  <br />
                  <span className="h5">
                    Kullanıcı Adı: {deleteItem && deleteItem.name}
                  </span>
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="deleteConfirmModalClose"
                ></button>
              </div>

              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={handleDelete}
                  >
                    Sil
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    İptal
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="modal modal-cover fade" id="birimlerDeleteConfirm">
          <div className="modal-dialog">
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">
                  Emin misiniz?
                  <br />
                  <span className="h5">
                    Departman: {birimlerDeleteItem?.name}
                  </span>
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="birimlerDeleteConfirmCloseButton"
                ></button>
              </div>

              {birimlerDeleteItem && (
                <div className="modal-body">
                  <div className="d-flex gap-3">
                    <button
                      type="button"
                      className="btn btn-outline-danger w-100"
                      onClick={handleBirimlerDelete}
                    >
                      Sil
                    </button>

                    <button
                      type="button"
                      className="btn btn-outline-theme w-100"
                      data-bs-dismiss="modal"
                    >
                      İptal
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <ToastContainer pauseOnFocusLoss={false} />
      </>
    )
  );
}

export default Kullanicilar;

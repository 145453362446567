import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import { Card, CardBody } from "../card/card";

export default function CardMedeniDurum({
  show = true,
  title,
  city,
  district,
  neighbourhood,
  type = 1,
  setSokakBinaCountData = false,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState();

  async function getData() {
    setData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Medeni Durum",
          type:
            type === 1
              ? "medeni-durum"
              : type === 2
              ? `bireysel-data-customer-${context?.me?.customer?.id}`
              : type === 3
              ? "hemsehrilik-durumu"
              : null,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column:
              type === 1
                ? [
                    {
                      key: "Alt_Kategori_2",
                      value: "select",
                    },
                    {
                      key: "Deger",
                      value: "select",
                    },
                  ]
                : type === 2
                ? [
                    {
                      key: "Adres Sokak",
                      value: "select",
                    },
                    {
                      key: "Bina NO",
                      value: "select",
                    },
                    {
                      key: "Daire",
                      value: "select",
                    },
                  ]
                : type === 3
                ? [
                    {
                      key: "SehIr",
                      value: "select",
                    },
                    {
                      key: "IlCe",
                      value: "select",
                    },
                    {
                      key: "DEĞERİ",
                      value: "sum",
                    },
                  ]
                : [],
            condition: [
              city
                ? {
                    key:
                      type === 1
                        ? "Sehir"
                        : type === 2
                        ? "Adres Il"
                        : type === 3
                        ? "SehIr"
                        : null,
                    value:
                      type === 1
                        ? city.toUpperCase()
                        : context.removeTurkishCharacters(city).toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood && type !== 3
                ? {
                    key:
                      type === 1
                        ? "Ilce"
                        : type === 2
                        ? "Adres Ilce"
                        : type === 3
                        ? "IlCe"
                        : null,
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhood && type !== 3
                ? {
                    key: type === 1 ? "Mahalle" : "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (type === 1) {
        if (response.length !== 0) {
          const order = ["EVLİ", "HİÇ EVLENMEDİ", "BOŞANDI", "EŞİ ÖLDÜ"];

          const result = response.reduce((acc, curr) => {
            const { alt_kategori_2, deger } = curr;
            if (!acc[alt_kategori_2]) {
              acc[alt_kategori_2] = 0;
            }
            acc[alt_kategori_2] += parseInt(deger, 10);
            return acc;
          }, {});

          const mergedResult = order.map((key) => ({
            alt_kategori_2: key,
            deger: result[key] || 0,
          }));

          setData(mergedResult);
        } else {
          setData([]);
        }
      }

      if (type === 2) {
        if (response.length !== 0) {
          const result = response.reduce((acc, item) => {
            const sokak = item["adres sokak"];
            const bina = item["bina no"];
            const daire = item["daire"];

            if (!acc[sokak]) {
              acc[sokak] = { binalar: new Set(), daireSayisi: 0 };
            }

            acc[sokak].binalar.add(bina);
            acc[sokak].daireSayisi += 1;

            return acc;
          }, {});

          const sokakBilgileri = Object.keys(result).map((sokak) => ({
            sokak,
            binaSayisi: result[sokak].binalar.size,
            daireSayisi: result[sokak].daireSayisi,
          }));

          const toplamlar = sokakBilgileri.reduce(
            (acc, sokak) => {
              acc.toplamBinaSayisi += sokak.binaSayisi;
              acc.toplamDaireSayisi += sokak.daireSayisi;
              return acc;
            },
            { toplamBinaSayisi: 0, toplamDaireSayisi: 0 }
          );

          setData({
            averageNumberOfBuildingsPerStreet: toplamlar.toplamBinaSayisi,
            averageNumberOfIndependentSectionsPerBuilding:
              toplamlar.toplamDaireSayisi,
          });
          if (setSokakBinaCountData) {
            setSokakBinaCountData({
              averageNumberOfBuildingsPerStreet: toplamlar.toplamBinaSayisi,
              averageNumberOfIndependentSectionsPerBuilding:
                toplamlar.toplamDaireSayisi,
            });
          }
        } else {
          setData([]);
          if (setSokakBinaCountData) {
            setSokakBinaCountData(null);
          }
        }
      }

      if (type === 3) {
        if (response.length !== 0) {
          setData(
            response
              .sort((a, b) => b["değeri̇_sum"] - a["değeri̇_sum"])
              .slice(0, 4)
          );
        } else {
          setData([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // if (city) {s
    getData();
    // }s
  }, [city, district, neighbourhood]);

  return (
    show && (
      <div className="col">
        <Card className="h-100">
          <CardBody>
            {data !== "filter" ? (
              data ? (
                data.length !== 0 ? (
                  <>
                    <div className="d-flex fw-bold h6 mb-4 text-center">
                      <span className="flex-grow-1">{title.toUpperCase()}</span>
                    </div>

                    <div className="row align-items-center text-center">
                      <div className={"col-12"}>
                        <div className={`lg text-inverse text-opacity-100`}>
                          {type === 1 ? (
                            <>
                              <div>
                                <strong className="h4">
                                  <CountUp end={data[0].deger || 0} />
                                </strong>{" "}
                                <br />
                                Evli Sayısı
                              </div>
                              <div className="mt-2">
                                <strong className="h4">
                                  <CountUp end={data[1].deger || 0} />
                                </strong>{" "}
                                <br />
                                Bekar
                              </div>
                              <div className="mt-2">
                                <strong className="h4">
                                  <CountUp end={data[2].deger || 0} />
                                </strong>{" "}
                                <br />
                                Boşanmış
                              </div>
                              <div className="mt-2">
                                <strong className="h4">
                                  <CountUp end={data[3].deger || 0} />
                                </strong>{" "}
                                <br />
                                Eşi Ölmüş
                              </div>
                            </>
                          ) : type === 2 ? (
                            <>
                              <div>
                                Sokak Başına Ortalama Bina Sayısı
                                <br />
                                <strong className="h4">
                                  <CountUp
                                    end={
                                      data.averageNumberOfBuildingsPerStreet ||
                                      0
                                    }
                                  />
                                </strong>{" "}
                              </div>
                              <div className="mt-2">
                                Bina Başına Ortalama Hane Sayısı
                                <br />
                                <strong className="h4">
                                  <CountUp
                                    end={
                                      data.averageNumberOfIndependentSectionsPerBuilding ||
                                      0
                                    }
                                  />
                                </strong>{" "}
                              </div>
                            </>
                          ) : (
                            type === 3 &&
                            data.map((item, index) => (
                              <div
                                key={index}
                                className={`${index > 0 && "mt-2"}`}
                              >
                                <strong className="h4">
                                  <CountUp end={item["değeri̇_sum"] || 0} />
                                </strong>
                                <br />
                                {item?.sehir || "-"} İlinin {item?.ilce || "-"}{" "}
                                İlçesi
                              </div>
                            ))
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    Analiz Verisi Eklenmemiştir
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">İl Seçmelisiniz</span>
            )}
          </CardBody>
        </Card>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}

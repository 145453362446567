import React, { useContext, useEffect, useState } from "react";

import { ToastContainer } from "react-toastify";
import CountUp from "react-countup";

import apiClient from "../../ApiClient";
import { AppSettings } from "../../config/app-settings";

import { Card, CardBody } from "../card/card";

export default function CardOlumSayisi({
  show = true,
  setNeighborhoodDeathBirthData = false,
  path = "home",
  city,
  district,
  neighbourhood,
  street,
  graphic,
  type = 1,
}) {
  const context = useContext(AppSettings);

  const [data, setData] = useState();

  async function getData(dataName) {
    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "Olum Sayısı",
          type: dataName,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Deger",
                value: "sum",
              },
            ],
            condition: [
              city
                ? {
                    key: "Sehir",
                    value:
                      dataName === "dogum-data"
                        ? context.removeTurkishCharacters(city).toUpperCase()
                        : city.toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              district
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(district)
                      .toUpperCase(),
                    operator: "=",
                    type: "and",
                  }
                : null,
              neighbourhood
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhood)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              street
                ? {
                    key: "Cadde_Sokak",
                    value: context
                      .removeTurkishCharacters(street)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      return response;
    } catch (error) {
      return null;
    }
  }

  useEffect(() => {
    async function fetchData() {
      setData(null);

      const results = await Promise.all([
        getData("olum-data"),
        getData("dogum-data"),
      ]);

      setData(results);

      if (setNeighborhoodDeathBirthData) {
        setNeighborhoodDeathBirthData(results);
      }
    }

    // if (city) {
    fetchData();
    // }
  }, [city, district, neighbourhood, street]);

  return (
    show && (
      <div className="col">
        <Card className="h-100">
          <CardBody>
            {data !== "filter" ? (
              data ? (
                data[0][0].deger_sum !== 0 && data[1][0].deger_sum !== 0 ? (
                  <>
                    <div className="d-flex fw-bold h6 text-center">
                      <span className="flex-grow-1">
                        {"Güncel".toUpperCase()}
                      </span>
                      {/* <CardExpandToggler /> */}
                    </div>

                    <div className="row align-items-center text-center">
                      <div className={"col-12"}>
                        <div className={`lg text-inverse text-opacity-100`}>
                          {type === 1 ? (
                            <>
                              <div>
                                Ölüm Sayısı
                                <br />
                                <strong className="h4">
                                  <CountUp end={data[0][0].deger_sum || 0} />
                                </strong>{" "}
                              </div>
                              <br />
                              <div>
                                Doğum Sayısı
                                <br />
                                <strong className="h4">
                                  <CountUp end={data[1][0].deger_sum || 0} />
                                </strong>{" "}
                              </div>
                            </>
                          ) : (
                            type === 2 && (
                              <div>
                                ILK DEFA OY KULLANACAK SECMEN SAYISI
                                <br />
                                <strong className="h4">
                                  {/* <CountUp end={data[0][1448] || 0} /> */}
                                </strong>{" "}
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <span className="d-block text-center">
                    Analiz Verisi Eklenmemiştir
                  </span>
                )
              ) : (
                <div className="spinner-border text-theme"></div>
              )
            ) : (
              <span className="d-block text-center">İl Seçmelisiniz</span>
            )}
          </CardBody>
        </Card>

        <ToastContainer pauseOnFocusLoss={false} />
      </div>
    )
  );
}

import React, { useRef, useState } from "react";

import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

import { Card, CardBody, CardExpandToggler } from "../card/card.jsx";
import bireyselJson from "../../json/bireysel.json";
import siyasetmetreAnalizJson from "../../json/siyasetmetre_analiz.json";
import siyasetmetreSecimJson from "../../json/siyasetmetre_secim.json";
import egitimJson from "../../json/egitim.json";
import { CSVLink } from "react-csv";
import { useEffect } from "react";
import apiClient from "../../ApiClient.js";
import PageChartCardNote from "./pageChartCardNote.jsx";
import { useNavigate } from "react-router-dom";
import toastMessage from "../ToastMessage.js";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

function PageChartCard({
  id,
  title,
  height = false,
  autoIncrement,
  chart,
  table = true,
  fullScreen = true,
  extraChart = true,
  note = true,
  setCardFullScreen,
  color = true,
  setChartColor,
  chartType = null,
  tableData = null,
  path = null,
  city = null,
  district = null,
  neighbourhood = null,
  street = null,
  graphic = null,
  category,
  parameterJson = null,
}) {
  const navigate = useNavigate();
  const [tableShow, setTableShow] = useState(false);
  const cardGetHeight = useRef(null);

  // Table
  function tableShowClick() {
    setTableShow(!tableShow);
  }

  useEffect(() => {
    if ($("#datatableDefault_deadth_" + id).length) {
      $("#datatableDefault_deadth_" + id).DataTable({
        dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center mt-3'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
        lengthMenu: [10, 20, 30, 40, 50],
        pageLength: 10,
        scrollX: true,
        scrollY: cardGetHeight.current.clientHeight - 260,
        deferRender: true,
        search: false,
        bDestroy: true,
        buttons: [
          {
            extend: "csv",
            className: "btn btn-outline-default btn-sm",
            title: "yillara_gore_olum_oranlari_tablo",
          },
        ],
        language: {
          info: "",
          infoEmpty: "",
          infoFiltered: "",
          lengthMenu: " _MENU_ ",
          zeroRecords: "Sonuç Bulunamadı",
          paginate: {
            next: "İleri",
            previous: "Geri",
          },
          search: "Arama:",
        },
      });
    }

    if ($("#datatableDefault_birth_" + id).length) {
      $("#datatableDefault_birth_" + id).DataTable({
        dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center mt-3'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
        lengthMenu: [10, 20, 30, 40, 50],
        pageLength: 10,
        scrollX: true,
        scrollY: cardGetHeight.current.clientHeight - 260,
        deferRender: true,
        search: false,
        bDestroy: true,
        buttons: [
          {
            extend: "csv",
            className: "btn btn-outline-default btn-sm",
            title: "yillara_gore_dogum_oranlari_tablo",
          },
        ],
        language: {
          info: "",
          infoEmpty: "",
          infoFiltered: "",
          lengthMenu: " _MENU_ ",
          zeroRecords: "Sonuç Bulunamadı",
          paginate: {
            next: "İleri",
            previous: "Geri",
          },
          search: "Arama:",
        },
      });
    }

    if ($("#datatableDefault_" + id).length) {
      $("#datatableDefault_" + id).DataTable({
        dom: "<'row mb-3'<'col-md-4 mb-3 mb-md-0'l><'col-md-8 text-end'<'d-lg-flex justify-content-end'f<'d-lg-block d-none'B>>>>t<'row align-items-center mt-3'<'mr-auto col-md-6 mb-3 mb-md-0 mt-n2 'i><'mb-0 col-md-6'p>>",
        lengthMenu: [10, 20, 30, 40, 50],
        pageLength: 10,
        scrollX: true,
        scrollY: cardGetHeight.current.clientHeight - 260,
        deferRender: true,
        search: false,
        bDestroy: true,
        buttons: [
          {
            extend: "csv",
            className: "btn btn-outline-default btn-sm",
            title: title + "_tablo",
          },
        ],
        language: {
          info: "",
          infoEmpty: "",
          infoFiltered: "",
          lengthMenu: " _MENU_ ",
          zeroRecords: "Sonuç Bulunamadı",
          paginate: {
            next: "İleri",
            previous: "Geri",
          },
          search: "Arama:",
        },
      });
    }
  }, [tableShow, cardGetHeight]);

  // Note
  const [noteData, setNoteData] = useState(null);

  async function getNote() {
    setNoteData(null);

    if (path) {
      try {
        const response = await apiClient.customGet("/notes", {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: {
            type:
              localStorage.getItem("user") &&
              JSON.parse(localStorage.getItem("user")).role.id === 1
                ? "public"
                : "private",
            page: path,
            graphic: graphic,
            city: city,
            district: district,
            neighbourhood: neighbourhood,
            street: street,
          },
        });
        if (response) {
          setNoteData(response);
        }
      } catch (error) {
        console.error(error);
        setNoteData([]);
        if (error.response.status === 401) {
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    } else {
      setNoteData([]);
    }
  }

  // Public Not
  const [publicNoteData, setPublicNoteData] = useState(null);

  async function getPublicNote(
    path,
    city,
    district,
    neighbourhood,
    street,
    graphic
  ) {
    setPublicNoteData(null);

    try {
      const response = await apiClient.customGet("/notes", {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        params: {
          type: "public",
          page: path,
          graphic: graphic,
          city: city,
          district: district,
          neighbourhood: neighbourhood,
          street: street,
        },
      });

      if (response) {
        setPublicNoteData(response);
      }
    } catch (error) {
      console.error(error);
      setPublicNoteData([]);
      if (error.response.status === 401) {
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }
    }
  }

  // useEffect(() => {
  //   getPublicNote(path, city, district, neighbourhood, street, graphic);
  // }, [path, city, district, neighbourhood, street, graphic, noteData]);

  function removeHTMLTags(inputString) {
    var doc = new DOMParser().parseFromString(inputString, "text/html");
    return doc.body.textContent || "";
  }

  const utterance = new SpeechSynthesisUtterance();

  function readPublicNote(text) {
    window.speechSynthesis.cancel();
    setTimeout(() => {
      utterance.text = text;
      utterance.lang = "tr-TR";
      utterance.rate = 0.8;
      utterance.pitch = 0.8;
      window.speechSynthesis.speak(utterance);
      toastMessage(
        title + " - ANALİZ NOTU, KAPATMAK İÇİN BAŞKA BİR ALANA TIKLAYIN.",
        "info"
      );
    }, 500);
  }

  let listenItem = document.querySelector(".listen-note");
  let listenNotes = document.querySelectorAll(".listen-note");

  React.useEffect(() => {
    if (listenItem) {
      listenNotes.forEach(function (note) {
        note.addEventListener("click", function () {
          // this.classList.add("active");

          listenNotes.forEach(function (otherNote) {
            if (otherNote !== note) {
              // otherNote.classList.remove("active");
              window.speechSynthesis.cancel();
            }
          });
        });
      });

      document.addEventListener("click", function (event) {
        const target = event.target;

        if (!listenItem.contains(target)) {
          window.speechSynthesis.cancel();

          listenNotes.forEach(function (otherNote) {
            // otherNote.classList.remove("active");
          });
        }
      });
    }
  }, [listenNotes, listenItem]);

  return (
    <>
      <Card className={height === "auto" ? "h-100" : ""}>
        <CardBody
          style={{
            height: height === "auto" ? "100%" : height ? "100vh" : "800px",
          }}
          className="d-flex flex-column"
        >
          <div
            ref={cardGetHeight}
            className="pe-none position-absolute start-0 top-0 w-100 h-100"
          ></div>

          <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
            <span
              className={`fw-bold h6 mb-0 d-flex align-items-center ${
                !fullScreen && "justify-content-center text-center flex-1"
              }`}
            >
              {title.toUpperCase()}
            </span>

            <div className="d-flex align-items-center">
              {table && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-theme btn-sm"
                    onClick={tableShowClick}
                  >
                    {tableShow ? "Grafik Göster" : "Tablo Göster"}
                  </button>

                  <span
                    className="d-block border-start mx-2"
                    style={{ height: 20 }}
                  ></span>
                </>
              )}

              {/* {note && (
                <>
                  <button
                    type="button"
                    className="btn btn-outline-theme btn-sm"
                    data-bs-toggle="modal"
                    data-bs-target={"#modalCoverExample_" + id}
                    onClick={() => getNote()}
                  >
                    Not Ekle
                  </button>

                  {publicNoteData &&
                    publicNoteData.length !== 0 &&
                    publicNoteData[0]?.note !== null && (
                      <span
                        className="d-block pointer ms-2 listen-note"
                        onClick={() =>
                          readPublicNote(
                            removeHTMLTags(publicNoteData[0]?.note)
                          )
                        }
                      >
                        <i className="fas fa-volume-up"></i>
                        <i className="fas fa-volume-off"></i>
                        <span>Analiz Et</span>
                      </span>
                    )}

                  <span
                    className="d-block border-start mx-2"
                    style={{ height: 20 }}
                  ></span>
                </>
              )} */}

              {fullScreen && (
                <CardExpandToggler setCardFullScreen={setCardFullScreen} />
              )}
            </div>
          </div>

          <div className="position-relative overflow-hidden flex-1">
            {tableShow ? (
              tableData ? (
                <div className="flex-1 h-100">
                  {graphic === 12 && path === "home" ? (
                    <div className="d-flex flex-column h-100">
                      <span className="d-block mb-2 border-bottom pb-1">
                        Doğum Oranları
                      </span>
                      <div className="flex-1 overflow-hidden">
                        {tableData?.birthCount ? (
                          <table
                            className="table w-100 h-100"
                            id={"datatableDefault_birth_" + id}
                          >
                            <thead>
                              <tr>
                                {Object.entries(tableData?.birthCount).map(
                                  ([year, count]) => (
                                    <th style={{ minWidth: 100 }}>{year}</th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {Object.entries(tableData?.birthCount).map(
                                  ([year, count]) => (
                                    <td key={count} style={{ minWidth: 100 }}>
                                      {Intl.NumberFormat("tr-TR", {
                                        style: "decimal",
                                      }).format(count)}
                                    </td>
                                  )
                                )}
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <span className="d-block text-center">
                            Analiz Verisi Eklenmemiştir
                          </span>
                        )}
                      </div>
                      <hr />
                      <span className="d-block mb-2 border-bottom pb-1">
                        Ölüm Oranları
                      </span>
                      <div className="flex-1 overflow-hidden">
                        {tableData?.DeathCount ? (
                          <table
                            className="table w-100 h-100"
                            id={"datatableDefault_deadth_" + id}
                          >
                            <thead>
                              <tr>
                                {Object.entries(tableData?.DeathCount).map(
                                  ([year, count]) => (
                                    <th style={{ minWidth: 100 }}>{year}</th>
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {Object.entries(tableData?.DeathCount).map(
                                  ([year, count]) => (
                                    <td key={count} style={{ minWidth: 100 }}>
                                      {Intl.NumberFormat("tr-TR", {
                                        style: "decimal",
                                      }).format(count)}
                                    </td>
                                  )
                                )}
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <span className="d-block text-center">
                            Analiz Verisi Eklenmemiştir
                          </span>
                        )}
                      </div>
                    </div>
                  ) : path === "home" && graphic === 10 ? (
                    <div>
                      {tableData.length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {Object.keys(tableData[0]).map(
                                (column, index) => (
                                  <th key={index}>
                                    {column === "city"
                                      ? "Şehir"
                                      : column === "district"
                                      ? "İlçe"
                                      : column === "citizenshipStatus"
                                      ? "Bireylık Durumu"
                                      : column}
                                  </th>
                                )
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData.map((item, rowIndex) => (
                              <tr key={rowIndex}>
                                {Object.keys(tableData[0]).map(
                                  (column, colIndex) =>
                                    path === "home" && graphic === 10 ? (
                                      colIndex === 0 ? (
                                        <td key={colIndex}>
                                          {
                                            siyasetmetreAnalizJson
                                              .alt_kategori_2[
                                              parseInt(item[column])
                                            ]
                                          }
                                        </td>
                                      ) : colIndex === 1 ? (
                                        <td key={colIndex}>
                                          {
                                            siyasetmetreAnalizJson.parametre[
                                              parseInt(item[column])
                                            ]
                                          }
                                        </td>
                                      ) : (
                                        <td key={colIndex}>
                                          {Intl.NumberFormat("tr-TR", {
                                            style: "decimal",
                                          }).format(item[column])}
                                        </td>
                                      )
                                    ) : (
                                      <td key={colIndex}>{item[column]}</td>
                                    )
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : path === "choice-analysis" && graphic === 12 ? (
                    <div>
                      {tableData.length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {tableData.map((item, index) => {
                                return (
                                  <th>
                                    {bireyselJson.Siyasal_Kimlik_1[
                                      parseInt(
                                        item["siyasal-kimlik"].split(" - ")[0]
                                      )
                                    ] +
                                      " - " +
                                      bireyselJson.Siyasal_Kimlik_2[
                                        parseInt(
                                          item["siyasal-kimlik"].split(" - ")[1]
                                        )
                                      ]}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {tableData.map((item, index) => {
                                return (
                                  <td style={{ minWidth: 200 }}>
                                    %{item.yuzde}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : path === "choice-analysis" && graphic === 13 ? (
                    <div>
                      {tableData.length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {tableData
                                .map((row) => [row[2], row[1], row[0]])[0]
                                .map((header, index) => (
                                  <th key={index}>
                                    {header === "Country"
                                      ? "Parti"
                                      : header === "Year"
                                      ? "Yıl"
                                      : header === "Income"
                                      ? "Oy"
                                      : ""}
                                  </th>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData
                              .map((row) => [row[2], row[1], row[0]])
                              .slice(1)
                              .map((row, rowIndex) => (
                                <tr key={rowIndex}>
                                  {row.map((cell, cellIndex) => (
                                    <td key={cellIndex}>{cell}</td>
                                  ))}
                                </tr>
                              ))}
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : path === "district-analysis" ? (
                    <div>
                      {tableData.length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {tableData.map((item, index) => {
                                return (
                                  <th key={index}>
                                    {
                                      egitimJson[category][
                                        parseInt(item["name"])
                                      ]
                                    }
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {tableData.map((item, index) => {
                                return (
                                  <td style={{ minWidth: 150 }} key={index}>
                                    {item.percentage}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : chartType === "pie-full" ? (
                    <div>
                      {tableData.length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {tableData.map((item, index) => {
                                const key = Object.keys(item)[0];

                                return (
                                  <th>
                                    {key === "difference"
                                      ? "TAHMİNİ HERHANGİ BİR ÜYE YAKINI OLMAYAN"
                                      : siyasetmetreAnalizJson.parametre[
                                          parseInt(key)
                                        ]}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {tableData.map((item, index) => {
                                const key = Object.keys(item)[0];
                                const value = item[key];

                                return (
                                  <td style={{ minWidth: 200 }}>
                                    {Intl.NumberFormat("tr-TR", {
                                      style: "decimal",
                                    }).format(value)}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : chartType === "pie" ? (
                    <div>
                      {Array.isArray(tableData) ? (
                        tableData.length !== 0 ? (
                          <table
                            className="table w-100 h-100"
                            id={"datatableDefault_" + id}
                          >
                            <thead>
                              <tr>
                                {tableData.map((item, rowIndex) =>
                                  Object.keys(tableData[0]).map(
                                    (column, colIndex) => (
                                      <th
                                        key={colIndex}
                                        style={{ minWidth: 200 }}
                                      >
                                        {
                                          siyasetmetreAnalizJson.parametre[
                                            parseInt(column)
                                          ]
                                        }
                                      </th>
                                    )
                                  )
                                )}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {tableData.map((item, index) => {
                                  const id = Object.keys(item)[0];
                                  const value = item[id];

                                  return <td>{value}</td>;
                                })}
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <span className="d-block text-center">
                            Analiz Verisi Eklenmemiştir
                          </span>
                        )
                      ) : [tableData].length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {Object.keys(tableData).map((key, index) => {
                                return (
                                  <th style={{ minWidth: 100 }}>
                                    {key
                                      ? parameterJson === "secmen-profili"
                                        ? bireyselJson.Secmen_Profili[
                                            parseInt(key)
                                          ]
                                        : siyasetmetreSecimJson.parametre[
                                            parseInt(key)
                                          ]
                                      : "Bilinmiyor"}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {Object.keys(tableData).map((key, index) => {
                                const value = tableData[key];
                                return (
                                  <td>
                                    {parameterJson === "secmen-profili"
                                      ? Intl.NumberFormat("tr-TR", {
                                          style: "decimal",
                                        }).format(parseFloat([value]))
                                      : parseFloat([value]).toFixed(2)}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : chartType === "timeline" ? (
                    <div>
                      {tableData[0].length !== 0 ? (
                        <table
                          className="table w-100 h-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              <th>YIL</th>
                              {tableData[0].map((item, index) => (
                                <th style={{ minWidth: 150 }} key={index}>
                                  {item}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody>
                            {tableData[1].map((item, index) => (
                              <tr key={index}>
                                <td>{item.title.text.split(" ")[0]}</td>
                                {item.series[0].data.map((value, i) => (
                                  <td key={i}>
                                    {Intl.NumberFormat("tr-TR", {
                                      style: "decimal",
                                    }).format(value)}
                                  </td>
                                ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  ) : (
                    <div>
                      {Array.isArray(tableData) ? (
                        tableData.length !== 0 ? (
                          <table
                            className="table w-100"
                            id={"datatableDefault_" + id}
                          >
                            <thead>
                              <tr>
                                {tableData.map((item) => {
                                  for (const key in item) {
                                    return (
                                      <th key={key} style={{ minWidth: 150 }}>
                                        {siyasetmetreAnalizJson.parametre[key]}
                                      </th>
                                    );
                                  }
                                  return null;
                                })}
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                {tableData.map((item) => {
                                  for (const key in item) {
                                    return (
                                      <td key={key}>
                                        {Intl.NumberFormat("tr-TR", {
                                          style: "decimal",
                                        }).format(item[key])}
                                      </td>
                                    );
                                  }
                                  return null;
                                })}
                              </tr>
                            </tbody>
                          </table>
                        ) : (
                          <span className="d-block text-center">
                            Analiz Verisi Eklenmemiştir
                          </span>
                        )
                      ) : [tableData].length !== 0 ? (
                        <table
                          className="table w-100"
                          id={"datatableDefault_" + id}
                        >
                          <thead>
                            <tr>
                              {Object.keys(tableData).map((key, index) => {
                                return (
                                  <th style={{ minWidth: 200 }}>
                                    {key
                                      ? siyasetmetreAnalizJson.parametre[
                                          parseInt(key)
                                        ]
                                      : "Bilinmiyor"}
                                  </th>
                                );
                              })}
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              {Object.keys(tableData).map((key, index) => {
                                const value = tableData[key];
                                return (
                                  <td>
                                    {Intl.NumberFormat("tr-TR", {
                                      style: "decimal",
                                    }).format([value])}
                                  </td>
                                );
                              })}
                            </tr>
                          </tbody>
                        </table>
                      ) : (
                        <span className="d-block text-center">
                          Analiz Verisi Eklenmemiştir
                        </span>
                      )}
                    </div>
                  )}
                </div>
              ) : (
                <div className="spinner-border text-theme my-2"></div>
              )
            ) : (
              <div className="position-absolute top-0 start-0 w-100 h-100">
                {chart}

                {/* {chartSwitch ? (
                  chart
                ) : (
                  <ReactECharts option={option} className="w-100 h-100" />
                )} */}
              </div>
            )}
          </div>
        </CardBody>
      </Card>

      {/* <PageChartCardNote
        noteData={noteData}
        getNote={getNote}
        id={id}
        path={path}
        city={city}
        district={district}
        neighbourhood={neighbourhood}
        street={street}
        graphic={graphic}
      /> */}
    </>
  );
}

export default PageChartCard;

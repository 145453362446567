import React, { useContext, useEffect, useState } from "react";

import { CardExpandToggler } from "../../../components/card/card.jsx";
import { Link, useNavigate } from "react-router-dom";
import apiClient from "../../../ApiClient.js";
import toastMessage from "../../../components/ToastMessage.js";
import { AppSettings } from "../../../config/app-settings.js";
import { ToastContainer } from "react-toastify";

function SecimAnaliziTable2({
  show = true,
  cityFilter,
  districtFilter,
  neighbourhoodFilter,
  streetFilter,
  setSecimAnaliziSandikNoModalData,
  setStreetChestData = false,
}) {
  const navigate = useNavigate();
  const context = useContext(AppSettings);

  const [currentPage, setCurrentPage] = useState(1);
  const [table2Data, setTable2Data] = useState(false);

  async function getTable2Data() {
    setTable2Data(null);

    const postOptions = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: "sokak-ve-sandıga-gore-secmen-sayısı",
      description: "",
      customer: null,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Sehir",
            value: "select",
          },
          {
            key: "Ilce",
            value: "select",
          },
          {
            key: "Mahalle",
            value: "select",
          },
          {
            key: "Cadde_Sokak",
            value: "select",
          },
          {
            key: "Alt_Kategori_2",
            value: "select",
          },
          {
            key: "Deger",
            value: "select",
          },
        ],
        condition: [
          cityFilter
            ? {
                key: "Sehir",
                value: context
                  .removeTurkishCharacters(cityFilter.label)
                  .toUpperCase(),
                operator: "like",
                type: "and",
              }
            : null,
          districtFilter
            ? {
                key: "Ilce",
                value: districtFilter.label.toUpperCase(),
                operator: "like",
                type: "and",
              }
            : null,
          neighbourhoodFilter
            ? {
                key: "Mahalle",
                value: neighbourhoodFilter.label.toUpperCase(),
                operator: "like",
                type: "and",
              }
            : null,
          streetFilter
            ? {
                key: "Cadde_Sokak",
                value: streetFilter.label.toUpperCase(),
                operator: "like",
                type: "and",
              }
            : null,
        ].filter((item) => item !== null),
        group: [],
      },
    };

    if (!setStreetChestData) {
      postOptions.paginate = true;
    }

    try {
      const response = await apiClient.customPost(
        `/query/preview?page=${currentPage}`,
        postOptions,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      // function compareByChest(a, b) {
      //   return a.Alt_Kategori_2 - b.Alt_Kategori_2;
      // }

      // response.data.sort(compareByChest);

      if (setStreetChestData) {
        if (response.length !== 0) {
          const reportTableWidth = ["*", "*", "*", "*", "*"];
          const reportTableValue = [
            ["İLÇE", "MAHALLE", "SOKAK", "SANDIK NO", "SECMEN SAYISI"],
          ];
          response.forEach((element) => {
            reportTableValue.push([
              element.ilce,
              element.mahalle,
              element.cadde_sokak,
              element.alt_kategori_2,
              element.deger,
            ]);
          });
          setStreetChestData([reportTableWidth, reportTableValue]);
        } else {
          setStreetChestData([[], [], []]);
        }
      }

      if (response.length !== 0) {
        setTable2Data(response);
      } else {
        setTable2Data([]);
      }
    } catch (error) {
      console.error("Genel Data Error", error);
      setTable2Data(null);
    }
  }

  useEffect(() => {
    if (show && cityFilter && districtFilter) {
      getTable2Data();
    }
    // eslint-disable-next-line
  }, [
    currentPage,
    cityFilter,
    districtFilter,
    neighbourhoodFilter,
    streetFilter,
    show,
  ]);

  useEffect(() => {
    if (setStreetChestData) {
      getTable2Data();
    }
    // eslint-disable-next-line
  }, [setStreetChestData]);

  // Pagination
  function handlePagination(value) {
    setCurrentPage(value);
  }

  // --

  return (
    <>
      {show && (
        <div className="d-flex flex-column h-100">
          <div className="d-flex align-items-center justify-content-between border-bottom pb-2 mb-3">
            <span className="fw-bold h6 mb-0 d-flex align-items-center">
              SOKAGA GORE SANDIK BASINA SECMEN SAYISI
            </span>

            <div className="d-flex align-items-center">
              <CardExpandToggler />
            </div>
          </div>

          <div className="dataTables_wrapper dt-bootstrap5 d-flex flex-column flex-1 overflow-hidden">
            {cityFilter && districtFilter ? (
              table2Data ? (
                table2Data.data.length !== 0 ? (
                  <>
                    <div className="dataTables_scroll flex-1 overflow-auto">
                      <div className="dataTables_scrollBody table-responsive">
                        <table className="table text-nowrap w-100 dataTable">
                          <thead>
                            <tr>
                              <th style={{ minWidth: 160 }}>İLÇE ADI</th>
                              <th style={{ minWidth: 160 }}>MAHALLE ADI</th>
                              <th style={{ minWidth: 160 }}>SOKAK ADI</th>
                              <th style={{ minWidth: 160 }}>SANDIK NO</th>
                              <th style={{ minWidth: 160 }}>SECMEN SAYISI</th>
                            </tr>
                          </thead>

                          <tbody>
                            {table2Data.data.map((item, index) => (
                              <tr key={index}>
                                <td>{item?.ilce || "Bilinmiyor"}</td>
                                <td>{item?.mahalle || "Bilinmiyor"}</td>
                                <td>{item?.cadde_sokak || "Bilinmiyor"}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-outline-theme"
                                    data-bs-toggle="modal"
                                    data-bs-target="#secimAnaliziSandikNoModal"
                                    onClick={() =>
                                      setSecimAnaliziSandikNoModalData &&
                                      setSecimAnaliziSandikNoModalData(item)
                                    }
                                  >
                                    {item?.alt_kategori_2 || "Bilinmiyor"}
                                  </button>
                                </td>
                                <td>{item?.deger || "Bilinmiyor"}</td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>

                    <div className="row align-items-center my-3">
                      <div className="mb-0 col-md-12">
                        <div className="dataTables_paginate paging_simple_numbers d-flex justify-content-center text-center">
                          <ul className="pagination">
                            <li
                              className={`paginate_button page-item ${
                                !table2Data.prev_page_url && "disabled"
                              }`}
                            >
                              <div
                                onClick={() =>
                                  table2Data.prev_page_url &&
                                  handlePagination(
                                    table2Data.prev_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                Geri
                              </div>
                            </li>

                            {table2Data.links.map(
                              (item, index) =>
                                !isNaN(parseInt(item.label)) && (
                                  <li
                                    key={index}
                                    className={`paginate_button page-item ${
                                      item.active ? "active" : ""
                                    }`}
                                  >
                                    <div
                                      onClick={() =>
                                        handlePagination(item.label)
                                      }
                                      className="page-link"
                                    >
                                      {item.label}
                                    </div>
                                  </li>
                                )
                            )}

                            <li
                              className={`paginate_button page-item ${
                                !table2Data.next_page_url && "disabled"
                              }`}
                            >
                              <div
                                onClick={() =>
                                  table2Data.next_page_url &&
                                  handlePagination(
                                    table2Data.next_page_url.split("?page=")[1]
                                  )
                                }
                                className="page-link"
                              >
                                İleri
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </>
                ) : (
                  <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                    Analiz Verisi Eklenmemiştir
                  </div>
                )
              ) : (
                <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                  <div className="spinner-border text-theme"></div>
                </div>
              )
            ) : (
              <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                İl ve İlçe Seçmelisiniz
              </div>
            )}
          </div>
        </div>
      )}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

export default SecimAnaliziTable2;

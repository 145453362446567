import React, { useEffect, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";

import apiClient from "../../ApiClient.js";
import { AppSettings } from "../../config/app-settings.js";

import TaskItem from "../../components/TaskItem.jsx";
import toastMessage from "../../components/ToastMessage.js";
import { ToastContainer } from "react-toastify";

export default function Todo({ setTodoEditData, todoData, getTodoData }) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Error Messages
  function errorMessages(error) {
    if (error.response.status === 422) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }

    if (error.response.status === 401) {
      context.setMe(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/giris-yap");
    }

    if (error.response.status === 403) {
      alert("Bir süre sonra tekrar deneyin.");
      setTimeout(() => {
        context.setMe(null);
        localStorage.removeItem("token");
        localStorage.removeItem("user");
        navigate("/giris-yap");
      }, 1000);
    }

    if (error.response.status === 500) {
      alert("Sunucu Hatası!");
    }
  }

  // Todo Edit
  async function handleTodoEdit(todo) {
    setTodoEditData(todo);
  }

  // Todo Delete
  async function handleTodoDelete(id) {
    try {
      const response = await apiClient.delete("/todos/" + id, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        toastMessage("Görev Silindi", "success");
        setTodoEditData(true);
        getTodoData();
      }
    } catch (error) {
      console.error("Görev Silindi Error", error);
      errorMessages(error);
    }
  }

  //--

  const [userSelectData, setUserSelectData] = useState([]);

  async function handleCustomerSelect(id) {
    if (id) {
      try {
        const response = await apiClient.get(`user?customer_id=${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUserSelectData(response.data);
      } catch (error) {
        setUserSelectData([]);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  //--

  useEffect(() => {
    getTodoData();
    handleCustomerSelect(context?.me?.customer?.id);
    // eslint-disable-next-line
  }, [context]);

  return (
    <>
      {todoData ? (
        todoData.length !== 0 ? (
          todoData.map(
            (item, index) =>
              item.category && (
                <div key={index + "_todoitem"}>
                  {item.category.name === "SİZE ATANANLAR" ? (
                    <>
                      {(context?.me?.role?.id === 1 ||
                        context?.me?.role?.id === 2) && (
                        <div className="border-top pt-3 mt-2 mb-3">
                          <Select
                            options={userSelectData
                              .filter((item) => item.role.id === 5)
                              .map((item) => {
                                return { value: item.id, label: item.name };
                              })}
                            placeholder="Kullanıcı Seç"
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "Seçenek Yok"}
                          />

                          <span className="d-block mt-2">Seçilen Kullanıcı Adı</span>
                        </div>
                      )}
                      <TaskItem
                        item={item}
                        index={index}
                        todoDataLength={todoData.length}
                        handleTodoEdit={handleTodoEdit}
                        handleTodoDelete={handleTodoDelete}
                        getTodoData={getTodoData}
                      />
                    </>
                  ) : (
                    <TaskItem
                      item={item}
                      index={index}
                      todoDataLength={todoData.length}
                      handleTodoEdit={handleTodoEdit}
                      handleTodoDelete={handleTodoDelete}
                      getTodoData={getTodoData}
                    />
                  )}
                </div>
              )
          )
        ) : (
          <span className="d-block text-center">
            Eklenmiş bir görev bulunmamaktadır
          </span>
        )
      ) : (
        <div className="spinner-border text-theme"></div>
      )}

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody } from "../../components/card/card";
import { AppSettings } from "../../config/app-settings";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";

const listData = [
  {
    category: "Zabıta Hizmetleri",
    title: "Kaldırım İşgali",
    answers: [
      {
        title: "Araç Parkı",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Esnaf / İşyeri",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Beton / Büyük Saksı",
        color: "unnecessary",
        type: "checkbox",
      },
      {
        title: "Yok",
        color: "danger",
      },
    ],
  },
];

export default function Profil() {
  const context = useContext(AppSettings);

  // Photo
  const [profileImage, setProfileImage] = useState(null);
  const [hover, setHover] = useState(false);

  useEffect(() => {
    if (context?.me) {
      setProfileImage(context?.me?.settings?.image || null);
    }
  }, [context]);

  const handleImageChange = async (e) => {
    const file = e.target.files[0];

    // Sadece resim dosyalarına izin ver
    if (file && !file.type.startsWith("image/")) {
      toastMessage("Lütfen bir resim dosyası seçin.", "error");
      return;
    }

    // Maksimum dosya boyutu 5MB
    if (file && file.size > 5 * 1024 * 1024) {
      toastMessage("Dosya boyutu en fazla 10MB olabilir.", "error");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await apiClient.post("/upload", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      if (response) {
        setProfileImage(response.data.url);
        UserUpdate(response.data.url);
      }
    } catch (error) {
      console.error("Upload File Error", error);
      setProfileImage(null);
    }
  };

  async function UserUpdate(img) {
    try {
      const response = await apiClient.put(
        `/user/${context?.me?.id}`,
        {
          settings: {
            title: context?.me?.settings?.title || "",
            image: img,
            kvkk_confirm_date: context?.me?.settings?.kvkk_confirm_date || null,
          },
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response) {
        toastMessage("Resim Güncellendi", "success");
      }
    } catch (error) {
      console.error("User Update Error", error);
    }
  }

  const getInitials = () => {
    if (context?.me?.name) {
      const nameParts = context.me.name.split(" ");
      const initials = nameParts[0][0] + nameParts[nameParts.length - 1][0];
      return initials.toUpperCase();
    }
    return "";
  };

  // Dogum Gunleri
  async function fetchBireysel() {
    const option = {
      chart: 1,
      page_id: 23,
      title: "-",
      type: `bireysel-data-customer-${context?.me?.customer?.id}`,
      description: "",
      customer: null,
      paginate: true,
      status: true,
      order: 1,
      sql: {
        column: [
          {
            key: "Adres Il",
            value: "select",
          },
          {
            key: "Adres Ilce",
            value: "select",
          },
          {
            key: "Adres Mahalle",
            value: "select",
          },
          {
            key: "Ad Soyad",
            value: "select",
          },
          {
            key: "Cinsiyet",
            value: "select",
          },
          {
            key: "Dogum Tarihi",
            value: "select",
          },
          {
            key: "Telefon NO",
            value: "select",
          },
        ],
        condition: [],
        group: [],
      },
    };

    try {
      const response = await apiClient.customPost(`/query/preview`, option, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      console.log(response);
    } catch (error) {
      console.error(error);
    }
  }

  // useEffect(() => {
  //   if (
  //     context?.me?.role?.id === 1 ||
  //     context?.me?.role?.id === 2 ||
  //     context?.me?.role?.id === 4
  //   ) {
  //     fetchBireysel();
  //   }
  // }, [context]);

  return (
    <Card>
      <CardBody>
        <div className="row">
          <div className="col-12 col-md-2 border-end">
            <div
              className="d-flex align-items-center justify-content-center mb-3 profil-photo-item"
              style={{
                position: "relative",
                maxWidth: "150px",
                height: "150px",
                borderRadius: "50%",
                overflow: "hidden",
                border: "2px solid #ddd",
                cursor: "pointer",
              }}
              onMouseEnter={() => setHover(true)}
              onMouseLeave={() => setHover(false)}
            >
              {profileImage ? (
                <img
                  src={profileImage}
                  alt="Profile"
                  style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
              ) : (
                <h1 className="text-white mb-0">
                  <span>{getInitials()}</span>
                </h1>
              )}
              <div
                className="profil-photo-item-upload"
                style={{
                  position: "absolute",
                  bottom: "0",
                  left: "0",
                  top: 0,
                  width: "100%",
                  backgroundColor: "rgba(0, 0, 0, .9)",
                  color: "#fff",
                  textAlign: "center",
                  padding: "5px",
                  fontWeight: "bold",
                }}
              >
                <label
                  htmlFor="profile-image-upload"
                  style={{ cursor: "pointer" }}
                  className="w-100 h-100 d-flex align-items-center justify-content-center"
                >
                  Resim <br /> Yükle
                </label>
                <input
                  id="profile-image-upload"
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={(e) => handleImageChange(e)}
                />
              </div>
            </div>

            <h4>{context?.me?.name}</h4>
            <strong>@{context?.me?.username}</strong>

            <hr />

            <ul className="ps-3 mt-3">
              <li className="mb-2">
                <span className="fs-12px">Müşteri:</span>
                <span className="d-block fw-bold">
                  {context?.me?.customer?.customer.name}
                </span>
              </li>
              <li className="mb-2">
                <span className="fs-12px">Yetkili İl:</span>
                <span className="d-block fw-bold">
                  {context?.me?.customer?.settings?.city.label || "Tümü"}
                </span>
              </li>
              <li className="mb-2">
                <span className="fs-12px">Yetkili İlçe:</span>
                <span className="d-block fw-bold">
                  {context?.me?.customer?.settings?.city.district || "Tümü"}
                </span>
              </li>
            </ul>

            <hr />

            <ul className="ps-3 mt-3">
              <li className="mb-2">
                <span className="fs-12px">Ünvan:</span>
                <span className="d-block fw-bold">
                  {context?.me?.settings?.title}
                </span>
              </li>
              <li className="mb-2">
                <span className="fs-12px">Yetkisi:</span>
                <span className="d-block fw-bold">
                  {context?.me?.role?.name}
                </span>
              </li>
              <li className="mb-2">
                <span className="fs-12px">Telefon:</span>
                <a
                  className="d-block fw-bold"
                  href={`tel:${context?.me?.phone}`}
                >
                  {context?.me?.phone}
                </a>
              </li>
              <li className="mb-2">
                <span className="fs-12px">Email:</span>
                <a
                  className="d-block fw-bold"
                  href={`mailto:${context?.me?.email}`}
                >
                  {context?.me?.email}
                </a>
              </li>
              <li>
                <span className="fs-12px">Adres:</span>
                <p className="d-block fw-bold">{context?.me?.address}</p>
              </li>
            </ul>
          </div>

          <div className="col">
            {/* {(context?.me?.role?.id === 1 ||
              context?.me?.role?.id === 2 ||
              context?.me?.role?.id === 4) && (
              <>
                <h3>Doğum Günü Olanlar</h3>

                <hr />

                <div className="mt-4">
                  <div className=""></div>
                </div>
              </>
            )} */}

            {(context?.me?.role?.id === 1 ||
              context?.me?.role?.id === 2 ||
              context?.me?.role?.id === 5) && (
              <>
                <h3>Size Atananlar</h3>

                <hr />

                <div className="mt-4">
                  <div className="d-flex align-items-center mb-3">
                    <h5 className="mb-0">Anket Adı</h5>

                    <ol className="breadcrumb border-start ps-2 ms-2 mb-0">
                      <li className="breadcrumb-item">İl</li>
                      <li className="breadcrumb-item">İlçe</li>
                      <li className="breadcrumb-item">Mahalle</li>
                      <li className="breadcrumb-item">Sokak</li>
                      <li className="breadcrumb-item">Bina</li>
                      <li className="breadcrumb-item">Daire</li>
                    </ol>
                  </div>

                  <Card>
                    <CardBody>
                      <div className="table-responsive">
                        <table className="table border align-middle">
                          <tbody>
                            {listData.map((item, index) => (
                              <tr key={index}>
                                <td className="py-2 text-center px-0 text-white">
                                  #{index + 1}
                                </td>
                                <td className="py-3 border-start">
                                  <span className="d-block">
                                    {item.category}
                                  </span>
                                  <span className="d-block h4 mt-1 mb-0">
                                    {item.title}
                                  </span>
                                </td>
                                <td className="py-2 border-start">
                                  {item.answers.length !== 0 ? (
                                    <div
                                      className="row g-1 overflow-hidden my-0"
                                      style={{ borderRadius: 8 }}
                                    >
                                      {item.answers.map(
                                        (answer, answerIndex) => (
                                          <div
                                            key={answerIndex}
                                            className="question-answer-button pe-none col p-0 my-0 d-flex flex-column justify-content-between"
                                          >
                                            <input
                                              hidden
                                              type={
                                                answer.type
                                                  ? "checkbox"
                                                  : "radio"
                                              }
                                              id={
                                                answer.type
                                                  ? `${index + 1}_${
                                                      answer.title
                                                    }`
                                                  : `${index + 1}_${
                                                      answer.color
                                                    }`
                                              }
                                              name={`${
                                                index + 1
                                              }_question_answer`}
                                              checked={
                                                answerIndex === 0 ? true : false
                                              }
                                              readOnly
                                            />
                                            <label
                                              htmlFor={
                                                answer.type
                                                  ? `${index + 1}_${
                                                      answer.title
                                                    }`
                                                  : `${index + 1}_${
                                                      answer.color
                                                    }`
                                              }
                                              className={`py-3 h-100 d-flex pointer align-items-center justify-content-center text-center text-uppercase fw-bold text-white bg-dark`}
                                              dangerouslySetInnerHTML={{
                                                __html: answer.title,
                                              }}
                                            />
                                          </div>
                                        )
                                      )}
                                    </div>
                                  ) : (
                                    <textarea
                                      type="text"
                                      className="form-control"
                                      placeholder="Cevabınızı Yazınız..."
                                      style={{ resize: "none", height: 80 }}
                                      value="5 kişiyiz"
                                      readOnly
                                    />
                                  )}
                                </td>
                                <td
                                  className="border-start"
                                  style={{ width: 200 }}
                                >
                                  <p className="mb-0">
                                    Açıklama: <br /> Deneme açıklama metni
                                  </p>
                                </td>
                                <td
                                  className="border-start"
                                  style={{ width: 100 }}
                                >
                                  <a
                                    href="http://www.golyapi.com.tr/upload/hizmetler/kaldirim_yapimi.jpg"
                                    target="_blank"
                                  >
                                    <img
                                      src="http://www.golyapi.com.tr/upload/hizmetler/kaldirim_yapimi.jpg"
                                      width={100}
                                      alt=""
                                    />
                                  </a>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </>
            )}
          </div>
        </div>
      </CardBody>
    </Card>
  );
}

import { useContext, useState } from "react";

import Select from "react-select";

import { NavItem } from "./nav-item.jsx";
import { AppSettings } from "../../config/app-settings.js";

function SidebarNav({ filteredMenu }) {
  const context = useContext(AppSettings);
  const [activeIndex, setActiveIndex] = useState(null);
  const [valueCustomer, setValueCustomer] = useState(null);

  const handleItemClick = (index) => {
    setActiveIndex(index === activeIndex ? null : index);
  };

  async function handleCustomerButton(e, id) {
    context.getPages(id);
    setValueCustomer(e);

    context.setActiveCustomer(id);
  }

  const [activeMenu, setActiveMenu] = useState(null);

  const toggleMenu = (menuId) => {
    setActiveMenu(activeMenu === menuId ? null : menuId);
  };

  return (
    <div className="menu">
      {context &&
        context?.me?.role?.id === 1 &&
        context.allCustomerSelect &&
        context.allCustomerSelect.length !== 0 && (
          <div>
            <div className="menu-header pt-0">Müşteriler</div>

            <div className="mx-3">
              <Select
                options={[
                  // {
                  //   value: null,
                  //   label: "Admin",
                  // },
                  ...context.allCustomerSelect
                    .sort((a, b) => a.value - b.value)
                    .map((customer) => {
                      return {
                        value: customer.value,
                        label: customer.label,
                      };
                    }),
                ]}
                placeholder="Müşteri Seçin"
                classNamePrefix="react-select"
                noOptionsMessage={() => "Seçenek Yok"}
                value={valueCustomer}
                onChange={(e) => handleCustomerButton(e, e.value)}
              />
            </div>
          </div>
        )}

      {!filteredMenu && (
        <div className="mx-3 mt-3">
          <div className="spinner-border spinner-border-sm text-theme"></div>
        </div>
      )}

      {context?.me && filteredMenu && filteredMenu.length !== 0 && (
        <>
          {context?.me?.role?.id !== 5 &&
            filteredMenu.filter((menu) => menu.page_type === "default")
              .length !== 0 && (
              <div
                className="d-flex align-items-center justify-content-between menu-header pointer"
                onClick={() => toggleMenu(1)}
              >
                Genel Görünüm
                <div className="menu-caret">
                  <b className="caret"></b>
                </div>
              </div>
            )}

          {activeMenu === 1 &&
            filteredMenu
              .filter(
                (menu) =>
                  menu.page_type === "default" &&
                  !menu.iframe &&
                  menu.name !== "Anket Oluştur" &&
                  menu.name !== "Yüklenen Dosyalar" &&
                  menu.name !== "Hazır Raporlar" &&
                  menu.name !== "Araştırma Raporu"
              )
              .sort((a, b) => a.order - b.order)
              .map((menu, i) => {
                if (
                  (menu.name === "Seçim Analizi" &&
                    context?.me?.customer?.settings?.secimAnalizi === false) ||
                  (menu.name === "Sandık Analizi" &&
                    context?.me?.customer?.settings?.sandikAnalizi === false)
                ) {
                  return null;
                }
                return (
                  <NavItem
                    key={i}
                    menu={menu}
                    isActive={i + "_default" === activeIndex}
                    onClick={() => handleItemClick(i + "_default")}
                  />
                );
              })}

          {context?.me &&
            (context?.me?.role?.id === 1 || context?.me?.role?.id === 2) && (
              <>
                <div
                  className="d-flex align-items-center justify-content-between menu-header pointer"
                  onClick={() => toggleMenu(2)}
                >
                  İleri Analiz
                  <div className="menu-caret">
                    <b className="caret"></b>
                  </div>
                </div>
                {activeMenu === 2 &&
                  (filteredMenu.filter(
                    (menu) => menu.page_type === "default" && menu.iframe
                  ).length !== 0 ? (
                    filteredMenu
                      .filter(
                        (menu) => menu.page_type === "default" && menu.iframe
                      )
                      .sort((a, b) => a.order - b.order)
                      .map((menu, i) => (
                        <NavItem
                          key={i}
                          menu={menu}
                          isActive={i + "_default_iframe" === activeIndex}
                          onClick={() => handleItemClick(i + "_default_iframe")}
                        />
                      ))
                  ) : (
                    <span className="px-3">Analiz İçin İletişime Geçin</span>
                  ))}
              </>
            )}

          {filteredMenu.filter((menu) => menu.page_type === "custom").length !==
            0 && (
            <div
              className="d-flex align-items-center justify-content-between menu-header pointer"
              onClick={() => toggleMenu(3)}
            >
              Özel Sayfalar
              <div className="menu-caret">
                <b className="caret"></b>
              </div>
            </div>
          )}

          {activeMenu === 3 &&
            filteredMenu
              .filter((menu) => menu.page_type === "custom")
              .sort((a, b) => a.order - b.order)
              .map((menu, i) => (
                <NavItem
                  key={i}
                  menu={menu}
                  isActive={i + "_custom" === activeIndex}
                  onClick={() => handleItemClick(i + "_custom")}
                />
              ))}

          {context?.me?.role?.id !== 3 &&
            filteredMenu.filter((menu) => menu.page_type === "default")
              .length !== 0 && (
              <div
                className="d-flex align-items-center justify-content-between menu-header pointer"
                onClick={() => toggleMenu(4)}
              >
                İşlemler
                <div className="menu-caret">
                  <b className="caret"></b>
                </div>
              </div>
            )}

          {activeMenu === 4 &&
            filteredMenu
              .filter(
                (menu) =>
                  menu.name === "Anket Oluştur" ||
                  menu.name === "Yüklenen Dosyalar" ||
                  menu.name === "Hazır Raporlar" ||
                  menu.name === "Araştırma Raporu"
              )
              .sort((a, b) => a.order - b.order)
              .map((menu, i) => (
                <NavItem
                  key={i}
                  menu={menu}
                  isActive={i + "_islemler_default" === activeIndex}
                  onClick={() => handleItemClick(i + "_islemler_default")}
                />
              ))}

          {activeMenu === 4 &&
            (context?.me?.role?.id === 1 ||
              context?.me?.role?.id === 2 ||
              context?.me?.role?.id === 4) && (
              <NavItem
                menu={{
                  name: "Kullanıcılar",
                  slug: "/kullanicilar",
                  icon: "fas fa-users",
                  order: 5,
                  type: "page",
                  page_type: "default",
                  children: [],
                }}
                isActive={5 + "_islemler_default" === activeIndex}
                onClick={() => handleItemClick(5 + "_islemler_default")}
              />
            )}

          {filteredMenu
            .filter((menu) => !menu.page_type)
            .sort((a, b) => a.order - b.order)
            .map((menu, i) => (
              <NavItem
                key={i}
                menu={menu}
                isActive={i + "_global" === activeIndex}
                onClick={() => handleItemClick(i + "_global")}
              />
            ))}
        </>
      )}
    </div>
  );
}

export default SidebarNav;

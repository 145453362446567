const Menu = [
  { is_header: true, title: "" },
  {
    path: "/",
    icon: "fas fa-home",
    title: "Durum Analizi",
  },
  {
    icon: "fas fa-balance-scale",
    path: "/secim-analizi",
    title: "Seçim Analizi",
  },
  {
    icon: "fas fa-archive",
    path: "/sandik-analizi",
    title: "Sandık Analizi",
  },
  {
    icon: "fas fa-signal",
    path: "/sandik-calismasi",
    title: "Sandık Çalışması",
  },
  {
    icon: "fas fa-street-view",
    path: "/sokak-analizi",
    title: "Sokak Analizi",
  },
  {
    icon: "fas fa-building",
    path: "/hane-ve-vatandas-analizi",
    title: "Hane ve Birey Analizi",
  },
  {
    path: "/talep-ve-hizmet-analizi",
    icon: "fas fa-chart-line",
    title: "Talep ve Hizmet Analizi",
  },
  {
    path: "/kentmetre-raporu",
    icon: "fas fa-file-alt",
    title: "Kentmetre Raporu",
    children: [
      { path: "/kentmetre-raporu/rapor-1", title: "Rapor 1" },
      { path: "/kentmetre-raporu/rapor-2", title: "Rapor 2" },
      { path: "/kentmetre-raporu/rapor-3", title: "Rapor 3" },
    ],
  },
  {
    path: "/iletisim-yonetimi",
    icon: "fas fa-phone",
    title: "İletişim Grubu Yönetimi",
  },
  {
    path: "/strateji-haritasi",
    icon: "fas fa-map",
    title: "Strateji Haritası",
  },
  {
    path: "/hazir-raporlar",
    icon: "fas fa-book",
    title: "Hazır Raporlar",
  },
  {
    path: "/is-zekasi",
    icon: "fas fa-cogs",
    title: "İş Zekası",
  },
  {
    path: "/sosyal-medya",
    icon: "fas fa-at",
    title: "Sosyal Medya",
  },
  {
    path: "/anket-ve-arastirmalar",
    icon: "fas fa-newspaper",
    title: "Anket ve Araştırmalar",
  },
  {
    path: "/ileri-raporlama",
    icon: "fas fa-chart-bar",
    title: "İleri Raporlama",
  },
  {
    path: "/hazir-raporlama",
    icon: "fas fa-chart-bar",
    title: "Hazır Raporlama",
  },
  {
    path: "/yuklenen-dosyalar",
    icon: "fas fa-file",
    title: "Yüklenen Dosyalar",
  },
  // { is_header: true, title: "T.C. Cumhurbaşkanlığı" },
  // {
  //   path: "gunluk-program",
  //   icon: "bi bi-calendar4",
  //   title: "Günlük Program",
  // },
  { is_header: true, title: "İletişim" },
  {
    path: "mailto:info@kentmetre.com",
    icon: "bi bi-envelope",
    title: "E-Posta",
  },
  { is_header: true, title: "Admin" },
  {
    path: "/yonetim",
    icon: "bi bi-gem",
    title: "Yönetim",
    children: [
      { path: "/yonetim/musteriler", title: "Müşteriler" },
      { path: "/yonetim/kullanicilar", title: "Kullanıcılar" },
      {
        path: "/yonetim/log-kayitlari",
        title: "Log Kayıtları",
      },
      {
        path: "/yonetim/sorular",
        title: "Sorular",
      },
      {
        path: "cache-temizle",
        title: "Cache Temizle",
      },
    ],
  },
];

export default Menu;

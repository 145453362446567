import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer } from "react-toastify";

import { AppSettings } from "../../../config/app-settings.js";
import apiClient from "../../../ApiClient.js";

import {
  Card,
  CardBody,
  CardExpandToggler,
} from "../../../components/card/card.jsx";
import ChartBar from "../../../components/echart/ChartBar.jsx";
import ChartPie from "../../../components/echart/ChartPie.jsx";
import PageLoader from "../../../components/PageLoader.jsx";
import PageFilter from "../../../components/PageFilter.jsx";
import siyasetmetreSecimJson from "../../../json/siyasetmetre_secim.json";
import CardBinaHane from "../../../components/info-cards/CardBinaHane.jsx";
import CardMedeniDurum from "../../../components/info-cards/CardMedeniDurum.jsx";
import CardOlumSayisi from "../../../components/info-cards/CardOlumSayisi.jsx";
import CardSosyoekonomik from "../../../components/info-cards/CardSosyoekonomik.jsx";
import toastMessage from "../../../components/ToastMessage.js";

import SecimAnaliziTable2 from "./SecimAnaliziTable2.jsx";
import siyasetmetreAnalizJson from "../../../json/siyasetmetre_analiz.json";
import CardHemsehrilikGoc from "../../../components/info-cards/CardHemsehrilikGoc.jsx";
import CardVatandasSayisi from "../../../components/info-cards/CardVatandasSayisi.jsx";
import DynamicCard from "../../../components/DynamicCard.jsx";
import DinamikPage from "../../dinamik-page/index.jsx";

const $ = require("jquery");
$.DataTable = require("datatables.net");
require("datatables.net-bs5");
require("datatables.net-buttons");
require("datatables.net-buttons/js/buttons.colVis.min.js");
require("datatables.net-buttons/js/buttons.flash.min.js");
require("datatables.net-buttons/js/buttons.html5.min.js");
require("datatables.net-buttons/js/buttons.print.min.js");
require("datatables.net-buttons-bs5");
require("datatables.net-responsive");
require("datatables.net-responsive-bs5");
require("datatables.net-fixedcolumns");
require("datatables.net-fixedcolumns-bs5");

function SecimAnalizi() {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  useEffect(() => {
    if (!context?.me?.customer?.settings?.secimAnalizi) {
      navigate("/404");
    }
  }, [context]);

  // Filter
  const [cityFilter, setCityFilter] = useState(null);
  const [districtFilter, setDistrictFilter] = useState(null);
  const [neighbourhoodFilter, setNeighbourhoodFilter] = useState(null);

  // SİYASAL KİMLİK ANALİZ SONUÇLARI & KUSAKLAR
  const [siyasalKimlikAnalizData, setSiyasalKimlikAnalizData] = useState(null);
  const [kusaklarData, setKusaklarData] = useState(null);

  async function fetchGenelData(chartType) {
    setSiyasalKimlikAnalizData(null);
    setKusaklarData(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column:
              chartType === "siyasal-kimlik"
                ? [
                    {
                      key: "Siyasal Kimlik 1",
                      value: "select",
                    },
                    {
                      key: "Siyasal Kimlik 2",
                      value: "select",
                    },
                    {
                      key: "TC",
                      value: "count",
                    },
                  ]
                : chartType === "kusaklar"
                ? [
                    {
                      key: "Dogum Tarihi",
                      value: "select",
                    },
                  ]
                : [],
            condition: [
              cityFilter
                ? {
                    key: "Adres Il",
                    value: context
                      .removeTurkishCharacters(cityFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              districtFilter
                ? {
                    key: "Adres Ilce",
                    value: context
                      .removeTurkishCharacters(districtFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhoodFilter
                ? {
                    key: "Adres Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhoodFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (chartType === "siyasal-kimlik") {
        if (response.length !== 0) {
          setSiyasalKimlikAnalizData(
            response.map((item) => {
              return {
                value: parseInt(item.tc_count) / 100,
                name:
                  item["siyasal kimlik 1"] + " - " + item["siyasal kimlik 2"],
              };
            })
          );
        } else {
          setSiyasalKimlikAnalizData([]);
        }
      }

      if (chartType === "kusaklar") {
        if (response.length !== 0) {
          // Kuşak aralıklarını tanımla
          const generations = [
            { name: "EN BUYUK NESIL", start: 1910, end: 1924 },
            { name: "SESSIZ KUSAK", start: 1925, end: 1945 },
            { name: "BABY BOOMER KUSAGI", start: 1946, end: 1960 },
            { name: "X KUSAGI", start: 1961, end: 1980 },
            { name: "Y KUSAGI", start: 1981, end: 1995 },
            { name: "Z KUSAGI", start: 1996, end: 2012 },
          ];

          // Kuşak adlarının sıralamasını tanımla (bu sıralama sabit olarak tanımlanır)
          const generationOrder = [
            "EN BUYUK NESIL",
            "SESSIZ KUSAK",
            "BABY BOOMER KUSAGI",
            "Y KUSAGI",
            "X KUSAGI",
            "Z KUSAGI",
          ];

          // Yardımcı fonksiyon: yılı kuşak adını bul
          const findGeneration = (year) => {
            for (const generation of generations) {
              if (year >= generation.start && year <= generation.end) {
                return generation.name;
              }
            }
            return null;
          };

          // Kuşak sayımlarını tut
          const generationCounts = {};

          // Verilen tarihlerden yılları al ve kuşakları say
          response.forEach((item) => {
            const year = item["dogum tarihi"].split(".")[2];
            const generation = findGeneration(year);

            if (generation) {
              if (!generationCounts[generation]) {
                generationCounts[generation] = 0;
              }
              generationCounts[generation]++;
            }
          });

          // Sonuçları sıralı olarak formatla
          const result = [[], []];

          // Kuşak adlarının sıralı dizisine göre sayıları ve isimleri ekle
          generationOrder.forEach((generation) => {
            if (generationCounts[generation] !== undefined) {
              result[0].push(generationCounts[generation]);
              result[1].push(generation);
            }
          });

          setKusaklarData(result);
        } else {
          setKusaklarData([]);
        }
      }
    } catch (error) {
      console.error("Genel Data Error", error);
      setSiyasalKimlikAnalizData(null);
      setKusaklarData(null);
    }
  }

  useEffect(() => {
    if (context?.me?.customer?.settings?.secimAnalizi) {
      fetchGenelData("siyasal-kimlik");
      fetchGenelData("kusaklar");
    }
  }, [context, cityFilter, districtFilter, neighbourhoodFilter]);

  // 2. Baskanlık Seçim Tur
  const [baskanlikSecim2Data, setBaskanlikSecim2Data] = useState(null);
  const [baskanlikSecim2Tur, setBaskanlikSecim2Tur] = useState(1);

  async function fetchBaskanlikSecim() {
    setBaskanlikSecim2Data(null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 23,
          title: "-",
          type: `2-baskanlık-secim-tur-${baskanlikSecim2Tur}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: "Aday",
                value: "select",
              },
              {
                key: "Deger",
                value: "avg",
              },
            ],
            condition: [
              cityFilter
                ? {
                    key: "Il",
                    value: context
                      .removeTurkishCharacters(cityFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              districtFilter
                ? {
                    key: "Ilce",
                    value: context
                      .removeTurkishCharacters(districtFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
              neighbourhoodFilter
                ? {
                    key: "Mahalle",
                    value: context
                      .removeTurkishCharacters(neighbourhoodFilter.label)
                      .toUpperCase(),
                    operator: "like",
                    type: "and",
                  }
                : null,
            ].filter((item) => item !== null),
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.length !== 0) {
        setBaskanlikSecim2Data(
          response.map((item) => {
            return {
              value: item.deger_avg.toFixed(2),
              name: item.aday,
            };
          })
        );
      } else {
        setBaskanlikSecim2Data([]);
      }
    } catch (error) {
      console.error("2. Başkanlık Seçim Error", error);
      setBaskanlikSecim2Data(null);
    }
  }

  useEffect(() => {
    if (context?.me?.customer?.settings?.secimAnalizi) {
      fetchBaskanlikSecim();
    }
  }, [
    context,
    baskanlikSecim2Tur,
    cityFilter,
    districtFilter,
    neighbourhoodFilter,
  ]);

  // SOKAGA GORE SANDIK BASINA SECMEN SAYISI
  const [chartInfoData1, setChartInfoData1] = useState(null);
  const [chartInfoData2, setChartInfoData2] = useState(null);
  const [chartInfoData3, setChartInfoData3] = useState(null);
  const [secimAnaliziSandikNoModalData, setSecimAnaliziSandikNoModalData] =
    useState(null);

  async function getChartDataInfo(
    graphic,
    city,
    district,
    neighbourhood,
    street,
    chest
  ) {
    setChartInfoData1(null);
    setChartInfoData2(null);
    setChartInfoData3(null);

    const conditionData = [
      city
        ? {
            key: graphic === 2 ? "Sehir" : "Adres Il",
            value: context.removeTurkishCharacters(city).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      district
        ? {
            key: graphic === 2 ? "Ilce" : "Adres Ilce",
            value: context.removeTurkishCharacters(district).toUpperCase(),
            operator: "=",
            type: "and",
          }
        : null,
      neighbourhood
        ? {
            key: graphic === 2 ? "Mahalle" : "Adres Mahalle",
            value: context.removeTurkishCharacters(neighbourhood).toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
      street && graphic !== 2
        ? {
            key: "Adres Sokak",
            value: street.toUpperCase(),
            operator: "like",
            type: "and",
          }
        : null,
      {
        key: graphic === 2 ? "Alt_Kategori_2" : "Sandık No",
        value: chest,
        operator: "=",
        type: "and",
      },
    ];
    const conditionDataFiltered = conditionData.filter((item) => item !== null);

    try {
      const response = await apiClient.customPost(
        `/query/preview`,
        {
          chart: 1,
          page_id: 48,
          title: "Sandık Analizi",
          type:
            graphic === 2
              ? "siyasetmetre_secim_3"
              : `bireysel-data-customer-${context?.me?.customer?.id}`,
          description: "",
          customer: null,
          status: true,
          order: 1,
          sql: {
            column: [
              {
                key: graphic === 2 ? "Sehir" : "Adres Il",
                value: "select",
              },
              {
                key: graphic === 2 ? "Ilce" : "Adres Ilce",
                value: "select",
              },
              {
                key: graphic === 2 ? "Mahalle" : "Adres Mahalle",
                value: "select",
              },
              graphic !== 2
                ? {
                    key: "Adres Sokak",
                    value: "select",
                  }
                : null,
              graphic !== 6
                ? {
                    key: graphic === 2 ? "Alt_Kategori_2" : "Sandık No",
                    value: "select",
                  }
                : null,
              graphic === 2
                ? {
                    key: "Parametre",
                    value: "select",
                  }
                : null,
              graphic === 2
                ? {
                    key: "Deger",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Secmen Profili",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Siyasal Kimlik 1",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Memleket Il",
                    value: "select",
                  }
                : null,
              graphic !== 2 && graphic !== 6
                ? {
                    key: "Memleket Ilce",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "TC",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Ad Soyad",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Dogum Tarihi",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Telefon NO",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Bina NO",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Daire",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Tahmini 1. Derece Ailede Uyelik",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Tahmini 2. Derece Ailede Uyelik",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Sandığa Gitme Ihtimali",
                    value: "select",
                  }
                : null,
              graphic === 6
                ? {
                    key: "Sandığa Gitmeme Ihtimali",
                    value: "select",
                  }
                : null,
            ].filter((item) => item !== null),
            condition: conditionDataFiltered,
            group: [],
          },
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (graphic === 2) {
        if (response.length !== 0) {
          setChartInfoData1(
            response.map((element) => {
              return {
                name: element.parametre,
                value: element.deger,
              };
            })
          );
        } else {
          setChartInfoData1([]);
        }
      }

      if (graphic === 3) {
        if (response.length !== 0) {
          const siyasalKimlikMap = new Map();
          const siyasalKimlikOptions = [];

          response.forEach((item) => {
            const value = item["siyasal kimlik 1"];
            siyasalKimlikMap.set(value, (siyasalKimlikMap.get(value) || 0) + 1);
          });

          siyasalKimlikMap.forEach((count, value) => {
            const percentage = Math.floor((count / response.length) * 100);
            siyasalKimlikOptions.push({
              value: percentage,
              name: value,
            });
          });

          setChartInfoData2(siyasalKimlikOptions);
        } else {
          setChartInfoData2([]);
        }
      }

      if (graphic === 4) {
        if (response.length !== 0) {
          const secmenProfiliMap = new Map();
          const secmenProfiliOptions = [];

          response.forEach((item) => {
            const value = item["secmen profili"];
            secmenProfiliMap.set(value, (secmenProfiliMap.get(value) || 0) + 1);
          });

          secmenProfiliMap.forEach((count, value) => {
            const percentage = Math.floor((count / response.length) * 100);
            secmenProfiliOptions.push({
              value: percentage,
              name: value,
            });
          });

          setChartInfoData3(secmenProfiliOptions);
        } else {
          setChartInfoData3([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    if (
      context?.me?.customer?.settings?.secimAnalizi &&
      secimAnaliziSandikNoModalData
    ) {
      getChartDataInfo(
        2,
        secimAnaliziSandikNoModalData?.sehir,
        secimAnaliziSandikNoModalData?.ilce,
        secimAnaliziSandikNoModalData?.mahalle,
        secimAnaliziSandikNoModalData?.cadde_sokak,
        secimAnaliziSandikNoModalData?.alt_kategori_2
      );
      getChartDataInfo(
        3,
        secimAnaliziSandikNoModalData?.sehir,
        secimAnaliziSandikNoModalData?.ilce,
        secimAnaliziSandikNoModalData?.mahalle,
        secimAnaliziSandikNoModalData?.cadde_sokak,
        secimAnaliziSandikNoModalData?.alt_kategori_2
      );
      getChartDataInfo(
        4,
        secimAnaliziSandikNoModalData?.sehir,
        secimAnaliziSandikNoModalData?.ilce,
        secimAnaliziSandikNoModalData?.mahalle,
        secimAnaliziSandikNoModalData?.cadde_sokak,
        secimAnaliziSandikNoModalData?.alt_kategori_2
      );
    }
  }, [context, secimAnaliziSandikNoModalData]);

  return (
    context?.me?.customer?.settings?.secimAnalizi && (
      <div className="row g-4 mb-5 pb-5 zoom-item">
        <PageFilter
          setCityFilter={setCityFilter}
          setDistrictFilter={setDistrictFilter}
          setNeighbourhoodFilter={setNeighbourhoodFilter}
          street={false}
          allActive={true}
        />

        <div className="col-12">
          <div className="row g-4">
            <CardBinaHane
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
            />

            <CardMedeniDurum
              title="SOKAK BINA VE HANE ANALIZLERI"
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={2}
              type={2}
            />

            <CardVatandasSayisi
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={3}
            />

            <CardOlumSayisi
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={4}
              type={1}
            />

            <CardHemsehrilikGoc
              path="choice-analysis"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={5}
            />

            <CardSosyoekonomik
              title="Sosyoekonomik Statü Dağılımı"
              city={cityFilter?.label}
              district={districtFilter?.label}
              neighbourhood={neighbourhoodFilter?.label}
              graphic={6}
            />
          </div>
        </div>

        <div className="col-12" style={{ height: 700 }}>
          <Card className="h-100">
            <CardBody className="h-100">
              <SecimAnaliziTable2
                cityFilter={cityFilter}
                districtFilter={districtFilter}
                setSecimAnaliziSandikNoModalData={
                  setSecimAnaliziSandikNoModalData
                }
              />
            </CardBody>
          </Card>
        </div>

        <div className="col-12">
          <DynamicCard
            fullScreen={true}
            events={false}
            height={700}
            cityFilter={cityFilter}
            data={kusaklarData}
            item={{
              title: "KUSAKLARA GORE SECMEN SAYISI",
              options: {
                options: {
                  grid: {
                    bottom: "20%",
                  },
                  xAxis: [
                    {
                      data: kusaklarData && kusaklarData[1],
                      type: "category",
                      axisLabel: {
                        rotate: 45,
                        textStyle: {
                          color: "#fff",
                          fontSize: 10,
                        },
                      },
                    },
                  ],
                  yAxis: [
                    {
                      type: "value",
                      axisLabel: {
                        textStyle: {
                          color: "#fff",
                        },
                      },
                    },
                  ],
                  series: [
                    {
                      data: kusaklarData && kusaklarData[0],
                      name: null,
                      type: "bar",
                      itemStyle: {
                        color: "#3cd2a5",
                      },
                      markPoint: {
                        data: [
                          {
                            name: "Max",
                            type: "max",
                          },
                          {
                            name: "Min",
                            type: "min",
                          },
                        ],
                      },
                    },
                  ],
                  toolbox: {
                    show: true,
                    feature: {
                      restore: {
                        show: false,
                      },
                      magicType: {
                        show: true,
                        type: ["line", "bar"],
                      },
                      saveAsImage: {
                        show: false,
                      },
                    },
                    iconStyle: {
                      borderColor: "#fff",
                    },
                  },
                  tooltip: {
                    trigger: "axis",
                  },
                  darkMode: true,
                  visualMap: {
                    max: 50,
                    min: 0,
                    show: false,
                    inRange: {
                      colorLightness: [0.2, 1],
                    },
                  },
                  calculable: true,
                },
              },
            }}
          />
        </div>

        {/* <div className="col-6">
          <DynamicCard
            fullScreen={true}
            events={false}
            height={700}
            cityFilter={cityFilter}
            data={siyasalKimlikAnalizData}
            item={{
              title: "SİYASAL KİMLİK ANALİZ SONUÇLARI",
              options: {
                options: {
                  series: [
                    {
                      data: siyasalKimlikAnalizData,
                      name: null,
                      type: "pie",
                      label: {
                        textStyle: {
                          fontSize: 15,
                        },
                      },
                      radius: "50%",
                      itemStyle: {
                        color: "#3cd2a5",
                      },
                    },
                  ],
                  tooltip: {
                    trigger: "item",
                    formatter: function (params) {
                      return params.data.name + "<br /> %" + params.data.value;
                    },
                  },
                  darkMode: true,
                  legend: {
                    show: true,
                    orient: "horizontal",
                    left: "center",
                    itemGap: 20,
                    textStyle: {
                      color: "#fff",
                    },
                  },
                  visualMap: {
                    min:
                      siyasalKimlikAnalizData &&
                      siyasalKimlikAnalizData.length !== 0
                        ? Math.min(
                            ...siyasalKimlikAnalizData.map((obj) => obj.value)
                          )
                        : 0,
                    max:
                      siyasalKimlikAnalizData &&
                      siyasalKimlikAnalizData.length !== 0
                        ? Math.max(
                            ...siyasalKimlikAnalizData.map((obj) => obj.value)
                          )
                        : 0,
                    show: false,
                    inRange: {
                      colorLightness: [0.2, 1],
                    },
                  },
                },
              },
            }}
          />
        </div> */}

        <div className="col-12">
          <div className="row g-4">
            <DinamikPage
              events={false}
              removeGrid={true}
              filterPath={`${
                cityFilter ? `?where[]=Il:${cityFilter.label}` : ""
              }${
                districtFilter
                  ? `${cityFilter ? "&" : "?"}where[]=Ilce:${
                      districtFilter.label
                    }`
                  : ""
              }${
                neighbourhoodFilter
                  ? `${districtFilter ? "&" : "?"}where[]=Mahalle:${
                      neighbourhoodFilter.label
                    }`
                  : ""
              }`}
            />

            <DynamicCard
              fullScreen={true}
              events={false}
              height={600}
              cityFilter={cityFilter}
              data={baskanlikSecim2Data}
              buttons={
                <div className="d-flex justify-content-center gap-2">
                  <button
                    className={`btn btn-theme fw-bold ${
                      baskanlikSecim2Tur === 1 ? "" : "opacity-50"
                    }`}
                    onClick={() => setBaskanlikSecim2Tur(1)}
                  >
                    Tur 1
                  </button>
                  <button
                    className={`btn btn-theme fw-bold ${
                      baskanlikSecim2Tur === 2 ? "" : "opacity-50"
                    }`}
                    onClick={() => setBaskanlikSecim2Tur(2)}
                  >
                    Tur 2
                  </button>
                </div>
              }
              item={{
                title: `2. BASKANLIK SECIMI ${baskanlikSecim2Tur}. TUR`,
                options: {
                  width: { value: "col-3" },
                  options: {
                    series: [
                      {
                        data: baskanlikSecim2Data,
                        name: null,
                        type: "pie",
                        label: {
                          textStyle: {
                            fontSize: 15,
                          },
                        },
                        radius: "50%",
                        itemStyle: {
                          color: "#3cd2a5",
                        },
                      },
                    ],
                    tooltip: {
                      trigger: "item",
                      formatter: function (params) {
                        return (
                          params.data.name + "<br /> %" + params.data.value
                        );
                      },
                    },
                    darkMode: true,
                    legend: {
                      show: true,
                      orient: "horizontal",
                      left: "center",
                      itemGap: 20,
                      textStyle: {
                        color: "#fff",
                      },
                    },
                    visualMap: {
                      min:
                        baskanlikSecim2Data && baskanlikSecim2Data.length !== 0
                          ? Math.min(
                              ...baskanlikSecim2Data.map((obj) => obj.value)
                            )
                          : 0,
                      max:
                        baskanlikSecim2Data && baskanlikSecim2Data.length !== 0
                          ? Math.max(
                              ...baskanlikSecim2Data.map((obj) => obj.value)
                            )
                          : 0,
                      show: false,
                      inRange: {
                        colorLightness: [0.2, 1],
                      },
                    },
                  },
                },
              }}
            />
          </div>
        </div>

        <div
          className="modal modal-cover fade details-modal"
          id="secimAnaliziSandikNoModal"
        >
          <div
            className="modal-dialog modal-fullscreen w-auto p-3"
            style={{
              minHeight: "calc(100% - 50px)",
              height: "calc(100% - 50px)",
            }}
          >
            <div
              className="modal-content rounded py-2 px-3"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <div className="d-flex align-items-end">
                  <h3 className="modal-title mb-0">
                    Sandık No: {secimAnaliziSandikNoModalData?.alt_kategori_2}
                    <br />
                    Seçmen Sayısı:{" "}
                    {secimAnaliziSandikNoModalData?.deger || "Bilinmiyor"}
                  </h3>
                  <ol className="breadcrumb mb-0 ms-4 mb-2">
                    <li className="breadcrumb-item">
                      {secimAnaliziSandikNoModalData?.ilce || "Bilinmiyor"}
                    </li>
                    <li className="breadcrumb-item">
                      {secimAnaliziSandikNoModalData?.mahalle || "Bilinmiyor"}
                    </li>
                    <li className="breadcrumb-item">
                      {secimAnaliziSandikNoModalData?.cadde_sokak ||
                        "Bilinmiyor"}
                    </li>
                  </ol>
                </div>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div className="modal-body">
                <div className="row align-items-center h-100">
                  <div className="col-12 col-lg-12 overflow-hidden h-100">
                    <DynamicCard
                      events={false}
                      height="h-full"
                      data={chartInfoData1}
                      item={{
                        title: "SANDIK SONUCU",
                        options: {
                          options: {
                            series: [
                              {
                                data: chartInfoData1,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: "#3cd2a5",
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                              formatter: function (params) {
                                return (
                                  params.data.name +
                                  "<br /> %" +
                                  params.data.value
                                );
                              },
                            },
                            darkMode: true,
                            visualMap: {
                              min:
                                chartInfoData1 && chartInfoData1.length !== 0
                                  ? Math.min(
                                      ...chartInfoData1.map((obj) => obj.value)
                                    )
                                  : 0,
                              max:
                                chartInfoData1 && chartInfoData1.length !== 0
                                  ? Math.max(
                                      ...chartInfoData1.map((obj) => obj.value)
                                    )
                                  : 0,
                              show: false,
                              inRange: {
                                colorLightness: [0.2, 1],
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div>

                  {/* <div className="col-12 col-lg-6 overflow-hidden h-100">
                    <DynamicCard
                      events={false}
                      height="h-full"
                      data={chartInfoData2}
                      item={{
                        title: "SİYASAL KİMLİK",
                        options: {
                          options: {
                            series: [
                              {
                                data: chartInfoData2,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: "#3cd2a5",
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                              formatter: function (params) {
                                return (
                                  params.data.name +
                                  "<br /> %" +
                                  params.data.value
                                );
                              },
                            },
                            darkMode: true,
                            visualMap: {
                              min:
                                chartInfoData2 && chartInfoData2.length !== 0
                                  ? Math.min(
                                      ...chartInfoData2.map((obj) => obj.value)
                                    )
                                  : 0,
                              max:
                                chartInfoData2 && chartInfoData2.length !== 0
                                  ? Math.max(
                                      ...chartInfoData2.map((obj) => obj.value)
                                    )
                                  : 0,
                              show: false,
                              inRange: {
                                colorLightness: [0.2, 1],
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div> */}

                  {/* <div className="col-12 col-lg-4 overflow-hidden h-100">
                    <DynamicCard
                      events={false}
                      height="h-full"
                      cityFilter={cityFilter}
                      data={chartInfoData3}
                      item={{
                        title: "SEÇMEN PROFİLİ",
                        options: {
                          options: {
                            series: [
                              {
                                data: chartInfoData3,
                                name: null,
                                type: "pie",
                                label: {
                                  textStyle: {
                                    fontSize: 15,
                                  },
                                },
                                radius: "50%",
                                itemStyle: {
                                  color: "#3cd2a5",
                                },
                              },
                            ],
                            tooltip: {
                              trigger: "item",
                              formatter: function (params) {
                                return (
                                  params.data.name +
                                  "<br /> %" +
                                  params.data.value
                                );
                              },
                            },
                            darkMode: true,
                            visualMap: {
                              min:
                                chartInfoData3 && chartInfoData3.length !== 0
                                  ? Math.min(
                                      ...chartInfoData3.map((obj) => obj.value)
                                    )
                                  : 0,
                              max:
                                chartInfoData3 && chartInfoData3.length !== 0
                                  ? Math.max(
                                      ...chartInfoData3.map((obj) => obj.value)
                                    )
                                  : 0,
                              show: false,
                              inRange: {
                                colorLightness: [0.2, 1],
                              },
                            },
                          },
                        },
                      }}
                    />
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default SecimAnalizi;

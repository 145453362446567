import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";

import Select from "react-select";
import { AppSettings } from "../../config/app-settings";
import apiClient from "../../ApiClient";
import { ToastContainer } from "react-toastify";
import AnketSorulari from "./AnketSorulari";
import toastMessage from "../../components/ToastMessage";
import { Card, CardBody } from "../../components/card/card";

import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";

const center = { lat: 39.1667, lng: 35.6667 };
const defaultZoom = 5.8;
const options = {
  center,
  disableDefaultUI: true,
  zoomControl: true,
  styles: [
    {
      featureType: "all",
      elementType: "all",
      stylers: [
        { invert_lightness: true },
        { saturation: -50 },
        { lightness: 40 },
        { gamma: 0.5 },
        { hue: "#3cd2a5" },
      ],
    },
  ],
};

export default function AddReport({
  getLocation,
  currentLocation,
  imageLocation,
  mapModalData,
  surveyData,
  handleRemoveMarker,
  assigmentSelect,
  scriptLoaded,
  surveyInnerPage = false,
}) {
  const context = useContext(AppSettings);
  const navigate = useNavigate();

  // Cities Filter
  const [city, setCity] = useState(null);
  const [cityValue, setCityValue] = useState(null);

  const [district, setDistrict] = useState(null);
  const [districtLoader, setDistrictLoader] = useState(true);
  const [districtValue, setDistrictValue] = useState(null);
  const [allDistrict, setAllDistrict] = useState([]);

  const [neighborhoodValue, setNeighborhoodValue] = useState(null);
  const [neighborhoodLoader, setNeighborhoodLoader] = useState(true);
  const [allNeighborhood, setAllNeighborhood] = useState([]);

  const [streetValue, setStreetValue] = useState(null);
  const [streetLoader, setStreetLoader] = useState(true);
  const [allStreet, setAllStreet] = useState([]);

  const [buildValue, setBuildValue] = useState("");
  const [apartmentValue, setApartmentValue] = useState("");

  const [submitSurveyLoading, setSubmitSurveyLoading] = useState(false);

  const [zoomLevel, setZoomLevel] = useState(defaultZoom);
  const [mapInstance, setMapInstance] = useState(null);
  const [selectedMarker, setSelectedMarker] = useState(null);
  const [modalMapCity, setModalMapCity] = useState(null);

  // Reset
  function resetNeighborhood() {
    setNeighborhoodValue(null);
    setNeighborhoodLoader(false);
    setTimeout(() => {
      setNeighborhoodLoader(true);
    }, 500);
  }

  function resetStreet() {
    setStreetValue(null);
    setStreetLoader(false);
    setTimeout(() => {
      setStreetLoader(true);
    }, 500);
  }

  // Select
  function handleCity(e) {
    setModalMapCity(e);
    setCityValue(e);

    setDistrictLoader(false);
    setTimeout(() => {
      setDistrictLoader(true);
    }, 500);
    setDistrictValue(null);
    fetchDistrict(e.value);

    resetNeighborhood();
    fetchNeighborhood(null);

    resetStreet();
    fetchStreet(null);
  }

  function handleDistrict(e) {
    setDistrictValue(e);
    setModalMapCity(e);

    resetNeighborhood();
    fetchNeighborhood(e.value && e.value !== "" ? e.value : null);

    resetStreet();
    fetchStreet(null);
  }

  function handleNeighborhood(e) {
    setNeighborhoodValue(e);
    setModalMapCity(e);

    resetStreet();
    fetchStreet(e.value && e.value !== "" ? e.label : null);
  }

  function handleStreet(e) {
    setStreetValue(e);
  }

  // Fetchs
  async function fetchDistrict(value, name) {
    if (value) {
      const data = await context.getAllDistrict(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: "ILCE SECINIZ",
        });

        setAllDistrict(newData);
      }
    }
  }

  async function fetchNeighborhood(value) {
    if (value) {
      const data = await context.getAllNeighborhood(value);

      if (data) {
        const newData = data.slice();
        newData.unshift({
          value: "",
          label: "MAHALLE SECINIZ",
        });

        setAllNeighborhood(newData);
      }
    } else {
      setAllNeighborhood([]);
    }
  }

  async function fetchStreet(value) {
    if (value) {
      try {
        const response = await apiClient.customPost(
          `/query/preview`,
          {
            chart: 1,
            page_id: 23,
            title: "Bina Hane Sayısı",
            type: `bireysel-data-customer-${context?.me?.customer?.id}`,
            description: "",
            customer: null,
            status: true,
            order: 1,
            sql: {
              column: [{ key: "Adres Sokak", value: "select" }],
              condition: [
                {
                  key: "Adres Il",
                  value: context
                    .removeTurkishCharacters(cityValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Ilce",
                  value: context
                    .removeTurkishCharacters(districtValue?.label)
                    .toUpperCase(),
                  operator: "=",
                  type: "and",
                },
                {
                  key: "Adres Mahalle",
                  value: context.removeTurkishCharacters(value).toUpperCase(),
                  operator: "like",
                  type: "and",
                },
              ],
              group: [],
            },
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response.length !== 0) {
          const data = response
            .map((item) => {
              return {
                value: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
                label: context
                  .removeTurkishCharacters(item["adres sokak"])
                  .toUpperCase(),
              };
            })
            .sort((a, b) => a.label.localeCompare(b.label));

          const newData = data.slice();
          newData.unshift({
            value: "",
            label: "SOKAK SECINIZ",
          });

          setAllStreet(newData);
        } else {
          setAllStreet([]);
        }
      } catch (error) {
        setAllStreet(null);
      }
    } else {
      setAllStreet([]);
    }
  }

  // User
  const [me, setMe] = useState(null);

  async function getMe() {
    if (localStorage.getItem("token")) {
      try {
        const response = await apiClient.get("/me", {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setMe(response.data);
      } catch (error) {
        if (error.response.status !== 404) {
          setMe(null);
          localStorage.removeItem("token");
          localStorage.removeItem("user");
          navigate("/giris-yap");
        }
      }
    } else {
      setMe(null);
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      navigate("/giris-yap");
    }
  }

  useEffect(() => {
    getMe();
  }, []);

  useEffect(() => {
    if (me?.customer?.city) {
      setCityValue({
        value: me?.customer?.city?.id || null,
        label: me?.customer?.city?.name || null,
      });
      setCity(me?.customer?.city?.id || null);
      fetchDistrict(me?.customer?.city?.id || null);
    }

    if (me?.customer?.district) {
      setDistrictValue({
        value: me?.customer?.district?.id || null,
        label: me?.customer?.district?.name.toUpperCase() || null,
      });
      setDistrict(me?.customer?.district || null);
      fetchNeighborhood(me?.customer?.district?.id || null);
    }
  }, [me]);

  // Create Report
  const [answers, setAnswers] = useState({});
  const [currentQuestion, setCurrentQuestion] = useState(null);
  const [imageFields, setImageFields] = useState({}); // Yeni eklenen state

  function resizeImage(file, maxWidth, maxHeight, callback) {
    const img = new Image();
    const reader = new FileReader();

    reader.onload = (e) => {
      img.src = e.target.result;
    };

    img.onload = () => {
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      let width = img.width;
      let height = img.height;

      if (width > maxWidth) {
        height *= maxWidth / width;
        width = maxWidth;
      }

      if (height > maxHeight) {
        width *= maxHeight / height;
        height = maxHeight;
      }

      canvas.width = width;
      canvas.height = height;

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob((blob) => {
        callback(new File([blob], file.name, { type: file.type }));
      }, file.type);
    };

    reader.readAsDataURL(file);
  }

  async function handleImageUpload(questionId, fieldId, file) {
    const maxFileSize = 5 * 1024 * 1024; // 5MB in bytes

    if (file.size > maxFileSize) {
      toastMessage("Dosya Boyutu 5MB Fazla Olamaz", "error");
      return;
    }

    resizeImage(file, 500, 500, async (resizedFile) => {
      const formData = new FormData();
      formData.append("file", resizedFile);

      try {
        const response = await apiClient.post("/upload", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        const imageUrl = response.data.url;

        setImageFields((prevFields) => ({
          ...prevFields,
          [questionId]: (prevFields[questionId] || []).map((field) =>
            field.id === fieldId
              ? { ...field, files: [...field.files, imageUrl] }
              : field
          ),
        }));
      } catch (error) {
        console.error("Resim yükleme hatası:", error);
      }
    });
  }

  function handleAddImageField(questionId) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: [
        ...(prevFields[questionId] || []),
        {
          id: Date.now(),
          files: [],
          description: "",
          location: imageLocation,
        },
      ],
    }));
  }

  function handleRemoveImageField(questionId, fieldId) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: (prevFields[questionId] || []).filter(
        (field) => field.id !== fieldId
      ),
    }));
  }

  function handleImageChange(questionId, fieldId, event) {
    const file = event.target.files[0]; // Tek dosya seçildiğini varsayıyoruz
    if (file) {
      getLocation();
      handleImageUpload(questionId, fieldId, file);
    }
  }

  async function handleRemoveImage(questionId, fieldId, imageUrl) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: (prevFields[questionId] || []).map((field) =>
        field.id === fieldId
          ? {
              ...field,
              files: field.files.filter((file) => file !== imageUrl),
            }
          : field
      ),
    }));
  }

  function handleDescriptionChange(questionId, fieldId, description) {
    setImageFields((prevFields) => ({
      ...prevFields,
      [questionId]: (prevFields[questionId] || []).map((field) =>
        field.id === fieldId ? { ...field, description } : field
      ),
    }));
  }

  //--

  function handleCreateReport() {
    if (assigmentSelect) {
      if (!cityValue || cityValue?.value === "") {
        toastMessage("Şehir Seçmelisiniz", "error");
        return;
      }

      if (!districtValue || districtValue?.value === "") {
        toastMessage("İlçe Seçmelisiniz", "error");
        return;
      }

      if (!neighborhoodValue || neighborhoodValue?.value === "") {
        toastMessage("Mahalle Seçmelisiniz", "error");
        return;
      }

      if (!streetValue || streetValue?.value === "") {
        toastMessage("Sokak Seçmelisiniz", "error");
        return;
      }

      if (!selectedMarker) {
        toastMessage("Pin Belirleyin", "error");
        return;
      }

      document.getElementById("reportQuestionsModalButton").click();
    }
  }

  const [errors, setErrors] = useState({});

  async function handleSubmitSurvey() {
    if (assigmentSelect) {
      const newErrors = {};
      let firstErrorId = null;

      surveyData.questions.forEach((question) => {
        if (question.is_required) {
          const answer = answers[question.id];
          const isAnswered =
            question.type !== "string"
              ? answer && answer.length > 0
              : answer && answer.trim() !== "";

          if (!isAnswered) {
            newErrors[question.id] = true;
            if (!firstErrorId) {
              firstErrorId = question.id;
            }
          }
        }
      });

      setErrors(newErrors);

      if (Object.keys(newErrors).length > 0) {
        toastMessage("Lütfen zorunlu soruları cevaplayın.", "error");
        return;
      }

      // Zorunlu resim alanlarının dolu olup olmadığını kontrol et
      const allRequiredImagesUploaded = surveyData.questions.every(
        (question) => {
          if (
            question.type !== "string" &&
            question.answers.some((a) => a.is_required)
          ) {
            const selectedAnswer = answers[question.id];
            if (selectedAnswer && selectedAnswer.length > 0) {
              const requiredAnswer = question.answers.find(
                (a) => selectedAnswer.includes(a.answer) && a.is_required
              );
              if (requiredAnswer) {
                const imageData = imageFields[question.id] || [];
                return (
                  imageData.length > 0 &&
                  imageData.every((field) => field.files.length > 0)
                );
              }
            }
          }
          return true;
        }
      );

      if (!allRequiredImagesUploaded) {
        toastMessage("Lütfen resim yükleme alanlarını doldurun.", "error");
        return;
      }

      const formattedAnswers = Object.entries(answers).map(
        ([question, answer]) => ({
          question: parseInt(question),
          answer: Array.isArray(answer) ? answer.join("-") : answer,
        })
      );

      const replyResult = formattedAnswers.map((answer) => {
        const imagesData = imageFields[answer.question] || [];
        const images = imagesData.map((item) => ({
          description: item.description || "",
          list: item.files || [],
          location: imageLocation || null,
        }));

        return {
          question: answer.question,
          answer: answer.answer,
          images: images,
        };
      });

      const payload = {
        report_id: surveyData.id,
        city: cityValue?.value,
        district: districtValue?.value,
        neighbourhood: neighborhoodValue?.value,
        street: streetValue?.value,
        building: buildValue,
        independent: apartmentValue,
        location: selectedMarker,
        reply: replyResult,
      };

      setSubmitSurveyLoading(true);

      try {
        const response = await apiClient.customPost(
          `/street-report-reply`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage("Cevaplar Gönderildi", "success");
          setTimeout(() => {
            window.location.reload();
          }, 500);
        }
      } catch (error) {
        setSubmitSurveyLoading(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  const [predictions, setPredictions] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [mapCenter, setMapCenter] = useState(null);
  const inputRef = useRef(null);
  const autocompleteServiceRef = useRef(null);
  const placesServiceRef = useRef(null);

  useEffect(() => {
    if (scriptLoaded && inputRef.current) {
      autocompleteServiceRef.current =
        new window.google.maps.places.AutocompleteService();
      placesServiceRef.current = new window.google.maps.places.PlacesService(
        document.createElement("div")
      );

      const handleInputChange = () => {
        const query = inputRef.current.value;
        setInputValue(query);

        if (query.length > 2) {
          autocompleteServiceRef.current.getPlacePredictions(
            { input: query, componentRestrictions: { country: "TR" } },
            (predictions, status) => {
              if (status === window.google.maps.places.PlacesServiceStatus.OK) {
                setPredictions(predictions);
              }
            }
          );
        } else {
          setPredictions([]);
        }
      };

      inputRef.current.addEventListener("input", handleInputChange);

      return () => {
        inputRef.current.removeEventListener("input", handleInputChange);
      };
    }
  }, [scriptLoaded]);

  //--

  const [map, setMap] = useState(null);
  const polygonRef = useRef(null);

  const updateMap = useCallback(() => {
    if (map && modalMapCity && modalMapCity.value) {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
      }

      let itemGEOModalTodo;

      if (modalMapCity.value === 34) {
        itemGEOModalTodo = JSON.parse(modalMapCity.geo);
      } else {
        itemGEOModalTodo = JSON.parse(modalMapCity.geo)[0][0];
      }

      const polygonCoords = itemGEOModalTodo
        .map((coordGroup, coordGroupIndex) => {
          if (
            modalMapCity.value === 34 &&
            (coordGroupIndex === 0 || coordGroupIndex === 1)
          ) {
            return coordGroup[0].map((coord) => ({
              lat: coord[1],
              lng: coord[0],
            }));
          } else if (modalMapCity.value !== 34) {
            return { lat: coordGroup[1], lng: coordGroup[0] };
          }
        })
        .flat()
        .filter(Boolean);

      const bounds = new window.google.maps.LatLngBounds();
      polygonCoords.forEach((coord) => {
        bounds.extend(new window.google.maps.LatLng(coord.lat, coord.lng));
      });

      const modalMapCityPolygon = new window.google.maps.Polygon({
        paths: polygonCoords,
        map: map,
        fillColor: "#15202bb5",
        strokeColor: "#fff",
        strokeWeight: 1,
        clickable: false,
      });

      polygonRef.current = modalMapCityPolygon;

      map.setZoom(defaultZoom); // Varsayılan zoom seviyesini ayarla
      setTimeout(() => {
        // Fit bounds ve zoom seviyesini sabitleme
        map.fitBounds(bounds);
      }, 100);
    } else {
      if (polygonRef.current) {
        polygonRef.current.setMap(null);
        map.setZoom(defaultZoom);
      }
    }
  }, [map, modalMapCity]);

  useEffect(() => {
    updateMap();
  }, [updateMap]);

  const handleMapClick = (event) => {
    const lat = event.latLng.lat();
    const lng = event.latLng.lng();
    const newMarker = {
      lat: lat,
      lng: lng,
      zoom: zoomLevel,
    };

    setSelectedMarker(newMarker);
  };

  const handleZoomChanged = () => {
    if (map) {
      setZoomLevel(map.getZoom());
    }
  };

  return (
    <>
      <button
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#reportQuestionsModal"
        className="d-none"
        id="reportQuestionsModalButton"
      />

      {!surveyInnerPage && (
        <div className="modal modal-cover fade" id="addReportModal">
          <div
            className="modal-dialog py-4 px-sm-4"
            style={{
              maxWidth: "100%",
            }}
          >
            <div
              className="modal-content rounded"
              style={{ background: "#141b24" }}
            >
              <div className="modal-header">
                <h3 className="modal-title">Tespit Raporu Oluştur</h3>

                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  id="addReportModalClose"
                ></button>
              </div>

              <div className="modal-body">
                <div className="mb-3">
                  <div className="row g-3">
                    <div className={`col-6`}>
                      {context.allCity ? (
                        <div className="select-height">
                          <Select
                            options={context.allCity}
                            value={
                              cityValue
                                ? cityValue
                                : city &&
                                  context.allCity.find(function (item) {
                                    return item.value === city;
                                  })
                            }
                            classNamePrefix="react-select"
                            placeholder="IL SECINIZ..."
                            noOptionsMessage={() => "Seçenek Yok"}
                            onChange={(e) => handleCity(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className={`col-6`}>
                      {me?.customer?.district ? (
                        district ? (
                          districtLoader ? (
                            <div className="select-height">
                              <Select
                                options={[]}
                                value={
                                  districtValue
                                    ? districtValue
                                    : district.city === cityValue.value
                                    ? allDistrict.find(function (item) {
                                        return item.value === district.id;
                                      })
                                    : ""
                                }
                                classNamePrefix="react-select"
                                placeholder="ILCE SECINIZ..."
                                noOptionsMessage={() => "Seçenek Yok"}
                                onChange={(e) => handleDistrict(e)}
                              />
                            </div>
                          ) : (
                            <div className="spinner-border spinner-border-sm text-theme"></div>
                          )
                        ) : (
                          <div className="spinner-border spinner-border-sm text-theme"></div>
                        )
                      ) : districtLoader ? (
                        <div className="select-height">
                          <Select
                            options={allDistrict}
                            value={districtValue}
                            classNamePrefix="react-select"
                            placeholder="ILCE SECINIZ..."
                            noOptionsMessage={() => "Seçenek Yok"}
                            onChange={(e) => handleDistrict(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className={`col-6`}>
                      {neighborhoodLoader ? (
                        <div className="select-height">
                          <Select
                            options={allNeighborhood}
                            value={neighborhoodValue ? neighborhoodValue : ""}
                            classNamePrefix="react-select"
                            placeholder="MAHALLE SECINIZ..."
                            noOptionsMessage={() => "Seçenek Yok"}
                            onChange={(e) => handleNeighborhood(e)}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className={`col-6`}>
                      {streetLoader ? (
                        <div className="select-height">
                          <Select
                            options={allStreet}
                            value={streetValue ? streetValue : ""}
                            classNamePrefix="react-select"
                            noOptionsMessage={() => "Seçenek Yok"}
                            placeholder="SOKAK SECINIZ..."
                            onChange={handleStreet}
                          />
                        </div>
                      ) : (
                        <div className="spinner-border spinner-border-sm text-theme"></div>
                      )}
                    </div>

                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        style={{ height: 60 }}
                        placeholder="BINA NO"
                        value={buildValue}
                        onChange={(e) => setBuildValue(e.target.value)}
                      />
                    </div>

                    <div className="col-6">
                      <input
                        type="text"
                        className="form-control form-control-lg"
                        style={{ height: 60 }}
                        placeholder="DAIRE NO"
                        value={apartmentValue}
                        onChange={(e) => setApartmentValue(e.target.value)}
                      />
                    </div>
                  </div>
                </div>

                <div className="d-flex align-items-center justify-content-between mb-1">
                  <span className="d-block h5 fw-normal">
                    Pin eklemek için haritaya tıklayabilirsiniz
                  </span>

                  {mapModalData && (
                    <span
                      className="d-block text-danger pointer h5 fw-normal"
                      onClick={() => handleRemoveMarker()}
                    >
                      Pin Kaldır
                    </span>
                  )}
                </div>

                <div className="mb-3">
                  <GoogleMap
                    mapContainerStyle={{
                      height: 400,
                      width: "100%",
                    }}
                    options={options}
                    zoom={zoomLevel}
                    onLoad={setMap}
                    onClick={handleMapClick}
                    onZoomChanged={handleZoomChanged}
                  >
                    {selectedMarker && (
                      <Marker
                        icon={{
                          path: "M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961 85.961 0 192 0s192 85.961 192 192c0 77.413-26.97 99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0zM192 272c44.183 0 80-35.817 80-80s-35.817-80-80-80-80 35.817-80 80 35.817 80 80 80z",
                          fillColor: "#de8e00",
                          fillOpacity: 1,
                          strokeWeight: 0,
                          strokeColor: "#fff",
                          scale: 0.04,
                          size: new window.google.maps.Size(800, 800),
                          anchor: new window.google.maps.Point(200, 400),
                        }}
                        position={{
                          lat: selectedMarker.lat,
                          lng: selectedMarker.lng,
                        }}
                        map={map}
                      />
                    )}
                  </GoogleMap>
                </div>

                <hr className="my-3" />

                <div className="row g-3">
                  <div className="col-12">
                    <div className="row g-2 justify-content-end h-100">
                      <div className="col-6 h-100">
                        <button
                          type="button"
                          data-bs-dismiss="modal"
                          className="btn btn-lg btn-outline-default w-100 h-100 d-flex align-items-center justify-content-center"
                        >
                          İptal
                        </button>
                      </div>

                      <div className="col-6 h-100">
                        <button
                          type="button"
                          className="btn btn-lg btn-theme w-100 h-100 d-flex align-items-center justify-content-center"
                          onClick={() => handleCreateReport()}
                        >
                          Rapor Oluştur
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className="modal modal-cover fade" id="reportQuestionsModal">
        <div className="modal-dialog py-4 px-sm-4" style={{ maxWidth: 1600 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                <span>Araştırma Soruları</span>
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target={
                    surveyInnerPage ? "#kisiAnketiModal" : "#addReportModal"
                  }
                  className="btn btn-dark btn-lg ms-3"
                >
                  Geri Dön
                </button>
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-toggle="modal"
                data-bs-target={
                  surveyInnerPage ? "#kisiAnketiModal" : "#addReportModal"
                }
              ></button>
            </div>

            <div className="modal-body">
              <h4>Anket Adı: {surveyData?.name}</h4>

              <hr />

              <AnketSorulari
                setCurrentQuestion={setCurrentQuestion}
                setAnswers={setAnswers}
                data={surveyData}
                errors={errors}
                setErrors={setErrors}
              />

              <hr className="my-3" />

              <div className="row g-3">
                <div className="col-12">
                  <div className="row g-2 justify-content-end h-100">
                    <div className="col-6 h-100">
                      <button
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target={
                          surveyInnerPage
                            ? "#kisiAnketiModal"
                            : "#addReportModal"
                        }
                        className="btn btn-lg btn-outline-default w-100 h-100 d-flex align-items-center justify-content-center"
                      >
                        İptal
                      </button>
                    </div>

                    <div className="col-6 h-100">
                      <button
                        type="button"
                        onClick={() => handleSubmitSurvey()}
                        className="btn btn-lg btn-outline-theme w-100 h-100 d-flex align-items-center justify-content-center"
                      >
                        Kaydet
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {submitSurveyLoading && (
          <div
            className="position-fixed top-0 start-0 end-0 bottom-0 d-flex align-items-center justify-content-center"
            style={{ zIndex: 99, background: "rgb(13 20 18 / 85%)" }}
          >
            <div className="spinner-border text-theme"></div>
          </div>
        )}
      </div>

      <div className="modal modal-cover fade" id="imageUploadModal">
        <div
          className="modal-dialog py-4 px-sm-4"
          style={{
            maxWidth: "100%",
          }}
        >
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header border-bottom">
              <h3 className="modal-title">
                Resim Yükle
                <br />
                Soru #{currentQuestion?.question}
              </h3>

              <button
                type="button"
                className="btn btn-dark btn-lg"
                data-bs-toggle="modal"
                data-bs-target="#reportQuestionsModal"
              >
                Sorulara Dön
              </button>
            </div>

            <div className="modal-body">
              <button
                type="button"
                className="btn btn-lg btn-outline-theme mb-3"
                onClick={() => handleAddImageField(currentQuestion?.id)}
              >
                Resim Alanı Ekle
              </button>

              {(imageFields[currentQuestion?.id] || []).map((field, index) => (
                <Card key={field.id} className="mb-3">
                  <CardBody>
                    <div className="border-bottom pb-3 mb-3">
                      <div className="row align-items-center">
                        <div className="col-6">
                          <span className="fw-normal h5">
                            {index + 1}. Alan
                          </span>
                        </div>

                        <div className="col-6 text-end">
                          <button
                            type="button"
                            className="btn btn-lg btn-danger"
                            onClick={() =>
                              handleRemoveImageField(
                                currentQuestion?.id,
                                field.id
                              )
                            }
                          >
                            Kaldır
                          </button>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6 mb-3">
                        <label className="form-label">Resim Yükle</label>
                        <input
                          type="file"
                          className="form-control form-control-lg"
                          accept="image/*"
                          onChange={(e) =>
                            handleImageChange(currentQuestion?.id, field.id, e)
                          }
                        />
                      </div>

                      <div className="col-6 mb-3">
                        <label className="form-label">Açıklama</label>
                        <input
                          type="text"
                          className="form-control form-control-lg"
                          value={field.description}
                          onChange={(e) =>
                            handleDescriptionChange(
                              currentQuestion?.id,
                              field.id,
                              e.target.value
                            )
                          }
                        />
                      </div>
                    </div>

                    {field.files.length > 0 && (
                      <div className="row g-3">
                        {field.files.map((file, fileIndex) => (
                          <div key={fileIndex} className="col-md-4">
                            <Card>
                              <CardBody className="p-1">
                                <div
                                  className="position-relative"
                                  style={{ height: 250 }}
                                >
                                  <img
                                    src={file}
                                    alt={`Uploaded ${fileIndex}`}
                                    className="card-img-top w-100 h-100 img-cover"
                                  />

                                  <button
                                    type="button"
                                    className="btn btn-danger position-absolute top-0 end-0 m-1 btn-sm"
                                    onClick={() =>
                                      handleRemoveImage(
                                        currentQuestion?.id,
                                        field.id,
                                        file
                                      )
                                    }
                                  >
                                    <i
                                      className="fas fa-trash-alt"
                                      style={{ fontSize: 14 }}
                                    ></i>
                                  </button>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        ))}
                      </div>
                    )}
                  </CardBody>
                </Card>
              ))}
            </div>
          </div>
        </div>
      </div>

      <ToastContainer pauseOnFocusLoss={false} />
    </>
  );
}

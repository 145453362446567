import React, { useState } from "react";
import Select from "react-select";

export default function DynamicTable({
  data,
  tablePage,
  setTablePage,
  setTableCondition,
  tableColumn,
}) {
  const [selectValue, setSelectValue] = useState(null);
  const [inputValue, setInputValue] = useState("");

  const handleFilter = (e) => {
    if (selectValue && inputValue) {
      setTablePage(1);
      setTableCondition({ column: selectValue.value, value: inputValue });
    }
  };

  const handleReset = (e) => {
    setTablePage(1);
    setSelectValue(null);
    setInputValue("");
    setTableCondition({ column: null, value: "" });
  };

  const handlePageChange = (page) => {
    setTablePage(page);
  };

  return (
    <div className="flex-1 overflow-hidden d-flex flex-column">
      {data ? (
        <>
          <div className="row g-3 mb-3">
            <div className="col">
              <Select
                options={tableColumn.map((column, index) => {
                  return {
                    label: column.selectedColumns
                      ? column.selectedColumns.value
                      : column.key,
                    value: column.selectedColumns
                      ? column.selectedColumns.value
                      : column.key,
                  };
                })}
                value={selectValue}
                classNamePrefix="react-select"
                placeholder="FİLTRE SECINIZ..."
                noOptionsMessage={() => "Seçenek Yok"}
                onChange={(e) => setSelectValue(e)}
              />
            </div>

            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="FİLTRE YAZINIZ..."
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
            </div>

            <div className="col w-auto flex-none">
              <button
                className="btn btn-outline-theme"
                onClick={() => handleFilter()}
              >
                BUL
              </button>
              {selectValue && inputValue && (
                <button
                  className="btn btn-danger ms-2"
                  onClick={() => handleReset()}
                >
                  Kaldır
                </button>
              )}
            </div>
          </div>

          {data.data.length !== 0 ? (
            <div className="dataTables_wrapper dt-bootstrap5 flex-1 overflow-hidden d-flex flex-column">
              <div className="dataTables_scroll flex-1 overflow-auto">
                <div className="dataTables_scrollBody table-responsive">
                  <table className="table text-nowrap w-100 dataTable">
                    <thead>
                      <tr>
                        {Object.keys(data.data[0]).map((header, index) => (
                          <th className="text-start p-3" key={index}>
                            {header.charAt(0).toUpperCase() + header.slice(1)}
                          </th>
                        ))}
                      </tr>
                    </thead>

                    <tbody>
                      {data.data.map((row, rowIndex) => (
                        <tr key={rowIndex}>
                          {Object.values(row).map((value, cellIndex) => (
                            <td key={cellIndex}>{value}</td>
                          ))}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>

              <div className="row align-items-center mt-2">
                <div className="mb-0 col-md-12">
                  <div className="dataTables_paginate paging_simple_numbers d-flex flex-wrap justify-content-center text-center mt-3">
                    <ul className="pagination mb-0">
                      <li className={`paginate_button page-item`}>
                        <button
                          type="button"
                          onClick={() => handlePageChange(tablePage - 1)}
                          className={`page-link pointer ${
                            !data.prev_page_url ? "disabled" : ""
                          }`}
                        >
                          Geri
                        </button>
                      </li>

                      {data.links.map(
                        (number, index) =>
                          index > 0 &&
                          index < data.links.length - 1 && (
                            <li
                              key={index}
                              className={`paginate_button page-item ${
                                tablePage === parseInt(number.label)
                                  ? "active"
                                  : ""
                              }`}
                            >
                              <div
                                onClick={() =>
                                  handlePageChange(parseInt(number.label))
                                }
                                className="page-link pointer"
                              >
                                {number.label}
                              </div>
                            </li>
                          )
                      )}

                      <li className={`paginate_button page-item`}>
                        <button
                          type="button"
                          onClick={() => handlePageChange(tablePage + 1)}
                          className={`page-link pointer ${
                            !data.next_page_url ? "disabled" : ""
                          }`}
                        >
                          İleri
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <span className="d-block">Analiz Verisi Eklenmemiştir</span>
          )}
        </>
      ) : (
        <div className="spinner-border text-theme"></div>
      )}
    </div>
  );
}

import React from "react";

import KentmetreMap from "../anasayfa/KentmetreMap";

export default function KentmetreHaritasi() {
  return (
    <>
      <nav>
        <div className="nav nav-tabs text-center pb-4 mb-4 gap-3">
          <a
            href="/strateji-haritasi"
            className="btn btn-lg btn-outline-theme flex-1"
          >
            Strateji Haritası
          </a>
          <a href="/kentmetre-haritasi" className="btn btn-lg btn-theme flex-1">
            Kentmetre Haritası
          </a>
        </div>
      </nav>

      <div style={{ height: "100vh" }} className="mb-5">
        <KentmetreMap tab={false} />
      </div>
    </>
  );
}

import { useRef, useState, useEffect } from "react";
import { Card, CardBody } from "../../components/card/card";

export default function AnketSorulari({
  data,
  setAnswers,
  setCurrentQuestion,
  errors,
  setErrors,
}) {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [numberValues, setNumberValues] = useState({});
  const questionRefs = useRef({});

  const handleRadioChange = (questionId, answer, isRequired) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: answer,
    }));
    setSelectedAnswers((prevSelectedAnswers) => ({
      ...prevSelectedAnswers,
      [questionId]: [answer],
    }));

    if (isRequired) {
      setNumberValues((prevValues) => ({
        ...prevValues,
        [questionId]: prevValues[questionId] || 0,
      }));
    }

    setErrors((prevErrors) => ({ ...prevErrors, [questionId]: false }));
  };

  const handleCheckboxChange = (questionId, answer, isRequired) => {
    setSelectedAnswers((prevSelectedAnswers) => {
      const currentAnswers = prevSelectedAnswers[questionId] || [];
      const newAnswers = currentAnswers.includes(answer)
        ? currentAnswers.filter((a) => a !== answer)
        : [...currentAnswers, answer];

      setAnswers((prevAnswers) => ({
        ...prevAnswers,
        [questionId]: newAnswers,
      }));

      if (isRequired) {
        setNumberValues((prevValues) => ({
          ...prevValues,
          [questionId]: prevValues[questionId] || 0,
        }));
      }

      setErrors((prevErrors) => ({ ...prevErrors, [questionId]: false }));

      return {
        ...prevSelectedAnswers,
        [questionId]: newAnswers,
      };
    });
  };

  const handleTextChange = (questionId, event) => {
    setAnswers((prevAnswers) => ({
      ...prevAnswers,
      [questionId]: event.target.value,
    }));

    setErrors((prevErrors) => ({ ...prevErrors, [questionId]: false }));
  };

  const incrementNumber = (questionId) => {
    setNumberValues((prevValues) => ({
      ...prevValues,
      [questionId]: (prevValues[questionId] || 0) + 1,
    }));
  };

  const decrementNumber = (questionId) => {
    setNumberValues((prevValues) => ({
      ...prevValues,
      [questionId]: Math.max((prevValues[questionId] || 0) - 1, 0),
    }));
  };

  useEffect(() => {
    // Boş bırakılan soruya scroll yapılması için
    if (errors) {
      const firstErrorKey = Object.keys(errors).find((key) => errors[key]);
      if (firstErrorKey) {
        questionRefs.current[firstErrorKey]?.scrollIntoView({
          behavior: "smooth",
          block: "center",
        });
      }
    }
  }, [errors]);

  return (
    data && (
      <div className="row gap-4 mb-4">
        <div className="col-12">
          <Card>
            <CardBody>
              <div className="survey-question-list">
                {data.questions.map((item, index) => (
                  <div
                    key={index}
                    className={`survey-question-list-item ${
                      errors[item.id] ? "border-danger" : ""
                    }`}
                    style={{ borderWidth: 2 }}
                    ref={(el) => (questionRefs.current[item.id] = el)}
                  >
                    <div className="list-item-header">
                      <div className="d-flex gap-2">
                        <div className="border-end pe-1 text-white">
                          #{index + 1}
                        </div>
                        <div>
                          <span className="d-block">
                            {item?.category?.name}
                          </span>
                          <span className={`d-block h4 mt-1 mb-0`}>
                            {item?.is_required && (
                              <span style={{ color: "red" }}>*</span>
                            )}
                            {item?.question}
                          </span>
                        </div>
                      </div>

                      <div>
                        {selectedAnswers[item.id]?.some(
                          (answer) =>
                            item.answers.find((a) => a.answer === answer)
                              ?.is_required
                        ) && (
                          <>
                            <button
                              type="button"
                              className="btn btn-theme w-100"
                              data-bs-toggle="modal"
                              data-bs-target="#imageUploadModal"
                              onClick={() => setCurrentQuestion(item)}
                            >
                              Resim Ekle
                            </button>

                            {selectedAnswers[item.id]?.length > 0 && (
                              <div className="d-flex align-items-center mt-2">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => decrementNumber(item.id)}
                                >
                                  -
                                </button>
                                <input
                                  type="text"
                                  className="form-control text-center mx-2"
                                  value={numberValues[item.id] || 0}
                                  readOnly
                                />
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => incrementNumber(item.id)}
                                >
                                  +
                                </button>
                              </div>
                            )}
                          </>
                        )}
                      </div>
                    </div>

                    <div>
                      {item.type !== "string" ? (
                        <div
                          className="overflow-hidden my-0"
                          style={{ borderRadius: 8 }}
                        >
                          {item.answers.map((answer, answerIndex) => {
                            const inputId = `question_${item.id}_answer_${answerIndex}`;
                            return (
                              <div
                                key={answerIndex}
                                className="form-check form-checkbox d-flex align-items-center"
                              >
                                <input
                                  id={inputId}
                                  className="form-check-input"
                                  type={item.type}
                                  name={`question_${item.id}`}
                                  value={answer.answer}
                                  onChange={() =>
                                    item.type === "radio"
                                      ? handleRadioChange(
                                          item.id,
                                          answer.answer,
                                          answer.is_required
                                        )
                                      : handleCheckboxChange(
                                          item.id,
                                          answer.answer,
                                          answer.is_required
                                        )
                                  }
                                  checked={selectedAnswers[item.id]?.includes(
                                    answer.answer
                                  )}
                                />

                                <label
                                  htmlFor={inputId}
                                  className="form-check-label py-1 ps-2 pointer"
                                >
                                  {answer.answer}
                                  <span className="h5" style={{ color: "red" }}>
                                    {answer.is_required && "*"}
                                  </span>
                                </label>
                              </div>
                            );
                          })}
                        </div>
                      ) : (
                        <textarea
                          type="text"
                          className="form-control"
                          placeholder="Cevabınızı Yazınız..."
                          style={{ resize: "none", height: 80 }}
                          onChange={(e) => handleTextChange(item.id, e)}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    )
  );
}

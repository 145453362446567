import React, { useContext, useEffect, useState } from "react";

import Select from "react-select";

import DatePicker, { registerLocale } from "react-datepicker";
import { AppSettings } from "../../config/app-settings";
import { Card, CardBody } from "../../components/card/card";
import toastMessage from "../../components/ToastMessage";
import apiClient from "../../ApiClient";
import moment from "moment";

import tr from "date-fns/locale/tr";
registerLocale("tr", tr);

const TreeItemModal = ({ item, onSelect, selectedId }) => {
  const handleChange = () => {
    onSelect(item.id);
  };

  return (
    <li className="mx-1 my-1">
      <div className="d-flex gap-2 mt-3">
        <div className="form-check form-radio">
          <input
            type="radio"
            className="form-check-input"
            id={`radio-${item.id}`}
            checked={selectedId === item.id}
            onChange={handleChange}
          />
          <label className="form-check-label" htmlFor={`radio-${item.id}`}>
            <strong>{item.name}</strong> - {item.point}
          </label>
        </div>
      </div>

      {item.children && item.children.length > 0 && (
        <ol className="my-1">
          {item.children.map((child) => (
            <TreeItemModal
              key={child.id}
              item={child}
              onSelect={onSelect}
              selectedId={selectedId}
            />
          ))}
        </ol>
      )}
    </li>
  );
};

const TreeItem = ({
  item,
  index,
  setCategoryDelete,
  setCategoryUpdate,
  createSurvey,
}) => {
  return (
    <Card className="my-3 bg-none">
      <CardBody className="p-3 bg-none">
        <div className="row align-items-center">
          <div className="col">
            <div>
              <strong className="me-2">{index + 1}.</strong>
              <strong className="text-white">{item.name}</strong> - {item.point}
            </div>
          </div>

          {createSurvey && (
            <div className="col w-auto flex-none">
              <button
                type="button"
                className="btn btn-sm btn-success me-2"
                data-bs-toggle="modal"
                data-bs-target="#createCategoryModal"
                onClick={() => setCategoryUpdate(item)}
              >
                Düzenle
              </button>
              <button
                type="button"
                className="btn btn-sm btn-danger"
                data-bs-toggle="modal"
                data-bs-target="#reportCategoryDeleteConfirmModal"
                onClick={() => setCategoryDelete(item)}
              >
                Kaldır
              </button>
            </div>
          )}
        </div>
        {item.children && item.children.length > 0 && (
          <div className="mt-3">
            {item.children.map((child, childIndex) => (
              <TreeItem
                key={child.id}
                item={child}
                index={childIndex}
                setCategoryDelete={setCategoryDelete}
                setCategoryUpdate={setCategoryUpdate}
                createSurvey={createSurvey}
              />
            ))}
          </div>
        )}
      </CardBody>
    </Card>
  );
};

const TreeView = ({
  data,
  setCategoryDelete,
  setCategoryUpdate,
  createSurvey,
}) => {
  return (
    <div className="overflow-auto">
      {data.map((item, index) => (
        <TreeItem
          key={item.id}
          item={item}
          index={index}
          setCategoryDelete={setCategoryDelete}
          setCategoryUpdate={setCategoryUpdate}
          createSurvey={createSurvey}
        />
      ))}
    </div>
  );
};

export default function RaporOlustur() {
  const context = useContext(AppSettings);

  const [raporCustomer, setRaporCustomer] = useState(null);
  const [raporAdi, setRaporAdi] = useState("");
  const [questions, setQuestions] = useState([]);
  const [reportEdit, setReportEdit] = useState(null);

  const [reportLoader, setReportLoader] = useState(false);

  const [userSelectValue, setUserSelectValue] = useState(false);
  const [userSelectData, setUserSelectData] = useState([]);
  const [raporUserSelectData, setRaporUserSelectData] = useState([]);

  const [userStartDate, setUserStartDate] = useState(new Date());
  const [userEndDate, setUserEndDate] = useState(new Date());

  const handleAddQuestion = () => {
    setQuestions([
      ...questions,
      {
        kategori: null,
        soruRequired: false,
        soruPuani: "",
        soruAdi: "",
        cevapTipi: null,
        textAnswer: "",
        textAnswerPuan: "",
        answers: [],
      },
    ]);
  };

  const handleQuestionChange = (index, key, value) => {
    const newQuestions = questions.slice();
    newQuestions[index][key] = value;
    setQuestions(newQuestions);
  };

  const handleRemoveQuestion = (questionIndex) => {
    const newQuestions = questions.slice();
    newQuestions.splice(questionIndex, 1);
    setQuestions(newQuestions);
  };

  const handleAddAnswer = (questionIndex) => {
    const newQuestions = questions.slice();
    newQuestions[questionIndex].answers.push({
      puan: "",
      cevap: "",
      is_required: false,
    });
    setQuestions(newQuestions);
  };

  const handleRemoveAnswer = (questionIndex, answerIndex) => {
    const newQuestions = questions.slice();
    newQuestions[questionIndex].answers.splice(answerIndex, 1);
    setQuestions(newQuestions);
  };

  const handleAnswerChange = (questionIndex, answerIndex, key, value) => {
    const newQuestions = questions.slice();
    newQuestions[questionIndex].answers[answerIndex][key] = value;
    setQuestions(newQuestions);
  };

  const handleSubmit = async () => {
    if (!raporCustomer) {
      toastMessage("Müşteri Seçmelisiniz", "error");
      return;
    }

    if (!raporAdi && raporAdi === "") {
      toastMessage("Anket Adı Girmelisiniz", "error");
      return;
    }

    if (questions.length === 0) {
      toastMessage("En Az Bir Soru Girmelisiniz", "error");
      return;
    }

    for (const question of questions) {
      if (
        !question.kategori ||
        !question.soruPuani ||
        !question.soruAdi ||
        !question.cevapTipi
      ) {
        toastMessage("Tüm Soru Alanlar Doldurulmalısınız", "error");
        return;
      }

      if (question.cevapTipi.value === "string") {
        if (!question.textAnswerPuan) {
          toastMessage("Metin Puanı Girmelisiniz", "error");
          return;
        }
      } else {
        if (question.answers.length === 0) {
          toastMessage("En Az Bir Cevap Girmelisiniz", "error");
          return;
        }

        for (const answer of question.answers) {
          if (!answer.puan || !answer.cevap) {
            toastMessage("Tüm Cevap Alanları Doldurulmalısınız", "error");
            return;
          }
        }
      }
    }

    const transformedData = questions.map((item) => {
      return {
        type: item.cevapTipi.value,
        question: item.soruAdi,
        is_required: item.soruRequired,
        point: parseInt(item.soruPuani, 10),
        category: item.kategori.value,
        answers:
          item.cevapTipi.value === "string"
            ? [
                {
                  answer: "-",
                  point: item.textAnswerPuan,
                  is_required: false,
                },
              ]
            : item.answers.map((answer) => ({
                answer: answer.cevap,
                point: parseFloat(answer.puan, 10),
                is_required: answer.is_required,
              })),
      };
    });

    const payload = {
      name: raporAdi,
      customer_id: raporCustomer.value,
      is_required: true,
      questions: transformedData,
    };

    setReportLoader(true);

    try {
      reportEdit
        ? await apiClient.put(`/street-report/${reportEdit[0].id}`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          })
        : await apiClient.customPost(`/street-report`, payload, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          });

      setReportLoader(false);

      toastMessage(
        `Anket ${reportEdit ? "Güncellendi" : "Oluşturuldu"}`,
        "success"
      );
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      setReportLoader(false);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  };

  const [reportDelete, setReportDelete] = useState(null);

  async function handleDeleteReport() {
    try {
      await apiClient.delete(`/street-report/${reportDelete[0].id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastMessage("Anket Silindi", "success");
      setTimeout(() => {
        window.location.reload();
      }, 100);
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (reportEdit) {
      setRaporCustomer({
        value: reportEdit[0]?.customer_id,
        label: context.allCustomerSelect.find(
          (customer) => customer.value === reportEdit[0]?.customer_id
        )?.label,
      });
      setRaporAdi(reportEdit[0]?.name);

      const editQuestionsData = reportEdit[0]?.questions.map((item) => {
        return {
          cevapTipi: {
            value: item.type,
            label:
              item.type === "checkbox"
                ? "Çoklu Cevap"
                : item.type === "radio"
                ? "Tekli Cevap"
                : item.type === "string"
                ? "Açık Uçlu Cevap"
                : "",
          },
          soruAdi: item.question,
          soruRequired: item.is_required,
          soruPuani: item.point,
          kategori: { value: item.category.id, label: item.category.name },
          textAnswer: "-",
          textAnswerPuan: item.answers[0].point
            ? parseFloat(item.answers[0].point)
            : "",
          answers:
            item.type !== "string"
              ? item.answers.map((answer) => ({
                  id: answer.id,
                  cevap: answer.answer,
                  is_required: answer.is_required,
                  puan: parseFloat(answer.point),
                }))
              : [],
        };
      });

      setQuestions(editQuestionsData);
    } else {
      setRaporCustomer(null);
      setRaporAdi("");
      setQuestions([]);
    }
  }, [reportEdit]);

  //--

  const [reportCustomerValue, setReportCustomerValue] = useState(null);
  const [reportsNameData, setReportsNameData] = useState([]);
  const [reportNameSelect, setReportNameSelect] = useState(null);
  const [reportData, setReportData] = useState([]);
  const [reportsUsersData, setReportsUsersData] = useState(null);

  const [categoryPoint, setCategoryPoint] = useState("");
  const [categoryTitle, setCategoryTitle] = useState("");

  const [categoryData, setCategoryData] = useState([]);
  const [categoryDelete, setCategoryDelete] = useState(null);
  const [categoryUpdate, setCategoryUpdate] = useState(null);
  const [categoryLoader, setCategoryLoader] = useState(false);

  const [selectedId, setSelectedId] = useState(null);

  async function handleRaporCustomerSelect(e) {
    setRaporCustomer(e);
    setRaporUserSelectData(null);

    if (e) {
      try {
        const response = await apiClient.get(`user?customer_id=${e.value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });

        setRaporUserSelectData(response.data);
      } catch (error) {
        setRaporUserSelectData([]);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleCustomerSelect(e) {
    setReportCustomerValue(e);

    if (e) {
      try {
        const response = await apiClient.get(`user?customer_id=${e.value}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        });
        setUserSelectData(response.data);
      } catch (error) {
        setUserSelectData([]);
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  useEffect(() => {
    if (context?.me?.role.id !== 1) {
      handleCustomerSelect({
        value: context?.me?.customer?.id,
        label: context?.me?.customer?.customer?.name,
      });
    }
  }, [context]);

  async function handleApplyUserReport(reportId) {
    if (userSelectValue) {
      try {
        const response = await apiClient.customPost(
          `/street-report-assigment`,
          {
            user_id: userSelectValue.value,
            report_id: reportData[0].id,
            started_at: moment(userStartDate).format("YYYY-MM-DD HH:mm:ss"),
            ended_at: moment(userEndDate).format("YYYY-MM-DD HH:mm:ss"),
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage("Kullanıcı Atandı", "success");
          setReportsUsersData(null);
          handleGetUsersReport({
            id: reportId,
          });
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleGetUsersReport(data) {
    if (data) {
      try {
        const response = await apiClient.get(
          `/street-report-assigment?report=${data.id}`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          setReportsUsersData(response.data);
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleDeleteUserReport(id, reportId) {
    try {
      await apiClient.delete(`/street-report-assigment/${id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastMessage("Kişi Kaldırıldı", "success");
      setReportsUsersData(null);
      handleGetUsersReport({
        id: reportId,
      });
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  const handleSelect = (id) => {
    setSelectedId(id);
  };

  const flattenData = (data) => {
    let result = [];

    const recurse = (items) => {
      items.forEach((item) => {
        result.push({ value: item.id, label: item.name });
        if (item.children && item.children.length > 0) {
          recurse(item.children);
        }
      });
    };

    recurse(data);
    return result;
  };

  async function handleGetReports() {
    setReportsNameData(null);

    try {
      const response = await apiClient.get(
        `/street-report?customer=${reportCustomerValue.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      if (response.data.length !== 0) {
        const formattedData = response.data.map((item) => ({
          value: item.id,
          label: item.name,
        }));

        setReportsNameData(formattedData);
      } else {
        setReportsNameData([]);
      }
    } catch (error) {
      setReportsNameData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (reportCustomerValue) {
      handleGetReports();
    }
  }, [reportCustomerValue]);

  async function handleGetShowReport() {
    setReportData(null);

    try {
      const response = await apiClient.get(
        `/street-report/${reportNameSelect.value}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setReportData([response.data]);
      handleGetUsersReport(response.data);
    } catch (error) {
      setReportData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (reportNameSelect) {
      handleGetShowReport();
    }
  }, [reportNameSelect]);

  // Category
  async function handleGetCategory() {
    setCategoryData(null);

    try {
      const response = await apiClient.get("/street-report-categories", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setCategoryData(response.data);
    } catch (error) {
      setCategoryData(null);

      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    handleGetCategory();
  }, []);

  async function handleCreateCategory() {
    setCategoryLoader(true);

    if (!categoryPoint && categoryPoint === "") {
      toastMessage("Kategori Puan Girmelisiniz", "error");
      return;
    }

    if (!categoryTitle && categoryTitle === "") {
      toastMessage("Kategori Adı Girmelisiniz", "error");
      return;
    }

    if (categoryUpdate) {
      try {
        const currentCategory = {
          name: categoryUpdate.name,
          point: categoryUpdate.point,
          parent_id: categoryUpdate.parent_id || null,
        };

        const updatedCategory = {
          name: categoryTitle,
          point: categoryPoint,
          parent_id: selectedId || null,
        };

        let payload = {};

        if (currentCategory.name !== updatedCategory.name) {
          payload.name = updatedCategory.name;
        }
        if (currentCategory.point !== updatedCategory.point) {
          payload.point = updatedCategory.point;
        }
        payload.parent_id = updatedCategory.parent_id;

        if (Object.keys(payload).length > 0) {
          await apiClient.put(
            "/street-report-categories/" + categoryUpdate.id,
            payload,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem("token")}`,
              },
            }
          );

          toastMessage(
            `Kategori ${categoryUpdate ? "Güncellendi" : "Oluşturuldu"}`,
            "success"
          );
          setTimeout(() => {
            window.location.reload();
          }, 500);
        } else {
          setCategoryLoader(false);
          toastMessage("Değişiklik Bulunamadı", "error");
        }
      } catch (error) {
        setCategoryLoader(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    } else {
      try {
        await apiClient.customPost(
          `/street-report-categories`,
          {
            name: categoryTitle,
            point: categoryPoint,
            parent_id: selectedId || null,
          },
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        toastMessage(
          `Kategori ${categoryUpdate ? "Güncellendi" : "Oluşturuldu"}`,
          "success"
        );
        setTimeout(() => {
          window.location.reload();
        }, 500);
      } catch (error) {
        setCategoryLoader(false);

        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    }
  }

  async function handleDeleteCategory() {
    try {
      await apiClient.delete(`/street-report-categories/${categoryDelete.id}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      toastMessage("Kategori Silindi", "success");
      setTimeout(() => {
        window.location.reload();
      }, 500);
    } catch (error) {
      if (error.response.data.errors) {
        Object.keys(error.response.data.errors).map((key, index) =>
          error.response.data.errors[key].map((errorMessage) =>
            toastMessage(errorMessage, "error")
          )
        );
      } else {
        toastMessage(error.response.data.message, "error");
      }
    }
  }

  useEffect(() => {
    if (categoryUpdate) {
      setCategoryPoint(categoryUpdate.point);
      setCategoryTitle(categoryUpdate.name);
      setSelectedId(categoryUpdate.parent_id);
    } else {
      setCategoryPoint("");
      setCategoryTitle("");
      setSelectedId(null);
    }
  }, [categoryUpdate]);

  // Departmanlar
  const [birimlerList, setBirimlerList] = useState(null);

  async function fetchBirimler() {
    setBirimlerList(null);

    try {
      const response = await apiClient.get(`/groups`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      });

      setBirimlerList(
        response.data.filter(
          (item) => item.customer_id === reportCustomerValue.value
        )
      );
    } catch (error) {
      setBirimlerList([]);
      console.error("Birimler", error);
    }
  }

  useEffect(() => {
    if (reportCustomerValue) {
      fetchBirimler();
    }
  }, [reportCustomerValue]);

  // Departman Assign
  async function departmanAssign(select, answer) {
    if (answer.id && select.value) {
      const payload = {
        answer_id: answer.id,
        group_id: select.value,
      };

      try {
        const response = await apiClient.customPost(
          `/groups/assignment`,
          payload,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );

        if (response) {
          toastMessage("Departman Atandı", "success");
        }
      } catch (error) {
        if (error.response.data.errors) {
          Object.keys(error.response.data.errors).map((key, index) =>
            error.response.data.errors[key].map((errorMessage) =>
              toastMessage(errorMessage, "error")
            )
          );
        } else {
          toastMessage(error.response.data.message, "error");
        }
      }
    } else {
      toastMessage("Cevap id ve departman bulunamadı", "error");
    }
  }

  return (
    <div className="row mb-5 pb-5">
      <div className="col">
        <div className="d-flex align-items-center mb-3">
          {context?.me?.customer?.settings?.createSurvey ? (
            <button
              type="button"
              className="btn btn-outline-theme me-3"
              data-bs-toggle="modal"
              data-bs-target="#createReportModal"
              onClick={() => setReportEdit(null)}
            >
              Anket Oluştur
            </button>
          ) : (
            <button
              type="button"
              className="btn btn-outline-theme me-3"
              onClick={() =>
                toastMessage(
                  "Yetkiniz bulunmamaktadır. Kentmetre teknik ekibiyle iletişime geçiniz.",
                  "error"
                )
              }
            >
              Anket Oluştur
            </button>
          )}

          {context?.me?.role.id === 1 && (
            <div className="me-3">
              {context.allCustomerSelect ? (
                <Select
                  options={context.allCustomerSelect}
                  placeholder="Müşteri Seç"
                  classNamePrefix="react-select"
                  noOptionsMessage={() => "Seçenek Yok"}
                  value={reportCustomerValue}
                  onChange={(e) => handleCustomerSelect(e)}
                />
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>
          )}

          <div>
            {reportsNameData ? (
              <Select
                options={reportsNameData}
                placeholder="Anket Seç"
                classNamePrefix="react-select"
                noOptionsMessage={() => "Seçenek Yok"}
                value={reportNameSelect}
                onChange={(e) => setReportNameSelect(e)}
              />
            ) : (
              <div className="spinner-border spinner-border-sm text-theme"></div>
            )}
          </div>
        </div>

        <div className="row">
          {reportData ? (
            reportData.length !== 0 ? (
              <div className="col-12">
                <Card>
                  <CardBody>
                    {context?.me?.customer?.settings?.createSurvey && (
                      <>
                        <div className="row g-2 align-items-center justify-content-between">
                          <div className="col-6 w-auto flex-none">
                            <div className="row g-2">
                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-success"
                                  data-bs-toggle="modal"
                                  data-bs-target="#createReportModal"
                                  onClick={() => setReportEdit(reportData)}
                                >
                                  Düzenle
                                </button>
                              </div>

                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-sm btn-danger"
                                  data-bs-toggle="modal"
                                  data-bs-target="#reportDeleteConfirmModal"
                                  onClick={() => setReportDelete(reportData)}
                                >
                                  Kaldır
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>

                        <hr />

                        <div
                          className="col-6 w-auto flex-none position-relative"
                          style={{ zIndex: 2 }}
                        >
                          <span className="d-block mb-2 text-white fw-bold">
                            Kişiye Atama
                          </span>

                          <div className="row g-2 align-items-end justify-content-between">
                            <div className="col">
                              <span className="d-block">Kişiler</span>
                              <Select
                                options={userSelectData
                                  .filter((item) => item.role.id === 5)
                                  .map((item) => {
                                    return { value: item.id, label: item.name };
                                  })}
                                placeholder="Kullanıcı Seç"
                                classNamePrefix="react-select"
                                noOptionsMessage={() => "Seçenek Yok"}
                                value={userSelectValue}
                                onChange={(e) => setUserSelectValue(e)}
                              />
                            </div>

                            <div className="col">
                              <span className="d-block">Başlangıç Tarihi</span>
                              <DatePicker
                                locale="tr"
                                className="form-control"
                                required
                                selected={userStartDate}
                                onChange={(e) => setUserStartDate(e)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Saat"
                                dateFormat="dd-MM-yyyy HH:mm"
                              />
                            </div>

                            <div className="col">
                              <span className="d-block">Bitiş Tarihi</span>
                              <DatePicker
                                locale="tr"
                                className="form-control"
                                required
                                selected={userEndDate}
                                onChange={(e) => setUserEndDate(e)}
                                showTimeSelect
                                timeFormat="HH:mm"
                                timeIntervals={15}
                                timeCaption="Saat"
                                dateFormat="dd-MM-yyyy HH:mm"
                              />
                            </div>

                            {userSelectValue && (
                              <div className="col w-auto flex-none">
                                <button
                                  type="button"
                                  className="btn btn-outline-theme"
                                  onClick={() =>
                                    handleApplyUserReport(reportData[0]?.id)
                                  }
                                >
                                  Ata
                                </button>
                                {/* <button
                                  type="button"
                                  className="btn btn-sm btn-danger ms-2"
                                  onClick={() => setUserSelectValue(null)}
                                >
                                  Kaldır
                                </button> */}
                              </div>
                            )}
                          </div>
                        </div>

                        <hr />
                      </>
                    )}

                    <div className="row align-items-center justify-content-between">
                      <div className="col-6">
                        <h4 className="mb-2">{reportData[0].name || "-"}</h4>
                        <h5>
                          Müşteri:{" "}
                          {
                            context.allCustomerSelect.find(
                              (customer) =>
                                customer.value === reportData[0].customer_id
                            )?.label
                          }
                        </h5>
                      </div>
                    </div>

                    {reportData[0].questions.length !== 0 &&
                      reportData[0].questions.map((item, index) => (
                        <Card
                          className="mt-3"
                          key={index}
                          style={{ zIndex: 1 }}
                        >
                          <CardBody>
                            <div className="border-bottom mb-3 pb-3">
                              <h5>
                                Soru Adı:{" "}
                                <span className="fw-normal opacity-90">
                                  {item.question}
                                  <span className="btn btn-dark py-0 px-2 ms-2">
                                    {item.point}
                                  </span>
                                </span>
                              </h5>
                              <h5>
                                Kategori:{" "}
                                <span className="fw-normal opacity-90">
                                  {item.category?.name}
                                  <span className="btn btn-dark py-0 px-2 ms-2">
                                    {item.category?.point}
                                  </span>
                                </span>
                              </h5>
                            </div>

                            <div className="row">
                              <div className="col-12">
                                <h6>
                                  Cevaplar -{" "}
                                  {item.type === "checkbox"
                                    ? "Çoklu Cevap"
                                    : item.type === "radio"
                                    ? "Tekli Cevap"
                                    : item.type === "string"
                                    ? "Açık Uçlu Cevap"
                                    : ""}
                                </h6>
                              </div>

                              <div className="col-12">
                                <div className="row g-2">
                                  {item.answers.length !== 0 &&
                                    item.answers.map((answer, answerIndex) => (
                                      <div
                                        className="col w-auto flex-none"
                                        key={answerIndex}
                                      >
                                        <div className="text-white btn btn-dark">
                                          {
                                            <div className="d-flex align-items-center gap-2">
                                              {item.type !== "string" && (
                                                <span>{answer.answer}</span>
                                              )}
                                              <span className="btn btn-white py-0 px-2">
                                                {answer?.point}
                                              </span>
                                            </div>
                                          }
                                        </div>
                                      </div>
                                    ))}
                                </div>
                              </div>
                            </div>
                          </CardBody>
                        </Card>
                      ))}

                    <hr />

                    <h5>Atanmış Kullanıcılar</h5>
                    {reportsUsersData ? (
                      reportsUsersData.length !== 0 ? (
                        <div className="dataTables_wrapper dt-bootstrap5">
                          <div className="dataTables_scroll">
                            <div className="dataTables_scrollBody table-responsive">
                              <table className="table text-nowrap w-100 text-center dataTable mb-0">
                                <thead>
                                  <tr>
                                    <th className="px-1 text-center">#</th>
                                    <th className="px-1 text-center">Kişi</th>
                                    <th className="px-1 text-center">
                                      Başlangıç Tarihi
                                    </th>
                                    <th className="px-1 text-center">
                                      Bitiş Tarihi
                                    </th>
                                    <th className="px-1 text-center">İşlem</th>
                                  </tr>
                                </thead>

                                <tbody>
                                  {reportsUsersData.map((item, index) => (
                                    <tr key={index}>
                                      <td>{index + 1}</td>
                                      <td>{item?.user?.name}</td>
                                      <td>
                                        {moment(item?.started_at).format(
                                          "DD MMMM YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        {moment(item?.ended_at).format(
                                          "DD MMMM YYYY HH:mm"
                                        )}
                                      </td>
                                      <td>
                                        {context?.me?.customer?.settings
                                          ?.createSurvey && (
                                          <button
                                            type="button"
                                            className="btn btn-sm btn-danger w-100"
                                            onClick={() =>
                                              handleDeleteUserReport(
                                                item?.id,
                                                item?.report_id
                                              )
                                            }
                                          >
                                            Kaldır
                                          </button>
                                        )}
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <span>Atanmış Kullanıcı Bulunamadı</span>
                      )
                    ) : (
                      <div className="spinner-border text-theme"></div>
                    )}
                  </CardBody>
                </Card>
              </div>
            ) : (
              <div className="col-12">
                <span>Bir Anket Seçin</span>
              </div>
            )
          ) : (
            <div className="col-12">
              <div className="spinner-border text-theme"></div>
            </div>
          )}
        </div>
      </div>

      {context?.me?.role?.id === 1 && (
        <div className="col-12 col-md-6">
          <div className="row">
            <div className="col-12">
              {context?.me?.customer?.settings?.createSurvey && (
                <div className="d-flex align-items-center gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-theme"
                    data-bs-toggle="modal"
                    data-bs-target="#createCategoryModal"
                    onClick={() => setCategoryUpdate(null)}
                  >
                    Kategori Oluştur
                  </button>
                </div>
              )}

              {categoryData ? (
                categoryData.length !== 0 ? (
                  <TreeView
                    data={categoryData}
                    setCategoryDelete={setCategoryDelete}
                    setCategoryUpdate={setCategoryUpdate}
                    createSurvey={context?.me?.customer?.settings?.createSurvey}
                  />
                ) : (
                  <span>Kategori Bulunamadı</span>
                )
              ) : (
                <div className="spinner-border spinner-border-sm text-theme"></div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="modal modal-cover fade" id="createCategoryModal">
        <div className="modal-dialog py-4" style={{ maxWidth: 800 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                Soru Kategori {categoryUpdate ? "Güncelle" : "Ekle"}
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex flex-column gap-3">
                  <div>
                    <span className="d-block mb-1">Puan</span>
                    <input
                      type="number"
                      className="form-control"
                      value={categoryPoint}
                      onChange={(e) => setCategoryPoint(e.target.value)}
                    />
                  </div>

                  <div>
                    <span className="d-block mb-1">Kategori Adı</span>
                    <input
                      type="text"
                      className="form-control"
                      value={categoryTitle}
                      onChange={(e) => setCategoryTitle(e.target.value)}
                    />
                  </div>

                  {categoryData && categoryData.length !== 0 && (
                    <div>
                      <div className="d-flex mb-1 gap-1">
                        <span className="d-block">Üst Kategori</span>
                        {selectedId && (
                          <span
                            className="d-block text-danger pointer"
                            onClick={() => setSelectedId(null)}
                          >
                            Kategori Kaldır
                          </span>
                        )}
                      </div>

                      <div className="overflow-auto" style={{ height: 300 }}>
                        <ol>
                          {categoryData.map((item) => (
                            <TreeItemModal
                              key={item.id}
                              item={item}
                              onSelect={handleSelect}
                              selectedId={selectedId}
                            />
                          ))}
                        </ol>
                      </div>
                    </div>
                  )}
                </div>

                {context?.me?.customer?.settings?.createSurvey && (
                  <button
                    type="button"
                    className="btn btn-theme mt-3"
                    onClick={() => handleCreateCategory()}
                    disabled={categoryLoader}
                  >
                    Kategori {categoryUpdate ? "Güncelle" : "Ekle"}
                    {categoryLoader && (
                      <div className="spinner-border spinner-border-sm text-white"></div>
                    )}
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="createReportModal">
        <div className="modal-dialog py-4" style={{ maxWidth: 1000 }}>
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                Anket {reportEdit ? "Düzenle" : "Oluştur"}
              </h3>

              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex flex-column gap-3">
                  <div>
                    <span className="d-block mb-1">Müşteri Seç</span>
                    {context.allCustomerSelect ? (
                      <Select
                        options={context.allCustomerSelect}
                        placeholder=""
                        classNamePrefix="react-select"
                        noOptionsMessage={() => "Seçenek Yok"}
                        value={raporCustomer}
                        onChange={(e) => handleRaporCustomerSelect(e)}
                      />
                    ) : (
                      <div className="spinner-border spinner-border-sm text-theme"></div>
                    )}
                  </div>

                  <div>
                    <span className="d-block mb-1">Anket Adı</span>
                    <input
                      type="text"
                      className="form-control"
                      value={raporAdi}
                      onChange={(e) => setRaporAdi(e.target.value)}
                    />
                  </div>

                  {questions.map((question, qIndex) => (
                    <div
                      key={qIndex}
                      className="mb-2 border p-3 rounded d-flex flex-column gap-3"
                    >
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="d-flex align-items-center">
                          <span>{qIndex + 1}. Soru</span>
                        </div>

                        <button
                          className="btn btn-danger"
                          onClick={() => handleRemoveQuestion(qIndex)}
                        >
                          Soru Kaldır
                        </button>
                      </div>

                      <div className="row g-4">
                        <div className="col-12">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id={`survey_question_${qIndex}_required`}
                              checked={question.soruRequired}
                              onChange={(e) =>
                                handleQuestionChange(
                                  qIndex,
                                  "soruRequired",
                                  e.target.checked
                                )
                              }
                            />
                            <label
                              className="form-check-label"
                              htmlFor={`survey_question_${qIndex}_required`}
                            >
                              Zorunlu
                            </label>
                          </div>
                        </div>

                        <div className="col-12 col-md-6">
                          <span className="d-block mb-1">Kategori</span>
                          {categoryData && (
                            <Select
                              options={flattenData(categoryData)}
                              classNamePrefix="react-select"
                              placeholder=""
                              noOptionsMessage={() => "Seçenek Yok"}
                              onChange={(e) =>
                                handleQuestionChange(qIndex, "kategori", e)
                              }
                              value={question.kategori}
                            />
                          )}
                        </div>

                        <div className="col-12 col-md-6">
                          <span className="d-block mb-1">Soru Puanı</span>
                          <input
                            type="number"
                            className="form-control"
                            value={question.soruPuani}
                            onChange={(e) =>
                              handleQuestionChange(
                                qIndex,
                                "soruPuani",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <span className="d-block mb-1">Soru Adı</span>
                          <input
                            type="text"
                            className="form-control"
                            value={question.soruAdi}
                            onChange={(e) =>
                              handleQuestionChange(
                                qIndex,
                                "soruAdi",
                                e.target.value
                              )
                            }
                          />
                        </div>

                        <div className="col-12 col-md-6">
                          <span className="d-block mb-1">Cevap Tipi</span>
                          <Select
                            options={[
                              { value: "checkbox", label: "Çoklu Cevap" },
                              { value: "radio", label: "Tekli Cevap" },
                              { value: "string", label: "Açık Uçlu Cevap" },
                            ]}
                            classNamePrefix="react-select"
                            placeholder=""
                            noOptionsMessage={() => "Seçenek Yok"}
                            onChange={(e) =>
                              handleQuestionChange(qIndex, "cevapTipi", e)
                            }
                            value={question.cevapTipi}
                          />
                        </div>
                      </div>

                      {question.cevapTipi?.value === "string" ? (
                        <div className="mt-2">
                          <span className="d-block mb-1">Metin Puanı</span>
                          <input
                            type="number"
                            className="form-control"
                            value={question.textAnswerPuan}
                            onChange={(e) =>
                              handleQuestionChange(
                                qIndex,
                                "textAnswerPuan",
                                e.target.value
                              )
                            }
                          />
                        </div>
                      ) : (
                        <>
                          {question.answers.map((answer, aIndex) => (
                            <div
                              key={aIndex}
                              className="row align-items-center mt-2"
                            >
                              <div className="col w-auto flex-none">
                                <span>{aIndex + 1}.</span>
                              </div>
                              <div className="col">
                                <input
                                  type="number"
                                  className="form-control"
                                  placeholder="Puan"
                                  value={answer.puan}
                                  onChange={(e) =>
                                    handleAnswerChange(
                                      qIndex,
                                      aIndex,
                                      "puan",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Cevap"
                                  value={answer.cevap}
                                  onChange={(e) =>
                                    handleAnswerChange(
                                      qIndex,
                                      aIndex,
                                      "cevap",
                                      e.target.value
                                    )
                                  }
                                />
                              </div>
                              <div className="col">
                                <Select
                                  options={[
                                    { value: true, label: "RESİM ZORUNLU" },
                                    {
                                      value: false,
                                      label: "RESİM ZORUNLU DEĞİL",
                                    },
                                  ]}
                                  value={[
                                    { value: true, label: "RESİM ZORUNLU" },
                                    {
                                      value: false,
                                      label: "RESİM ZORUNLU DEĞİL",
                                    },
                                  ].find(
                                    (item) => item.value === answer.is_required
                                  )}
                                  classNamePrefix="react-select"
                                  placeholder="RESİM ZORUNLU"
                                  noOptionsMessage={() => "Seçenek Yok"}
                                  onChange={(e) =>
                                    handleAnswerChange(
                                      qIndex,
                                      aIndex,
                                      "is_required",
                                      e.value
                                    )
                                  }
                                />
                              </div>
                              {reportEdit && (
                                <div className="col">
                                  {birimlerList ? (
                                    <Select
                                      options={birimlerList.map((item) => {
                                        return {
                                          value: item.id,
                                          label: item.name,
                                        };
                                      })}
                                      classNamePrefix="react-select"
                                      placeholder="Departman Seç"
                                      noOptionsMessage={() => "Seçenek Yok"}
                                      onChange={(e) =>
                                        departmanAssign(e, answer)
                                      }
                                    />
                                  ) : (
                                    <div className="spinner-border spinner-border-sm text-theme"></div>
                                  )}
                                </div>
                              )}
                              <div className="col-auto">
                                <button
                                  className="btn btn-danger"
                                  onClick={() =>
                                    handleRemoveAnswer(qIndex, aIndex)
                                  }
                                >
                                  Cevap Kaldır
                                </button>
                              </div>
                            </div>
                          ))}

                          <div>
                            <button
                              className="btn btn-theme text-black btn-lg w-100 mt-2"
                              onClick={() => handleAddAnswer(qIndex)}
                            >
                              + Cevap Ekle
                            </button>
                          </div>
                        </>
                      )}
                    </div>
                  ))}

                  <button
                    className="btn btn-dark btn-lg mb-2"
                    onClick={handleAddQuestion}
                  >
                    + Soru Ekle
                  </button>
                </div>

                <div className="d-flex gap-3 border-top pt-4 mt-3">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    className="btn btn-danger btn-lg w-100"
                  >
                    İptal
                  </button>

                  <button
                    type="button"
                    className="btn btn-theme text-black btn-lg w-100"
                    onClick={handleSubmit}
                    disabled={reportLoader}
                  >
                    {reportEdit ? "Düzenle" : "Oluştur"}
                    {reportLoader && (
                      <div className="spinner-border spinner-border-sm"></div>
                    )}
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div
        className="modal modal-cover fade"
        id="reportCategoryDeleteConfirmModal"
      >
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                Emin misiniz?
                <br />
                <span className="h5">Altındaki Tüm Kategoriler Silinir</span>
                <br />
                <span className="h5">
                  Kategori Adı: {categoryDelete && categoryDelete.name}
                </span>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                id="deleteConfirmModalClose"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={() => handleDeleteCategory()}
                  >
                    Sil
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    İptal
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="modal modal-cover fade" id="reportDeleteConfirmModal">
        <div className="modal-dialog">
          <div
            className="modal-content rounded"
            style={{ background: "#141b24" }}
          >
            <div className="modal-header">
              <h3 className="modal-title">
                Emin misiniz?
                <br />
                <span className="h5">
                  Anket Adı: {reportDelete && reportDelete[0].name}
                </span>
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
              ></button>
            </div>

            {context?.me?.customer?.settings?.createSurvey && (
              <div className="modal-body">
                <div className="d-flex gap-3">
                  <button
                    type="button"
                    className="btn btn-outline-danger w-100"
                    onClick={() => handleDeleteReport()}
                  >
                    Sil
                  </button>

                  <button
                    type="button"
                    className="btn btn-outline-theme w-100"
                    data-bs-dismiss="modal"
                  >
                    İptal
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
